//== Bloc collection Accueil
//
#block-views-collection-block-1 {
  .block-title {
    @include block-title(140px, #fff, $rouge);
  }
  .view-content {
    display: flex;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin-bottom: 10px;
  }
  .views-row {
    flex-grow: 1;
    margin: 10px 5px 0 0;
  }
  .collection-link {
    padding-left: 5px;
    padding-right: 5px;
    @include balayage-gauche($evenement, $bg-hover);
    span {
      display: block;
      height: 50px;
      min-width: 140px;
    }
  }
  .views-row:nth-child(3n+2) {
    .collection-link {
      span {
        min-width: 160px;
      }
    }
  }
  .views-row:nth-child(3n+1) {
    .collection-link {
      span {
        min-width: 190px;
      }
    }
  }
}
