//
// Bandeau
//
.header-wrapper {
  @media (min-width: $screen-sm-min) {
    display: flex;
  }
}

.header-content {
  background: rgba(1, 1, 1, 1);
  color: #fff;
  padding:10px;
  width: 400px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  .header-title {
    font-size: 1.3em;
    text-transform: uppercase;
  }
  @media (min-width: $screen-sm-min) {
    background: rgba(1, 1, 1, 0.5);
    padding: 30px;
    position: absolute;
    left: 0;
    height: 100%;
  }
  @media (min-width: $screen-md-min) {
    left: 50%;
    margin-left: -600px;
    width: 600px;
    .header-title {
      font-size: 1.6em;
      text-transform: uppercase;
    }
   /* p {
      font-size: 1.5em;
    }*/
  }
}

.header-image {
  display: block;
  overflow: hidden;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  img {
    width: 100%;
    height: auto;
  }
}
