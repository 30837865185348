
  /*not-front*/
.not-front {
  .region-content {
    @media (max-width: $m1) {
      padding: 0 10px;
    }
  }
  .custom-page-header {
    padding: 20px 10px;
    margin-bottom: 20px;
    text-align: center;
   // background: #fbfafa;
    .page-header {
      margin: 0;
      padding: 0;
      font-family: $frutiger-bold;
      font-size: 3rem;
      letter-spacing: 0;
      text-transform: uppercase;
      color: $gray-light;
      border-bottom: none;
      box-shadow: none;
      /*&::first-letter{
        font-size: 1.4em !important;
        font-weight: bold;
        color: $blue;
      }*/
      @media (max-width:$ms) {
        font-size: 2.4rem;
      }
    }
  }
}




/*page fiche auteur*/

.node-type-auteur {
  .auteurs {
    @media (max-width: $tp) {
      .dropdown-toggle:before {
        border-bottom: 8px solid #fff !important;
        bottom: 0;
        color: #fff !important;
        content: "";
        display: block;
        left: 0;
        position: absolute;
        width: 100%;
        z-index: 9999;
      }
    }
  }
  @media (max-width:$m1) {
    .container {
      max-width: 100%;
    }
    #navbar {
      .header-container {
        .searchbar-toggle.highlighted {
          background-color: $menu-2;
        }
        .region-header-mobile {
          #block-views-recherche-home-block-5 .view-filters {
            background: $menu-2;
          }
        }
      }
    }
  }
  #block-edl-auteur-edl-auteur-bonus {
    .header-bonus {
      display: none;
      background: $menu-2;
      padding: 13px 0;
      cursor: pointer;
      &.fermer-bonus {
        .title-header {
          background: url("../img/acc-blan-big-mobile.png") no-repeat 95% center transparent;
        }
      }
      .title-header {
        padding: 0 0 0 20px;
        color: #fff;
        @include font-style($frutiger-roman,
                23px,
                23px,
                0px);
        background: url("../img/acc-blan-big-mobile-up.png") no-repeat 95% center transparent;
      }
      @media (max-width:$m1) {
        display: block;
      }
    }
    @media (max-width:$m1) {
      .all-bonus {
        padding: 10px 10px 0;
      }
    }
    .media-auteur,
    .bonus-auteur {
      border-bottom: 1px solid #f3a8c7;
      margin: 0 0 10px;
      padding: 0 0 10px;
      @media (max-width: $m1) {
        border: none;
        padding: 0;
        position: relative;
      }
    }
    .titre-video,
    .lien-download a {
      margin: 8px 0 0 0;
      color: $menu-2;
      @include font-style($frutiger-light,
              16px,
              16px,
              0px);
      text-align: center;
      @media (max-width: $m1) {
        position: absolute;
        bottom: 10px;
        left: 10px;
        @include font-style($frutiger-light,
                28px,
                28px,
                0px);
        color: #fff;
      }
      @media (max-width:$ms) {
        @include font-style($frutiger-light,
                24px,
                24px,
                0px);
      }
      @media (max-width:$mmm) {
        @include font-style($frutiger-light,
                20px,
                20px,
                0px);
        bottom: 5px;
      }
    }
    .auteur-video,
    .auteur-image {
      .thumb-video,
      .thumb-video-upload,
      .image-wrapper {
        position: relative;
        .mobile,
        .img-mobile {
          display: none;
        }
        @media (max-width:$m1) {
          .mobile,
          .img-mobile {
            display: block;
            width: 100%;
            height: auto;
          }
          .desktop,
          .img-desktop {
            display: none;
          }
        }
        &:hover {
          .overlay-video-auteur,
          .overlay-image-auteur {
            @include opacity(0);
          }
        }
        span {
          @include transition(all,
                  0.3s,
                  ease,
                  0s);
          display: block;
          position: absolute;
        }
        .overlay-video-auteur,
        .overlay-image-auteur {
          width: 100%;
          height: 100%;
          left: 0;
          top: 0;
          background: url("../img/opacity-video.png") repeat 0 0 transparent;
          @include opacity(1);
        }
        .overlay-image-auteur {
          @media (max-width: $m1) {
            display: none;
          }
        }
        .picto-video-auteur {
          background: url("../img/video-icon-acteur.png") no-repeat 0 0 transparent;
          height: 42px;
          left: 42%;
          top: 28%;
          width: 42px;
          @include opacity(0.75);
          @media (max-width: $m1) {
            left: 45%;
            top: 33%;
          }
          @media (max-width:$mmm) {
            left: 41%;
            top: 22%;
          }
        }
      }
    }
    #soundcloud-player {
      iframe {
        height: 165px;
        @media (max-width: $m1) {
          height: 200px;
        }
        @media (max-width:$mmm) {
          height: 165px;
        }
      }
    }
    .containerPlayer {
      position: relative;
      height: 88px;
      z-index: 0;
      @media (max-width: $m1) {
        background: #f1f1f1;
        border: 1px solid #d6d6d6;
        height: 125px;
      }
      &:hover {
        .mejs-controls .mejs-play button {
          background-position: 0 -54px;
        }
        .mejs-container .mejs-replay-button {
          span {
            background-position: 0 -29px;
          }
        }
      }
      .mejs-controls .mejs-time-rail .mejs-time-loaded {
        background: #f2f2f2;
        @media (max-width: $m1) {
          background: #fff;
        }
      }
      h2 {
        color: $menu-2;
        @include font-style($frutiger-light,
                16px,
                16px,
                0px);
        margin: 0;
        position: absolute;
        left: 55px;
        bottom: 12px;
        @media (max-width: $m1) {
          @include font-style($frutiger-light, 28px, 28px, 0px);
          bottom: 21px;
          left: 61px;
        }
      }
      .mejs-controls div.mejs-time-rail {
        position: absolute;
        left: 48px;
        top: 38px;
        padding: 0;
        width: 240px !important;
        @media (max-width: $m1) {
          width: 78% !important;
          left: 58px;
          top: 48px;
        }
      }
      .mejs-controls .mejs-time-rail .mejs-time-total,
      .mejs-controls .mejs-time-rail .mejs-time-loaded {
        width: 240px !important;
        @media (max-width: $m1) {
          width: 100% !important;
        }
      }
      .mejs-container .mejs-controls .mejs-playpause-button {
        position: absolute;
        left: 0;
        top: 28px;
        width: 40px;
        height: 40px;
        @media (max-width: $m1) {
          left: 10px;
          top: 40px;
        }
      }
      .mejs-container .mejs-controls .mejs-time {
        left: 54px;
        position: absolute;
        top: 15px;
        @media (max-width: $m1) {
          @include font-style($frutiger-light, 20px, 20px, 0px);
          left: 60px;
          top: 22px;
          width: auto;
        }
      }
      .mejs-container .mejs-replay-button {
        position: absolute;
        left: 65px;
        top: 12px;
        width: 20px;
        height: 22px;
        @media (max-width: $m1) {
          left: 62px;
          top: 20px;
          width: 20px;
          span {
            background-position: 0 -29px;
          }
        }
        span {
          background-image: url("../img/reset-audio.png");
          background-repeat: no-repeat;
          background-position: 0 -1px;
          width: 20px;
          height: 22px;
        }
      }
      .mejs-controls button {
        background-image: url("../img/audio-auteur.png");
        background-repeat: no-repeat;
        background-position: 0 0;
        width: 40px;
        height: 40px;
      }
      .mejs-controls .mejs-play button {
        background-position: 0 0;
      }
      .mejs-controls .mejs-pause button {
        background-position: 0 -65px;
        @media (max-width: $m1) {
          background-position: 0 -54px;
        }
      }
    }
    .autre-fichier {
      @media (max-width: $m1) {
        background: #f1f1f1;
        border: 1px solid #d6d6d6;
        padding: 50px 15px 0;
        height: 190px;
      }
      @media (max-width:$ms) {
        padding: 35px 15px 0;
        height: 160px;
      }
      &:after {
        @include clearfix();
      }
      &:hover {
        .picto-pdf {
          background-position: 0 0;
        }
        .lien-pdf {
          a {
            color: #827f7f;
          }
        }
      }
      .picto-pdf {
        display: block;
        float: left;
        background-repeat: no-repeat;
        background-image: url("../img/icon-pdf.png");
        height: 50px;
        width: 41px;
        background-position: 0 -72px;
        margin: 0 10px 0 0;
        @include transition(all,
                0.3s,
                ease,
                0s);
        @media (max-width: $m1) {
          background-image: url("../img/icon-pdf-mobile.png");
          background-position: 0 0;
          height: 86px;
          width: 72px;
          margin: 0 20px 0 0;
        }
      }
      .lien-pdf {
        a {
          @include font-style($frutiger-light,
                  16px,
                  18px,
                  0px);
          color: $menu-2;
          @include transition(all,
                  0.3s,
                  ease,
                  0s);
          padding: 8px 0 0 0;
          @media (max-width: $m1) {
            @include font-style($frutiger-light, 32px, 34px, 0px);
          }
          span {
            display: block;
          }
        }
      }
    }
  }
  .main-container {
    .col-sm-3 {
      margin: 0 30px 0 0;
      @media (min-width: $tp) and (max-width: $t) {
        margin: 0 30px 0 0;
      }
      @media (max-width:$m1) {
        width: auto;
        padding: 0 20px;
        margin: 0;
      }
      #block-edl-auteur-edl-auteur-picto {
        .bloc-auteur-picto {
          position: relative;
          margin-bottom: 15px;
          .copyright {
            position: absolute;
            bottom: 6px;
            left: 6px;
            @include font-style($frutiger-light,
                    12px,
                    12px,
                    0px);
            color: #fff;
          }
        }
      }
      .well {
        margin: 0;
        padding: 0;
        @include default-disable();
        background-color: transparent;
        #block-edl-auteur-edl-auteur-picto {
          @include border-auteur();
          @media (max-width: $m1) {
            margin: 0;
            border: none;
          }
          .original-image {
            border: 1px solid #A09D9D;
            display: table;
            height: 400px;
            position: relative;
            width: 290px;
            background: #f4f4f4;
            .picto-auteur {
              display: table-cell;
              text-align: center;
              vertical-align: middle;
              img {
                max-width: 200px;
                max-height: 275px;
              }
            }
          }
          img {
            @include filter-grayscale(100%);
            @include transition(all,
                    0.8s,
                    ease,
                    0s);
            @media (max-width: $m1) {
              max-width: 300px;
            }
            &:hover {
              @include filter-grayscale(0%);
            }
          }
        }
      }
    }
    .col-sm-9 {
      width: 70%;
      @media (min-width: $tl) and (max-width: $site-size) {
        width: 65%;
      }
      @media (min-width:$tp) and (max-width:$t) {
        width: 57%;
      }
      @media (max-width:$m1) {
        width: auto;
      }
      .region-content {
        @media (max-width: $m1) {
          padding: 0;
        }
      }
      #block-system-main {
        @media (max-width: $m1) {
          padding: 0 20px;
        }
        .prenom-auteur {
          .field-item {
            @include font-style($frutiger-light,
                    42px,
                    42px,
                    0px);
            color: $noir;
            margin: 0;
            padding: 0;
            @media (max-width: $m1) {
              @include font-style($frutiger-light, 52px, 52px, 0px);
            }
          }
        }
        .nom-auteur {
          .field-item {
            @include font-style($frutiger-roman,
                    48px,
                    48px,
                    0px);
            color: $menu-2;
            margin: 0 0 12px;
            padding: 0;
            @media (max-width: $m1) {
              @include font-style($frutiger-roman, 60px, 60px, 0px);
            }
          }
        }
        .field-name-field-auteur-qualite {
          .field-item {
            padding: 0 0 7px;
            margin: 0 0 15px;
            border-bottom: 1px solid #868686;
            color: $textmaron;
            @include font-style($frutiger-roman,
                    18px,
                    18px,
                    0px);
            @media (max-width: $m1) {
              @include font-style($frutiger-roman, 24px, 24px, 0px);
            }
          }
        }
        .field-name-field-auteur-biographie .field-item,
        #biographie-complet {
          @include font-style($frutiger-light,
                  16px,
                  20px,
                  0px);
          color: $noir;
          margin: 0 0 5px 0;
          padding: 0;
          @media (max-width: $m1) {
            width: auto;
            @include font-style($frutiger-light,
                    24px,
                    28px,
                    0px);
          }
        }
        .toggle-savoir {

          @media (max-width: $m1) {
            display: none;
          }
          &.toggled {
            a {
              @include transform(rotate(180deg));
            }
          }
          a {
            text-indent: -9999px;
            display: inline-block;
            width: 20px;
            height: 12px;
            background: url("../img/accordion-bottom-auteur.png") no-repeat 0 0 transparent;
            @include transition(all,
                    0.3s,
                    ease,
                    0s);
          }
        }
        .savoir-plus-auteur {
          clear: both;
          .field-item {
            @include font-style($frutiger-light,
                    16px,
                    20px,
                    0px);
            color: $noir;
            margin: 0 0 5px 0;
            padding: 0;
          }
        }
        .content-lower {
          padding: 6px 0;
          margin: 30px 0 10px;
          border-bottom: 1px solid #d4d0ce;
          border-top: 1px solid #d4d0ce;
          position: relative;
          &:after {
            @include clearfix();
          }
          .form-suivre {
            position: absolute;
            left: 0;
            top: -42px;
            background: #e9e9e9;
            padding: 5px 10px;
            width: 220px;
            @include border-radius(5px);
            &:after {
              width: 0;
              height: 0;
              border-left: 12px solid transparent;
              border-right: 12px solid transparent;
              border-top: 12px solid #e9e9e9;
              position: absolute;
              content: "";
              bottom: -8px;
              left: 44%;
            }
            .form-type-textfield {
              float: left;
              margin: 0;
              @include placeholder-text($frutiger-light,
                      $noir,
                      12px);
              input {
                background: #fff;
                @include default-disable;
                color: $noir;
                @include border-radius(50px);
                width: 175px;
                height: 20px;
                padding: 0 12px;
                @include font-style($frutiger-light,
                        12px,
                        20px,
                        0px);
                border: 1px solid #979494;
              }
            }
            .btn {
              float: right;
              color: $color-auteur-bg;
              @include font-style($frutiger-bold,
                      12px,
                      18px,
                      0px);
              padding: 0;
              margin: 0;
              border: none;
              border-radius: 0;
              background: none;
            }
          }
          .lien-partage {
            position: absolute;
            left: -59px;
            top: -42px;
            background: #e9e9e9;
            padding: 4px 14px;
            @include border-radius(5px);
            @media (max-width: $m1) {
              left: 0;
              width: 100%;
              border-radius: 0;
              padding: 15px;
              top: -67px;
            }
            &:after {
              width: 0;
              height: 0;
              border-left: 12px solid transparent;
              border-right: 12px solid transparent;
              border-top: 12px solid #e9e9e9;
              position: absolute;
              content: "";
              bottom: -8px;
              left: 39%;
              @media (max-width: $m1) {
                left: 4px;
              }
            }
            .texte-partage {
              float: left;
              margin: 0 9px 0 0;
              @include font-style($frutiger-light,
                      14px,
                      23px,
                      0px);
              @media (max-width: $m1) {
                @include font-style($frutiger-light, 23px, 36px, 0px);
                margin: 0 20px 0 0;
              }
              color: $noir;
            }
            .addthis_sharing_toolbox {
              float: left;
              .at-svc-facebook {
                max-width: 9px;
                max-height: 23px;
                margin: 0 7px 0 0;
                background: transparent !important;
                span {
                  background-repeat: no-repeat;
                  background-image: url("../img/social-icon-partager.png");
                  background-position: 0 0;
                  width: 9px;
                  height: 23px;
                  background-size: auto auto !important;
                  @media (max-width: $m1) {
                    width: 17px;
                    height: 35px;
                    background-image: url("../img/social-icon-partager-mobile.png");
                    background-position: 0 0;
                    margin: 0 20px 0 0;
                  }
                }
              }
              .at-svc-twitter {
                max-width: 23px;
                max-height: 23px;
                margin: 0;
                background: transparent !important;
                span {
                  margin: 0;
                  background-repeat: no-repeat;
                  background-image: url("../img/social-icon-partager.png");
                  background-position: -18px 0;
                  width: 23px;
                  height: 23px;
                  background-size: auto auto !important;
                  @media (max-width: $m1) {
                    width: 31px;
                    height: 35px;
                    background-image: url("../img/social-icon-partager-mobile.png");
                    background-position: -58px 0;
                    margin: 0 20px 0 0;
                  }
                }
              }
              .at-svc-email {
                max-width: 21px;
                max-height: 23px;
                background: transparent !important;
                span {
                  background-repeat: no-repeat;
                  background-image: url("../img/social-icon-partager.png");
                  background-position: -44px 0;
                  background-size: auto auto !important;
                  width: 21px;
                  height: 23px;
                  @media (max-width: $m1) {
                    width: 30px;
                    height: 35px;
                    background-image: url("../img/social-icon-partager-mobile.png");
                    background-position: -130px 0;
                  }
                }
              }
            }
          }
          .picto-partage {
            height: 32px;
            width: 32px;
            background-repeat: no-repeat;
            background-image: url("../img/icon-partager.png");
            display: block;
            float: left;
            cursor: pointer;
            margin: 0 45px 0 0;
            &:hover,
            &.active {
              background-position: 0 -100px;
            }
            @media (max-width:$m1) {
              background-image: url("../img/icon-partager-mobile.png");
              background-position: 0 0;
              width: 31px;
              height: 50px;
              &:hover {
                background-position: 0 -59px;
              }
            }
          }
          .suivre-auteur {
            float: left;
            cursor: pointer;
            @include font-style($frutiger-roman,
                    14px,
                    14px,
                    0px);
            color: $textmaron;
            margin: 0 30px 0 0;
            padding: 8px 0 0 0;
            @media (max-width: $m1) {
              margin: 0 22px;
              @include font-style($frutiger-roman,
                      24px,
                      24px,
                      0px);
              padding: 15px 0 0 0;
            }
            @media (max-width:$ms) {
              padding: 20px 0 0 0;
              margin: 0 15px;
              @include font-style($frutiger-roman,
                      17px,
                      17px,
                      0px);
            }
            &:hover,
            &.active {
              color: $menu-2;
            }
          }
          .field-name-field-auteur-site {
            a {
              @include font-style($frutiger-roman,
                      14px,
                      14px,
                      0px);
              color: $textmaron;
              margin: 0;
              padding: 8px 0 0 0;
              display: table;
              @media (max-width: $m1) {
                @include font-style($frutiger-roman, 24px, 24px, 0px);
              }
              @media (max-width:$m1) {
                width: 100px;
                height: 40px;
                overflow: hidden;
                display: block;
                padding: 15px 0 0 0;
              }
              @media (max-width:$ms) {
                padding: 20px 0 0 0;
                @include font-style($frutiger-roman,
                        17px,
                        17px,
                        0px);
                width: 85px;
                height: 37px;
              }
              &:hover {
                color: $menu-2;
              }
            }
          }
        }
      }
      #block-views-recherche-home-block-4 {
        position: relative;
        width: 100%;
        height: 165px;
        margin: 0 0 8px;
        @media (max-width: $m1) {
          height: 213px;
        }
        .block-title {
          @include block-title(95px,
                  $menu-2,
                  #fff);
          @media (max-width: $m1) {
            width: 170px;
            font-size: 26px;
            height: 40px;
            line-height: 40px;
          }
        }
        .flex-control-nav {
          display: none;
        }
        &:hover {
          .flex-direction-nav {
            li {
              a {
                background-image: url("../img/nav-auteur-active.png");
              }
            }
          }
        }
        .flex-direction-nav {
          li {
            a {
              @include opacity(1);
              height: 29px;
              width: 12px;
              margin: 0;
              text-indent: -9999px;
              background-repeat: no-repeat;
              background-image: url("../img/nav-auteur.png");
              text-align: left;
              top: 78px;
              z-index: 1;
              @media (max-width: $m1) {
                top: 100px;
              }
              &.flex-prev {
                left: 17px;
                background-position: 0 0;
              }
              &.flex-next {
                left: 45px;
                right: auto;
                background-position: -30px 0;
              }
            }
          }
        }
        .slider-content-container {
          overflow: hidden;
          position: relative;
          z-index: 1;
          background: url("../img/actu-auteur.png") repeat 0 0 transparent;
          width: 100%;
          height: 100%;
          .homeslider-image {
            position: relative;
            width: 125px;
            overflow: hidden;
            @media (max-width: $m1) {
              width: 160px;
            }
            img {
              @include opacity(0.14);
              @include filter-grayscale(100%);
              @media (max-width: $m1) {
                width: 100%;
                height: 100%;
              }
            }
            .picto-overlay {
              &:after {
                @include gradient-auteur(70%);
                content: "";
                display: block;
                height: 100%;
                left: 0;
                position: absolute;
                top: 0;
                width: 100%;
                z-index: 2;
                @media (max-width: $m1) {
                  @include gradient-auteur(90%);
                }
              }
            }
          }
          .slider-right-wrapper {
            height: 100px;
            left: 95px;
            overflow: hidden;
            position: absolute;
            top: 50px;
            width: 575px;
            z-index: 3;
            @media (max-width: $m1) {
              width: auto;
              top: 70px;
              padding: 0 30px 0 0;
            }
            @media (min-width:$tp) and (max-width:$site-size) {
              width: 75%;
            }
            .auteurslider-title {
              color: #fff;
              @include font-style($frutiger-light,
                      22px,
                      22px,
                      0px);
              margin: 0 0 5px;
              @media (max-width: $m1) {
                @include font-style($frutiger-light, 30px, 30px, 0px);
              }
            }
            .auteurslider-date {
              span {
                color: #fff;
                @include font-style($frutiger-light,
                        14px,
                        14px,
                        0px);
                display: block;
                @media (max-width: $m1) {
                  @include font-style($frutiger-light, 18px, 18px, 0px);
                }
              }
            }
            .auteurslider-desc {
              margin: 5px 0 0;
              color: #fff;
              @include font-style($frutiger-roman,
                      14px,
                      18px,
                      0px);
              @media (max-width: $m1) {
                @include font-style($frutiger-roman, 18px, 22px, 0px);
              }
            }
          }
        }
      }
      #block-edl-auteur-edl-auteur-livres,
      #block-edl-auteur-edl-auteur-livres-mobile {
        position: relative;
        .fermer-titre-auteur {
          .titre-auteur-livres {
            background: transparent url("../img/acc-big-mobile.png") no-repeat 95% center;
          }
        }
        .titre-auteur-livres {
          position: absolute;
          left: 0;
          top: 20px;
          color: $color-auteur;
          @include font-style($frutiger-roman,
                  18px,
                  18px,
                  0px);
          @media (max-width: $m1) {
            position: relative;
            cursor: pointer;
            top: 0;
            margin: 0 0 10px;
            color: $menu-2;
            @include font-style($frutiger-roman,
                    23px,
                    23px,
                    0px);
            background: transparent url("../img/acc-big-mobile-up.png") no-repeat 95% center;
            padding: 0 60px 0 20px;
          }
        }
        .tab-content {
          #tab0 {
            .titre-head {
              display: none;
            }
          }
          #tab1 {
            .table-auteurs {
              margin: 0 0 25px;
              border-bottom: 1px solid #d4d0ce;
              .titre-head {
                margin: 0 0 6px;
                background: $color-auteur-bg;
                padding: 8px 0 8px 10px;
                color: #fff;
                @include font-style($frutiger-roman,
                        15px,
                        15px,
                        0px);
              }
              table {
                margin: 0;
                tr {
                  th {
                    padding: 5px;
                    background: $bg-balayage;
                    border: none;
                    a {
                      background-repeat: no-repeat;
                      background-image: url("../img/lastest-scroll.png");
                      background-position: 100% 5px;
                      padding: 0 25px 0 0;
                      color: #535353;
                      @include font-style($frutiger-roman,
                              14px,
                              14px,
                              0px);
                      font-weight: normal;
                      display: inline-block;
                      img {
                        display: none;
                      }
                    }
                  }
                }
                tbody {
                  tr {
                    &.even {
                      background: $bg-balayage;
                    }
                    &.odd {
                      background: #fff;
                    }
                    &:hover {
                      td {
                        background-color: $color-auteur;
                        border: none !important;
                        padding: 7px 6px;
                        color: #fff !important;
                        a {
                          color: #fff !important;
                        }
                      }
                    }
                    td {
                      padding: 7px 10px;
                      background: transparent;
                      border: none;
                      @include transition(padding,
                              0.3s,
                              ease,
                              0s);
                      .livre-image {
                        left: 0;
                        position: absolute;
                        bottom: 40px;
                        z-index: 100;
                        border: 8px solid $color-auteur;
                        @include border-radius(5px);
                        background: #fff;
                        &:after {
                          border-left: 12px solid transparent;
                          border-right: 12px solid transparent;
                          border-top: 12px solid $color-auteur;
                          bottom: -20px;
                          content: "";
                          height: 0;
                          left: 44%;
                          position: absolute;
                          width: 0;
                        }
                      }
                      &:nth-child(1) {
                        width: 370px;
                        position: relative;
                        a {
                          color: $menu-2;
                          @include font-style($frutiger-roman,
                                  14px,
                                  14px,
                                  0px);
                        }
                      }
                      &:nth-child(2) {
                        color: $textmaron;
                        @include font-style($frutiger-roman,
                                12px,
                                12px,
                                0px);
                        width: 150px;
                      }
                      &:nth-child(3) {
                        color: $textmaron;
                        @include font-style($frutiger-roman,
                                12px,
                                12px,
                                0px);
                        width: 80px;
                      }
                      &:nth-child(4) {
                        color: $textmaron;
                        @include font-style($frutiger-roman,
                                12px,
                                12px,
                                0px);
                        width: 140px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      #block-edl-auteur-edl-auteur-livres-mobile {
        .titres-livre-wrapper {
          .titres-livre-wrapper-inner {
            margin: 0 0 20px;
          }
          .titre-head {
            background: $color-auteur-bg;
            color: #fff;
            @include font-style($frutiger-light,
                    20px,
                    20px,
                    0px);
            padding: 8px 0 8px 20px;
          }
          .ui-accordion {
            &:after {
              @include clearfix();
            }
            .titre-auteur-header {
              &:after {
                @include clearfix();
              }
              border: none;
              border-radius: 0;
              margin: 0;
              padding: 0 20px;
              background-repeat: no-repeat;
              background-image: url("../img/acc-small-mobile.png");
              background-position: 95% 50%;
              @include transition(all,
                      0.3s,
                      ease,
                      0s);
              &.ui-state-active {
                background-image: url("../img/acc-small-mobile-up.png");
              }
              &.even {
                background-color: #f1f1f1;
              }
              &.odd {
                background-color: #e9e9e9;
              }
              .ui-accordion-header-icon {
                display: none;
              }
              .titre-livre {
                float: left;
                width: 65%;
                color: $menu-2;
                @include font-style($frutiger-light,
                        22px,
                        24px,
                        0px);
                padding: 8px 0;
              }
              .dispo-collection {
                float: left;
                width: 77px;
                padding: 6px 0 0 0;
                .dispo {
                  color: $textmaron;
                  @include font-style($frutiger-light,
                          14px,
                          14px,
                          0px);
                }
                .collection {
                  color: $noir;
                  @include font-style($frutiger-bold,
                          13px,
                          13px,
                          0px);
                }
              }
            }
            .ui-accordion-content {
              padding: 20px 35px 35px 20px;
              border: none;
              border-radius: 0;
              .image-mobile-auteur {
                float: left;
                margin: 0 15px 0 0;
                max-width: 200px;
                width: 46%;
                img {
                  width: 100%;
                  height: auto;
                }
              }
              .detail-mobile-auteur {
                float: left;
                width: 48%;
                .age-livre {
                  color: $menu-1;
                  @include font-style($frutiger-bold,
                          20px,
                          22px,
                          0px);
                  margin: 0 0 12px;
                }
                .theme-livre {
                  color: #7d7d7d;
                  margin: 0 0 12px;
                  @include font-style($frutiger-roman,
                          12px,
                          12px,
                          0px);
                }
                .resume-livre {
                  color: $textmaron;
                  @include font-style($frutiger-roman,
                          16px,
                          18px,
                          0px);
                }
                .lien-fiche-complet {
                  a {
                    color: $menu-2;
                    @include font-style($frutiger-light,
                            20px,
                            20px,
                            0px);
                    display: block;
                    margin: 12px 0 0 0;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}


/*page video*/

.page-recherche-video {
  #navbar {
    .header-container {
      .navbar-collapse {
        .nav {
          li {
            a.dropdown-toggle.active-trail {
              &:before {
                border-bottom: 8px solid #fff !important;
                bottom: 0;
                color: #fff !important;
                content: "";
                display: block;
                left: 0;
                position: absolute;
                width: 100%;
                z-index: 9999;
              }
              @media (min-width:$m1) {
                border: none !important;
                padding: 0 !important;
                color: #ccc4c3 !important;
              }
            }
          }
        }
      }
    }
  }
  div.breadcrumb {
    background: #b3b3b3;
    margin: 0;
    padding: 10px 0;
    @media (max-width: $m1) {
      display: block;
      padding: 20px 0 20px 20px;
    }
    ol {
      max-width: 1190px;
      width: 100%;
      margin: 0 auto;
      padding: 0;
      background: #b3b3b3;
      @media (max-width: $m1) {
        display: block;
      }
      @media (min-width:$tl) and (max-width:$site-size) {
        max-width: 1024px;
        padding: 0 10px;
      }
      @media (min-width:$tp) and (max-width:$t) {
        max-width: 768px;
        padding: 0 10px;
      }
      li,
      a {
        color: $accordion;
        @include font-style($frutiger-roman,
                13px,
                13px,
                0px);
        text-transform: uppercase;
        @media (max-width: $m1) {
          &.first {
            display: none;
          }
          color: #000;
          @include font-style($frutiger-light,
                  38px,
                  38px,
                  0px);
        }
        a {
          background: transparent url("../img/arrow-breadcrumb-video.png") no-repeat scroll 97% 2px;
        }
      }
    }
  }
  .region-content {
    background: #000;
    #block-system-main,
    #block-views-recherche-video-block-1,
    #block-views-recherche-video-block-2 {
      max-width: 1088px;
      width: 100%;
      margin: 0 auto;
      padding: 0;
      @media (min-width: $tl) and (max-width: $site-size) {
        max-width: 1020px;
      }
      @media (min-width:$tp) and (max-width:$t) {
        max-width: 725px;
      }
      @media (max-width:$m1) {
        max-width: 100%;
      }
      .view-filters {
        margin: 55px auto 45px;
        text-align: center;
        width: 412px;
        @media (max-width: $m1) {
          width: 90%;
          margin: 30px auto 50px;
        }
        .filter-title {
          color: #fff;
          text-transform: uppercase;
          @include font-style($frutiger-light,
                  22px,
                  22px,
                  0px);
          margin: 0 0 15px 0;
          @media (max-width: $m1) {
            display: none;
          }
        }
        .views-exposed-widgets {
          margin: 0;
          #edit-titre-video-wrapper {
            padding: 0;
            float: left;
            @media (max-width: $m1) {
              width: 85%;
            }
            .form-type-textfield {
              @include placeholder-text($frutiger-light,
                      $textmaron,
                      16px);
              input {
                background: #fff;
                @include default-disable;
                color: $textmaron;
                @include border-radius(5px);
                width: 370px;
                height: 40px;
                @include font-style($frutiger-light,
                        16px,
                        40px,
                        0px);
                font-style: italic;
                @media (max-width: $m1) {
                  @include border-radius(50px);
                  width: 100%;
                }
              }
            }
          }
          .views-submit-button {
            float: right;
            padding: 0;
            button {
              display: inline-block;
              width: 32px;
              height: 32px;
              background: url("../img/main-loupe.png") no-repeat 0 0 transparent;
              cursor: pointer;
              margin: 5px 0 0 0;
              border: none;
              text-indent: -9999px;
            }
          }
        }
      }
      .view-content {
        padding: 0;
        margin: 0 0 40px 0;
        max-width: 100%;
        position: relative;
        @media (max-width: $m1) {
          padding: 0 20px;
        }
        &:after {
          @include clearfix();
        }
        .liste-video-wrapper {
          position: relative;
          float: left;
          margin: 0 6px 6px 0;
          @media (max-width: $m1) {
            margin: 0 0 20px;
            float: none;
          }
          @media (min-width:$tp) and (max-width:$t) {
            width: 32%;
          }
          .picto-share {
            display: none;
            position: absolute;
            top: 15px;
            left: 20px;
            width: 25px;
            height: 39px;
            text-indent: -9999px;
            z-index: 10;
            @include transition(opacity,
                    0.4s,
                    ease,
                    0s);
            background: url("../img/icon-partager-video.png") no-repeat 0 0 transparent;
          }
          .lien-partage {
            position: absolute;
            z-index: 10;
            left: -37px;
            top: -31px;
            background: #e9e9e9;
            padding: 1px 14px;
            @include border-radius(5px);
            @media (max-width: $m1) {
              left: 0;
              width: 100%;
              border-radius: 0;
              padding: 15px;
              top: -67px;
            }
            &:after {
              width: 0;
              height: 0;
              border-left: 12px solid transparent;
              border-right: 12px solid transparent;
              border-top: 12px solid #e9e9e9;
              position: absolute;
              content: "";
              bottom: -8px;
              left: 39%;
              @media (max-width: $m1) {
                left: 19px;
              }
            }
            .texte-partage {
              float: left;
              margin: 0 9px 0 0;
              @include font-style($frutiger-light,
                      14px,
                      23px,
                      0px);
              @media (max-width: $m1) {
                @include font-style($frutiger-light, 23px, 36px, 0px);
                margin: 0 20px 0 0;
              }
              color: $noir;
            }
            .addthis_sharing_toolbox {
              float: left;
              .addthis_button_facebook {
                max-width: 9px;
                max-height: 23px;
                margin: 0 7px 0 0;
                background: transparent !important;
                float: left;
                @media (max-width: $m1) {
                  max-width: 20px;
                }
                span {
                  background-repeat: no-repeat;
                  background-image: url("../img/social-icon-partager.png");
                  background-position: 0 0;
                  background-color: transparent !important;
                  width: 9px;
                  height: 23px;
                  background-size: auto auto !important;
                  @media (max-width: $m1) {
                    width: 17px;
                    height: 35px;
                    background-image: url("../img/social-icon-partager-mobile.png");
                    background-position: 0 0;
                    margin: 0 20px 0 0;
                  }
                }
              }
              .addthis_button_twitter {
                max-width: 23px;
                max-height: 23px;
                margin: 0;
                background: transparent !important;
                float: left;
                @media (max-width: $m1) {
                  max-width: 40px;
                }
                span {
                  margin: 0;
                  background-repeat: no-repeat;
                  background-image: url("../img/social-icon-partager.png");
                  background-position: -18px 0;
                  background-color: transparent !important;
                  width: 23px;
                  height: 23px;
                  background-size: auto auto !important;
                  @media (max-width: $m1) {
                    width: 31px;
                    height: 35px;
                    background-image: url("../img/social-icon-partager-mobile.png");
                    background-position: -58px 0;
                    margin: 0 20px 0 0;
                  }
                }
              }
              .addthis_button_email {
                max-width: 21px;
                max-height: 23px;
                background: transparent !important;
                float: left;
                span {
                  background-repeat: no-repeat;
                  background-image: url("../img/social-icon-partager.png");
                  background-position: -44px 0;
                  background-size: auto auto !important;
                  background-color: transparent !important;
                  width: 21px;
                  height: 23px;
                  @media (max-width: $m1) {
                    width: 30px;
                    height: 35px;
                    background-image: url("../img/social-icon-partager-mobile.png");
                    background-position: -130px 0;
                  }
                }
              }
            }
          }
          &.last {
            margin: 0 0 6px 0;
            @media (min-width: $tp) and (max-width: $t) {
              margin: 0 6px 6px 0;
            }
          }
          &:hover {
            .first_wrapper {
              .hover-video {
                @include opacity(1);
              }
            }
          }
          &.active-picto-share {
            .picto-share {
              display: block;
              @include opacity(0);
              &.ouvert {
                @include opacity(1);
              }
            }
            &:hover {
              .picto-share {
                @include opacity(1);
              }
            }
          }
          .first_wrapper {
            position: relative;
            z-index: 3;
            .thumb {
              img {
                @media (min-width: $tl) and (max-width: $site-size) {
                  width: 335px;
                }
                @media (min-width:$tp) and (max-width:$t) {
                  width: 100%;
                  height: 175px;
                }
                @media (max-width:$m1) {
                  width: 100%;
                  height: auto;
                }
              }
            }
            .hover-video {
              height: 100%;
              left: 0;
              position: absolute;
              top: 0;
              @include opacity(0);
              @include transition(all,
                      0.3s,
                      ease,
                      0s);
              width: 100%;
              z-index: 1;
              background: url("../img/video-overlay-hover.png") repeat 0 0 transparent;
              a.lien-video {
                display: block;
                width: 100%;
                height: 100%;
                .hover-video-wrapper {
                  display: table;
                  padding: 0 15px 20px 25px;
                  width: 100%;
                  height: 205px;
                  @media (max-width: $m1) {
                    display: block;
                    height: 100%;
                    position: relative;
                    width: 100%;
                  }
                  @media (min-width:$tp) and (max-width:$t) {
                    height: 177px;
                  }
                  @media (min-width:$tl) and (max-width:$site-size) {
                    height: 200px;
                  }
                  .hover-video-inner {
                    vertical-align: bottom;
                    display: table-cell;
                    @media (max-width: $m1) {
                      display: block;
                      height: 100%;
                      left: 20px;
                      position: absolute;
                      top: 60%;
                      vertical-align: bottom;
                      width: 90%;
                    }
                    .title {
                      float: left;
                      color: #fff;
                      @include font-style($frutiger-roman,
                              14px,
                              16px,
                              0px);
                      width: 80%;
                      margin: 5px 0 0 0;
                      @media (max-width: $m1) {
                        width: 75%;
                        @include font-style($frutiger-roman,
                                28px,
                                30px,
                                0px);
                      }
                      @media (min-width:$tp) and (max-width:$t) {
                        width: 68%;
                      }
                      @media (max-width:$ms) {
                        @include font-style($frutiger-roman,
                                18px,
                                20px,
                                0px);
                      }
                    }
                    .play-video {
                      float: right;
                      display: block;
                      width: 57px;
                      height: 57px;
                      background: url("../img/play-page-video.png") no-repeat 0 0 transparent;
                    }
                  }
                }
              }
            }
          }
          #ajax-video-grand {
            @media (max-width: $m1) {
              width: 100%;
              height: 100%;
            }
            @media (min-width:$tl) and (max-width:$site-size) {
              height: 406px;
              width: 676px;
            }
            @media (min-width:$tp) and (max-width:$t) {
              height: 355px;
              width: 470px;
            }
            position: absolute;
            top: 0;
            width: 716px;
            height: 407px;
            display: none;
            z-index: 3;
            @include transition(all,
                    0.3s,
                    ease,
                    0s);
            &.overlayed {
              display: block;
              z-index: 10;
            }
            iframe,
            video {
              width: 100%;
              height: 100%;
              border: none;
            }
          }
          &.col-video1,
          &.col-video2 {
            #ajax-video-grand {
              left: 0;
            }
          }
          &.col-video3 {
            #ajax-video-grand {
              right: 0;
            }
            .picto-share {
              left: -337px;
              @media (min-width: $tp) and (max-width: $t) {
                left: -220px;
              }
            }
            .lien-partage {
              left: -395px;
              @media (min-width: $tp) and (max-width: $t) {
                left: -278px;
              }
            }
          }
        }
      }
    }
  }
  .videos-overlay {
    background: #000;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 4;
    left: 0;
    top: 0;
    @include opacity(0.7);
    display: none;
    @include transition (all,
            0.3s,
            ease,
            0s);
    cursor: pointer;
    &.overlayed {
      display: block;
    }
  }
}


/*page video end */


/*list image views*/

.auteur-detail-wrapper {
  border-bottom: 1px solid #ad7086;
  padding: 0 0 12px 0;
  margin: 0 0 12px;
  &:hover {
    .detail-livre {
      .titre-livre {
        a {
          color: #827f7f;
        }
      }
    }
  }
  .image-livre {
    margin: 0 10px 0 0;
    float: left;
    width: 140px;
    img {
      width: 100%;
      height: auto;
    }
  }
  .detail-livre {
    float: left;
    width: 682px;
    @media (min-width: $tl) and (max-width: $site-size) {
      width: 500px;
    }
    @media (min-width:$tp) and (max-width:$t) {
      width: 275px;
    }
    .titre-livre {
      a {
        color: $menu-2;
        @include font-style($frutiger-roman,
                18px,
                18px,
                0px);
        margin: 4px 0 8px;
      }
    }
    .detail-collection-livre {
      border-top: 1px solid #e5e5e5;
      border-bottom: 1px solid #e5e5e5;
      padding: 8px 0;
      margin: 0 0 4px;
      color: $textmaron;
      @include font-style($frutiger-roman,
              14px,
              14px,
              0.5px);
      span {
        color: $noir;
        @include font-style($frutiger-bold,
                14px,
                14px,
                0px);
        text-transform: uppercase;
      }
    }
    .theme-livre {
      margin: 0;
      color: $textmaron;
      @include font-style($frutiger-roman,
              12px,
              12px,
              0px);
    }
    .description-livre {
      color: $textmaron;
      @include font-style($frutiger-roman,
              12px,
              12px,
              0px);
      width: 475px;
      @media (min-width: $tp) and (max-width: $t) {
        width: 100%;
      }
      p {
        margin: 12px 0 5px;
        color: $noir;
        @include font-style($frutiger-roman,
                13px,
                16px,
                0px);
      }
    }
  }
}


/*node type livre*/

.node-type-livre {
  .catalogue {
    @media (max-width: $tp) {
      .dropdown-toggle:before {
        border-bottom: 8px solid #fff !important;
        bottom: 0;
        color: #fff !important;
        content: "";
        display: block;
        left: 0;
        position: absolute;
        width: 100%;
        z-index: 9999;
      }
    }
  }
  @media (max-width:$m1) {
    .view-evenement-livre .views-row {
      margin: 0;
    }
    .ui-accordion-content {
      padding: 10px 20px !important;
      .type-event {
        @include font-style($frutiger-bold,
                20px,
                20px,
                0px);
        margin: 0 0 12px;
      }
      .title-event {
        @include font-style($frutiger-light,
                22px,
                22px,
                0px);
        margin: 0 0 4px;
      }
      .date-event {
        color: $textmaron;
        @include font-style($frutiger-bold,
                14px,
                14px,
                0px);
        margin: 0 0 4px;
        span {
          color: $textmaron;
          @include font-style($frutiger-bold,
                  14px,
                  14px,
                  0px);
        }
      }
      .link-event {
        a {
          margin: 0 0 25px;
          @include font-style($frutiger-light,
                  14px,
                  14px,
                  0px);
          display: block;
          &:hover {
            color: #000;
          }
        }
      }
      .collection-inner-wrapper {
        .current-collection {
          border-bottom: 1px solid #adacac;
          padding: 12px 0;
          span {
            color: #a09d9d;
            @include font-style($frutiger-light,
                    16px,
                    16px,
                    0);
            text-transform: uppercase;
            &.livre-col {
              @include font-style($frutiger-light,
                      22px,
                      22px,
                      0);
              display: block;
              text-transform: none;
              padding: 0 0 2px;
              color: #000;
            }
          }
        }
      }
      .format-container {
        .formats {
          border-bottom: 1px solid #000;
          padding: 12px 0;
          &:after {
            @include clearfix();
          }
          .format-name {
            color: #000;
            @include font-style($frutiger-light,
                    22px,
                    22px,
                    0);
            margin: 0 0 3px;
            clear: both;
          }
          .format-left {
            float: left;
            margin: 0 10px 0 0;
          }
          .format-right {
            float: left;
            width: 50%;
            color: #a09d9d;
            @include font-style($frutiger-roman,
                    16px,
                    16px,
                    0);
            text-transform: uppercase;
            .format-desc {
              text-transform: none;
              margin: 20px 0 0 0;
              line-height: 18px;
            }
          }
        }
      }
    }
    .region-content {
      padding: 0;
      /*#block-edl-livre-edl-livre-infos {
        padding: 0 20px;
      }*/
      #block-edl-livre-edl-livre-details-col {
        padding: 0 0 20px;
        .ui-accordion-header {
          background-color: #3774a9;
        }
      }
    }
    #parallax-1 {
      padding: 0 0 20px;
      background: #fff;
      .ui-accordion-header {
        background-color: #434343;
      }
    }
    #parallax-2 {
      padding: 0;
      background: #fff;
      #block-views-presse-blog-block-1 {
        padding: 0 0 20px;
        .ui-accordion-header {
          background-color: #535353;
        }
      }
      #block-views-autres-lecture-block-1,
      #block-views-fausse-serie-block-1 {
        .ui-accordion-header {
          background-color: #727272;
        }
      }
    }
    .ui-accordion-header {
      border: none;
      border-radius: 0;
      @include font-style($frutiger-roman,
              24px,
              24px,
              0px);
      color: #fff;
      text-transform: uppercase;
      background-image: url("../img/acc-blan-big-mobile-up.png");
      background-position: 95% center;
      background-repeat: no-repeat;
      padding: 10px 0 10px 20px;
    }
    .ui-accordion-content {
      height: auto !important;
      padding: 0;
      margin: 0;
      border: none;
      border-radius: 0;
      .mob-slides-livres {
        position: relative;
        .flex-viewport {
          margin: 0 auto;
          text-align: center;
          padding: 20px 0 0 0;
          .views-field-title a,
          .titre-livre a {
            @include font-style($frutiger-light,
                    24px,
                    24px,
                    0px);
            margin: 5px 0 0 0;
          }
          .views-field-field-livre-collection,
          .titre-collection {
            @include font-style($frutiger-light,
                    20px,
                    20px,
                    0px);
            color: #a09d9d;
          }
        }
        .flex-control-nav {
          display: none;
        }
        .flex-direction-nav {
          li {
            a {
              background-image: url("../img/fleche-collection.png");
              background-repeat: no-repeat;
              display: block;
              width: 58px;
              height: 64px;
              margin: 0;
              @include opacity(1);
              text-indent: -9999px;
              top: 33%;
              text-align: left;
              z-index: 1;
              &.flex-prev {
                left: -20px;
                background-position: 30px 10px !important;
              }
              &.flex-next {
                right: -20px;
                background-position: -19px 10PX !important;
              }
            }
          }
        }
      }
    }
  }
  .main-container {
    margin: 20px auto;
    @media (max-width: $m1) {
      margin: 0 auto;
    }
    /*aside.col-sm-3 {
      width: 340px;
      margin: 0 60px 0 0;
      @media (max-width: $m1) {
        width: 100%;
        margin: 0;
      }
      @media (min-width:$tp) and (max-width:$t) {
        margin: 0 30px 0 0;
      }
    }*/
    .col-sm-9 {
      .breadcrumb {
        display: none;
      }
      #block-edl-livre-edl-livre-infos {
        .les-auteurs-livre {
          p {
            margin: 0 0 5px;
            a {
              color: $noir;
              @include font-style($frutiger-bold,
                      14px,
                      14px,
                      0px);
              display: inline-block;
            }
            span {
              color: $noir;
              @include font-style($frutiger-bold,
                      14px,
                      14px,
                      0px);
            }
            &.class-1,
            &.class-2 {
              a {
                @include font-style($frutiger-bold,
                        20px,
                        20px,
                        0px);
                &:hover {
                  color: #827f7f;
                }
              }
            }
          }
        }

        .edl-accordion {
          @media (max-width: $m1) {
            display: none;
          }
          .ui-accordion-header {
            background: #fff;
            border-bottom: 1px solid #e5e5e5;
            border-left: none;
            border-right: none;
            border-top: none;
            border-radius: 0;
            box-shadow: none;
            padding: 7px 0;
            margin: 0;
            clear: both;
            &.ui-state-focus {
              outline: none !important;
              border: none !important;
            }
            &.ui-state-active {
              border-bottom: none;
              span {
                display: none;
                &.format-name,
                &.ui-icon {
                  display: block;
                  line-height: 19px;
                }
              }
              .ui-icon {
                @include transform(rotate(180deg));
              }
            }
            span {
              color: #6d6d6d;
              @include font-style($frutiger-roman,
                      12px,
                      12px,
                      0px);
              margin: 0;
              &.format-name {
                color: #000;
              }
            }
            .ui-icon {
              background-image: url("../img/arrow-fiche-livre.png");
              background-repeat: no-repeat;
              width: 19px;
              height: 10px;
              display: block;
              right: 0;
              left: auto;
              top: 42%;
            }
          }
          .ui-accordion-content {
            padding: 0;
            height: auto !important;
            overflow: visible;
            @include default-disable();
            .format-wrapper {
              &:after {
                @include clearfix();
              }
              .format-left {
                float: left;
                margin: 0 10px 0 0;
                @media (min-width: $tp) and (max-width: $t) {
                  float: none;
                  margin: 0 10px 0 0;
                }
              }
              .format-right {
                float: left;
                width: 535px;
                @media (min-width: $tl) and (max-width: $site-size) {
                  width: 400px;
                }
                @media (min-width:$tp) and (max-width:$t) {
                  float: none;
                  width: auto;
                }
                div {
                  color: #6d6d6d;
                  @include font-style($frutiger-roman,
                          12px,
                          14px,
                          0px);
                  text-transform: uppercase;
                  &.format-desc {
                    text-transform: none;
                    margin: 15px 0 10px 0;
                    width: 460px;
                    line-height: 17px;
                    @media (min-width: $tl) and (max-width: $site-size) {
                      width: 370px;
                    }
                    @media (min-width:$tp) and (max-width:$t) {
                      width: auto;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  #parallax-1 {
    background: #eae8ea;
    @media (max-width: $m1) {
      background: #fff;
    }
    .region-parallax-1 {
      width: 100%;
      max-width: 890px;
      margin: 0 auto;
      @media (min-width: $tp) and (max-width: $t) {
        max-width: 95%;
      }
      .block-title {
        @include font-style($frutiger-light,
                50px,
                50px,
                0px);
        margin: 30px 0 50px -5px;
        text-transform: uppercase;
      }
      .view-evenement-livre {
        .views-row {
          margin: 0 0 40px;
          @media (max-width: $m1) {
            margin: 0;
            border-bottom: 1px solid #a09d9d;
            padding: 10px 0;
          }
          &:after {
            @include clearfix();
          }
          .left-content {
            float: left;
            width: 190px;
            margin: 0 60px 0 0;
            position: relative;
            img {
              @include filter-grayscale(100%);
            }
            .event-overlay {
              display: block;
              width: 100%;
              height: 100%;
              position: absolute;
              left: 0;
              top: 0;
              @include opacity(0.39);
              &:hover {
                background-color: transparent;
              }
            }
          }
          .right-content {
            float: left;
            width: 560px;
            @media (min-width: $tp) and (max-width: $t) {
              width: 455px;
            }
            .type-event {
              @include font-style($frutiger-bold,
                      20px,
                      20px,
                      0px);
              margin: 0 0 12px;
            }
            .title-event {
              @include font-style($frutiger-light,
                      22px,
                      22px,
                      0px);
              margin: 0 0 4px;
            }
            .date-event {
              color: $textmaron;
              @include font-style($frutiger-bold,
                      14px,
                      14px,
                      0px);
              margin: 0 0 4px;
              span {
                color: $textmaron;
                @include font-style($frutiger-bold,
                        14px,
                        14px,
                        0px);
              }
            }
            .link-event {
              a {
                margin: 0 0 25px;
                @include font-style($frutiger-light,
                        14px,
                        14px,
                        0px);
                display: block;
                &:hover {
                  color: #000;
                }
              }
            }
          }
        }
      }
    }
  }
  #parallax-2 {
    @media (max-width: $m1) {
      background: #fff;
    }
    .region-parallax-2 {
      width: 100%;
      max-width: 890px;
      margin: 0 auto;
      @media (min-width: $tp) and (max-width: $t) {
        max-width: 95%;
      }
      .block-title {
        @include font-style($frutiger-light,
                50px,
                50px,
                0px);
        margin: 30px 0 50px -5px;
        text-transform: uppercase;
      }
      .view-presse-blog {
        .views-row {
          margin: 0 0 30px;
          &:after {
            @include clearfix();
          }
          .left-content {
            float: left;
            width: 190px;
            margin: 0 60px 0 10px;
            position: relative;
            @media (max-width: $m1) {
              width: 100%;
              margin: 0 0 5px 0;
              .press-img {
                img {
                  width: 100%;
                  height: 100%;
                }
              }
            }
            &:hover {
              .press-cat {
                background-color: #ded9d8;
              }
              .press-overlay {
                display: block;
              }
            }
            .press-overlay {
              display: none;
              width: 100%;
              height: 100%;
              position: absolute;
              left: 0;
              top: 0;
              @include opacity(0.51);
            }
            .press-cat {
              position: absolute;
              top: 10px;
              left: -10px;
              @include opacity(0.9);
              color: #fff;
              text-transform: uppercase;
              @include font-style($frutiger-bold,
                      22px,
                      22px,
                      0px);
              padding: 7px 0 7px 10px;
              min-width: 100px;
              @include transition(all,
                      0.3s,
                      ease,
                      0s);
              z-index: 1;
              @media (max-width: $m1) {
                left: 0;
                top: auto;
                bottom: 0;
              }
            }
          }
          .right-content {
            float: left;
            width: 560px;
            @media (min-width: $tp) and (max-width: $t) {
              width: 455px;
            }
            @media (max-width:$m1) {
              float: none;
              width: auto;
            }
            .press-media {
              @include font-style($frutiger-light,
                      28px,
                      28px,
                      0px);
              margin: 0 0 4px;
              &:hover {
                color: $textmaron;
              }
            }
            .press-auteur {
              @include font-style($frutiger-light,
                      14px,
                      14px,
                      0px);
              margin: 0 0 6px;
              color: $textmaron;
            }
            .press-titre {
              @include font-style($frutiger-light,
                      22px,
                      22px,
                      0px);
              margin: 0 0 3px;
              color: $noir;
            }
            .press-texte {
              p {
                @include font-style($frutiger-light,
                        14px,
                        16px,
                        0px);
                margin: 0 0 6px;
                color: $noir;
              }
            }
            .press-link {
              a {
                @include font-style($frutiger-bold,
                        12px,
                        12px,
                        0px);
                &:hover {
                  color: $textmaron;
                }
              }
            }
          }
        }
      }
    }
  }
  #parallax-3 {
    display: none;
    .region-parallax-3 {
      background: $bg-balayage;
      margin: 0 auto;
      width: 100%;
      max-width: 1115px;
      @media (min-width: $tl) and (max-width: $site-size) {
        max-width: 1024px;
        padding: 0 10px;
      }
      @media (min-width:$tp) and (max-width:$t) {
        max-width: 768px;
        padding: 0 10px;
      }
      .block-title {
        @include font-style($frutiger-light,
                50px,
                50px,
                0px);
        margin: 40px 0 40px 108px;
        text-transform: uppercase;
      }
      .view-bonus-fiche-livre {
        background: #fff;
        margin: 0 50px 100px 50px;
        @media (min-width: $tp) and (max-width: $t) {
          margin: 0 10px 50px;
          padding: 0 10px;
        }
        .view-filters {
          margin: 0 auto;
          width: 100%;
          max-width: 890px;
          padding: 20px 0 0 0;
          #edit-field-bonus-type-value-wrapper {
            display: none;
          }
          ul {
            li {
              float: left;
              margin: 0 35px 0 0;
              a {
                margin: 0;
                color: #a09d9d;
                padding: 10px;
                @include font-style($frutiger-roman,
                        15px,
                        15px,
                        0px);
                border: 1px solid #fff;
                text-transform: uppercase;
                &.active,
                &:hover {
                  border: 1px solid #a09d9d;
                  @include border-radius(5px);
                }
              }
            }
          }
        }
        .view-content {
          margin: 30px 0 0 0;
          position: relative;
          .flex-viewport {
            margin: 0 auto;
            width: 100%;
            max-width: 870px;
            height: 405px;
            @media (min-width: $tl) and (max-width: $site-size) {
              max-width: 790px;
            }
            @media (min-width:$tp) and (max-width:$t) {
              max-width: 95%;
            }
            li {
              .views-row {
                float: left;
                width: 425px;
                margin: 0 0 33px 0;
                max-height: 105px;
                @media (min-width: $tl) and (max-width: $site-size) {
                  max-width: 395px;
                }
                @media (min-width:$tp) and (max-width:$t) {
                  width: 50%;
                }
                .left-content {
                  float: left;
                  margin: 0 12px 0 0;
                  width: 185px;
                  height: 105px;
                  border: 1px solid #a9a6a6;
                  @media (min-width: $tp) and (max-width: $t) {
                    width: 160px;
                  }
                  img {
                    width: 100%;
                    height: 100%;
                  }
                }
                .right-content {
                  width: 215px;
                  float: left;
                  @media (min-width: $tp) and (max-width: $t) {
                    width: 160px;
                  }
                  @media (min-width:$tl) and (max-width:$site-size) {
                    width: 193px;
                  }
                  .bonus-title {
                    @include font-style($frutiger-light,
                            22px,
                            22px,
                            0px);
                    margin: 0 0 4px;
                  }
                  .bonus-desc {
                    @include font-style($frutiger-roman,
                            12px,
                            14px,
                            0px);
                    color: #000;
                    margin: 0 0 5px;
                  }
                  .bonus-lien {
                    a {
                      color: #97a719;
                      @include font-style($frutiger-bold,
                              12px,
                              12px,
                              0px);
                    }
                  }
                }
              }
            }
          }
          .flex-control-nav {
            display: none;
          }
          .flex-direction-nav {
            li {
              a {
                background-image: url("../img/arrow-fiche-slider.png");
                background-repeat: no-repeat;
                display: block;
                width: 32px;
                height: 20px;
                margin: 0;
                @include opacity(1);
                text-indent: -9999px;
                top: 45%;
                text-align: left;
                z-index: 1;
                &.flex-disabled {
                  @include opacity(0.5);
                }
                &.flex-prev {
                  left: 15px;
                  @include transform(rotate(90deg));
                  @media (min-width: $tp) and (max-width: $t) {
                    left: -15px;
                  }
                }
                &.flex-next {
                  right: 15px;
                  @include transform(rotate(270deg));
                  @media (min-width: $tp) and (max-width: $t) {
                    right: -15px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}


/*page single collection*/
.page-taxonomy-term {
  .footer-container {
    margin: 0;
  }
  .custom-page-header,
  .empty-term {
    display: none;
  }
  .bandeau-bloc {
    z-index: 2;
    position: fixed;
    left: 35px;
    top: 145px;
    @media (max-width: $m1) {
      left: 20px;
      top: 82px;
    }
    a {
      float: left;
      background: url("../img/overlay-contact.png") repeat 0 0 transparent;
      text-transform: uppercase;
      color: $noir;
      @include font-style($frutiger-light,
              18px,
              18px,
              0px);
      margin: 0 10px 0 0;
      padding: 7px;
      &.active {
        background: #fff;
      }
      @media (max-width:$m1) {
        padding: 10px;
        @include font-style($frutiger-light,
                24px,
                24px,
                0px);
        margin: 0 10px 10px 0;
      }
    }
  }
  .wrapper-breadcrumb {
    position: absolute;
    top: 0;
    left: 0;
    background: url("../img/overlay-contact.png") repeat 0 0 transparent;
    width: 100%;
    z-index: 1;
    .breadcrumb {
      background: none;
      padding: 10px 0;
      max-width: 1190px;
      width: 100%;
      margin: 0 auto;
      @media (min-width: $tl) and (max-width: $site-size) {
        max-width: 1024px;
        padding: 10px;
      }
      @media (min-width:$tp) and (max-width:$t) {
        max-width: 768px;
        padding: 10px;
      }
      li,
      a {
        color: $accordion;
        @include font-style($frutiger-roman,
                13px,
                13px,
                0px);
        text-transform: uppercase;
        a {
          background: transparent url("../img/arrow-breadcrumb-video.png") no-repeat scroll 97% 2px;
        }
      }
    }
  }
  .main-container.container {
    max-width: 100%;
    @media (min-width: $tp) and (max-width: $site-size) {
      padding: 0;
    }
    .region-content {
      @media (max-width: $m1) {
        padding: 0;
      }
    }
    #block-system-main {
      .bloc-presentation {
        position: relative;
        height: 549px;
        @media (max-width: $m1) {
          height: 640px;
        }
        .presentation-image {
          background-position: center center;
          background-repeat: no-repeat;
          background-size: cover;
          @include background-size();
          height: 549px;
          left: 0;
          overflow: hidden;
          position: absolute;
          top: 0;
          width: 100%;
          @media (max-width: $m1) {
            height: 640px;
          }
        }
        .bloc-centre {
          position: relative;
          z-index: 1;
          width: 585px;
          height: 300px;
          margin: 170px auto 0;
          background: url("../img/overlay-contact.png") repeat 0 0 transparent;
          text-align: center;
          color: #000;
          font-family: $frutiger-light;
          @media (max-width: $m1) {
            width: 92%;
            padding: 0 0 10px;
            height: auto;
            min-height: 300px;
          }
          .titre-collection {
            text-transform: uppercase;
            padding: 12px 0 8px;
            color: #262f32;
            @include font-style($frutiger-bold,
                    24px,
                    24px,
                    0px);
            @media (max-width: $m1) {
              @include font-style($frutiger-bold, 28px, 28px, 0px);
            }
          }
          .logo-site {
            background: url("../img/logo-edl-collection.png") repeat 0 0 transparent;
            width: 248px;
            height: 30px;
            display: block;
            padding: 0;
            margin: 0 auto 18px;
          }
          .soustitre-collection {
            text-transform: uppercase;
            font-size: 24px;
            line-height: 24px;
            padding: 0 0 20px;
            @media (max-width: $m1) {
              padding: 0 5px 20px 5px;
              font-size: 26px;
              line-height: 26px;
            }
          }
          .description-collection {
            font-size: 16px;
            line-height: 18px;
            @media (max-width: $m1) {
              font-size: 28px;
              line-height: 28px;
              max-height: 255px;
              overflow: hidden;
              padding: 0 5px;
            }
          }
          .fleche {
            background: url("../img/scroll-collection.png") repeat 0 0 transparent;
            width: 68px;
            height: 23px;
            display: block;
            margin: 20px auto 5px;
            cursor: pointer;
          }
        }
      }
      .bloc-evenement-actu {
        background: $bluefonce;
        .titre-bloc {
          text-align: center;
          color: #fefefe;
          text-transform: uppercase;
          margin: 35px 0;
          padding: 0;
          @include font-style($frutiger-light,
                  30px,
                  30px,
                  0px);
        }
        .slider-evenement {
          position: relative;
          margin: 0 auto 65px;
          width: 100%;
          max-width: 1000px;
          @media (min-width: $tl) and (max-width: $site-size) {
            max-width: 850px;
          }
          @media (min-width:$tp) and (max-width:$t) {
            max-width: 600px;
          }
          @media (max-width:$m1) {
            max-width: 240px;
          }
          .flex-viewport {
            li {
              margin: 0 30px 0 0;
              width: auto !important;
              background: #fffcfc;
              height: 355px;
              max-width: 310px;
              @media (max-width: $m1) {
                max-width: 240px;
                margin: 0;
              }
              .picto-evenement {
                img {
                  width: 100%;
                  height: auto;
                  max-height: 185px;
                }
              }
              .titre-evenement {
                padding: 20px 25px 10px 25px;
                color: $noir;
                @include font-style($frutiger-light,
                        16px,
                        16px,
                        0px);
                text-transform: uppercase;
              }
              .desc-evenement {
                padding: 10px 25px 0 25px;
                color: $noir;
                @include font-style($frutiger-light,
                        16px,
                        16px,
                        0px);
              }
            }
          }
          .flex-direction-nav {
            li {
              a {
                background-image: url("../img/fleche-collection.png");
                background-repeat: no-repeat;
                display: block;
                width: 19px;
                height: 45px;
                margin: 0;
                @include opacity(1);
                text-indent: -9999px;
                top: 46%;
                text-align: left;
                z-index: 1;
                &.flex-prev {
                  left: -35px;
                  background-position: 0 0;
                }
                &.flex-next {
                  right: -35px;
                  background-position: -28px 0;
                }
              }
            }
          }
        }
      }
      .bloc-livres {
        position: relative;
        padding: 30px 0;
        .block-title,
        .titre-bloc {
          text-align: center;
          color: #437daf;
          text-transform: uppercase;
          margin: 5px 0 30px;
          padding: 0;
          @include font-style($frutiger-light,
                  30px,
                  30px,
                  0px);
          position: relative;
          top: 0;
          left: auto;
          float: none;
        }
        .container {
          max-width: 1000px;
          @media (min-width: $tl) and (max-width: $site-size) {
            max-width: 1030px;
          }
          .loading-content {
            width: 100%;
            height: 600px;
            background: #fff url("../img/ajax-loader.gif") no-repeat 50% 50%;
          }
          .lien-catalogue {
            @media (max-width: $m1) {
              border-bottom: 1px solid #d7d4d2;
              a {
                @media (max-width: $ms) {
                  background: #fff url("../img/icon-select-livre.png") no-repeat scroll 0 -82px;
                }
              }
            }
          }
          label,
          .lien-catalogue a {
            margin: 0;
            color: #a09d9d;
            padding: 7px 10px;
            @include font-style($frutiger-roman,
                    15px,
                    15px,
                    0px);
            border: 1px solid #fff;
            text-transform: uppercase;
            display: table;
            @media (max-width: $m1) {
              @include font-style($frutiger-roman, 22px, 22px, 0px);
              background: #fff url("../img/icon-select-livre.png") no-repeat scroll 0 -94px;
              padding: 12px 10px 12px 50px;
              margin: 0 0 0 18px;
            }
            &:hover {
              border: 1px solid #a09d9d;
              @include border-radius(5px);
              @media (max-width: $m1) {
                border: none;
                border-radius: 0;
              }
            }
          }
          #block-edl-collection-edl-filtre-collection {
            .form-item {
              margin: 0 35px 0 0;
              float: left;
              @media (max-width: $m1) {
                float: none;
                margin: 0;
                border-bottom: 1px solid #d7d4d2;
                &.form-item-recherche-collection-1 {
                  border-top: 1px solid #d7d4d2;
                }
              }
              @media (min-width:$tp) and (max-width:$t) {
                margin: 0;
              }
              input {
                display: none;
              }
              &.cocher {
                label {
                  border: 1px solid #a09d9d;
                  @include border-radius(5px);
                  @media (max-width: $m1) {
                    background: #fff url("../img/icon-select-livre.png") no-repeat scroll 0 6px;
                    border: none;
                    border-radius: 0;
                  }
                }
              }
            }
          }
          #block-views-page-collection-livres-block,
          .block-edl-collection {
            position: relative;
            .block-title {
              display: none;
            }
            .view-filters {
              @media (max-width: $m1) {
                padding: 15px 20px 0;
              }
              .views-exposed-widgets {
                margin: 0;
                position: absolute;
                top: -37px;
                right: 0;
                @media (max-width: $m1) {
                  position: relative;
                  top: 0;
                }
                #edit-field-parution-value-wrapper,
                #edit-field-essentiel-value-wrapper {
                  display: none;
                }
                #edit-title-wrapper {
                  float: left;
                  @media (max-width: $m1) {
                    width: 85%;
                  }
                  label {
                    display: none;
                  }
                  input {
                    background: #fff;
                    border: 1px solid #d4d0ce;
                    border-radius: 15px;
                    box-shadow: none;
                    color: #a09d9d;
                    height: 27px;
                    @include font-style($frutiger-light,
                            14px,
                            27px,
                            0px);
                    margin: 0;
                    padding: 0 12px;
                    width: 240px;
                    @media (min-width: $tp) and (max-width: $t) {
                      width: 150px;
                    }
                    @media (max-width:$m1) {
                      width: 100%;
                    }
                  }
                }
                .views-submit-button {
                  float: right;
                  button {
                    width: 25px;
                    height: 25px;
                    margin: 0;
                    background: url("../img/loupe-gris.png") no-repeat 0 0 transparent;
                    @include default-disable();
                    display: block;
                    padding: 0;
                    text-indent: -9999px;
                  }
                }
              }
            }
            .view-content,
            .row-catalogue {
              margin: 35px 0 0 0;
              @media (max-width: $m1) {
                max-width: 640px;
                width: 100%;
                margin: 25px auto 0;
              }
              .views-row-1,
              .views-row-8 {
                background: $case-1;
              }
              .views-row-2,
              .views-row-7 {
                background: $case-2;
              }
              .views-row-3,
              .views-row-6 {
                background: $case-3;
              }
              .views-row-4,
              .views-row-5 {
                background: #fecc1e;
              }
              .livre-container {
                float: left;
                height: 295px;
                margin: 0 10px 10px 0;
                overflow: hidden;
                position: relative;
                width: 240px;
                @media (min-width: $tp) and (max-width: $t) {
                  width: 175px;
                  height: 270px;
                }
                @media (max-width:$m1) {
                  height: auto;
                  max-width: 270px;
                  max-height: 335px;
                  width: 44%;
                  margin: 0 0 50px;
                  @media (max-width: $ms) {
                    width: 100%;
                    margin: 0 auto 50px;
                    float: none;
                  }
                  &.even {
                    float: right;
                    margin: 0 0 50px 10%;
                    @media (max-width: $ms) {
                      float: none;
                      margin: 0 auto 50px;
                    }
                  }
                }
                .couverture-livre {
                  display: table-cell;
                  height: 295px;
                  text-align: center;
                  vertical-align: middle;
                  width: 240px;
                  @media (min-width: $tp) and (max-width: $t) {
                    width: 175px;
                    height: 270px;
                  }
                  @media (max-width:$m1) {
                    height: auto;
                    padding: 40px;
                    display: block;
                    width: 100%;
                  }
                  img {
                    max-height: 290px;
                    @include transition(all,
                            0.45s,
                            ease,
                            0s);
                    width: 170px;
                    height: auto;
                    @media (min-width: $tp) and (max-width: $t) {
                      max-height: 270px;
                      width: 150px;
                    }
                    @media (max-width:$m1) {
                      width: 100%;
                      max-height: 100%;
                    }
                  }
                }
                &:hover {
                  .couverture-livre {
                    img {
                      @include filter-custom();
                      @include transform-custom();
                    }
                  }
                  .hover-info {
                    @include opacity(1);
                  }
                }
                .hover-info {
                  height: 100%;
                  left: 0;
                  position: absolute;
                  top: 0;
                  @include opacity(0);
                  @include transition(all,
                          0.45s,
                          ease,
                          0s);
                  width: 100%;
                  z-index: 1;
                  background: url("../img/overlay-catalogue.png") repeat 0 0 transparent;
                  .wrapper-hover-info {
                    padding: 15px 20px;
                    .titre-livre a {
                      color: $catalogue-text;
                      @include font-style($frutiger-roman,
                              16px,
                              18px,
                              0px);
                      margin: 0 0 7px;
                      @media (min-width: $ml) and (max-width: $m) {
                        margin: 0 0 3px;
                        @include font-style($frutiger-roman,
                                14px,
                                16px,
                                0px);
                      }
                    }
                    .nom-auteur {
                      color: #b8b6b6;
                      @include font-style($frutiger-roman,
                              16px,
                              18px,
                              0px);
                      margin: 0 0 12px;
                      @media (min-width: $ml) and (max-width: $m) {
                        margin: 0 0 7px;
                        @include font-style($frutiger-roman,
                                15px,
                                15px,
                                0px);
                      }
                    }
                    .resume-livre p {
                      color: #fdfefe;
                      @include font-style($frutiger-roman,
                              14px,
                              16px,
                              0px);
                      margin: 0 0 14px;
                      @media (min-width: $ml) and (max-width: $m) {
                        margin: 0 0 10px;
                        line-height: 15px;
                      }
                      @media (min-width:$tp) and (max-width:$t) {
                        height: 50px;
                        overflow: hidden;
                      }
                    }
                    .age-livre,
                    .collection-livre {
                      background: url("../img/overlay-inof-catalague.png") repeat 0 0 transparent;
                      @include border-radius(15px);
                      padding: 5px 20px;
                      color: #fff;
                      @include font-style($frutiger-bold,
                              14px,
                              14px,
                              0px);
                      margin: 0 0 12px;
                      display: table;
                      min-width: 105px;
                      @media (min-width: $ml) and (max-width: $m) {
                        margin: 0 0 7px;
                        min-width: 100px;
                        padding: 4px 20px;
                      }
                    }
                    .fleche {
                      background: url("../img/flechette-catalogue.png") no-repeat 0 0 transparent;
                      width: 20px;
                      height: 36px;
                      display: block;
                      float: right;
                    }
                  }
                }
              }
            }
          }
        }
      }
      .bloc-contact {
        position: relative;
        height: 368px;
        .contact-image {
          background-position: center center;
          background-repeat: no-repeat;
          background-size: cover;
          @include background-size();
          height: 368px;
          left: 0;
          overflow: hidden;
          position: absolute;
          top: 0;
          width: 100%;
        }
        .bloc-contact-wrapper {
          padding: 0 20px;
        }
        .bloc-centre-contact {
          position: relative;
          z-index: 1;
          max-width: 400px;
          width: 100%;
          height: 240px;
          margin: 60px auto 0;
          background: url("../img/overlay-contact.png") repeat 0 0 transparent;
          text-align: center;
          color: #000;
          font-family: $frutiger-light;
          @media (max-width: $m1) {
            height: auto;
            min-height: 240px;
          }
          .titre-contact {
            text-transform: uppercase;
            font-size: 30px;
            line-height: 30px;
            padding: 17px 0 30px;
          }
          .soustitre-contact {
            text-transform: uppercase;
            font-size: 18px;
            line-height: 18px;
            padding: 0 0 25px;
          }
          .addresse-contact,
          .pays-contact,
          .tel-contact,
          .email-contact,
          .email-contact a {
            font-size: 18px;
            line-height: 18px;
            padding: 0 0 5px;
          }
          .email-contact a {
            display: inline;
            color: #000;
            @include font-style($frutiger-light,
                    18px,
                    18px,
                    0px);
          }
        }
      }
    }
  }
}

.page--evenement {
  .view-evenement-view {
    .view-content {
      background: #fff;
      border: 1px solid #d3d1d1;
      padding: 10px;
      margin: 30px 0 0 0;
      @media (max-width: $m1) {
        margin: 0;
      }
      #map-event {
        margin: 0 0 10px;
      }
      .event-containers {
        padding: 0 0 0 33px;
        .event-content {
          margin: 0 0 10px;
        }
      }
      .event-title {
        color: $p-actu;
        @include font-style($frutiger-bold,
                20px,
                22px,
                0px);
        margin: 0 0 10px;
        position: relative;
        &:before {
          content: "";
          display: block;
          width: 24px;
          height: 32px;
          position: absolute;
          left: -32px;
          top: 0;
          background: url("../img/icon-map-event-2.png") no-repeat 0 0 transparent;
        }
      }
      .event-desc {
        color: #000;
        @include font-style($frutiger-light,
                14px,
                16px,
                0px);
      }
      .event-date {
        border-top: 1px solid #a09d9d;
        padding: 8px 0 0 0;
        margin: 8px 0 0 0;
        span,
        div {
          color: $p-actu;
          @include font-style($frutiger-bold,
                  20px,
                  22px,
                  0px);
        }
      }
      .event-address {
        div {
          color: #8f8c8c;
          @include font-style($frutiger-roman,
                  14px,
                  16px,
                  0px);
        }
      }
      .event-icons {
        clear: both;
        padding: 8px 0;
        margin: 8px 0;
        border-top: 1px solid #e5e5e5;
        border-bottom: 1px solid #e5e5e5;
        position: relative;
        &:after {
          @include clearfix();
        }
        .details-lower {
          .event-map-logo {
            float: left;
            display: block;
            text-indent: -9999px;
            width: 30px;
            height: 30px;
            cursor: pointer;
            background-image: url("../img/icon-map-partager-active.png");
            background-repeat: no-repeat;
            background-position: 0 0;
          }
          .siteweb-evenement a {
            color: #a09d9d;
            padding: 10px 0 0 0;
            @include font-style($frutiger-roman,
                    14px,
                    16px,
                    0px);
          }
          .picto-partage {
            height: 28px;
            width: 20px;
            background-repeat: no-repeat;
            background-image: url("../img/icon-map-partager-inactive.png");
            background-position: -50px 0;
            display: block;
            float: left;
            cursor: pointer;
            text-indent: -9999px;
            margin: 0 20px;
            &.active {
              background-image: url("../img/icon-map-partager-active.png");
            }
          }
          .lien-partage {
            position: absolute;
            left: -10px;
            top: -42px;
            background: #E9E9E9;
            padding: 4px 5px;
            width: 135px;
            @include border-radius(5px);
            &:after {
              width: 0;
              height: 0;
              border-left: 12px solid transparent;
              border-right: 12px solid transparent;
              border-top: 12px solid #E9E9E9;
              position: absolute;
              content: "";
              bottom: -8px;
              left: 43%;
            }
            .texte-partage {
              float: left;
              margin: 0 9px 0 0;
              @include font-style($frutiger-light,
                      14px,
                      23px,
                      0px);
              color: $noir;
            }
            .addthis_sharing_toolbox {
              float: left;
              .at-svc-facebook {
                max-width: 9px;
                max-height: 23px;
                margin: 0 7px 0 0;
                background: transparent !important;
                span {
                  background-repeat: no-repeat;
                  background-image: url("../img/social-icon-partager.png");
                  background-position: 0 0;
                  width: 9px;
                  height: 23px;
                  background-size: auto auto !important;
                }
              }
              .at-svc-twitter {
                max-width: 23px;
                max-height: 23px;
                margin: 0;
                background: transparent !important;
                span {
                  margin: 0;
                  background-repeat: no-repeat;
                  background-image: url("../img/social-icon-partager.png");
                  background-position: -18px 0;
                  width: 23px;
                  height: 23px;
                  background-size: auto auto !important;
                }
              }
              .at-svc-email {
                max-width: 21px;
                max-height: 23px;
                background: transparent !important;
                span {
                  background-repeat: no-repeat;
                  background-image: url("../img/social-icon-partager.png");
                  background-position: -44px 0;
                  background-size: auto auto !important;
                  width: 21px;
                  height: 23px;
                }
              }
            }
          }
        }
      }
      .event-hashtags {
        div {
          color: #000;
          margin: 0 0 2px;
          @include font-style($frutiger-light-italic,
                  14px,
                  16px,
                  0px);
          &:before {
            content: "#";
          }
        }
      }
    }
  }
}


/*page event*/

.page-evenement,
.page--evenement {
  .haute-wrapper {
    margin: 30px auto 0;
    width: 100%;
    max-width: 850px;
    .view-header {
      .date-nav-wrapper {
        margin: 0;
        .date-nav {
          padding: 0;
          margin: 0;
          ul {
            margin: 0 auto;
            text-align: center;
            @media (max-width: $m1) {
              margin: 0 0 0 38px;
            }
            li {
              float: left;
              position: relative;
              padding: 0;
              text-align: left;
              &.date-prev,
              &.date-next {
                width: 45px;
                display: block;
                height: 38px;
                margin: 0 8px 0 0;
                @media (max-width: $m1) {
                  margin: 0 0 15px;
                }
                a {
                  width: 45px;
                  display: block;
                  height: 38px;
                  background-repeat: no-repeat;
                  background-image: url("../img/nav-month-slider.png");
                  background-position: 50% 50%;
                  background-color: #e9e9e9;
                  text-indent: -9999px;
                  @include border-radius(5px);
                  margin: 0;
                }
              }
              &.date-next {
                margin: 0;
                top: -12px;
                a {
                  @include transform(rotate(180deg));
                }
              }
              &.month_filter {
                a {
                  margin: 0 10px 0 0;
                  background: #e9e9e9;
                  text-transform: uppercase;
                  padding: 7px 0 7px 15px;
                  width: 240px;
                  float: left;
                  @include font-style($frutiger-light,
                          25px,
                          25px,
                          0px);
                  color: $textmaron;
                  @include transition(all,
                          0.3s,
                          ease,
                          0s);
                  @include border-radius(5px);
                  text-align: left;
                  cursor: pointer;
                  @media (min-width: $tp) and (max-width: $t) {
                    width: 205px;
                  }
                  @media (max-width:$m1) {
                    margin: 0 0 15px;
                  }
                  .month_count {
                    float: right;
                    @include font-style($frutiger-roman,
                            12px,
                            12px,
                            0px);
                    color: $textmaron;
                    @include border-radius(50%);
                    background: #fff;
                    padding: 6px 7px;
                    margin: 0 7px 0 0;
                  }
                  .filter-year {
                    display: none;
                    @media (max-width: $m1) {
                      display: block;
                      @include font-style($frutiger-light,
                              25px,
                              25px,
                              0px);
                      color: $textmaron;
                    }
                  }
                  &.active,
                  &:hover {
                    background: $p-actu;
                    color: #fff;
                    .filter-year {
                      color: #fff;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    .view-filters {
      padding: 10px 0;
      margin: 20px 0 0 0;
      border-top: 1px solid #e5e5e5;
      border-bottom: 1px solid #e5e5e5;
      position: relative;
      @media (max-width: $m1) {
        padding: 0;
        margin: 20px 0 0 0;
        border-top: 0;
        border-bottom: 0;
      }
      #edit-field-type-evenement-tid-wrapper,
      label {
        display: none;
      }
      .views-exposed-widgets {
        margin: 0;
        .views-exposed-widget {
          float: none;
          width: 100%;
          padding: 0;
          &:after {
            @include clearfix();
          }
          #pop-filter-list {
            li {
              float: left;
              margin: 0 25px 0 0;
              @media (min-width: $tp) and (max-width: $t) {
                margin: 0 20px 0 0;
              }
              @media (max-width:$m1) {
                margin: 0;
                border-top: 1px solid #000;
                float: none;
                &.last {
                  border-bottom: none;
                }
              }
              a {
                color: #a09d9d;
                @include font-style($frutiger-light,
                        25px,
                        25px,
                        0px);
                text-transform: uppercase;
                @media (min-width: $tp) and (max-width: $t) {
                  font-size: 22px;
                  line-height: 22px;
                }
                @media (max-width:$m1) {
                  @include font-style($frutiger-light,
                          25px,
                          25px,
                          0px);
                  color: #000;
                  text-transform: uppercase;
                  background-repeat: no-repeat;
                  background-image: url("../img/select-cat-mobile.png");
                  background-position: 15px -457px;
                  padding: 12px 0 12px 63px;
                }
                &.active,
                &:hover {
                  color: $p-actu;
                  @media (max-width: $m1) {
                    color: #000;
                    background-position: 15px 7px;
                  }
                }
              }
            }
          }
        }
        #edit-filtre-auteurs {
          position: absolute;
          top: 102%;
          left: 0;
          z-index: 3;
          span {
            background: url("../img/select-event.png") no-repeat 49% 51% transparent;
            width: 415px;
            height: 35px;
            padding: 0 25px;
            color: $p-actu;
            @include font-style($frutiger-light,
                    22px,
                    35px,
                    0px);
            cursor: pointer;
            display: block;
            @media (max-width: $m1) {
              width: 320px;
              background-position: 65% 51%
            }
          }
          ul {
            border: 1px solid #e5e5e5;
            li {
              &.first {
                a {
                  border-top: none;
                }
              }
              a {
                border-top: 1px solid #e5e5e5;
                color: #000;
                @include font-style($frutiger-light,
                        18px,
                        18px,
                        0px);
                padding: 5px 25px;
                background: #fff;
                &:hover {
                  background: $p-actu;
                  color: #fff;
                }
              }
            }
          }
        }
        #edit-swap-container {
          margin: 0;
          @media (max-width: $m1) {
            float: right;
            margin: 0 20px 0 0;
          }
          &:after {
            @include clearfix();
          }
          .view-1,
          .view-2 {
            float: left;
            @include default-disable();
            width: 24px;
            display: block;
            height: 24px;
            background-repeat: no-repeat;
            text-indent: -9999px;
            margin: 0 0 0 10px
          }
          .view-1 {
            background-image: url("../img/icon-tab-event-1.png");
            background-position: 0 0;
            &.active {
              background-image: url("../img/icon-tab-event-2.png");
              background-position: 0 0;
            }
          }
          .view-2 {
            background-image: url("../img/icon-tab-event-2.png");
            background-position: -34px 0;
            &.active {
              background-image: url("../img/icon-tab-event-1.png");
              background-position: -34px 0;
            }
          }
        }
      }
    }
  }
  .col-sm-9 {
    @media (min-width: $m1) {
      width: 65.5%;
      margin: 0 15px 0 0;
    }
    .breadcrumb {
      display: none;
    }
    .views-row {
      border-top: 1px solid $p-actu;
      padding: 10px 0 0 0;
      margin: 30px 0 0 0;
      position: relative;
      .wrapper-picto {
        position: relative;
        @media (min-width: $m1) {
          width: 37%;
          margin: 0 12px 0 0;
          float: left;
        }
        &:hover {
          .layer-visuel-picto {
            @include opacity(0.25);
          }
        }
        .visuel-evenement {
          img {
            width: 100%;
            height: auto;
          }
        }
        .layer-visuel-picto {
          background: #ff0076;
          position: absolute;
          height: 100%;
          width: 100%;
          top: 0;
          left: 0;
          display: block;
          z-index: 2;
          @include opacity(0);
          @include transition(opacity,
                  0.3s,
                  ease,
                  0);
        }
        .type-evenement {
          position: absolute;
          top: 5px;
          left: 10px;
          z-index: 3;
          .field-item {
            background: #fff;
            color: $p-actu;
            text-transform: uppercase;
            @include font-style($frutiger-bold,
                    14px,
                    14px,
                    0px);
            padding: 5px 10px;
          }
        }
      }
      .details-evenement {
        float: left;
        width: 61%;
        @media (max-width: $m1) {
          float: none;
          width: 100%;
        }
        .details-upper {
          .titre-evenement {
            color: $p-actu;
            @include font-style($frutiger-bold,
                    20px,
                    22px,
                    0px);
            margin: 0 0 10px;
          }
          .desc-evenement {
            color: #000;
            @include font-style($frutiger-light,
                    14px,
                    16px,
                    0px);
          }
        }
        .details-middle {
          border-top: 1px solid #a09d9d;
          padding: 8px 0 0 0;
          margin: 8px 0 0 0;
          .date-evenement,
          .time-evenement {
            color: $p-actu;
            @include font-style($frutiger-bold,
                    20px,
                    22px,
                    0px);
          }
          .adresse-evenement {
            &:after {
              @include clearfix();
            }
            color: #8f8c8c;
            @include font-style($frutiger-roman,
                    14px,
                    16px,
                    0px);
            margin: 2px 0 0 0;
            .upper-address,
            .lower-address {
              clear: both;
              div {
                float: left;
                margin: 0 7px 0 0;
                &.lieu-evenement {
                  clear: both;
                }
              }
            }
          }
        }
        .livres-evenement,
        .auteur-evenement {
          .field-item {
            color: #000;
            margin: 0 0 2px;
            @include font-style($frutiger-light-italic,
                    14px,
                    16px,
                    0px);
            &:before {
              content: "#";
            }
          }
        }
        .details-lower {
          clear: both;
          padding: 8px 0;
          margin: 8px 0;
          border-top: 1px solid #e5e5e5;
          border-bottom: 1px solid #e5e5e5;
          position: relative;
          &:after {
            @include clearfix();
          }
          .siteweb-evenement a {
            color: #a09d9d;
            padding: 10px 0 0 0;
            @include font-style($frutiger-roman,
                    14px,
                    16px,
                    0px);
          }
          .auteur-evenement {
            color: #a09d9d;
            @include font-style($frutiger-roman,
                    14px,
                    16px,
                    0px);
          }
          .trigger-map-evenement {
            float: left;
            display: block;
            text-indent: -9999px;
            width: 30px;
            height: 30px;
            cursor: pointer;
            background-image: url("../img/icon-map-partager-inactive.png");
            background-repeat: no-repeat;
            background-position: 0 0;
            &.active {
              background-image: url("../img/icon-map-partager-active.png");
            }
          }
          .lien-partage {
            position: absolute;
            left: -10px;
            top: -42px;
            background: #E9E9E9;
            padding: 4px 5px;
            width: 135px;
            @include border-radius(5px);
            &:after {
              width: 0;
              height: 0;
              border-left: 12px solid transparent;
              border-right: 12px solid transparent;
              border-top: 12px solid #E9E9E9;
              position: absolute;
              content: "";
              bottom: -8px;
              left: 43%;
            }
            .texte-partage {
              float: left;
              margin: 0 9px 0 0;
              @include font-style($frutiger-light,
                      14px,
                      23px,
                      0px);
              color: $noir;
            }
            .addthis_sharing_toolbox {
              float: left;
              .at-svc-facebook {
                max-width: 9px;
                max-height: 23px;
                margin: 0 7px 0 0;
                background: transparent !important;
                span {
                  background-repeat: no-repeat;
                  background-image: url("../img/social-icon-partager.png");
                  background-position: 0 0;
                  width: 9px;
                  height: 23px;
                  background-size: auto auto !important;
                }
              }
              .at-svc-twitter {
                max-width: 23px;
                max-height: 23px;
                margin: 0;
                background: transparent !important;
                span {
                  margin: 0;
                  background-repeat: no-repeat;
                  background-image: url("../img/social-icon-partager.png");
                  background-position: -18px 0;
                  width: 23px;
                  height: 23px;
                  background-size: auto auto !important;
                }
              }
              .at-svc-email {
                max-width: 21px;
                max-height: 23px;
                background: transparent !important;
                span {
                  background-repeat: no-repeat;
                  background-image: url("../img/social-icon-partager.png");
                  background-position: -44px 0;
                  background-size: auto auto !important;
                  width: 21px;
                  height: 23px;
                }
              }
            }
          }
          .picto-partage {
            height: 28px;
            width: 20px;
            background-repeat: no-repeat;
            background-image: url("../img/icon-map-partager-inactive.png");
            background-position: -50px 0;
            display: block;
            float: left;
            cursor: pointer;
            text-indent: -9999px;
            margin: 0 20px;
            &.active {
              background-image: url("../img/icon-map-partager-active.png");
            }
          }
        }
      }
      .map-evenement {
        position: absolute;
        left: 0;
        top: 91%;
        z-index: 2;
        width: 100%;
        padding: 10px;
        border: 1px solid #d3d1d1;
        background: #fff;
        @include border-radius(3px);
        &:before {
          content: "";
          position: absolute;
          left: 310px;
          top: -8px;
          z-index: 1;
          width: 15px;
          height: 8px;
          background: url("../img/icon-map-actu.png") repeat 0 0 transparent;
          @media (max-width: $m1) {
            display: none;
          }
        }
      }
    }
  }
  .col-sm-3 {
    @media (min-width: $m1) {
      width: 32%;
      margin: 50px 0 0 0;
    }
    @media (max-width:$m1) {
      padding: 0 20px;
      margin: 15px 0 0 0;
    }
    #block-edl-main-edl-main-bloc-like {
      position: relative;
      margin: 0 0 12px;
      .background-img {
        img {
          width: 100%;
          height: auto;
        }
      }
      .fb-like {
        position: absolute;
        bottom: 6px;
        left: 6px;
      }
    }
    #block-facebook-pull-facebook-pull-default {
      @media (max-width: $m1) {
        height: auto;
      }
      .balayage-gauche {
        @include balayage-gauche($bg-balayage,
                $bg-hover-1);
        &:hover {
          .facebook-latest-post {
            .fb-date {
              color: #fff;
            }
            .flechete {
              background-position: 0 0;
              background-image: url("../img/lien-fb-event-active.png");
            }
          }
        }
        .facebook-latest-post {
          padding: 18px 30px 15px 18px;
          .fb-post-logo {
            display: block;
            width: 13px;
            height: 26px;
            background: url("../img/block-fb-icon.png") no-repeat 0 0 transparent;
            margin: 0 0 15px;
          }
          .fb-date {
            @include font-style($frutiger-bold,
                    16px,
                    16px,
                    0px);
            color: $p-actu;
            margin: 0 0 6px;
            display: block;
            @media (max-width: $m1) {
              @include font-style($frutiger-bold, 20px, 20px, 0px);
            }
          }
          .fb-post-message {
            color: #070505;
            @include font-style($frutiger-light,
                    16px,
                    18px,
                    0px);
            margin: 0 0 20px;
            @media (max-width: $m1) {
              @include font-style($frutiger-light, 20px, 22px, 0px);
            }
          }
          .flechete {
            background-position: 0 0;
            background-image: url("../img/lien-fb-event.png");
          }
        }
      }
    }
  }
}


/*page actu*/

.page-evenement-actualites {
  #backtotop {
    width: 60px;
    height: 58px;
    background: url("../img/scroll-to-top-actu.png") repeat 0 0 transparent;
  }
  .breadcrumb {
    display: none;
  }
  .view-actualites {
    margin: 30px auto 0;
    width: 100%;
    max-width: 890px;
    h3 {
      margin: 0 0 20px;
      background: #e9e9e9;
      text-transform: uppercase;
      padding: 10px 0 10px 15px;
      width: 240px;
      @include font-style($frutiger-light,
              25px,
              25px,
              0px);
      color: $textmaron;
      @include transition(all,
              0.3s,
              ease,
              0s);
      @include border-radius(10px);
      cursor: pointer;
      span {
        float: right;
        @include font-style($frutiger-roman,
                12px,
                12px,
                0px);
        color: $p-actu;
        @include border-radius(50%);
        background: #fff;
        display: none;
        padding: 6px 7px;
        margin: 0 7px 0 0;
      }
      &.active,
      &:hover {
        background: $p-actu;
        color: #fff;
        span {
          display: block;
        }
      }
    }
    .actu-container {
      &:after {
        @include clearfix();
      }
      background: $bg-balayage;
      margin: 0 0 20px;
      position: relative;
      .actu-location-map {
        position: absolute;
        left: 0;
        top: 102%;
        z-index: 2;
        width: 100%;
        padding: 10px;
        border: 1px solid #d3d1d1;
        background: #fff;
        @include border-radius(3px);
        &:before {
          content: "";
          position: absolute;
          left: 335px;
          top: -8px;
          z-index: 1;
          width: 15px;
          height: 8px;
          background: url("../img/icon-map-actu.png") repeat 0 0 transparent;
          @media (max-width: $m1) {
            display: none;
          }
        }
      }
      .actu-left {
        float: left;
        margin: 0 30px 0 0;
        @media (max-width: $m1) {
          margin: 30px 15px 30px 0;
          height: auto;
          width: 50%;
          img {
            width: 100%;
            height: auto;
          }
        }
      }
      .actu-right {
        float: left;
        padding: 0 0 50px;
        width: 450px;
        @media (max-width: $m1) {
          width: 44%;
        }
        .actu-title {
          margin: 60px 0 15px 0;
          color: $p-actu;
          @include font-style($frutiger-light,
                  32px,
                  32px,
                  0px);
          @media (max-width: $m1) {
            margin: 30px 0 15px;
          }
        }
        .actu-desc {
          @include font-style($frutiger-light,
                  15px,
                  18px,
                  0px);
          color: #000;
          margin: 0 0 15px;
          img, iframe{
            max-width:100% !important;
          }
        }
        .actu-date {
          span {
            @include font-style($frutiger-bold,
                    20px,
                    20px,
                    0px);
            color: $p-actu;
          }
        }
        .actu-time {
          span {
            @include font-style($frutiger-bold,
                    20px,
                    20px,
                    0px);
            color: $p-actu;
          }
        }
        .actu-auteurs-tags,
        .actu-livre-tags {
          @include font-style($frutiger-light-italic,
                  14px,
                  14px,
                  0px);
          color: $p-actu;
          &:before {
            content: "#";
          }
        }
        .actu-address {
          padding: 5px 0 10px;
          .street-address {
            display: inline;
          }
          span {
            color: #706e6e;
            @include font-style($frutiger-roman,
                    14px,
                    14px,
                    0px);
          }
        }
        .actu-lower {
          position: absolute;
          bottom: 10px;
          &:after {
            @include clearfix();
          }
          .actu-lower-wrapper {
            position: relative;
          }
          .lien-partage {
            position: absolute;
            left: -59px;
            top: -42px;
            background: #fff;
            padding: 4px 5px;
            width: 135px;
            @include border-radius(5px);
            &:after {
              width: 0;
              height: 0;
              border-left: 12px solid transparent;
              border-right: 12px solid transparent;
              border-top: 12px solid #fff;
              position: absolute;
              content: "";
              bottom: -8px;
              left: 43%;
            }
            .texte-partage {
              float: left;
              margin: 0 9px 0 0;
              @include font-style($frutiger-light,
                      14px,
                      23px,
                      0px);
              color: $noir;
            }
            .addthis_sharing_toolbox {
              float: left;
              .at-svc-facebook {
                max-width: 9px;
                max-height: 23px;
                margin: 0 7px 0 0;
                background: transparent !important;
                span {
                  background-repeat: no-repeat;
                  background-image: url("../img/social-icon-partager.png");
                  background-position: 0 0;
                  width: 9px;
                  height: 23px;
                  background-size: auto auto !important;
                }
              }
              .at-svc-twitter {
                max-width: 23px;
                max-height: 23px;
                margin: 0;
                background: transparent !important;
                span {
                  margin: 0;
                  background-repeat: no-repeat;
                  background-image: url("../img/social-icon-partager.png");
                  background-position: -18px 0;
                  width: 23px;
                  height: 23px;
                  background-size: auto auto !important;
                }
              }
              .at-svc-email {
                max-width: 21px;
                max-height: 23px;
                background: transparent !important;
                span {
                  background-repeat: no-repeat;
                  background-image: url("../img/social-icon-partager.png");
                  background-position: -44px 0;
                  background-size: auto auto !important;
                  width: 21px;
                  height: 23px;
                }
              }
            }
          }
          .picto-partage {
            height: 29px;
            width: 20px;
            background-repeat: no-repeat;
            background-image: url("../img/actu-icon.png");
            background-position: 0 0;
            display: block;
            float: left;
            cursor: pointer;
            text-indent: -9999px;
            margin: 0 15px 0 0;
            &:hover,
            &.active {
              background-position: 0 -39px;
            }
          }
          .actu-location {
            float: left;
            height: 32px;
            width: 27px;
            background-repeat: no-repeat;
            background-image: url("../img/actu-icon.png");
            background-position: -36px 0;
            display: block;
            float: left;
            cursor: pointer;
            text-indent: -9999px;
            &:hover,
            &.active {
              background-position: -36px -39px;
            }
          }
        }
      }
    }
  }
}

.page-recherche-avancee {
  .col-sm-3 {
    @media (min-width: $tp) {
      width: 28.5%;
      margin: 33px 15px 0 0;
    }
    .well {
      padding: 0;
      margin: 0;
      @include default-disable();
      background-color: transparent;
      .block-title {
        position: relative;
        left: 0;
        top: 0;
      }
      #block-views-exp-recherche-avancee-page {
        background: #e9e9e9;
        padding: 0 5px;
        .form-text {
          background: #e9e9e9;
          border: 1px solid #7e7d7d;
          border-radius: 15px;
          box-shadow: none;
          color: #7e7d7d;
          @include font-style($frutiger-roman,
                  15px,
                  15px,
                  0px);
          height: 25px;
          margin: 0 10px 0 0;
          padding: 0 12px;
          width: 210px;
          @media (min-width: $tp) and (max-width: $site-size) {
            width: 155px;
          }
        }
        .input-group-addon {
          border: none;
          background-image: url("../img/recherche2-icon.png");
          background-repeat: no-repeat;
          background-position: 0 0;
          width: 24px;
          height: 25px;
          i {
            display: none;
          }
        }
      }
      .edl-genre-filter,
      .edl-age-filter,
      .edl-collection-filter {
        background: #e9e9e9;
        &.expanded {
          .block-title {
            span {
              @include transform(rotate(180deg));
            }
          }
        }
        &.active {
          .block-title {
            background-position: 0 0;
          }
        }
        .block-title {
          @include font-style($frutiger-roman,
                  15px,
                  15px,
                  0px);
          color: #535353;
          text-transform: uppercase;
          background-image: url("../img/chekbox-recherche.png");
          background-repeat: no-repeat;
          background-position: 0 -37px;
          padding: 0 0 0 25px;
          span {
            display: inline-block !important;
            float: none;
            background: transparent url("../img/scroll-recherche.png") no-repeat scroll 0 0;
            width: 14px;
            height: 9px;
            margin: 0 0 0 5px;
          }
        }
        .facet-collapsible-wrapper {
          padding: 0 30px;
          ul {
            li {
              margin: 0 0 10px;
              @include font-style($frutiger-roman,
                      15px,
                      15px,
                      0px);
              color: #7e7d7d;
              text-transform: uppercase;
              a {
                display: inline-block;
                @include font-style($frutiger-roman,
                        15px,
                        15px,
                        0px);
                color: #7e7d7d;
                text-transform: uppercase;
                background-image: url("../img/chekbox-recherche.png");
                background-repeat: no-repeat;
                background-position: 0 -37px;
                padding: 0 0 0 25px;
                &.facetapi-active {
                  background-position: 0 0;
                }
              }
            }
          }
        }
      }
      .edl-genre-filter {
        padding: 10px 20px 0 20px;
        .recherche-avancee {
          h2 {
            color: #616161;
            @include font-style($frutiger-roman,
                    22px,
                    22px,
                    0px);
            margin: 10px 0 15px;
            text-align: center;
            text-transform: uppercase;
            @media (min-width: $tp) and (max-width: $site-size) {
              font-size: 18px;
              line-height: 18px;
            }
          }
        }
      }
      .edl-age-filter,
      .edl-collection-filter {
        padding: 0 20px;
      }
      .edl-content-filter {
        padding: 20px;
        background: #e9e9e9;
        margin: 0 0 15px;
        .block-title {
          color: #616161;
          @include font-style($frutiger-roman,
                  22px,
                  22px,
                  0px);
          text-transform: uppercase;
          margin: 0 0 15px 0;
          padding: 0;
          @media (min-width: $tp) and (max-width: $site-size) {
            font-size: 18px;
            line-height: 18px;
          }
        }
        ul {
          li {
            margin: 0 0 10px 0;
            @include font-style($frutiger-roman,
                    15px,
                    15px,
                    0px);
            color: #000;
            text-transform: uppercase;
            label {
              margin: 0;
            }
            .checker {
              display: inline-block;
              width: 16px !important;
              height: 16px !important;
              margin-right: 10px !important;
              span {
                width: 16px !important;
                height: 16px !important;
                background-image: url("../img/chekbox-recherche.png");
                background-repeat: no-repeat;
                background-position: 0 -37px;
                &.checked {
                  background-position: 0 0;
                }
                input {
                  margin: 0;
                  cursor: pointer;
                }
              }
            }
            a {
              display: inline-block;
              @include font-style($frutiger-roman,
                      15px,
                      15px,
                      0px);
              color: #000;
              text-transform: uppercase;
            }
          }
        }
      }
    }
  }
  .col-sm-9 {
    .pager-load-more {
      a {
        color: #ff2204;
        @include font-style($frutiger-roman,
                15px,
                15px,
                0px);
        text-transform: uppercase;
        @include default-disable();
        background-color: transparent !important;
        div {
          display: none;
        }
        &:hover {
          background-color: transparent;
        }
      }
    }
    .view-header {
      color: #ff2204;
      @include font-style($frutiger-roman,
              18px,
              18px,
              0px);
      text-transform: uppercase;
      margin: 0 0 15px;
    }
    .views-row {
      margin: 10px 0 0 0;
      padding: 10px 0 0 0;
      border-top: 1px solid #c6c6c6;
      &:after {
        @include clearfix();
      }
      &.views-row-last {
        border-bottom: 1px solid #c6c6c6;
        padding: 10px 0;
      }
      .views-field {
        float: right;
        &.views-field-bonus-title,
        &.views-field-node-title,
        &.views-field-taxonomy-term-name {
          float: left;
          span {
            @include font-style($frutiger-light,
                    18px,
                    18px,
                    0px);
            color: $noir;
            a {
              @include font-style($frutiger-light,
                      18px,
                      18px,
                      0px);
              color: $noir;
              padding: 0 0 6px 0;
            }
          }
        }
        &.views-field-bonus-field-bonus-type {
          margin: 0 0 0 10px;
          span {
            color: $menu-4;
          }
        }
        &.views-field-node-field-auteur-qualite {
          margin: 0 0 0 10px;
          span {
            color: $menu-2;
          }
        }
        &.views-field-node-field-livre-genre {
          margin: 0 0 0 10px;
          span {
            color: $menu-1;
          }
        }
        &.views-field-node-field-type-evenement {
          margin: 0 0 0 10px;
          span {
            color: $menu-3;
          }
        }
        &.views-field-taxonomy-term-name-1 {
          margin: 0 0 0 10px;
          span {
            color: #ef8427;
          }
        }
        &.views-field-node-field-auteur-biographie,
        &.views-field-node-field-evenement-description,
        &.views-field-nothing,
        &.views-field-node-field-actus-description,
        &.views-field-node-field-livre-resume-value {
          clear: both;
          float: none;
          span {
            @include font-style($frutiger-roman,
                    14px,
                    14px,
                    0px);
            color: $noir;
            display: block;
          }
        }
        span {
          @include font-style($frutiger-bold,
                  14px,
                  17px,
                  0px);
          color: $menu-2;
        }
      }
    }
  }
}

body.admin-menu.adminimal-menu:before {
  height: 0;
}

#turnkey-credit {
  display: none;
}
