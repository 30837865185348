//
// Main container
//
.main-container {
  margin: 15px auto 0;
}
.front, .page-espace-libraires { // #Todo fixer class
  .main-container {
    margin: 0 auto;
  }
}
//
// Breadcrumb
//
.breadcrumb {
  z-index: 7;
  padding: 20px 0 10px 0;
  margin-bottom: 0;
  // @media (max-width: $m1) {
  //   display: none;
  // }
  padding: $grid-gutter-width 0;
  @include default-disable();
  li {
    &:before {
      color: $noir;
      content: "";
      padding: 0;
      display: none;
    }
    &.active {
      color: $textmaron;
      @include font-style($frutiger-bold, 14px, 16px, 0px);
    }
    padding: 0;
    margin: 0;
    @include font-style($frutiger-light, 16px, 16px, 0px);
    color: $textmaron;
    a {
      @include font-style($frutiger-light, 16px, 16px, 0px);
      color: $textmaron;
      margin: 0;
      padding: 0 21px 0 0;
      display: block;
      background: url("../img/arrow-breadcrumb.png") no-repeat 100% 4px transparent;
    }
  }
}
.breadcrumb-top {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  z-index: 100;
  .breadcrumb {
    margin-bottom: 0;
    color: #fff;
    a, li.active {
      color: #fff;
    }
  }
}
//
// Header
//
#page-header {
  position: relative;
}
//
// Sidebar
//
.region-sidebar-first {
  padding: 0;
  margin: 0;
  overflow: hidden;
  @include default-disable();
  background-color: transparent;
  .bloc-bonus-auteur-page, .picto-auteur {
    img {
      width: 100%;
      height: auto;
    }
  }
  #block-edl-livre-edl-livre-image {
    position: relative;
  }
}
/*all tags for display none*/
.caret {
  display: none;
}
.menu-fixed {
  .header-container {
    @include position-fix;
    z-index: 9998;
  }
  #navbar {
    .header-container.searching {
      .searchbar-result-container {
        top: 55px;
      }
    }
  }
  &.page-taxonomy-term {
    .bandeau-bloc {
      top: 70px;
      @media (max-width: $m1) {
        top: 90px;
      }
    }
  }
  .livre-submenu {
    top: 75px;
  }

}
.admin-menu {
  &.menu-fixed {
    #navbar .header-container.searching .searchbar-result-container {
      top: 85px;
    }
  }
  #navbar .header-container.searching .searchbar-result-container {
    top: 125px;
  }
}
#parallax-0.searching {
  .region-content {
    .view-filters {
      #edit-title-wrapper {
        .icon-recherche-main {
          display: none;
        }
      }
    }
  }
}
.main-container {
  h2.block-title { // #Todo supprimer...?
    text-transform: uppercase;
    @include font-style($frutiger-roman, 13px, 25px, 0px);
    //height: 25px;
    padding: 0 0 0 8px;
    margin: 0;
    z-index: 2;
  }
}
.flechete {
  width: 29px;
  height: 25px;
  display: inline-block;
  background-repeat: no-repeat;
  background-image: url("../img/flechette.png");
}
.bonus-picto {
  display: block;
  width: 68px;
  height: 68px;
  margin: 0 auto;
  background: url("../img/icon-bonus.png") no-repeat 0 0 transparent;
}
.searching .searchbar-result-container {
  .view-content {
    margin: 50px auto 0;
    width: 100%;
    height: auto;
    max-width: 800px;
    padding: 0 5px;
    @include view-row-search();
  }
  .view-empty {
    height: auto;
    margin: 50px auto 0;
    max-width: 800px;
    padding: 0 5px;
    width: 100%;
    text-align: center;
    p {
      margin: 0;
      padding: 50px 0 0 0;
      color: $noir;
      text-align: center;
      @include font-style($frutiger-light, 18px, 18px, 0px);
    }
  }
}
.front {
  #block-views-recherche-home-block-3 {
    @include transform(translateY(100px));
  }
  .searchbar {
    #block-views-recherche-home-block-3 {
      @include transform(translateY(0px) !important);
    }
  }
}
.searching-header, .searching-header-mobile {
  .main-container, .footer-container {
    @include blur(15px);
  }
}
.to-top, #backtotop {
  display: block;
  float: right;
  width: 57px;
  height: 57px;
  background-repeat: no-repeat;
  background-image: url("../img/scroll-to-top.png");
  background-position: 0 0;
  text-indent: -9999px;
  cursor: pointer;
  z-index: 999;
  @media (max-width: $m1) {
    display: none !important;
  }
}
/*colorbox*/
#colorbox, #cboxWrapper {
  @media (max-width: $tl) {
    max-width: 100% !important;
    width: 100% !important;
    #cboxContent, #cboxLoadedContent {
      width: 99% !important;
    }
  }
}
ul.links.list-inline {
  display: none;
}
/*button affiacge*/
.bouton-affichage {
  border-bottom: 1px solid #d4d0ce;
  border-top: 1px solid #d4d0ce;
  margin: 8px 0;
  padding: 8px 0;
  &:after {
    @include clearfix();
  }
  .nav-tabs {
    float: right;
    @include default-disable();
    li {
      margin: 0 10px 0 0;
      &.active {
        a, a:hover {
          background-position: 0 0;
        }
      }
      a, a:hover {
        @include default-disable();
        background-color: transparent;
        border: none !important;
        padding: 0;
        margin: 0;
        text-indent: -9999px;
        width: 24px;
        height: 24px;
        background-repeat: no-repeat;
        background-image: url("../img/tab-auteur.png");
        background-position: -35px 0;
        cursor: pointer;
      }
    }
  }
}
#views-exposed-form-auteur-list-block-1, #views-exposed-form-auteur-list-page /*, #edl-catalogue-search-form*/
{
  .views-exposed-widgets {
    margin: 0;
  }
  .views-exposed-widget {
    padding: 0;
  }
  .form-type-textfield {
    @include placeholder-text($frutiger-light, $noir, 14px);
    @media (max-width: $m1) {
      font-size: 26px;
    }
    input {
      border-radius: 15px;
      border: 1px solid $noir;
      background: #fff;
      height: 27px;
      width: 205px;
      box-shadow: none;
      color: $noir;
      @include font-style($frutiger-light, 14px, 27px, 0px);
      margin: 0;
      padding: 0 12px;
      @media (max-width: $m1) {
        height: 50px;
        @include font-style($frutiger-light, 26px, 50px, 0px);
        width: 100%;
      }
    }
  }
  .form-submit {
    display: inline-block;
    width: 25px;
    height: 25px;
    background: url("../img/loupe-noir.png") no-repeat 0 0 transparent;
    cursor: pointer;
    @include default-disable;
    text-indent: -9999px;
    margin: 0 0 0 8px;
    @media (max-width: $m1) {
      width: 48px;
      height: 48px;
      background: url("../img/loupe-noir-mobile.png") no-repeat 0 0 transparent;
    }
  }
}
#video-ajax-loader {
  .loader {
    background: url("../img/ajax-loader-video.gif") no-repeat 50% 50% transparent !important;
    width: 355px !important;
    margin: 50px 6px 0 0 !important;
    @media (min-width: $tp) and (max-width: $t) {
      width: 235px !important;
    }
    @media (max-width: $m1) {
      width: 100% !important;
      margin: 25px auto !important;
      &.loader-2, &.loader-3 {
        display: none;
      }
    }
  }
}
#views_infinite_scroll, #catalogue-ajax-loader, #liste-ajax-loader, #video-ajax-loader {
  clear: both;
  .loader {
    background: url("../img/ajax-loader.gif") no-repeat 50% 50% transparent;
    width: 140px;
    height: 32px;
    display: inline-block;
    margin: 0 10px 0 0;

    &.next-line {
      margin: 0;
    }
    @media (min-width: $tl) and (max-width: $site-size) {
      width: 133px;
    }
    @media (min-width: $tp) and (max-width: $t) {
      width: 138px;
      &.loader-6, &.loader-7 {
        display: none;
      }
    }
  }
}
.scroll-container {
  @include transition(transform, 0.5s, ease, 0s);
  @include transform(translateY(275px));
  cursor: pointer;
  &:hover {
    .scroll-page {
      background-color: transparent;
      background-image: url("../img/scroll-section-hover.png");
    }
  }
}
.scroll-container {
  top: 25%;
  left: 47%;
  position: absolute;
  width: 100%;
  .scroll-page {
    background-color: #fff;
    background-image: url("../img/scroll-section.png");
    background-position: 49% 55%;
    background-repeat: no-repeat;
    width: 62px;
    height: 62px;
    z-index: 50;
    border: 2px solid #fff;
    border-radius: 50%;
    @include transition(all, 0.3s, ease, 0s);
    cursor: pointer;
  }
}
.infoBox {
  padding: 15px;
  background: #fff;
}
#edl-catalogue-alert-form {
  margin: 0 auto;
  padding: 25px;
  width: 260px;
  input {
    height: 32px;
    background: #ebe7e7;
    @include default-disable;
    color: $textmaron;
    @include font-style($frutiger-light, 18px, 32px, 0px);
  }

  .form-submit {
    background: #c00418;
    @include default-disable;
    display: block;
    width: 50px;
    padding: 10px 0;
    margin: 0 auto;
    text-align: center;
    color: #fff;
    @include font-style($frutiger-roman, 16px, 16px, 0.5px);
    text-transform: uppercase;
  }
}
.region {
  .pagination {
    li {
      height: 19px;
      width: 19px;
      &.active {
        a {
          background-position: 0 0;
        }
      }
      &.pager-ellipsis {
        display: none;
      }
      &.next {
        a {
          background-repeat: no-repeat;
          background-image: url("../img/pager-nav.png");
          background-position: -232px 0;
          @include default-disable();
          padding: 0;
          margin: 0;
          height: 40px;
          width: 36px;
        }
      }
      &.prev {
        a {
          background-repeat: no-repeat;
          background-image: url("../img/pager-nav.png");
          background-position: 0 0;
          @include default-disable();
          padding: 0;
          margin: 0 10px 0 0;
          height: 40px;
          width: 36px;
        }
      }
      a {
        margin: 10px 25px 0 0;
        padding: 0;
        @include default-disable();
        background-repeat: no-repeat;
        background-image: url("../img/pager-active.png");
        background-position: -44px 0;
        width: 19px;
        height: 19px;
        text-indent: -9999px;
        background-color: transparent;
        &.progress-disabled {
          float: left;
        }
      }
    }
  }
}
.pager {
  margin: 20px 0 60px 0;
}
.pager li>a, .pager li>span {
  font-family: $frutiger-bold;
  border-width: 2px;
}
.livre-submenu {
  position: fixed;
  right: 0;
  top: 110px;
  z-index: 2;
  @media (max-width: $m1) {
    display: none;
  }
  a {
    background: #eaeaea;
    margin: 0 0 2px;
    @include font-style($frutiger-roman, 13px, 13px, 0px);
    color: #000;
    text-transform: uppercase;
    padding: 5px 10px;
    text-align: left;
  }
}
/*catalogue color*/
.node-type-livre.catalogue-1 {
  @include catalogue-color-theme(#febc1e, 0, 0, 0, 0, 0, 0);
}
.node-type-livre.catalogue-2 {
  @include catalogue-color-theme($catalogue-2, 0, -14px, 0, -23px, 0, -45px);
}
.node-type-livre.catalogue-3 {
  @include catalogue-color-theme($catalogue-3, 0, -29px, 0, -47px, 0, -88px);
}
.node-type-livre.catalogue-4 {
  @include catalogue-color-theme($catalogue-4, 0, -42px, 0, -69px, 0, -130px);
}
.node-type-livre.catalogue-5 {
  @include catalogue-color-theme($catalogue-5, 0, -56px, 0, -95px, 0, -172px);
}
.node-type-livre.catalogue-6 {
  @include catalogue-color-theme($catalogue-6, 0, -70px, 0, -118px, 0, -215px);
}
.node-type-livre.catalogue-7 {
  @include catalogue-color-theme($catalogue-7, 0, -84px, 0, -139px, 0, -255px);
}
.node-type-livre.catalogue-8 {
  @include catalogue-color-theme($catalogue-8, 0, -98px, 0, -164px, 0, -300px);
}
.node-type-livre.catalogue-9 {
  @include catalogue-color-theme($catalogue-9, 0, -113px, 0, -189px, 0, -350px);
}
.node-type-livre .ui-accordion-content .format-container .formats .format-right {
  @media (max-width: $ms) {
    float: none;
    width: auto;
  }
}
.livre-container {
  float: left;
  margin: 0 10px 10px 0;
  width: 240px;
  height: 295px;
  position: relative;
  overflow: hidden;
  @media (max-width: $m1) {
    height: auto;
    max-width: 270px;
    max-height: 335px;
    width: 44%;
    margin: 0 10% 50px 0;
    @media (max-width: $ms) {
      width: 100%;
      margin: 0 auto 50px;
      float: none;
    }
    &.even {
      float: left;
      margin: 0 0 50px 0;

      @media (max-width: $ms) {
        float: none;
        margin: 0 auto 50px;
      }
    }
  }
  @media (min-width: $tp) and (max-width: $t) {
    width: 237px;
    &.last {
      margin: 0 10px 10px 0 !important;
    }
  }
  @media (min-width: $screen-sm-min) {
    &.last {
      margin: 0 0 10px;
    }
  }
  &.catalogue-1 {
    background: #febc1e;
  }
  &.catalogue-2 {
    background: $catalogue-2;
  }
  &.catalogue-3 {
    background: $catalogue-3;
  }
  &.catalogue-4 {
    background: $catalogue-4;
  }
  &.catalogue-5 {
    background: $catalogue-5;
  }
  &.catalogue-6 {
    background: $catalogue-6;
  }
  &.catalogue-7 {
    background: $catalogue-7;
  }
  &.catalogue-8 {
    background: $catalogue-8;
  }
  &.catalogue-9 {
    background: $catalogue-9;
  }
  &:hover {
    .date-parution {
      @include opacity(0);
    }
    .livre-infos {
      top: 0;
      @include opacity(1);
    }
    .livre-image-content {
      img {
        @include filter-custom();
        @include transform-custom();
      }
    }
  }
  .date-parution {
    background: url("../img/overlay-catalogue.png") repeat 0 0 transparent;
    padding: 7px 0;
    text-align: center;
    color: #fff;
    @include font-style($frutiger-roman, 11px, 11px, 0px);
    text-transform: uppercase;
    @include transition(opacity, 0.45s, ease, 0s);
    @include opacity(1);
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
  }
  .livre-image-content {
    display: table-cell;
    height: 295px;
    text-align: center;
    vertical-align: middle;
    width: 240px;
    @media (max-width: $m1) {
      height: auto;
      /*padding: 40px;*/
      display: block;
      width: 100%;
    }
    img {
      max-height: 290px;
      width: 170px;
      @include transition(all, 0.45s, ease, 0s);
      @media (max-width: $m1) {
        width: 100%;
        max-height: 100%;
      }

    }
  }
  .livre-infos {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    @include opacity(0);
    @include transition(all, 0.45s, ease, 0s);
    width: 100%;
    z-index: 1;
    background: url("../img/overlay-catalogue.png") repeat 0 0 transparent;
    a {
      display: block;
      width: 100%;
      height: 100%;
      .livre-infos-wrapper {
        padding: 15px 20px;
        @media (min-width: $ml) and (max-width: $m) {
          padding: 8px 10px;
        }
        .livre-title {
          color: $catalogue-text;
          @include font-style($frutiger-roman, 16px, 18px, 0px);
          margin: 0 0 7px;
          @media (min-width: $ml) and (max-width: $m) {
            margin: 0 0 3px;
            @include font-style($frutiger-roman, 14px, 16px, 0px);
          }
        }
        .livre-auteur {
          color: #b8b6b6;
          @include font-style($frutiger-roman, 16px, 18px, 0px);
          margin: 0 0 12px;
          @media (min-width: $ml) and (max-width: $m) {
            margin: 0 0 7px;
            @include font-style($frutiger-roman, 15px, 15px, 0px);
          }
        }
        .livre-desc {
          color: #fdfefe;
          @include font-style($frutiger-roman, 14px, 16px, 0px);
          margin: 0 0 14px;
          @media (min-width: $ml) and (max-width: $m) {
            margin: 0 0 10px;
            line-height: 15px;
          }
        }
        .livre-age, .livre-collection {
          background: url("../img/overlay-inof-catalague.png") repeat 0 0 transparent;
          @include border-radius(15px);
          padding: 5px 20px;
          color: #fff;
          @include font-style($frutiger-bold, 14px, 14px, 0px);
          margin: 0 0 12px;
          display: table;
          min-width: 105px;
          @media (min-width: $ml) and (max-width: $m) {
            margin: 0 0 7px;
            min-width: 100px;
            padding: 4px 20px;
          }
        }

        .livre-fleche {
          background: url("../img/flechette-catalogue.png") no-repeat 0 0 transparent;
          width: 20px;
          height: 36px;
          display: block;
          float: right;
        }
      }
    }
  }
}
