.resources-body {
    section {
        margin-top: 40px;

        &:last-child {
            margin-bottom: 80px;
        }
    }

    h2,
    h3,
    h4 {
        margin: 0;
    }

    h2 {
        @include font-style($frutiger-bold, 20px, 28px, 0);
        color: $queen-blue;
    }

    .section-subtitle {
        margin-top: 12px;
    }

    .grid-container {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(150px, 320px));
        gap: 20px;
        margin-top: 20px;
        justify-content: center;
    }

    .card {
        &__item {
            background-color: #F1F1F1;
            border-top-right-radius: 8px;
            border-top-left-radius: 8px;
            border-bottom-left-radius: 8px;
            position: relative;
            overflow: hidden;
            padding: 20px 16px 20px 16px;
            transition: 220ms ease-in-out;
            will-change: transform;

            &:hover {
                transform: scale(1.02);
            }
        }

        &__link {
            color: #000000;

            &:after {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                z-index: 10;
            }
        }

        &__image {
            display: none;

            & + br {
                display: none;
            }
        }

        &__title-wrapper {
            display: flex;
            align-items: center;
            gap: 8px;

            & h3 {
                @include font-style($frutiger-bold, 20px, 28px, 0);
            }
        }

        h4 {
            @include font-style($frutiger-bold, 16px, 24px, 0);
            color: $queen-blue;
            margin-top: 8px;
        }

        &__description {
            @include font-style($frutiger-roman, 14px, 20px, 0);
            margin: 8px 0 0;
        }

        .triangle {
            width: 0;
            height: 0;
            border-left: 24px solid transparent;
            border-bottom: 24px solid #007BFF;
            position: absolute;
            bottom: 0;
            right: 0;
        }

    }

}

@media (min-width: 768px) {
    .resources-body {
        section {
            margin-top: 60px;
        }

        h2 {
            @include font-style($frutiger-bold, 32px, 40px, 0);
        }

        .grid-container {
            justify-content: start;
        }
    }
}

@media (min-width: 992px) {
    .resources-body {
        .card {
            &__image {
                display: inline;
                width: 100%;
                margin-bottom: 16px
            }

            &__description {
                @include font-style($frutiger-roman, 16px, 24px, 0);
            }
        }

        .grid-container {
            grid-template-columns: repeat(auto-fit, minmax(150px, 384px));
            gap: 24px;
        }
    }
}