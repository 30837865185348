@page {
  size: A4;
  margin: 0;
}
@media print {
  // Base
  html, body {
    width: 210mm;
    height: 297mm;
  }
  a:link:after, a:visited:after {
    content: normal !important;
  }
  .footer-container {
    display: none;
  }
  .container, .main-container{
    width: 100%;
  }
  // Typography
  h1{
    font-size: 20pt !important;
  }
  // Page livre
  .toolbar-wrapper, .recompense-majeur, .livre-tab, .tabs--primary, #infos-livre, #bonus-livre, #evenement-livre, #block-views-fausse-serie-block {
    display: none;
  }
  .main-container{
    #sidebar-left{
      float:left !important;
      width: 30% !important;
      margin: 0 !important;
    }
    #content{
      float:left !important;
      width: 70% !important;
      margin: 0 !important;
    }
    #sidebar-right{
      float:left !important;
      width: 100% !important;
      margin: 0 !important;
    }
  }
}

