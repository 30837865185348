.page-recherche-avancee {
  background-color: $gray-lightest;
  .main-container {
    margin-top: 84px;
  }
}

.view-recherche-avancee {
  .view-header {
    font-weight: bold;
  }
  // #Todo checker si surcharge Boostrap ...
  .view-content {
    margin-left: -15px;
    margin-right: -15px;
  }
}
// Résultats - cards
.teaser-block {
  background-color: #fff;
  padding: 10px 10px 10px 0;
  margin: 15px;
  position: relative;
  box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.14), 0 1px 7px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -1px rgba(0, 0, 0, 0.2);
  .livre-age {
    margin-right: 0;
  }
  .livre-genre {
    display: inline-block;
    float: left;
    font-weight: bold;
    margin: 0 5px 0 0;
    color: $gray-dark;
  }
  .views-field-node-field-type-evenement {
    font-weight: bold;
    margin-left: 5px;
  }
  .views-details-livre{
    margin-bottom: 5px;
  }
  .livre-isbn {
    color: $textmaron;
  }
  img {
    max-height: 205px;
    padding: 0;
  }
}

.teaser-readmore {
  text-align: right;
  font-weight: bold;
}
