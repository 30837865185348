// --------------------------------------------------
// - base
// - sidebar-left
// - sidebar-right
// - content
// - slider
// - couleurs
// - Modal addToCart
// --------------------------------------------------

//
// Base
//

// loader sur page livre
.node-type-livre .main-container {
  // #Todo A supprimer ?
  position: relative;
}

// Cacher Id - Effets delay au chargement de la page
#sidebar-left,
#content,
#sidebar-right {
  display: none;
  top: 30px;
  opacity: 0;
}

// fix colorbox
#colorbox #content {
  display: block;
  opacity: 1;
}

.node-type-livre {
  @media (min-width: $screen-md) {

    #sidebar-left,
    #content,
    #sidebar-right {
      padding-left: 12px;
      padding-right: 12px;
    }
  }
}

.link-arrow {
  background: url("../img/icones/readmore-arrow.svg") no-repeat center right;
  background-size: 12px;
  padding-right: 20px;
}

//
// Sidebar left
//
// Cover
/*@media (max-width: $screen-xs) {
  .image-container {
    margin-top: 20px;
    padding: 0;
  }
}*/
.image-container {
  position: relative;
  margin-top: 20px;
  // margin-bottom: ($grid-gutter-width*2);
  padding: 15px;
  border: 1px solid $gray-lighter;

  @media (min-width: $screen-sm-min) {
    margin-top: 0;
    margin-left: 30px;
    margin-right: 30px;
    padding: 30px;
  }

  @media (min-width: $screen-md-min) {
    margin-left: 0;
    margin-right: 0;
    padding: 0;
  }

  img {
    width: 100%;
    height: auto;
  }
}

#cover-flip {
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  opacity: 0;
  background-color: rgba(1, 1, 1, .8);
  color: #fff;
  font-size: 1.125em; //18px/16
  transition: $transition;

  &:hover {
    opacity: 1;
    transition: $transition;
  }

  img {
    width: 32px;
    height: 32px;
    margin-bottom: 15px;
  }
}

.block-edl-livre {
  .recompense-majeur {
    background: rgba(215, 0, 26, 0.8); //couleur = $rouge-livre
    color: #fff;
    width: 100%;
    position: absolute;
    font-size: 1.2em;
    bottom: $grid-gutter-width;
    left: 0;
    padding: ($grid-gutter-width) ($grid-gutter-width/2);
    line-height: 20px;
    text-align: center;

    @media (max-width: $m1) {
      bottom: ($grid-gutter-width*2);
    }
  }

  .recompense-autres-wrapper {
    &:after {
      @include clearfix();
    }

    &.active-recompenses,
    &.active-recompenses:hover {

      .btn-voir-rec,
      .btn-voir-rec:hover {
        background-color: #f4f4f4;
        color: $rouge-livre;
        background-image: url("../img/recompense-active.png");

        @media (max-width: $m1) {
          background-color: #e5e5e5;
          color: #000;
          background-image: url("../img/recompense-inactive.png");
        }
      }
    }

    &:hover {
      .btn-voir-rec {
        background-color: $rouge-fonce-livre;
      }
    }

    .btn-voir-rec {
      border-right: 1px solid #e3d1d4;
      background-color: $accordion;
      width: 204px;
      height: 27px;
      @include font-style($frutiger-light, 14px, 27px, 0px);
      color: #fff;
      display: block;
      float: left;
      background-image: url("../img/recompense-inactive.png");
      background-position: 95% 50%;
      background-repeat: no-repeat;
      padding: 0 0 0 12px;
      @include transition(all, 0.3s, ease, 0s);

      &.full {
        cursor: pointer;
      }

      &.empty {
        background-image: none;
      }

      @media (max-width: $m1) {
        height: 40px;
        background-color: #731726;
        @include font-style($frutiger-light, 22px, 40px, 0px);
        width: 50%;
      }

      @media (max-width: $ms) {
        @include font-style($frutiger-light, 13px, 40px, 0px);
      }
    }
  }
}

//Toolbar
.toolbar-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  font-size: 0.95em;
  background-color: transparent;

  @media (min-width: $screen-md-min) {
    justify-content: flex-start;
  }

  a {
    display: inline-block;
    color: $gray-dark;
  }
}

.btn-voir-livre,
.btn-print,
.btn-favorite,
.btn-favorite-deconnecte,
.alert-livre {
  padding: 15px;
  text-align: center;
  color: $gray-dark;
  cursor: pointer;
  transition: $transition;

  &:hover {
    opacity: 0.7;
    transition: $transition;
  }

  .i-svg {
    display: block;
    margin: 0 auto;
    width: auto;
    height: 24px;
  }

  @media (min-width: $screen-sm-min) {
    padding: 30px 45px;
  }

  @media (min-width: $screen-md-min) {
    padding: 10px;

    .i-svg {
      height: 18px;
    }

  }
}

a.flag-action {
  display: block;
}

// Fix flag
span.flag-throbber {
  display: none;
}

.livre-share {
  padding: $grid-gutter-width 8px ($grid-gutter-width * 2);
  margin-bottom: $grid-gutter-width;
  text-transform: uppercase;
  text-align: center;
  cursor: pointer;
}

// Collapse
.node-type-livre {
  .panel-group .panel+.panel {
    margin-top: 0;
  }

  .panel-heading {
    padding: 12px;
  }

  .panel-default>.panel-heading,
  .panel {
    color: #333;
    background-color: transparent;
    border-color: #ddd;
    border-bottom: none;
    border-left: none;
    border-right: none;
  }

  .panel-title {
    a {
      display: flex;
      justify-content: space-between;
      font-family: $frutiger-bold;
      width: 100%;
    }
  }

  .panel-body {
    border: none !important;
  }
}

// Icone croix animée
.collapsed .cross {
  &:before {
    background-color: $gray-light;
  }

  &:after {
    opacity: 1;
    background-color: $gray-light;
    transition: all 0.3s ease-in;
    transform: rotate(0);
  }
}

.cross {
  position: relative;
  float: right;
  width: 14px;
  height: 14px;

  &:before {
    position: absolute;
    display: block;
    content: '';
    width: 100%;
    height: 12%;
    top: 47%;
    left: 0;
    background-color: $gray-light;
  }

  &:after {
    position: absolute;
    display: block;
    content: '';
    width: 12%;
    height: 100%;
    left: 47%;
    top: 0;
    opacity: 0;
    transform: rotate(90deg);
    transition: $transition;
    background-color: $gray-light;
  }
}

// Collection
.current-collection {

  .panel-heading,
  &.panel {
    background-color: $gray-lightest !important;
  }

  .panel-heading {
    padding: $grid-gutter-width;
  }
}

.col-principale {

  .panel-heading,
  .panel-collapse {
    background-color: $gray-lightest !important;
  }
}

.livre-dispo {
  font-family: $frutiger-bold;
  text-transform: uppercase;
}

.livre-isbn {
  color: $gray-light;
}

//
// sidebar right
//
// auteurs
#auteurs-livre {
  margin-bottom: 15px;
}

.author-item {
  display: flex;
  justify-content: start;
  margin-bottom: 10px;

  &:last-of-type {
    margin-bottom: 0;
  }
}

.author-content {
  padding: 0 10px 5px 10px;
}

.author-job {
  color: $gray-light;
}

.author-title {
  margin-top: 0;
  margin-bottom: 5px;
  font-size: 16px;
  font-family: $frutiger-bold;
  color: $gray-base;
}

// accordion
#accordion-right {

  .panel-default>.panel-heading,
  .panel {
    background-color: #fff;
  }

  .panel-heading {
    padding: 12px 8px;
  }

  .panel-title {
    text-transform: uppercase;
    color: $gray-light;
    font-size: 14px;
    letter-spacing: 0;
  }
}

// Informations complémentaires
.autres-recompenses {
  top: 100%;
  background: #fff;
  width: 100%;
  left: 0;
  background: #f4f4f4;
  position: absolute;

  @media (max-width: $m1) {
    background: #e5e5e5;
    z-index: 1;
  }

  li {
    border-top: 2px solid #fff;
    padding: 6px 0 6px 12px;
    @include font-style($frutiger-light, 13px, 13px, 0px);
    color: $noir;
  }
}

// Bonus
.bonus-item {
  display: flex;
  justify-content: start;
  margin-bottom: 15px;
}

.bonus-icon {
  margin-right: 10px;
  width: 32px;
  height: 32px;
  background-image: url("../img/icones/bonus-icons.gif");
  background-size: 800%;
}

.bonus-video {
  background-position: 40% top;
}

.bonus-game {
  background-position: right top;
}

.bonus-topic {
  background-position: left top;
}

.bonus-title {
  color: $gray-light;
  font-family: $frutiger-bold;
}

.bonus-desc {
  color: $gray-light;
  font-size: 1em;
  line-height: 1em;
}

.bonus-lien {
  display: inline-block;
  margin-top: 5px;
  color: $brand-danger;

  a {
    font-size: 0.9em;
    color: $brand-danger;

    @media (min-width: $screen-lg) {
      font-size: 1em;
    }
  }
}

// Evenement
#evenement-livre {
  .views-row {
    margin-bottom: 15px;
  }

  .type-event,
  .link-event {
    margin-top: 10px;
    color: $p-actu;
  }

  .title-event,
  .date-event {
    font-family: $frutiger-bold;
  }

  .desc-event p {
    line-height: 1em;
    font-size: 1em;
    margin: 0;

    &:after {
      content: ' ...';
    }
  }

  .city-event {
    font-family: $frutiger-bold;
  }
}

//
// Content
//
.titre-livre {
  text-transform: uppercase;
  font-family: "FrutigerLTStd-Light";
  font-size: 2em;
  line-height: 0.9em;
  margin-top: 0;
  margin-bottom: 1rem;

  @media(min-width: $screen-md) {
    font-size: 2.3em;
  }
}

.infos-wrapper {
  .info-upper-wrapper {
    &:after {
      @include clearfix();
    }

    margin-bottom: ($grid-gutter-width*2);
  }
}

.livre-label {
  margin-right: 10px;
  font-weight: bold;
  line-height: 1.6em;
}

.livre-age,
.livre-genre {
  display: inline-block;
}

.livre-age {
  font-family: $frutiger-bold;
  margin-right: 4px;
  margin-bottom: 10px;
}

.livre-genre {
  font-size: $frutiger-bold;
  text-transform: capitalize;
  margin-left: 4px;
}

.livre-ministere {
  color: #e74d53;
  @include font-style($frutiger-roman, 13px, 16px, 0px);
  height: 16px;
  float: left;
  background: url("../img/livre-selectione.png") no-repeat left center transparent;
  padding: 0 0 0 30px;

  @media (min-width: $tp) and (max-width: $t) {
    width: 185px;
    height: auto;
  }

  @media (max-width: $m1) {
    float: right;
    height: 30px;
    width: 235px;
  }
}

.node-type-livre #commerce-cart-add-to-cart-form #edit-line-item-fields {
  display: none;
}

// Thèmes
.livre-themes {
  margin-bottom: $grid-gutter-width;

  @media (max-width: $m1) {
    border-bottom: none;
  }
}

.ce-terme {
  a {
    @include font-style($frutiger-roman, 16px, 1.2, 0px);
    display: inline-block;
    margin-right: 10px;
    border: 1px solid #fff;
    border-radius: 60px;
    transition: $transition;

    &:hover {
      color: $gray-light !important;
    }
  }
}

.livre-accroche {
  margin-bottom: $grid-gutter-width;
  padding: 15px 0 0;
  @include font-style($frutiger-light, 1.6rem, 1.4, 0);
  color: $noir;
  text-align: justify;

  h2 {
    @include font-style($frutiger-bold, 20px, 20px, 0px);
    margin: 0 0 5px;
  }

  @media (min-width: $screen-sm-min) {
    font-size: 1.7rem;
    line-height: 1.5;
  }
}

//
// fiche technique du livre & Achat
//
.livre-tab {
  margin-top: ($grid-gutter-width * 2);
  margin-bottom: $grid-gutter-width;

  h2.block-title {
    margin-bottom: 10px;
  }

  .ui-tabs-anchor h2 {
    margin: 0;
    font-size: 16px;
  }

  .nav-tabs {
    border: none;

    .icone-svg {
      margin-right: $grid-gutter-width;
      width: 20px;
      height: 26px;
    }

    a {
      padding: 8px 12px;
      color: $gray-dark;
      background-color: #fff;
      border: 2px solid $gray-lighter;
      border-radius: 4px;
    }
  }

  .nav-tabs>li {
    font-family: $frutiger-bold;
    border-radius: 0;
    margin-right: 5px;
    margin-bottom: 5px;

    @media (max-width: $screen-xs-max) {
      width: 100%;
    }
  }

  .nav-tabs>li.active>a,
  .nav-tabs>li.active>a:focus,
  .nav-tabs>li.active>a:hover {
    color: #fff;
    background-color: $pink;
    border: 1px solid $pink;
    cursor: pointer;
  }

  .tab-content {
    background-color: #fff;
    border: 2px solid $gray-lighter;
    border-radius: 4px;

    a {
      color: $pink;

      &:hover {
        color: $pink-dark;
      }
    }
  }

  .tab-content>.tab-pane {
    padding: 20px;
  }

  .livre-prix {
    margin-bottom: 20px;
    font-size: 1.5em; //24px/16
    font-family: $frutiger-bold;
  }
}

.product-description {
  margin-top: 10px;
  margin-bottom: 20px;
  font-size: 1.0625em; //17px/16
}

.product-details {
  margin-bottom: 20px;
}

.livre-infos {
  margin-top: 10px;
  margin-bottom: 10px;
}

.product-atc {
  margin-top: 5px;
  word-break: break-word;
}

//
// Boutons
.btn-teaser,
.btn-purchase,
.commerce-add-to-cart button#edit-submit {
  display: inline-flex;
  justify-content: center;
  padding: 8px 24px;

  &:focus,
  &.active:focus,
  &:active:focus {
    outline: none;
  }

  @media (max-width: $screen-xs-max) {
    width: 100%;
    margin-bottom: 10px;
  }

  .glyphicon {
    font-size: 1.2em;
    margin-right: 5px;
  }
}

@media (max-width: $screen-xs-max) {
  .commerce-add-to-cart {
    width: 100%;
  }

  .commerce-add-to-cart button#edit-submit--2 {
    width: 100%;
    margin-bottom: 10px;
  }
}

.btn-teaser {
  background-color: transparent;
  color: $gray-light !important;
  border: 2px solid $gray;
  border-radius: 60px;

  a {
    color: $gray-light !important;
  }

  &:hover {
    border-color: #333;
  }
}

.btn-purchase,
.commerce-add-to-cart button#edit-submit,
.commerce-add-to-cart button#edit-submit--2,
.commerce-add-to-cart button#edit-submit--3 {
  padding-left: 30px;
  padding-right: 30px;
  color: #fff !important;
  background-color: $pink;
  border-color: $pink;

  @media (min-width: $screen-sm-min) {
    margin-right: 10px;
  }

  &:hover,
  &:focus,
  &:active {
    color: #fff !important;
    background-color: $pink-dark;
    border-color: $pink-dark;

    a {
      color: #fff !important;
    }
  }
}

.btn-purchase-sm {
  padding: 6px 10px;
  font-size: 0.875em; //14px/16
  color: #fff !important;
  background-color: $pink;
  border-color: $pink;

  @media (max-width: $screen-xs) {
    width: 100%;
  }

  &:hover,
  &:focus,
  &:active {
    color: #fff !important;
    background-color: $pink-dark;
    border-color: $pink-dark;

    a {
      color: #fff !important;
    }
  }

  &:focus,
  &.active:focus,
  &:active:focus {
    outline: none;
  }
}

// form purchase
.commerce-add-to-cart {
  display: inline-block; //Fix align btn-teaser
}

// Achat sur librairies indépendantes
.collapseList-content {
  margin-top: $grid-gutter-width;
  margin-bottom: $grid-gutter-width;
  text-align: justify;
}

#purchase-list {
  margin-bottom: 30px;

  li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ddd;
    padding-top: 8px;
    padding-bottom: 8px;

    &:first-child {
      border-top: 1px solid #ddd;
    }

    @media (max-width: $screen-xs-max) {
      flex-direction: column;
    }
  }

  .btn-purchase-sm {
    @media (min-width: $screen-sm-min) {
      margin-left: 10px;
    }
  }
}

@media (max-width: $screen-xs-max) {
  .purchase-item {
    white-space: pre-line;
  }
}

.map-librairie iframe {
  border: none;
}

.node-type-livre .livre-annee-premiere-vente,
.node-type-livre .livre-atc,
.node-type-livre .livre-duree {
  color: $gray-dark;
  font-family: "FrutigerLTStd-Roman";
  margin: 0 7px 0 0;
}

//
// Informations complémentaires
//
.infos-wrapper {
  display: flex;
  justify-content: start;
  align-items: start;
  margin-top: 10px;
}

.icon-infos {
  font-size: 1.1em;
  margin-right: 15px;
}

.node-type-livre .livre-infos-complementaire {
  text-align: justify;
  font-size: 0.9em;
}

//
// Dans la même collection - Slider
//
.node-type-livre {
  .view-fausse-serie .title {
    background-color: rgba(1, 1, 1, 0.8);
    width: 100%;
    height: 100%;
    opacity: 0 !important;
    position: absolute;
    top: 0;
    transition: all 0.45s ease 0s;
    z-index: 1;
    color: #f5fbfb;
    font-family: "FrutigerLTStd-Light";
    font-size: 14px;
    letter-spacing: 0;
    text-align: center;
    line-height: 18px;
    margin: 0 0 7px;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .slide-collection {
    position: absolute;
    left: 0;
    bottom: 0;
    padding: 5px;
    color: #fff;
  }
}

.node-type-livre .view-fausse-serie li:hover .title {
  opacity: 80 !important;
}

.node-type-livre .format-container-chut h3 {
  font-size: 12px !important;
}

.node-type-livre .format-container-chut .info_complementaire_collection_chut {
  margin-bottom: 10px;
}

#block-views-fausse-serie-block {
  position: relative;

  .block-title {
    margin-top: 15px; // Fix align
  }
}

.node-type-livre a.lien-autre {
  position: relative;
  display: block;
  box-shadow: $box-shadow;
}

.node-type-livre {

  .view-autres-lecture,
  .view-fausse-serie {
    padding: 20px 0;
    margin: 0 0 30px;

    .flex-viewport {
      margin: 0 auto;
      width: 100%;

      li {
        margin: $grid-gutter-width;
        width: 130px !important;
        height: auto;

        img {
          width: 100%;
          height: auto;
        }
      }
    }

    .flex-control-nav {
      display: none;
    }

    .flex-direction-nav {
      width: 100%;
      max-width: 80px;
      height: 72px;
      position: absolute;
      bottom: 0;
      right: 10px;

      li a {
        opacity: 1 !important;
        height: 72px;
        width: 18px;
        text-indent: -9999px;
        background-repeat: no-repeat;
        background-image: url(../img/fleche-autre-lecturer.png);
        text-align: left;
        top: 0px;
        filter: brightness(0.6);

        &.flex-prev {
          left: 14px; // Fix size icon
          background-position: 0 0;
        }

        &.flex-next {
          right: 0;
          background-position: 100% 0;
        }
      }

      @media (min-width: $screen-sm) {
        top: 0;
        bottom: auto;
      }
    }
  }

  h2.block-title {
    position: relative;
    left: 0;
    padding: 0;
    color: $gray-base;
    top: auto; // #Todo fix temporaire
    @include font-style($frutiger-bold,
      1.3em,
      28px,
      0px);
  }
}

//== Logos Audio
//
.audio-logo {
  width: 113px;
  height: 136px;
  display: inline-block;
  background-image: url("/sites/all/themes/edl/img/icones/sprite-audio.png");
}

.audio-mouche {
  background-position: -2px 0;
}

.audio-neuf {
  background-position: -123px 0;
}

.audio-medium {
  background-position: -240px 0;
}

.audio-mediumPlus {
  background-position: -355px 0;
}

//
// Couleurs fiche livre par âge
//
// Album filmé
.catalogue-20 {

  .titre-livre,
  .livre-age,
  .ce-terme a,
  a.author-link,
  .author-title a {
    color: #37beb7;
  }

  .current-collection {
    .panel-title {
      color: #37beb7;
    }
  }
}

//
// Modal addToCart
.addToCart-title {
  margin-bottom: 15px;
  font-family: $frutiger-bold;

  &:before {
    content: '';
    display: block;
    width: 53px;
    height: 47px;
    margin: 0 auto 15px auto;
    background: url(../img/commerce/add-to-cart.svg) center no-repeat;
  }
}

.addToCart-btn {
  display: block !important;
  margin-top: 15px;
  margin-bottom: 15px;
}

.addToCart-or {
  margin-bottom: 15px;

  &:after,
  &:before {
    content: '';
    display: inline-block;
    width: 30px;
    height: 2px;
    margin-bottom: 3px;
    background-color: $gray-lighter;
  }

  &:before {
    margin-right: 10px;
  }

  &:after {
    margin-left: 10px;
  }
}

.addToCart-back {
  color: $pink;
}

.click-collect-block {
  display: flex;
  flex-direction: column;
  max-width: 700px;

  &__wrapper {
    display: flex;
    align-items: center;
    gap: 13px;

    >img {
      width: 30% !important;
    }
  }

  &__right-side {
    width: 70%;

    p {
      margin: 0;
    }

    p:first-child {
      @include font-style($frutiger-bold, 15px, 21px, 0);
    }

    p:last-child {
      @include font-style($frutiger-roman, 15px, 21px, 0);
    }

    .click-collect-link-desktop-wrapper {
      display: none;
    }
  }

  .click-collect-link-mobile-wrapper {
    max-width: 333px;
    margin-top: 16px;
    display: flex;
    justify-content: center;
    gap: 12px;
  }
}

@media (min-width: 992px) {
  .click-collect-block {
    .click-collect-link-mobile-wrapper {
      display: none;
    }

    .click-collect-link-desktop-wrapper {
      display: block;
      max-width: fit-content;
      margin-top: 16px;
      display: flex;
      justify-content: start;
      gap: 12px;
    }
  }
}