/*** PAGE PANIER ***/
/*
.page-cart .commerce_coupon:not(.bottom){
	display:none;
}

.bas-panier{
	width:100%;
}

.bas-panier .code-promo{
	width:60%;
}
.bas-panier .prix-total{
	width:28%;
}
.code-promo input{
	width: auto;
}

.bas-panier .prix-total-chiffres p,
.bas-panier .prix-total p{
	margin-top:6px;
}

.page-checkout #commerce-checkout-coupon-ajax-wrapper{
	margin-bottom:20px;
}

.page-checkout #edit-cart-contents,
.page-checkout #commerce-checkout-coupon-ajax-wrapper{
	width:49%;
	float:left;
}

.page-checkout #commerce-checkout-coupon-ajax-wrapper{
	float:right;
}

.page-checkout #edit-commerce-coupon,
.page-checkout #commerce-checkout-coupon-ajax-wrapper #commerce-checkout-coupon-ajax-wrapper{
	width:100%;
}

#edit-commerce-payment{
	clear:both;
}


.page-checkout- #commerce-checkout-form-checkout #edit-commerce-payment legend{
	display:block;
}

#edit-commerce-payment legend .panel-title{
	content:'toto';
}

.form-item-commerce-payment-payment-details-commerce-paylinebymonext-contract input{
	display:inline!important;
	margin-top:15px;
}

.form-item-commerce-payment-payment-details-commerce-paylinebymonext-contract label{
	min-width:250px;
	text-align:left;
}*/

/*** CHECKOUT ***/

#edit-terms-conditions{
	width: 100%;
	clear:both;
}


/** DISPLAY REDCHERCHE ***/


body.html.front #block-views-recherche-home-block-3 {
    -webkit-transform: translateY(0) !important;
    transform: translateY(0) !important;
    -moz-transform: translateY(0) !important;
}

#commerce-cart-add-to-cart-form,
#add_cart_not_logged{
	display:none;
}

#vod .btn-purchase{
	color:white;
}

.product_download,
.commerce-add-to-cart button{
	white-space: normal;
}

.commerce-add-to-cart .form-wrapper{
	display:none;
}

.page-auteur-list .region-content #block-system-main .view-auteur-list .pager, .page-auteur-list .region-content #block-views-auteur-list-block-1 .view-auteur-list .pager, .page-theme .pager{
	display: block!important;
}
