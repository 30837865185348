.page-les-collections {
  .collection-link {
    display: block;
    span {
      width: 100%;
      height: 87px;
      display: block;
    }
  }
  .view-id-collection {
    margin-bottom: 60px;
    @media(min-width: $screen-sm-min) {
      margin-bottom: 90px;
    }
  }
  .collection-photo {
    @media (max-width: $screen-xs) {
      margin-bottom: 10px;
    }
    &:last-child {
      margin: 0 0 10px;
    }
    &.active {
      a {
        background: $bg-hover;
        overflow: visible;
        span {
          @include collection-active();
          &:after {
            background: url("../img/arrow-rouge.png") no-repeat bottom center transparent;
            content: " ";
            height: 25px;
            width: 100%;
            position: absolute;
            left: 0;
            bottom: -25px;
          }
        }
      }
    }
    a {
      @include balayage-gauche(#f1f1f1,
              $bg-hover);
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: row;
      padding: 2rem 1rem;
      span {
        display: block;
        height: 42px;
        width: 100%;
        @include collection-page();
        @media (max-width: $site-size) {
          @include background-size-collection();
        }
        @media (min-width: $tl) and (max-width: $site-size) {
          width: 243px;
        }
        @media (min-width: $tp) and (max-width: $t) {
          width: 179px;
          height: 70px;
        }
        @media (min-width: $ml) and (max-width: $m) {
          height: 75px;
          max-width: 290px;
          width: 100%;
        }
        @media (max-width: $ms) {
          height: 55px;
          max-width: 290px;
          width: 100%;
        }
      }
    }
  }
}
// Cacher collection "Coédition"
.collection-bg2100 {
  display: none !important;
}
