//
// Bandeau de partage sur les réseaux sociaux
.bandeau-partage {
  margin-top: ($grid-gutter-width / 2);
  display: flex;
  align-items: center;
  justify-content: center;
}

.partage-facebook,
.partage-twitter,
.partage-email {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: ($grid-gutter-width / 2);
  border-radius: 50%;
  &:hover{
  	opacity: 0.8;
  	box-shadow: $box-shadow;
  }
}

.partage-facebook {
  width: 42px;
  height: 42px;
  border: #254887 1px solid;
}

.partage-twitter {
  width: 42px;
  height: 42px;
  border: #33bdf1 1px solid;
}

.partage-email {
  width: 42px;
  height: 42px;
  border: #333 1px solid;
}

