/*page lists catalogue*/
@media (max-width: 767px) {
  .page-taxonomy #block-edl-catalogue-edl-catalogue-mobile {
    margin: 20px 0 0 0;
  }
  .page-taxonomy #block-edl-catalogue-edl-catalogue-mobile #tab1 {
    border-top: 1px solid #447daf;
    margin: 0 20px 0;
    padding: 20px 0 0 0;
  }
}
@media (max-width: 767px) {
  .page-taxonomy .container {
    max-width: 100%;
  }
  .page-taxonomy .container #block-edl-catalogue-edl-filtre-catalogue-mobile .filter-container .ui-accordion-header, .page-taxonomy .container #block-edl-catalogue-edl-filtre-catalogue-mobile .filter-container a {
    border-top: 1px solid #8f8f8f;
    border-bottom: none !important;
    padding: 15px 60px 15px 90px;
    margin: 0;
    background: #fff;
    text-transform: uppercase;
    font-family: "FrutigerLTStd-Light";
    font-size: 28px;
    line-height: 28px;
    letter-spacing: 0px;
    color: #000;
    background-repeat: no-repeat;
    background: url("../img/select-cat-mobile.png");
    background-position: 28px -452px;
    border-radius: 0;
    box-shadow: none;
  }
  .page-taxonomy .container #block-edl-catalogue-edl-filtre-catalogue-mobile .filter-container .ui-accordion-header.ui-state-active, .page-taxonomy .container #block-edl-catalogue-edl-filtre-catalogue-mobile .filter-container .ui-accordion-header.active, .page-taxonomy .container #block-edl-catalogue-edl-filtre-catalogue-mobile .filter-container a.ui-state-active, .page-taxonomy .container #block-edl-catalogue-edl-filtre-catalogue-mobile .filter-container a.active {
    background-position: 28px 12px;
  }
  .page-taxonomy .container #block-edl-catalogue-edl-filtre-catalogue-mobile .filter-container .ui-accordion-header.ui-state-active span, .page-taxonomy .container #block-edl-catalogue-edl-filtre-catalogue-mobile .filter-container .ui-accordion-header.active span, .page-taxonomy .container #block-edl-catalogue-edl-filtre-catalogue-mobile .filter-container a.ui-state-active span, .page-taxonomy .container #block-edl-catalogue-edl-filtre-catalogue-mobile .filter-container a.active span {
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg);
    -moz-transform: rotate(180deg);
  }
  .page-taxonomy .container #block-edl-catalogue-edl-filtre-catalogue-mobile .filter-container .ui-accordion-header span, .page-taxonomy .container #block-edl-catalogue-edl-filtre-catalogue-mobile .filter-container a span {
    right: 25px;
    left: auto;
    background: url("../img/arrow-mobile-cat.png") no-repeat 0 0 transparent;
    width: 36px;
    height: 19px;
    display: block;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    -ms-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
  }
  .page-taxonomy .container #block-edl-catalogue-edl-filtre-catalogue-mobile .filter-container .ui-accordion-content {
    height: auto !important;
    padding: 0;
  }
  .page-taxonomy .container #block-edl-catalogue-edl-filtre-catalogue-mobile .filter-container .ui-accordion-content a {
    border: none;
    color: #3774a9;
    text-transform: none;
    background-position: 95px -309px;
    padding: 15px 0 15px 155px;
    line-height: 34px;
  }
  .page-taxonomy .container #block-edl-catalogue-edl-filtre-catalogue-mobile .filter-container .ui-accordion-content a.active {
    background-position: 95px -165px;
  }
  .page-taxonomy .container #block-edl-catalogue-edl-filtre-catalogue-mobile .recherche-catalogue {
    border-top: 1px solid #8f8f8f;
    padding: 15px 0 25px;
  }
  .page-taxonomy .container #block-edl-catalogue-edl-filtre-catalogue-mobile .recherche-catalogue:after {
    content: ".";
    display: block;
    clear: both;
    visibility: hidden;
    line-height: 0;
    height: 0;
  }
  .page-taxonomy .container #block-edl-catalogue-edl-filtre-catalogue-mobile .recherche-catalogue #edl-catalogue-search-form {
    padding: 0 25px;
  }
  .page-taxonomy .container #block-edl-catalogue-edl-filtre-catalogue-mobile .recherche-catalogue #edl-catalogue-search-form .form-type-textfield {
    float: left;
    width: 75%;
  }
  .ppage-taxonomy.container #block-edl-catalogue-edl-filtre-catalogue-mobile .recherche-catalogue #edl-catalogue-search-form .form-type-textfield input::-webkit-input-placeholder {
    font-family: "FrutigerLTStd-Light" !important;
    color: #a09d9d !important;
    font-size: 26px !important;
    opacity: 1 !important;
  }
  .page-taxonomy .container #block-edl-catalogue-edl-filtre-catalogue-mobile .recherche-catalogue #edl-catalogue-search-form .form-type-textfield input:-moz-placeholder {
    font-family: "FrutigerLTStd-Light" !important;
    color: #a09d9d !important;
    font-size: 26px !important;
    opacity: 1 !important;
  }
  .page-taxonomy .container #block-edl-catalogue-edl-filtre-catalogue-mobile .recherche-catalogue #edl-catalogue-search-form .form-type-textfield input:-ms-input-placeholder {
    font-family: "FrutigerLTStd-Light" !important;
    color: #a09d9d !important;
    font-size: 26px !important;
    opacity: 1 !important;
  }
  .page-taxonomy .container #block-edl-catalogue-edl-filtre-catalogue-mobile .recherche-catalogue #edl-catalogue-search-form .form-type-textfield input::-moz-placeholder {
    font-family: "FrutigerLTStd-Light" !important;
    color: #a09d9d !important;
    font-size: 26px !important;
    opacity: 1 !important;
  }
  .page-taxonomy .container #block-edl-catalogue-edl-filtre-catalogue-mobile .recherche-catalogue #edl-catalogue-search-form .form-type-textfield input {
    height: 48px;
    border: 1px solid #d4d0ce;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    border-radius: 50px;
    -o-border-radius: 50px;
    font-family: "FrutigerLTStd-Light";
    font-size: 26px;
    line-height: 48px;
    letter-spacing: 0px;
    color: #a09d9d;
    padding: 0 22px;
  }
  .page-taxonomy .container #block-edl-catalogue-edl-filtre-catalogue-mobile .recherche-catalogue #edl-catalogue-search-form button {
    float: right;
    width: 48px;
    height: 48px;
    margin: 0;
    background: url("../img/recherche-loupe-catalogue.png") no-repeat 0 0 transparent;
  }
  .page-taxonomy .container #block-edl-catalogue-edl-filtre-catalogue-mobile .bouton-affichage {
    margin: 0 0 30px 0;
    padding: 0;
    border: none;
  }
  .page-taxonomy .container #block-edl-catalogue-edl-filtre-catalogue-mobile .bouton-affichage .nav-tabs li {
    margin: 0 20px 0 0;
  }
  .page-taxonomy .container #block-edl-catalogue-edl-filtre-catalogue-mobile .bouton-affichage .nav-tabs li.active a, .page-taxonomy .container #block-edl-catalogue-edl-filtre-catalogue-mobile .bouton-affichage .nav-tabs li.active a:hover {
    background-position: -80px 0;
  }
  .page-taxonomy .container #block-edl-catalogue-edl-filtre-catalogue-mobile .bouton-affichage .nav-tabs li a, .page-taxonomy .container #block-edl-catalogue-edl-filtre-catalogue-mobile .bouton-affichage .nav-tabs li a:hover {
    background-image: url("../img/tab-catalogue-mobile.png");
    background-position: 0 0;
    width: 55px;
    height: 55px;
  }
}
.page-taxonomy .taxo_reduit .region-content, .page-taxonomy .taxo_reduit .region-sidebar-second {
  margin: 0 auto;
  max-width: 990px;
  width: 100%;
}
.page-theme .region-content {
  margin: 0 auto;
  max-width: 1000px;
  width: 100%;
}
.view-taxonomy-term-theme > .text-center {
  clear: both;
}
.page-taxonomy .region-content #block-edl-catalogue-edl-catalogue-mobile, .page-taxonomy .region-sidebar-second #block-edl-catalogue-edl-catalogue-mobile {
  padding: 0 20px;
}
@media (max-width: 767px) {
  .page-taxonomy .region-content #block-edl-catalogue-edl-filtre-nouveautes, .page-taxonomy .region-sidebar-second #block-edl-catalogue-edl-filtre-nouveautes {
    padding: 0 20px;
  }
}
@media (max-width: 359px) {
  .page-taxonomy .region-content #block-edl-catalogue-edl-filtre-nouveautes, .page-taxonomy .region-sidebar-second #block-edl-catalogue-edl-filtre-nouveautes {
    padding: 0 10px;
  }
}
.page-taxonomy .region-content #block-edl-catalogue-edl-filtre-nouveautes a, .page-taxonomy .region-sidebar-second #block-edl-catalogue-edl-filtre-nouveautes a {
  margin: 0 7px;
  display: inline-block;
  vertical-align: bottom;
}
.page-taxonomy .region-content #block-edl-catalogue-edl-filtre-nouveautes a.link-cal, .page-taxonomy .region-sidebar-second #block-edl-catalogue-edl-filtre-nouveautes a.link-cal {
  -webkit-border-radius: 15px 15px 0 0;
  -moz-border-radius: 15px 15px 0 0;
  border-radius: 15px 15px 0 0;
  -o-border-radius: 15px 15px 0 0;
  width: 82px;
}
@media (max-width: 359px) {
  .page-taxonomy .region-content #block-edl-catalogue-edl-filtre-nouveautes a.link-cal, .page-taxonomy .region-sidebar-second #block-edl-catalogue-edl-filtre-nouveautes a.link-cal {
    margin: 0 2px;
  }
}
.page-taxonomy .region-content #block-edl-catalogue-edl-filtre-nouveautes a.prev-link, .page-taxonomy .region-sidebar-second #block-edl-catalogue-edl-filtre-nouveautes a.prev-link {
  background: url("../img/prev-nouveautes.png") no-repeat 0 0 transparent;
  text-indent: -9999px;
  width: 16px;
  height: 32px;
  margin: 0 5px 12px 0;
}
.page-taxonomy .region-content #block-edl-catalogue-edl-filtre-nouveautes a .cal-container, .page-taxonomy .region-sidebar-second #block-edl-catalogue-edl-filtre-nouveautes a .cal-container {
  text-align: center;
}
.page-taxonomy .region-content #block-edl-catalogue-edl-filtre-nouveautes a .cal-container .month-year, .page-taxonomy .region-sidebar-second #block-edl-catalogue-edl-filtre-nouveautes a .cal-container .month-year {
  padding: 7px 0 5px;
  color: #fff;
  height: 50px;
}
.page-taxonomy .region-content #block-edl-catalogue-edl-filtre-nouveautes a .cal-container .month-year .cal-month, .page-taxonomy .region-sidebar-second #block-edl-catalogue-edl-filtre-nouveautes a .cal-container .month-year .cal-month {
  font-family: "FrutigerLTStd-Roman";
  font-size: 20px;
  line-height: 20px;
  letter-spacing: 0px;
  margin: 0 0 2px;
  display: block;
}
.page-taxonomy .region-content #block-edl-catalogue-edl-filtre-nouveautes a .cal-container .month-year .cal-year, .page-taxonomy .region-sidebar-second #block-edl-catalogue-edl-filtre-nouveautes a .cal-container .month-year .cal-year {
  font-family: "FrutigerLTStd-Roman";
  font-size: 15px;
  line-height: 15px;
  letter-spacing: 0px;
}
.page-taxonomy .region-content #block-edl-catalogue-edl-filtre-nouveautes a .cal-container .cal-count, .page-taxonomy .region-sidebar-second #block-edl-catalogue-edl-filtre-nouveautes a .cal-container .cal-count {
  background: #e9e9e9;
  color: #858282;
  font-family: "FrutigerLTStd-Roman";
  font-size: 11px;
  line-height: 17px;
  letter-spacing: 0px;
  height: 17px;
  width: 100%;
  display: block;
}
@media (max-width: 767px) {
  .page-taxonomy .region-content #block-edl-catalogue-edl-filtre-nouveautes a .cal-container .cal-count, .page-taxonomy .region-sidebar-second #block-edl-catalogue-edl-filtre-nouveautes a .cal-container .cal-count {
    height: 20px;
    line-height: 20px;
  }
}
.page-taxonomy .region-content #block-edl-catalogue-edl-filtre-nouveautes a.link-first, .page-taxonomy .region-sidebar-second #block-edl-catalogue-edl-filtre-nouveautes a.link-first {
  background: #e50f16;
}
.page-taxonomy .region-content #block-edl-catalogue-edl-filtre-nouveautes a.link-first.active .cal-container .cal-count, .page-taxonomy .region-sidebar-second #block-edl-catalogue-edl-filtre-nouveautes a.link-first.active .cal-container .cal-count {
  background: #e50f16;
  color: #fff;
}
.ppage-taxonomy.region-content #block-edl-catalogue-edl-filtre-nouveautes a.link-second, .page-taxonomy .region-sidebar-second #block-edl-catalogue-edl-filtre-nouveautes a.link-second {
  background: #ff4e00;
}
.page-taxonomy .region-content #block-edl-catalogue-edl-filtre-nouveautes a.link-second.active .cal-container .cal-count, .page-taxonomy .region-sidebar-second #block-edl-catalogue-edl-filtre-nouveautes a.link-second.active .cal-container .cal-count {
  background: #ff4e00;
  color: #fff;
}
.page-taxonomy .region-content #block-edl-catalogue-edl-filtre-nouveautes a.link-third, .page-taxonomy .region-sidebar-second #block-edl-catalogue-edl-filtre-nouveautes a.link-third {
  background: #fe9b1e;
}
.page-taxonomy .region-content #block-edl-catalogue-edl-filtre-nouveautes a.link-third.active .cal-container .cal-count, .page-taxonomy .region-sidebar-second #block-edl-catalogue-edl-filtre-nouveautes a.link-third.active .cal-container .cal-count {
  background: #fe9b1e;
  color: #fff;
}
.page-taxonomy .region-content #block-edl-catalogue-edl-filtre-catalogue, .page-taxonomy .region-sidebar-second #block-edl-catalogue-edl-filtre-catalogue {
  margin: 0 0 30px 0;
  position: relative;
}
.page-taxonomy .region-content #block-edl-catalogue-edl-filtre-catalogue .filter-container:after, .page-taxonomy .region-sidebar-second #block-edl-catalogue-edl-filtre-catalogue .filter-container:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}
.page-taxonomy .region-content #block-edl-catalogue-edl-filtre-catalogue .filter-top-level, .page-taxonomy .region-sidebar-second #block-edl-catalogue-edl-filtre-catalogue .filter-top-level {
  border-bottom: 1px solid #000;
  padding: 0 0 15px;
  margin: 0 0 15px;
}
.page-taxonomy .region-content #block-edl-catalogue-edl-filtre-catalogue .filter-top-level:after, .page-taxonomy .region-sidebar-second #block-edl-catalogue-edl-filtre-catalogue .filter-top-level:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}
.page-taxonomy .region-content #block-edl-catalogue-edl-filtre-catalogue .filter-top-level a, .page-taxonomy .region-sidebar-second #block-edl-catalogue-edl-filtre-catalogue .filter-top-level a {
  float: left;
  margin: 0 28px 0 0;
  text-transform: uppercase;
  font-family: "FrutigerLTStd-Roman";
  font-size: 15px;
  line-height: 15px;
  letter-spacing: 0px;
  color: black;
  padding: 8px 10px;
  position: relative;
  border: 1px solid #fff;
}
@media (min-width: 768px) and (max-width: 1023px) {
  .page-taxonomy .region-content #block-edl-catalogue-edl-filtre-catalogue .filter-top-level a, .page-taxonomy .region-sidebar-second #block-edl-catalogue-edl-filtre-catalogue .filter-top-level a {
    margin: 0;
    font-family: "FrutigerLTStd-Roman";
    font-size: 13px;
    line-height: 13px;
    letter-spacing: 0px;
  }
  .page-taxonomy .region-content #block-edl-catalogue-edl-filtre-catalogue .filter-top-level a:last-child, .page-taxonomy .region-sidebar-second #block-edl-catalogue-edl-filtre-catalogue .filter-top-level a:last-child {
    padding: 8px 0;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .page-taxonomy .region-content #block-edl-catalogue-edl-filtre-catalogue .filter-top-level a#filter-genre, .page-taxonomy .region-content #block-edl-catalogue-edl-filtre-catalogue .filter-top-level a#filter-age, .page-taxonomy .region-content #block-edl-catalogue-edl-filtre-catalogue .filter-top-level a#filter-collection, .page-taxonomy .region-content #block-edl-catalogue-edl-filtre-catalogue .filter-top-level a#filter-prix, .page-taxonomy .region-sidebar-second #block-edl-catalogue-edl-filtre-catalogue .filter-top-level a#filter-genre, .page-taxonomy .region-sidebar-second #block-edl-catalogue-edl-filtre-catalogue .filter-top-level a#filter-age, .page-taxonomy .region-sidebar-second #block-edl-catalogue-edl-filtre-catalogue .filter-top-level a#filter-collection, .page-taxonomy .region-sidebar-second #block-edl-catalogue-edl-filtre-catalogue .filter-top-level a#filter-prix {
    margin: 0 18px 0 0;
  }
}
.page-taxonomy .region-content #block-edl-catalogue-edl-filtre-catalogue .filter-top-level a#filter-genre:after, .page-taxonomy .region-content #block-edl-catalogue-edl-filtre-catalogue .filter-top-level a#filter-age:after, .page-taxonomy .region-content #block-edl-catalogue-edl-filtre-catalogue .filter-top-level a#filter-collection:after, .page-taxonomy .region-content #block-edl-catalogue-edl-filtre-catalogue .filter-top-level a#filter-prix:after, .page-taxonomy .region-sidebar-second #block-edl-catalogue-edl-filtre-catalogue .filter-top-level a#filter-genre:after, .page-taxonomy .region-sidebar-second #block-edl-catalogue-edl-filtre-catalogue .filter-top-level a#filter-age:after, .page-taxonomy .region-sidebar-second #block-edl-catalogue-edl-filtre-catalogue .filter-top-level a#filter-collection:after, .page-taxonomy .region-sidebar-second #block-edl-catalogue-edl-filtre-catalogue .filter-top-level a#filter-prix:after {
  content: "";
  display: block;
  background: url("../img/dropdown-catalogue.png") no-repeat 0 0 transparent;
  width: 14px;
  height: 7px;
  position: absolute;
  top: 13px;
  right: -20px;
}
@media (min-width: 768px) and (max-width: 1023px) {
  .page-taxonomy .region-content #block-edl-catalogue-edl-filtre-catalogue .filter-top-level a#filter-genre:after, .page-taxonomy .region-content #block-edl-catalogue-edl-filtre-catalogue .filter-top-level a#filter-age:after, .page-taxonomy .region-content #block-edl-catalogue-edl-filtre-catalogue .filter-top-level a#filter-collection:after, .page-taxonomy .region-content #block-edl-catalogue-edl-filtre-catalogue .filter-top-level a#filter-prix:after, .page-taxonomy .region-sidebar-second #block-edl-catalogue-edl-filtre-catalogue .filter-top-level a#filter-genre:after, .page-taxonomy .region-sidebar-second #block-edl-catalogue-edl-filtre-catalogue .filter-top-level a#filter-age:after, .page-taxonomy .region-sidebar-second #block-edl-catalogue-edl-filtre-catalogue .filter-top-level a#filter-collection:after, .page-taxonomy .region-sidebar-second #block-edl-catalogue-edl-filtre-catalogue .filter-top-level a#filter-prix:after {
    right: -16px;
  }
}
.page-taxonomy .region-content #block-edl-catalogue-edl-filtre-catalogue .filter-top-level a.active, .page-taxonomy .region-content #block-edl-catalogue-edl-filtre-catalogue .filter-top-level a:hover, .page-taxonomy .region-sidebar-second #block-edl-catalogue-edl-filtre-catalogue .filter-top-level a.active, .page-taxonomy .region-sidebar-second #block-edl-catalogue-edl-filtre-catalogue .filter-top-level a:hover {
  border: 1px solid #000;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  -o-border-radius: 5px;
}
.page-taxonomy .region-content #block-edl-catalogue-edl-filtre-catalogue .filter-top-level .recherche-catalogue, .page-taxonomy .region-sidebar-second #block-edl-catalogue-edl-filtre-catalogue .filter-top-level .recherche-catalogue {
  float: right;
  margin: 3px 0 0 0;
}
.page-taxonomy .region-content #block-edl-catalogue-edl-filtre-catalogue .filter-top-level .recherche-catalogue .form-type-textfield, .page-taxonomy .region-sidebar-second #block-edl-catalogue-edl-filtre-catalogue .filter-top-level .recherche-catalogue .form-type-textfield {
  float: left;
  margin: 0;
}
@media (min-width: 768px) and (max-width: 1023px) {
  .page-taxonomy .region-content #block-edl-catalogue-edl-filtre-catalogue .filter-top-level .recherche-catalogue #edl-catalogue-search-form .form-type-textfield input, .page-taxonomy .region-sidebar-second #block-edl-catalogue-edl-filtre-catalogue .filter-top-level .recherche-catalogue #edl-catalogue-search-form .form-type-textfield input {
    width: 130px;
  }
}
.ppage-taxonomy.region-content #block-edl-catalogue-edl-filtre-catalogue .low-filter-container, .page-taxonomy .region-sidebar-second #block-edl-catalogue-edl-filtre-catalogue .low-filter-container {
  clear: both;
  width: 900px;
  margin: 10px 0 0 0;
}
@media (min-width: 768px) and (max-width: 1023px) {
  .page-taxonomy .region-content #block-edl-catalogue-edl-filtre-catalogue .low-filter-container, .page-taxonomy .region-sidebar-second #block-edl-catalogue-edl-filtre-catalogue .low-filter-container {
    width: 665px;
  }
}
.page-taxonomy .region-content #block-edl-catalogue-edl-filtre-catalogue .low-filter-container:after, .page-taxonomy .region-sidebar-second #block-edl-catalogue-edl-filtre-catalogue .low-filter-container:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}
.page-taxonomy .region-content #block-edl-catalogue-edl-filtre-catalogue .low-filter-container a, .page-taxonomy .region-sidebar-second #block-edl-catalogue-edl-filtre-catalogue .low-filter-container a {
  float: left;
  margin: 0 25px 5px 0;
  color: #3774a9;
  padding: 8px 10px;
  font-family: "FrutigerLTStd-Roman";
  font-size: 15px;
  line-height: 15px;
  letter-spacing: 0px;
  border: 1px solid #fff;
}
.page-taxonomy .region-content #block-edl-catalogue-edl-filtre-catalogue .low-filter-container a.active, .page-taxonomy .region-content #block-edl-catalogue-edl-filtre-catalogue .low-filter-container a:hover, .page-taxonomy .region-sidebar-second #block-edl-catalogue-edl-filtre-catalogue .low-filter-container a.active, .page-taxonomy .region-sidebar-second #block-edl-catalogue-edl-filtre-catalogue .low-filter-container a:hover {
  border: 1px solid #3774a9;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  -o-border-radius: 5px;
}
.page-taxonomy .region-content #block-edl-catalogue-edl-filtre-catalogue .bouton-affichage, .page-taxonomy .region-sidebar-second #block-edl-catalogue-edl-filtre-catalogue .bouton-affichage {
  border: medium none;
  position: absolute;
  right: 0;
  top: 65px;
  margin: 0;
  padding: 0;
}
.page-taxonomy .region-content #block-edl-catalogue-edl-filtre-catalogue .bouton-affichage .nav-tabs li, .page-taxonomy .region-sidebar-second #block-edl-catalogue-edl-filtre-catalogue .bouton-affichage .nav-tabs li {
  margin: 0 0 0 10px;
}
.page-taxonomy .region-content #block-edl-catalogue-edl-filtre-catalogue .bouton-affichage .nav-tabs li.active a, .page-taxonomy .region-content #block-edl-catalogue-edl-filtre-catalogue .bouton-affichage .nav-tabs li.active a:hover, .page-taxonomy .region-sidebar-second #block-edl-catalogue-edl-filtre-catalogue .bouton-affichage .nav-tabs li.active a, .page-taxonomy .region-sidebar-second #block-edl-catalogue-edl-filtre-catalogue .bouton-affichage .nav-tabs li.active a:hover {
  background-position: -39px 0;
}
.page-taxonomy .region-content #block-edl-catalogue-edl-filtre-catalogue .bouton-affichage .nav-tabs li a, .page-taxonomy .region-content #block-edl-catalogue-edl-filtre-catalogue .bouton-affichage .nav-tabs li a:hover, .page-taxonomy .region-sidebar-second #block-edl-catalogue-edl-filtre-catalogue .bouton-affichage .nav-tabs li a, .ppage-taxonomy.region-sidebar-second #block-edl-catalogue-edl-filtre-catalogue .bouton-affichage .nav-tabs li a:hover {
  background-image: url("../img/tab-catalogue.png");
  background-position: 0 0;
  width: 30px;
  height: 30px;
}
.page-taxonomy .region-content #block-system-main, .page-taxonomy .region-sidebar-second #block-system-main {
  padding: 0px 0 0 0 !important;
}
.ppage-taxonomy.region-content .row-catalogue:after, .page-taxonomy .region-sidebar-second .row-catalogue:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}
@media (max-width: 767px) {
  .page-taxonomy .region-content .row-catalogue, .page-taxonomy .region-sidebar-second .row-catalogue {
    max-width: 640px;
    width: 100%;
    margin: 0 auto;
  }
}
.page-taxonomy .region-content .livre-container, .page-taxonomy .region-sidebar-second .livre-container {
  float: left;
  margin: 0 10px 10px 0;
  width: 240px;
  height: 295px;
  position: relative;
  overflow: hidden;
}
@media (max-width: 767px) {
  .page-taxonomy .region-content .livre-container, .page-taxonomy .region-sidebar-second .livre-container {
    height: auto;
    max-width: 270px;
    max-height: 335px;
    width: 44%;
    margin: 0 10% 50px 0;
  }
  .page-taxonomy .region-content .livre-container.even, .page-taxonomy .region-sidebar-second .livre-container.even {
    float: left;
    margin: 0 0 50px 0;
  }
}
@media (max-width: 767px) and (max-width: 479px) {
  .page-taxonomy .region-content .livre-container, .page-taxonomy .region-sidebar-second .livre-container {
    width: 100%;
    margin: 0 auto 50px;
    float: none;
  }
}
@media (max-width: 767px) and (max-width: 479px) {
  .page-taxonomy .region-content .livre-container.even, .page-taxonomy .region-sidebar-second .livre-container.even {
    float: none;
    margin: 0 auto 50px;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .page-taxonomy .region-content .livre-container, .page-taxonomy .region-sidebar-second .livre-container {
    width: 237px;
  }
  .page-taxonomy .region-content .livre-container.last, .page-taxonomy .region-sidebar-second .livre-container.last {
    margin: 0 10px 10px 0 !important;
  }
}
.page-taxonomy .region-content .livre-container.last, .page-taxonomy .region-sidebar-second .livre-container.last {
  margin: 0 0 10px;
}
.page-taxonomy .region-content .livre-container:hover .date-parution, .page-taxonomy .region-sidebar-second .livre-container:hover .date-parution {
  opacity: 0 !important;
  -moz-opacity: 0 !important;
  -khtml-opacity: 0 !important;
  -ms-filter: alpha(opacity=0);
  filter: alpha(opacity=0);
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(opacity=0)";
}
.page-taxonomy .region-content .livre-container:hover .livre-infos, .page-taxonomy .region-sidebar-second .livre-container:hover .livre-infos {
  top: 0;
  opacity: 1 !important;
  -moz-opacity: 1 !important;
  -khtml-opacity: 1 !important;
  -ms-filter: alpha(opacity=100);
  filter: alpha(opacity=100);
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(opacity=100)";
}
.page-taxonomy .region-content .livre-container:hover .livre-image-content img, .page-taxonomy .region-sidebar-second .livre-container:hover .livre-image-content img {
  -webkit-filter: grayscale(100%) opacity(0.5);
  filter: grayscale(100%) opacity(0.5);
  -webkit-transform: scale(1.2) rotate(5deg);
  -ms-transform: scale(1.2) rotate(5deg);
  -o-transform: scale(1.2) rotate(5deg);
  transform: scale(1.2) rotate(5deg);
  -moz-transform: scale(1.2) rotate(5deg);
}
.page-taxonomy .region-content .livre-container .date-parution, .ppage-taxonomy.region-sidebar-second .livre-container .date-parution {
  background: url("../img/overlay-catalogue.png") repeat 0 0 transparent;
  padding: 7px 0;
  text-align: center;
  color: #fff;
  font-family: "FrutigerLTStd-Roman";
  font-size: 11px;
  line-height: 11px;
  letter-spacing: 0px;
  text-transform: uppercase;
  -webkit-transition: opacity 0.45s ease 0s;
  -moz-transition: opacity 0.45s ease 0s;
  -o-transition: opacity 0.45s ease 0s;
  -ms-transition: opacity 0.45s ease 0s;
  transition: opacity 0.45s ease 0s;
  opacity: 1 !important;
  -moz-opacity: 1 !important;
  -khtml-opacity: 1 !important;
  -ms-filter: alpha(opacity=100);
  filter: alpha(opacity=100);
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(opacity=100)";
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
}
.page-taxonomy .region-content .livre-container .livre-image-content, .page-taxonomy .region-sidebar-second .livre-container .livre-image-content {
  display: table-cell;
  height: 295px;
  text-align: center;
  vertical-align: middle;
  width: 240px;
}
@media (max-width: 767px) {
  .page-taxonomy .region-content .livre-container .livre-image-content, .page-taxonomy .region-sidebar-second .livre-container .livre-image-content {
    height: auto;
    padding: 40px;
    display: block;
    width: 100%;
  }
}
.page-taxonomy .region-content .livre-container .livre-image-content img, .page-taxonomy .region-sidebar-second .livre-container .livre-image-content img {
  max-height: 290px;
  width: 170px;
  -webkit-transition: all 0.45s ease 0s;
  -moz-transition: all 0.45s ease 0s;
  -o-transition: all 0.45s ease 0s;
  -ms-transition: all 0.45s ease 0s;
  transition: all 0.45s ease 0s;
  border: 6px solid white;
}
@media (max-width: 767px) {
  .page-taxonomy .region-content .livre-container .livre-image-content img, .page-taxonomy .region-sidebar-second .livre-container .livre-image-content img {
    width: 100%;
    max-height: 100%;
  }
}
.page-taxonomy .region-content .livre-container .livre-infos, .page-taxonomy .region-sidebar-second .livre-container .livre-infos {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  opacity: 0 !important;
  -moz-opacity: 0 !important;
  -khtml-opacity: 0 !important;
  -ms-filter: alpha(opacity=0);
  filter: alpha(opacity=0);
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(opacity=0)";
  -webkit-transition: all 0.45s ease 0s;
  -moz-transition: all 0.45s ease 0s;
  -o-transition: all 0.45s ease 0s;
  -ms-transition: all 0.45s ease 0s;
  transition: all 0.45s ease 0s;
  width: 100%;
  z-index: 1;
  background: url("../img/overlay-catalogue.png") repeat 0 0 transparent;
}
.page-taxonomy .region-content .livre-container .livre-infos a, .page-taxonomy .region-sidebar-second .livre-container .livre-infos a {
  display: block;
  width: 100%;
  height: 100%;
}
.page-taxonomy .region-content .livre-container .livre-infos a .livre-infos-wrapper, .page-taxonomy .region-sidebar-second .livre-container .livre-infos a .livre-infos-wrapper {
  padding: 15px 20px;
}
@media (min-width: 480px) and (max-width: 767px) {
  .page-taxonomy .region-content .livre-container .livre-infos a .livre-infos-wrapper, .ppage-taxonomy.region-sidebar-second .livre-container .livre-infos a .livre-infos-wrapper {
    padding: 8px 10px;
  }
}
.page-taxonomy .region-content .livre-container .livre-infos a .livre-infos-wrapper .livre-title, .page-taxonomy .region-sidebar-second .livre-container .livre-infos a .livre-infos-wrapper .livre-title {
  color: #f5fbfb;
  font-family: "FrutigerLTStd-Roman";
  font-size: 16px;
  line-height: 18px;
  letter-spacing: 0px;
  margin: 0 0 7px;
}
@media (min-width: 480px) and (max-width: 767px) {
  .page-taxonomy .region-content .livre-container .livre-infos a .livre-infos-wrapper .livre-title, .page-taxonomy .region-sidebar-second .livre-container .livre-infos a .livre-infos-wrapper .livre-title {
    margin: 0 0 3px;
    font-family: "FrutigerLTStd-Roman";
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0px;
  }
}
.page-taxonomy .region-content .livre-container .livre-infos a .livre-infos-wrapper .livre-auteur, .page-taxonomy .region-sidebar-second .livre-container .livre-infos a .livre-infos-wrapper .livre-auteur {
  color: #b8b6b6;
  font-family: "FrutigerLTStd-Roman";
  font-size: 16px;
  line-height: 18px;
  letter-spacing: 0px;
  margin: 0 0 12px;
}
@media (min-width: 480px) and (max-width: 767px) {
  .page-taxonomy .region-content .livre-container .livre-infos a .livre-infos-wrapper .livre-auteur, .page-taxonomy .region-sidebar-second .livre-container .livre-infos a .livre-infos-wrapper .livre-auteur {
    margin: 0 0 7px;
    font-family: "FrutigerLTStd-Roman";
    font-size: 15px;
    line-height: 15px;
    letter-spacing: 0px;
  }
}
.page-taxonomy .region-content .livre-container .livre-infos a .livre-infos-wrapper .livre-desc, .page-taxonomy .region-sidebar-second .livre-container .livre-infos a .livre-infos-wrapper .livre-desc {
  color: #fdfefe;
  font-family: "FrutigerLTStd-Roman";
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0px;
  margin: 0 0 14px;
}
@media (min-width: 480px) and (max-width: 767px) {
  .page-taxonomy .region-content .livre-container .livre-infos a .livre-infos-wrapper .livre-desc, .page-taxonomy .region-sidebar-second .livre-container .livre-infos a .livre-infos-wrapper .livre-desc {
    margin: 0 0 10px;
    line-height: 15px;
  }
}
.page-taxonomy .region-content .livre-container .livre-infos a .livre-infos-wrapper .livre-age, .page-taxonomy .region-content .livre-container .livre-infos a .livre-infos-wrapper .livre-collection, .page-taxonomy .region-sidebar-second .livre-container .livre-infos a .livre-infos-wrapper .livre-age, .page-taxonomy .region-sidebar-second .livre-container .livre-infos a .livre-infos-wrapper .livre-collection {
  background: url("../img/overlay-inof-catalague.png") repeat 0 0 transparent;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  border-radius: 15px;
  -o-border-radius: 15px;
  padding: 5px 20px;
  color: #fff;
  font-family: "FrutigerLTStd-Bold";
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 0px;
  margin: 0 0 12px;
  display: table;
  min-width: 105px;
}
@media (min-width: 480px) and (max-width: 767px) {
  .page-taxonomy .region-content .livre-container .livre-infos a .livre-infos-wrapper .livre-age, .page-taxonomy .region-content .livre-container .livre-infos a .livre-infos-wrapper .livre-collection, .page-taxonomy .region-sidebar-second .livre-container .livre-infos a .livre-infos-wrapper .livre-age, .page-taxonomy .region-sidebar-second .livre-container .livre-infos a .livre-infos-wrapper .livre-collection {
    margin: 0 0 7px;
    min-width: 100px;
    padding: 4px 20px;
  }
}
.page-taxonomy .region-content .livre-container .livre-infos a .livre-infos-wrapper .livre-fleche, .page-taxonomy .region-sidebar-second .livre-container .livre-infos a .livre-infos-wrapper .livre-fleche {
  background: url("../img/flechette-catalogue.png") no-repeat 0 0 transparent;
  width: 20px;
  height: 36px;
  display: block;
  float: right;
}
.page-taxonomy .region-content #tab0 #liste-ajax-loader, .page-taxonomy .region-sidebar-second #tab0 #liste-ajax-loader {
  padding: 20px 0 0 0;
}
.page-taxonomy .region-content #tab0 #liste-ajax-loader .loader, .page-taxonomy .region-sidebar-second #tab0 #liste-ajax-loader .loader {
  width: 100%;
  text-align: center;
}
.page-taxonomy .region-content #tab1 #catalogue-ajax-loader, .page-taxonomy .region-sidebar-second #tab1 #catalogue-ajax-loader {
  padding: 20px 0 0 0;
}
.page-taxonomy .region-content #tab1 #catalogue-ajax-loader .loader, .page-taxonomy .region-sidebar-second #tab1 #catalogue-ajax-loader .loader {
  width: 240px;
}
@media (min-width: 768px) and (max-width: 1023px) {
  .page-taxonomy .region-content #tab1 #catalogue-ajax-loader .loader, .page-taxonomy .region-sidebar-second #tab1 #catalogue-ajax-loader .loader {
    width: 237px;
  }
}
.page-taxonomy .region-content .block-system .auteur-detail-wrapper, .page-taxonomy .region-sidebar-second .block-system .auteur-detail-wrapper {
  border-bottom: 1px solid #3774a9;
}
.page-taxonomy .region-content .block-system .auteur-detail-wrapper .detail-livre, .page-taxonomy .region-sidebar-second .block-system .auteur-detail-wrapper .detail-livre {
  width: 840px;
}
@media (min-width: 768px) and (max-width: 1023px) {
  .page-taxonomy .region-content .block-system .auteur-detail-wrapper .detail-livre, .page-taxonomy .region-sidebar-second .block-system .auteur-detail-wrapper .detail-livre {
    width: 597px;
  }
}
@media (max-width: 767px) {
  .page-taxonomy .region-content .block-system .auteur-detail-wrapper .detail-livre, .page-taxonomy .region-sidebar-second .block-system .auteur-detail-wrapper .detail-livre {
    width: 65%;
  }
}
@media (max-width: 479px) {
  .page-taxonomy .region-content .block-system .auteur-detail-wrapper .detail-livre, .page-taxonomy .region-sidebar-second .block-system .auteur-detail-wrapper .detail-livre {
    width: 100%;
  }
}
.page-taxonomy .region-content .block-system .auteur-detail-wrapper .detail-livre .titre-livre a, .page-taxonomy .region-sidebar-second .block-system .auteur-detail-wrapper .detail-livre .titre-livre a {
  color: black;
}
@media (max-width: 767px) {
  .page-taxonomy .region-content .block-system .auteur-detail-wrapper .detail-livre .detail-collection-livre, .page-taxonomy .region-sidebar-second .block-system .auteur-detail-wrapper .detail-livre .detail-collection-livre {
    font-family: "FrutigerLTStd-Roman";
    font-size: 18px;
    line-height: 18px;
    letter-spacing: 0px;
  }
}
.page-taxonomy .region-content .block-system .auteur-detail-wrapper .detail-livre .detail-collection-livre span, .page-taxonomy .region-sidebar-second .block-system .auteur-detail-wrapper .detail-livre .detail-collection-livre span {
  color: #3774a9;
}
.page-taxonomy .region-content .block-system .auteur-detail-wrapper .detail-livre .description-livre, .page-taxonomy .region-sidebar-second .block-system .auteur-detail-wrapper .detail-livre .description-livre {
  width: auto;
}
@media (max-width: 767px) {
  .page-taxonomy .region-content .block-system .auteur-detail-wrapper .detail-livre .description-livre p, .page-taxonomy .region-sidebar-second .block-system .auteur-detail-wrapper .detail-livre .description-livre p {
    font-family: "FrutigerLTStd-Roman";
    font-size: 16px;
    line-height: 18px;
    letter-spacing: 0px;
  }
}
.page-taxonomy .region-content .block-system .auteur-detail-wrapper .detail-livre .fiche-complete, .page-taxonomy .region-sidebar-second .block-system .auteur-detail-wrapper .detail-livre .fiche-complete {
  color: #3774a9;
  font-family: "FrutigerLTStd-Light";
  font-size: 24px;
  line-height: 24px;
  letter-spacing: 0px;
  padding: 5px 0 0 0;
}
.page-taxonomy .node_liste {
  display: none;
}
.page-taxonomy .bouton-affichage {
  border: none;
}
.page-taxonomy .region-content .bouton-affichage, .page-taxonomy .region-sidebar-second .bouton-affichage {
  border: medium none;
  margin: 0;
  padding: 0;
}
.page-taxonomy .region-content .bouton-affichage .nav-tabs li, .page-taxonomy .region-sidebar-second .bouton-affichage .nav-tabs li {
  margin: 0 0 0 10px;
}
@media (max-width: 767px) {
  .page-taxonomy .container .bouton-affichage {
    margin: 0 0 30px 0;
    padding: 0;
    border: none;
  }
  .page-taxonomy .container .bouton-affichage .nav-tabs li {
    margin: 0 20px 0 0;
  }
  .page-taxonomy .container .bouton-affichage .nav-tabs li.active a, .page-taxonomy .container .bouton-affichage .nav-tabs li.active a:hover {
    background-position: -80px 0;
  }
  .page-taxonomy .container .bouton-affichage .nav-tabs li a, .page-taxonomy .container .bouton-affichage .nav-tabs li a:hover {
    background-image: url("../img/tab-catalogue-mobile.png");
    background-position: 0 0;
    width: 55px;
    height: 55px;
  }
}
#block-views-recherche-home-block-3 h2.block-title {
  display: none;
}
#block-views-recherche-home-block-3 .view-recherche-home .view-content {
  display: none;
}
.page-resultat-recherche .region-content .view-filters {
  background-color: #e9e9e9;
}
.page-resultat-recherche .region-content .view-content {
  padding-left: 3%;
}
.page-resultat-recherche .region-content .view-content .views-row {
  border-bottom: 1px solid #c6c6c6;
  padding: 8px 0;
  overflow: auto;
}
.page-resultat-recherche .region-content .view-content .views-row-first {
  border-top: 1px solid #c6c6c6;
}
.page-resultat-recherche .region-content .view-content .views-row-last {
  border-bottom: none;
}
.page-resultat-recherche .region-content .view-content .views-row .views-field-title {
  float: left;
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: bold;
}
.page-resultat-recherche .region-content .view-content .views-row .views-field-title a {
  color: black;
}
.page-resultat-recherche .region-content .view-content .views-row .views-field-field-auteur-qualite, .page-resultat-recherche .region-content .view-content .views-row .views-field-field-livre-collection, .page-resultat-recherche .region-content .view-content .views-row .views-field-field-type-evenement, .page-resultat-recherche .region-content .view-content .views-row .views-field-field-type {
  float: right;
}
.page-resultat-recherche .region-content .view-content .views-row .views-field-field-auteur-qualite {
  color: #a7003b;
  font-weight: bold;
}
.page-resultat-recherche .region-content .view-content .views-row .views-field-field-livre-collection {
  color: #3774a9;
  font-weight: bold;
}
.page-resultat-recherche .region-content .view-content .views-row .views-field-field-type {
  color: #3774a9;
  font-weight: bold;
}
.page-resultat-recherche .region-content .view-content .views-row .views-field-field-livre-collection .field-content .field-collection-item-field-livre-collection {
  display: inline-table !important;
}
.page-resultat-recherche .region-content .view-content .views-row .views-field-field-type .field-content {
  display: inline-table !important;
}
.page-resultat-recherche .region-content .view-content .views-row .views-field-field-type-evenement {
  color: #711e80;
  font-weight: bold;
}
.page-resultat-recherche .region-content .view-content .views-row .views-field-field-livre-resume, .page-resultat-recherche .region-content .view-content .views-row .views-field-field-auteur-biographie, .page-resultat-recherche .region-content .view-content .views-row .views-field-field-actus-description, .page-resultat-recherche .region-content .view-content .views-row .views-field-field-evenement-description {
  clear: both;
}
.page-resultat-recherche .region-content .nb_results {
  color: red;
  font-size: 16px;
  margin: 0px 0 25px 0;
  padding-left: 3%;
}
.page-resultat-recherche .region-content .pager {
  display: none;
}
.page-resultat-recherche .region-content #edit-title {
  display: none;
}
.page-resultat-recherche .region-content .views-exposed-form .views-exposed-widget {
  clear: both;
}
.page-resultat-recherche .region-content .views-exposed-form .views-exposed-widget label {
  float: left;
  margin-right: 10px;
}
.page-resultat-recherche .region-content .views-exposed-form .views-exposed-widget .views-widget {
  float: left;
}
.page-taxonomy-term .livre-container:nth-child(4n) {
  margin: 0 0 10px 0;
}
.page-taxonomy-term .main-container.taxonomy-collection {
  max-width: 100%;
}
.page-taxonomy-term .livre-container:nth-child(4n) {
  margin: 0 0 10px 0;
}
#parallax-1 #block-edl-main-edl-main-evenement:hover .block-title {
  width: 100px;
  color: #fe0404;
  background: white;
}
#parallax-1 #block-edl-main-edl-main-evenement .block-title {
  width: 100px;
  color: white;
  background: #fe0404;
}
#parallax-1 #block-edl-main-edl-main-evenement .balayage-gauche {
  background: #f1f1f1;
  cursor: pointer;
  display: block;
  overflow: hidden;
  position: relative;
  vertical-align: middle;
  white-space: normal;
  z-index: 1;
  height: 100%;
  width: 100%;
}
#parallax-1 #block-edl-main-edl-main-evenement .balayage-gauche:before {
  background: #ff2204;
  bottom: 0;
  position: absolute;
  left: 0;
  top: 0;
  width: 0;
  z-index: -1;
  height: 100%;
  display: block;
  content: "";
  -webkit-transition: width 0.3s ease-in-out 0.1s;
  -moz-transition: width 0.3s ease-in-out 0.1s;
  -o-transition: width 0.3s ease-in-out 0.1s;
  -ms-transition: width 0.3s ease-in-out 0.1s;
  transition: width 0.3s ease-in-out 0.1s;
}
#parallax-1 #block-edl-main-edl-main-evenement .balayage-gauche:hover:before {
  width: 100%;
}
#parallax-1 #block-edl-main-edl-main-evenement .balayage-gauche:hover .views-field-title .field-content {
  color: #fff;
}
#parallax-1 #block-edl-main-edl-main-evenement .balayage-gauche:hover .flechete {
  background-position: 0 -34px;
}
#parallax-1 #block-edl-main-edl-main-evenement .view-content {
  padding: 45px 45px 10px 10px;
}
@media (max-width: 767px) {
  #parallax-1 #block-edl-main-edl-main-evenement .view-content {
    padding: 45px 10px 10px;
  }
}
#parallax-1 #block-edl-main-edl-main-evenement .view-content .views-field-field-visuel {
  float: left;
  margin: 0 30px 0 0;
}
@media (max-width: 767px) {
  #parallax-1 #block-edl-main-edl-main-evenement .view-content .views-field-field-visuel {
    margin: 0 25px 0 0;
  }
}
#parallax-1 #block-edl-main-edl-main-evenement .view-content .views-field-title .field-content {
  color: #ec432e;
  font-family: "FrutigerLTStd-Light";
  font-size: 22px;
  line-height: 22px;
  letter-spacing: 0px;
}
@media (max-width: 767px) {
  #parallax-1 #block-edl-main-edl-main-evenement .view-content .views-field-title .field-content {
    font-family: "FrutigerLTStd-Light";
    font-size: 32px;
    line-height: 32px;
    letter-spacing: 0px;
  }
}
@media (max-width: 479px) {
  #parallax-1 #block-edl-main-edl-main-evenement .view-content .views-field-title .field-content {
    font-family: "FrutigerLTStd-Light";
    font-size: 25px;
    line-height: 25px;
    letter-spacing: 0px;
  }
}
#parallax-1 #block-edl-main-edl-main-evenement .view-content .views-field-field-evenement-description .field-content {
  margin: 15px 0;
  color: black;
  font-family: "FrutigerLTStd-Light";
  font-size: 16px;
  line-height: 16px;
  letter-spacing: 0px;
}
@media (max-width: 767px) {
  #parallax-1 #block-edl-main-edl-main-evenement .view-content .views-field-field-evenement-description .field-content {
    font-family: "FrutigerLTStd-Light";
    font-size: 20px;
    line-height: 20px;
    letter-spacing: 0px;
    margin: 5px 0;
  }
}
@media (max-width: 479px) {
  #parallax-1 #block-edl-main-edl-main-evenement .view-content .views-field-field-evenement-description .field-content {
    font-family: "FrutigerLTStd-Light";
    font-size: 15px;
    line-height: 15px;
    letter-spacing: 0px;
    margin: 7px 0;
  }
}
#parallax-1 #block-edl-main-edl-main-evenement .view-content .flechete {
  background-position: 0 0;
}
.node-type-livre #block-views-bonus-fiche-livre-block .views-exposed-widget, .node-type-livre #block-edl-livre-edl-livre-bonus-video {
  display: none;
}
.node-type-auteur .main-container .col-sm-3 .well #block-edl-auteur-edl-auteur-picto .original-image {
  border: none;
}
.savoir-plus-auteur {
  padding: 20px;
}
.livre-container {
  background: #e9e9e9 none repeat scroll 0 0 !important;
}
.searchbar-result-container .views-field-field-livre-collection .field-collection-item-field-livre-collection {
  display: inline-table;
}
.node-type-livre .format-container {
  display: none;
}
.page-mon-compte section.contextual-links-region ul.nav-tabs {
  display: none;
}
/* pour mobile */

@media (max-width: 768px) {
  .page-mon-compte .account_col1, .page-mon-compte .account_col2 {
    width: 95%;
  }
  .page-mon-compte section.col-sm-12 ul.tabs--primary {
    display: none;
  }
  .page-mon-compte section.contextual-links-region {
    margin-bottom: 2em;
  }
  .page-mon-compte section.contextual-links-region ul.nav-tabs {
    display: inline-block;
  }
  .page-mon-compte section.contextual-links-region div.pager {
    margin: 0px;
  }
  .page-mon-compte .main-container .nav-tabs > li.fleche_gauche a, .page-mon-compte .main-container .nav-tabs > li.fleche_droite a {
    width: 18px;
    height: 30px;
    min-width: 18px;
    background-color: transparent;
  }
  .page-mon-compte .main-container .nav-tabs > li.active {
    width: 50%;
  }
}
.page-mon-compte .fleche_gauche {
  width: 18px;
  background-image: url("/sites/all/modules/custom/zedl_account/theme/images/fleche_gauche.png");
}
.page-mon-compte .fleche_droite {
  width: 18px;
  float: right;
  background-image: url("/sites/all/modules/custom/zedl_account/theme/images/fleche_droite.png");
}
@media (max-width: 479px) {
  #parallax-1 #block-edl-main-edl-main-evenement .view-content .views-field-title .field-content {
    font-family: "FrutigerLTStd-Light";
    font-size: 25px;
    line-height: 25px;
    letter-spacing: 0px;
  }
}
#parallax-1 #block-edl-main-edl-main-evenement .view-content .views-field-field-evenement-description .field-content {
  margin: 15px 0;
  color: black;
  font-family: "FrutigerLTStd-Light";
  font-size: 16px;
  line-height: 16px;
  letter-spacing: 0px;
}
@media (max-width: 767px) {
  #parallax-1 #block-edl-main-edl-main-evenement .view-content .views-field-field-evenement-description .field-content {
    font-family: "FrutigerLTStd-Light";
    font-size: 20px;
    line-height: 20px;
    letter-spacing: 0px;
    margin: 5px 0;
  }
}
@media (max-width: 479px) {
  #parallax-1 #block-edl-main-edl-main-evenement .view-content .views-field-field-evenement-description .field-content {
    font-family: "FrutigerLTStd-Light";
    font-size: 15px;
    line-height: 15px;
    letter-spacing: 0px;
    margin: 7px 0;
  }
}
#parallax-1 #block-edl-main-edl-main-evenement .view-content .flechete {
  background-position: 0 0;
}
.savoir-plus-auteur {
  padding: 20px;
}
.livre-container {
  background: #e9e9e9 none repeat scroll 0 0 !important;
}
.searchbar-result-container .views-field-field-livre-collection .field-collection-item-field-livre-collection {
  display: inline-table;
}
.node-type-livre .format-container {
  display: none;
}
.page-mon-compte section.contextual-links-region ul.nav-tabs {
  display: none;
}
/* pour mobile */

@media (max-width: 768px) {
  .page-mon-compte .account_col1, .page-mon-compte .account_col2 {
    width: 95%;
  }
  /*.page-mon-compte section.col-sm-12 ul.tabs--primary {
		display: none;
	}*/
  .page-mon-compte section.contextual-links-region {
    margin-bottom: 2em;
  }
  .page-mon-compte section.contextual-links-region ul.nav-tabs {
    display: inline-block;
  }
  .page-mon-compte section.contextual-links-region div.pager {
    margin: 0px;
  }
  .page-mon-compte .main-container .nav-tabs > li.fleche_gauche a, .page-mon-compte .main-container .nav-tabs > li.fleche_droite a {
    width: 18px;
    height: 30px;
    min-width: 18px;
    background-color: transparent;
  }
  .page-mon-compte .main-container .nav-tabs > li.active {
    width: 50%;
  }
}
.page-mon-compte .fleche_gauche {
  width: 18px;
  background-image: url("/sites/all/modules/custom/zedl_account/theme/images/fleche_gauche.png");
}
.page-mon-compte .fleche_droite {
  width: 18px;
  float: right;
  background-image: url("/sites/all/modules/custom/zedl_account/theme/images/fleche_droite.png");
}
.filters #filter-prix {
  display: none;
}
.node-type-auteur .main-container .col-sm-9 #block-system-main .content-lower .form-suivre {
  top: -82px;
}
.node-type-auteur .main-container .col-sm-9 #block-system-main #biographie-auteur {
  font-family: "FrutigerLTStd-Light";
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0px;
  color: #000;
  margin: 0 0 5px 0;
  padding: 0;
}
.view-resultat-de-recherche .views-field-field-theme .field-content {
  color: black;
  float: left;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
}
.view-resultat-de-recherche .views-field-field-theme .field-content a {
  color: black;
  text-decoration: none;
}
.view-resultat-de-recherche .views-field-field-type {
  color: red;
  float: right;
  font-weight: bold;
}
.view-resultat-de-recherche .views-field-title a.thematique {
  display: none;
}
.view-bonus-home .voir {
  display: none;
}
.bouton-affichage ul li a#btn_tab0, .bouton-affichage ul li a#btn_tab0:hover {
  background-image: url("../img/tab-catalogue_liste.png") !important;
}
.bouton-affichage ul li a#btn_tab1, .bouton-affichage ul li a#btn_tab1:hover {
  background-image: url("../img/tab-catalogue_mosaique.png") !important;
}
.node-type-livre #block-edl-livre-edl-livre-infos .les-auteurs-livre p a {
  font-size: 20px !important;
}
.node-type-livre #block-edl-livre-edl-livre-infos .collection-wrapper .livre-dispo {
  text-transform: lowercase;
}
.node-type-auteur .bouton-affichage ul li a#btn_tab0 {
  width: 32px;
  height: 32px;
}
.node-type-auteur .bouton-affichage ul li a#btn_tab1 {
  width: 32px;
  height: 32px;
}
.node-type-auteur .bouton-affichage .nav-tabs li a#btn_tab0 {
  background-position: -37px 0;
}
.node-type-auteur .bouton-affichage .nav-tabs li a#btn_tab1 {
  background-position: -37px 0;
}
.node-type-auteur .bouton-affichage .nav-tabs li.active a#btn_tab0 {
  background-position: 0 0;
}
.node-type-auteur .bouton-affichage .nav-tabs li.active a#btn_tab1 {
  background-position: 0 0;
}
.node-type-livre .livre-panier {
  display: none;
}
#block-edl-main-edl-main-evenement {
  float: right;
}
.node-type-livre .livre-themes p span a {
  color: inherit;
  display: inline;
  text-decoration: none;
}
.page-taxonomy .taxo_reduit {
  max-width: 1190px !important;
}
.page-taxonomy .taxo_reduit .custom-page-header {
  display: block;
}
.view-bonus-home .bonus-description .telechager, .view-bonus-home .bonus-description .voir {
  display: none !important;
}
#parallax-4 #block-views-bonus-home-block.col-sm-4 .views-row .field-content .bonus-description {
  bottom: 15px !important;
}
.page-recherche-videos, .page-recherche-videos .breadcrumb {
  background-color: black;
}
.node-type-livre .recompense-autres-wrapper .btn-voir-livre.empty {
  background-image: none !important;
}
.node-type-livre .recompense-autres-wrapper .btn-voir-livre.empty:hover {
  cursor: auto !important;
}
.node-type-livre .recompense-autres-wrapper:hover .btn-voir-livre.empty {
  background-color: #535353 !important;
}
.page-taxonomy-term .block-edl-collection-edl-collection-essentiel .edl-pager, .page-taxonomy-term .block-edl-collection-edl-collection-nouveautes .edl-pager {
  clear: both;
}
.page-admin #sliding-popup {
  display: none;
}
#sliding-popup {
  position: absolute;
  top: 0;
  left: 0;
  background-color: #333 !important;
  /* opacity: 0.9;
	filter: alpha(opacity=90);*/
}
#sliding-popup a {
  color: silver;
  display: inline;
}
#sliding-popup #popup-buttons .find-more-button {
  display: none !important;
}
.node-type-livre .main-container .col-sm-9 #block-edl-livre-edl-livre-infos .infos-wrapper .livre-icons-wrapper .livre-share .picto-partage {
  width: 30px;
  height: 32px;
  background-image: url("../img/icon-partager-livre2.png");
  background-position: inherit;
  margin-top: 2px;
}
.node-type-livre .main-container .col-sm-9 #block-edl-livre-edl-livre-infos .infos-wrapper .livre-icons-wrapper .livre-share .lien-partage::after, .node-type-auteur .main-container .col-sm-9 #block-system-main .content-lower .lien-partage::after, .page-evenement-actualites .view-actualites .actu-container .actu-right .actu-lower .lien-partage::after, .page-evenement .col-sm-9 .views-row .details-evenement .details-lower .lien-partage::after {
  left: 42% !important;
}
.node-type-auteur .main-container .col-sm-9 #block-system-main .toggle-savoir {
  text-align: center;
  float: none;
}
.node-type-livre .main-container aside.col-sm-3 .region-sidebar-first #block-edl-livre-edl-livre-image .recompense-autres-wrapper {
  z-index: 3;
}
.node-type-livre .main-container aside.col-sm-3 .region-sidebar-first #block-edl-livre-edl-livre-image .recompense-autres-wrapper .btn-voir-rec {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 3;
}
.node-type-livre .livre-icons-wrapper .livre-panier {
  display: none !important;
}
.page-taxonomy-term .vocabulary-collection #contact {
  background-image: url("../../../../default/files/footer_collection_1_4.jpg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
#block-views-bonus-home-block .view-bonus-home .views-field-field-bonus-description {
  font-family: "FrutigerLTStd-Light";
  font-size: 24px;
  letter-spacing: 0;
  line-height: 24px;
  margin: 15px auto;
  bottom: 20px;
  position: absolute;
  text-align: center;
  width: 100%;
}
#block-views-bonus-home-block .view-bonus-home .views-field-field-bonus-description .field-content {
  background: none !important;
  color: white;
}
#block-views-bonus-home-block .view-content .bonus-description .upload {
  display: none;
}
#block-views-bonus-home-block .view-content .bonus-description .telecharger {
  font-family: "FrutigerLTStd-Light";
  font-size: 15px;
  letter-spacing: 0;
  padding-top: 15px;
  color: #000 !important;
  padding-left: 7px;
  padding-right: 7px;
}
#block-edl-main-edl-main-video .video-viewer {
  display: none;
}
/*.node-type-livre .slides-livres ul.slides li a img{
	-moz-box-shadow: 7px 7px 12px 1px #878787;
	-webkit-box-shadow: 17px 7px 12px 1px #878787;
	-o-box-shadow: 7px 7px 12px 1px #878787;
	box-shadow: 7px 7px 12px 1px #878787;
	filter:progid:DXImageTransform.Microsoft.Shadow(color=#878787, Direction=134, Strength=12);
	}

	.node-type-livre .slides-livres ul.slides li a img[src*="home130815_03_-_copy.png"]{
	-moz-box-shadow: none;
	-webkit-box-shadow: none;
	-o-box-shadow: none;
	box-shadow: none;
	filter: none;
}*/

#block-edl-audio-edl-audio-soundcloud-home h2.block-title-block {
  background: white none repeat scroll 0 0;
  color: #fe0404 !important;
  position: absolute;
  width: 80px;
  font-family: "FrutigerLTStd-Roman" !important;
  font-size: 13px !important;
  height: 25px;
  left: 10px;
  letter-spacing: 0 !important;
  line-height: 25px !important;
  margin: 0 !important;
  padding: 0 0 0 8px;
  position: absolute;
  text-transform: uppercase;
  top: 10px;
  z-index: 2;
  font-style: normal !important;
  text-align: left;
}
.bouton-affichage ul.nav-tabs li a.show_list {
  background-image: url("/sites/all/themes/edl/img/tab-catalogue_liste.png") !important;
  background-position: 0 0;
  height: 30px;
  width: 30px;
}
.bouton-affichage ul.nav-tabs li a.show_list.active {
  background-position: -39px 0 !important;
}
.bouton-affichage ul.nav-tabs li a.show_item {
  background-image: url("/sites/all/themes/edl/img/tab-catalogue_mosaique.png") !important;
  background-position: 0 0;
  height: 30px;
  width: 30px;
}
.bouton-affichage ul.nav-tabs li a.show_item.active {
  background-position: -39px 0 !important;
}
.page-taxonomy-term .node_liste .image-livre img {
  -moz-box-shadow: 4px 4px 10px 1px #878787;
  -webkit-box-shadow: 4px 4px 10px 1px #878787;
  -o-box-shadow: 4px 4px 10px 1px #878787;
  box-shadow: 4px 4px 10px 1px #878787;
  filter: progid:DXImageTransform.Microsoft.Shadow(color=#878787, Direction=134, Strength=10);
}
.page-taxonomy-term .node_liste .image-livre img[src*="home130815_03_-_copy.png"] {
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  -o-box-shadow: none;
  box-shadow: none;
  filter: none;
}
.page-evenement #edit-filtre-auteurs {
  display: none !important;
}
.node-type-livre .main-container .col-sm-9 #block-edl-livre-edl-livre-infos .infos-wrapper .livre-icons-wrapper .livre-ajouter-liste-deconnecte {
  width: 32px;
  height: 32px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  -o-border-radius: 50%;
  background-image: url("../img/livre-accroche.png");
  background-repeat: no-repeat;
  display: block;
  float: left;
  margin: 0 10px 0 0;
  cursor: pointer;
  background-position: 9px 9px;
  background-color: #8ab5dd;
}
.node-type-livre #alerte_non_connecte {
  display: none;
}
/*
	AJOUT DE MARIE (LIBEO)
*/

body .ui-tooltip {
  visibility: hidden;
}
@media (min-width: 641px) and (max-width: 767px) {
  body .ui-tooltip {
    visibility: hidden;
  }
  #navbar .header-container .searchbar-toggle {
    background-size: 20px 20px;
    width: 15%;
    height: 60px;
  }
  #navbar .header-container .logo-mobile-wrapper {
    width: 58%;
    margin-top: 19px;
  }
  #navbar .header-container .logo-mobile img {
    width: 125px;
    margin: 0 auto;
  }

  #navbar .header-container .logo-mobile {
    float: left !important;
    width: auto;
    margin-left: 13px;
  }
  #navbar .header-container .region-header-mobile {
    float: right !important;
    width: auto;
  }
  footer .footer-top #block-menu-menu-lien-footer-top .menu-customised li a {
    font-size: 16px;
  }
  #parallax-2 #block-edl-main-edl-block-semaine-home .col-sm-4.case-rouge .balayage-gauche:hover::before, #parallax-2 #block-edl-main-edl-block-semaine-home .col-sm-4.case-rouge-orange .balayage-gauche:hover::before, #parallax-2 #block-edl-main-edl-block-semaine-home .col-sm-4.case-orange .balayage-gauche:hover::before, #parallax-3 #block-views-collection-block-1 .view-collection .views-row .views-field-field-collection-photo a:hover::before, #parallax-1 #block-views-recherche-home-block-2 .balayage-gauche:hover::before, #parallax-4 .col-sm-4.block-facebook-pull .balayage-gauche:hover::before, .page-les-collections .view-collection .result-group-row .image-row .collection-photo a:hover::before {
    width: 0;
  }
  #navbar #block-edl-main-edl-block-login .form-block-login .link-container a {
    font-size: 13px;
    margin-bottom: 12px;
  }
  #navbar #block-edl-main-edl-block-login .form-block-login {
    height: auto;
  }
  /*.not-front .custom-page-header .page-header {
		font-size: 28px;
	}*/
/*  .page-user-register #user-register-form {
    width: 100%;
  }
  .page-user-register #user-register-form .captcha {
    width: 100%;
  }*/
 /* .page-user-register #user-register-form .fond-couleur label, */.page-mon-compte .account_block_librairies .form-item-field-librairies-preferees-und label, .page-mon-compte #zedl-account-custom-profile-catpro-centreinteret label, .page-mon-compte #zedl-account-custom-profile-catpro label {
    font-weight: normal;
    font-family: "FrutigerLTStd-Light";
    line-height: 20px;
    padding-left: 11px;
    padding-right: 11px;
  }
/*  .page-user-register #user-register-form select, .page-user-register #user-register-form input[type="text"], .page-user-register #user-register-form input[type="password"] {
    width: 94%;
  }*/
  .pager {
    margin-top: 0;
  }
  .page-mon-compte .main-container .nav-tabs > li {
    margin-right: 0;
  }
  .page-mon-compte .main-container .nav-tabs > li.active {
    float: none;
    width: auto;
  }
  .page-mon-compte .region-content select, .page-mon-compte .region-content input[type="text"], .page-mon-compte .region-content input[type="password"], .page-mon-compte .region-content textarea {
    width: 100%;
  }
  .page-mon-compte #zedl-account-custom-profile-mail-password {
    padding: 25px;
  }
  .page-mon-compte #pointeur {
    margin-top: 0;
    position: absolute;
    top: 25px;
    right: 20px;
  }
  .page-mon-compte .account_block_librairies {
    padding: 25px;
  }
  .page-mon-compte .account_block_librairies {
    position: relative;
  }
  #navbar .header-container .navbar-collapse .nav > li a.dropdown-toggle {
    font-size: 20px;
    line-height: 15px;
  }
  #navbar .header-container .navbar-header .navbar-toggle.highlighted {
    height: 60px;
    width: 100%;
    background-size: 30px 30px;
  }
  .dropdown-menu > li > a {
    font-size: 15px;
  }
  .page-catalogue .container #block-edl-catalogue-edl-filtre-catalogue-mobile .filter-container .ui-accordion-header span, .page-catalogue .container #block-edl-catalogue-edl-filtre-catalogue-mobile .filter-container a span {
    background-size: 18px 9px;
  }
  .page-catalogue .container #block-edl-catalogue-edl-filtre-catalogue-mobile .filter-container .ui-accordion-header, .page-catalogue .container #block-edl-catalogue-edl-filtre-catalogue-mobile .filter-container a {
    font-size: 20px;
    padding-left: 70px;
  }
  .page-catalogue .container #block-edl-catalogue-edl-filtre-catalogue-mobile .filter-container .ui-accordion-content a {
    background-position: 28px -322px;
    padding: 7px 0 7px 70px;
  }
  .page-catalogue .container #block-edl-catalogue-edl-filtre-catalogue-mobile .filter-container .ui-accordion-content a.active {
    background-position: 28px -175px;
  }
  #views-exposed-form-auteur-list-block-1 .form-type-textfield, #views-exposed-form-auteur-list-page .form-type-textfield, #edl-catalogue-search-form .form-type-textfield {
    font-size: 15px;
  }
  .page-catalogue .container #block-edl-catalogue-edl-filtre-catalogue-mobile .recherche-catalogue #edl-catalogue-search-form .form-type-textfield input {
    font-size: 15px;
    height: 35px;
    line-height: 24px;
  }
  .page-catalogue .container #block-edl-catalogue-edl-filtre-catalogue-mobile .recherche-catalogue #edl-catalogue-search-form button {
    width: 48px;
    height: 30px;
    margin: 0;
    background-position: left center;
    background-size: 30px 30px;
  }
  .page-catalogue .region-content .liste-catalogue .auteur-detail-wrapper .detail-livre .titre-livre a, .page-catalogue .region-sidebar-second .liste-catalogue .auteur-detail-wrapper .detail-livre .titre-livre a {
    font-size: 23px;
  }
  .page-catalogue .region-content .liste-catalogue .auteur-detail-wrapper .detail-livre .fiche-complete, .page-catalogue .region-sidebar-second .liste-catalogue .auteur-detail-wrapper .detail-livre .fiche-complete {
    font-size: 20px;
  }
  .auteur-detail-wrapper .image-livre {
    margin: 0 auto 0 auto;
    float: none;
  }
  .page-catalogue .region-content .liste-catalogue .auteur-detail-wrapper, .page-catalogue .region-sidebar-second .liste-catalogue .auteur-detail-wrapper {
    margin-bottom: 35px;
  }
  .node-type-livre .main-container .col-sm-9 .custom-page-header h1 {
    font-size: 28px;
  }
  .node-type-livre .ui-accordion-header {
    font-size: 18px;
    background-size: 18px 9px;
  }
  .node-type-livre .catalogue .dropdown-toggle:before, .node-type-auteur .auteurs .dropdown-toggle:before {
    display: none;
  }
  .node-type-livre .ui-accordion-content .format-container .formats .format-right {
    width: 100%;
    padding-top: 20px;
  }
  .node-type-livre .main-container .col-sm-9 #block-edl-livre-edl-livre-infos .infos-wrapper .livre-icons-wrapper .livre-share .lien-partage:after, .node-type-auteur .main-container .col-sm-9 #block-system-main .content-lower .lien-partage:after, .page-evenement-actualites .view-actualites .actu-container .actu-right .actu-lower .lien-partage:after, .page-evenement .col-sm-9 .views-row .details-evenement .details-lower .lien-partage:after {
    left: 122px;
  }
  .node-type-livre .main-container .col-sm-9 #block-edl-livre-edl-livre-infos .infos-wrapper .livre-icons-wrapper .livre-share .lien-partage .addthis_sharing_toolbox .at-svc-facebook, .node-type-livre .main-container .col-sm-9 #block-edl-livre-edl-livre-infos .infos-wrapper .livre-icons-wrapper .livre-share .lien-partage .addthis_sharing_toolbox .at-svc-twitter, .node-type-livre .main-container .col-sm-9 #block-edl-livre-edl-livre-infos .infos-wrapper .livre-icons-wrapper .livre-share .lien-partage .addthis_sharing_toolbox .at-svc-email, .node-type-auteur .main-container .col-sm-9 #block-system-main .content-lower .lien-partage .addthis_sharing_toolbox .at-svc-twitter, .node-type-auteur .main-container .col-sm-9 #block-system-main .content-lower .lien-partage .addthis_sharing_toolbox .at-svc-facebook, .node-type-auteur .main-container .col-sm-9 #block-system-main .content-lower .lien-partage .addthis_sharing_toolbox .at-svc-twitter .page-evenement-actualites .view-actualites .actu-container .actu-right .actu-lower .lien-partage .addthis_sharing_toolbox .at-svc-twitter, .page-evenement-actualites .view-actualites .actu-container .actu-right .actu-lower .lien-partage .addthis_sharing_toolbox .at-svc-facebook, .page-evenement-actualites .view-actualites .actu-container .actu-right .actu-lower .lien-partage .addthis_sharing_toolbox .at-svc-twitter, .page-evenement .col-sm-9 .views-row .details-evenement .details-lower .lien-partage .addthis_sharing_toolbox .at-svc-twitter, .page-evenement .col-sm-9 .views-row .details-evenement .details-lower .lien-partage .addthis_sharing_toolbox .at-svc-facebook, .page-evenement .col-sm-9 .views-row .details-evenement .details-lower .lien-partage .addthis_sharing_toolbox .at-svc-twitter {
    max-height: none;
    max-width: none;
  }
  .page-les-collections .view-collection .result-group-row .description-row .collection-description .description-value p {
    font-size: 17px;
  }
  .page-taxonomy-term .bandeau-bloc a {
    font-size: 15px;
  }
  .page-taxonomy-term .main-container.container #block-system-main .bloc-presentation .bloc-centre {
    margin: 60px auto 0;
  }
  .page-taxonomy-term .main-container.container #block-system-main .bloc-presentation .bloc-centre .description-collection {
    font-size: 18px;
    max-height: 303px;
  }
  .page-taxonomy-term .main-container.container #block-system-main .bloc-presentation .bloc-centre .titre-collection {
    font-size: 24px;
  }
  .page-taxonomy-term .main-container.container #block-system-main .bloc-presentation .bloc-centre .soustitre-collection {
    font-size: 20px;
  }
  .page-taxonomy-term .main-container.container #block-system-main .bloc-livres .container label, .page-taxonomy-term .main-container.container #block-system-main .bloc-livres .container .lien-catalogue a {
    font-weight: normal;
    font-family: "FrutigerLTStd-Light";
  }
  .page-taxonomy .region-content #block-system-main, .page-taxonomy .region-sidebar-second #block-system-main {
    padding-top: 0 !important;
  }
  #views-exposed-form-auteur-list-block-1 .form-submit, #views-exposed-form-auteur-list-page .form-submit, #edl-catalogue-search-form .form-submit {
    background-size: 24px 24px;
    height: 24px;
  }
  #views-exposed-form-auteur-list-block-1 .form-type-textfield input, #views-exposed-form-auteur-list-page .form-type-textfield input, #edl-catalogue-search-form .form-type-textfield input {
    height: 30px;
    line-height: 30px;
  }
  .page-taxonomy-term .bandeau-bloc {
    left: 13px;
    top: 67px;
  }
  .col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {
    min-height: 0;
  }
  .page-evenement .haute-wrapper .view-filters .views-exposed-widgets .views-exposed-widget #pop-filter-list li a, .page--evenement .haute-wrapper .view-filters .views-exposed-widgets .views-exposed-widget #pop-filter-list li a {
    font-size: 20px;
    padding-left: 70px;
  }
  .page-evenement .haute-wrapper .view-header .date-nav-wrapper .date-nav ul li.month_filter a.active, .page-evenement .haute-wrapper .view-header .date-nav-wrapper .date-nav ul li.month_filter a:hover, .page--evenement .haute-wrapper .view-header .date-nav-wrapper .date-nav ul li.month_filter a.active, .page--evenement .haute-wrapper .view-header .date-nav-wrapper .date-nav ul li.month_filter a:hover, .page-evenement .haute-wrapper .view-header .date-nav-wrapper .date-nav ul li.month_filter a, .page--evenement .haute-wrapper .view-header .date-nav-wrapper .date-nav ul li.month_filter a {
    position: relative;
  }
  .page-evenement .haute-wrapper .view-header .date-nav-wrapper .date-nav ul li.month_filter a .month_count, .page--evenement .haute-wrapper .view-header .date-nav-wrapper .date-nav ul li.month_filter a .month_count {
    top: 18px;
    position: absolute;
    right: 0;
    font-size: 18px;
  }
  .page-evenement-actualites .view-actualites .actu-container .actu-left, .page-evenement-actualites .view-actualites .actu-container .actu-right {
    float: none;
    width: 80%;
    margin: 0 auto;
  }
  .page-evenement-actualites .view-actualites .actu-container .actu-left {
    padding-top: 15px;
  }
  .page-evenement-actualites .view-actualites .actu-container .actu-right .actu-title {
    margin: 15px 0;
    font-size: 24px;
    line-height: normal;
  }
  .page-evenement-actualites .view-actualites .actu-container .actu-right .actu-date span, .page-evenement-actualites .view-actualites .actu-container .actu-right .actu-time span {
    font-size: 14px;
  }
  .page-evenement-actualites .view-actualites {
    margin-top: 0;
  }
  .node-type-auteur .main-container .col-sm-9 #block-system-main .prenom-auteur .field-item {
    font-size: 45px;
    line-height: 45px;
  }
  .node-type-auteur .main-container .col-sm-9 #block-system-main .nom-auteur .field-item {
    font-size: 53px;
    line-height: 53px;
  }
  .node-type-auteur .main-container .col-sm-9 #block-system-main .field-name-field-auteur-qualite .field-item {
    font-family: "FrutigerLTStd-Light";
  }
  .node-type-auteur .main-container .col-sm-9 #block-system-main .field-name-field-auteur-biographie .field-item, .node-type-auteur .main-container .col-sm-9 #block-system-main #biographie-complet {
    font-size: 16px;
    line-height: normal;
  }
  .node-type-auteur .main-container .col-sm-9 #block-system-main .content-lower .picto-partage {
    margin-right: 30px;
  }
  .node-type-auteur .main-container .col-sm-9 #block-system-main .content-lower .suivre-auteur {
    margin: 0;
  }
  .node-type-auteur .main-container .col-sm-9 #block-system-main .content-lower .field-name-field-auteur-site a {
    padding-top: 0;
    width: 100%;
  }
  .node-type-auteur .main-container .col-sm-9 #block-edl-auteur-edl-auteur-livres .titre-auteur-livres, .node-type-auteur .main-container .col-sm-9 #block-edl-auteur-edl-auteur-livres-mobile .titre-auteur-livres, .node-type-auteur .main-container .col-sm-9 #block-edl-auteur-edl-auteur-livres .fermer-titre-auteur .titre-auteur-livres, .node-type-auteur .main-container .col-sm-9 #block-edl-auteur-edl-auteur-livres-mobile .fermer-titre-auteur .titre-auteur-livres {
    background-size: 24px 12px;
  }
  .node-type-auteur .main-container .col-sm-9 #block-edl-auteur-edl-auteur-livres-mobile .titres-livre-wrapper .ui-accordion .ui-accordion-content .image-mobile-auteur {
    float: none;
    width: 100%;
    margin: 0 auto;
  }
  .node-type-auteur .main-container .col-sm-9 #block-edl-auteur-edl-auteur-livres-mobile .titres-livre-wrapper .ui-accordion .ui-accordion-content .detail-mobile-auteur {
    float: none;
    width: 100%;
    padding-top: 15px;
  }
  .node-type-auteur .main-container .col-sm-9 #block-edl-auteur-edl-auteur-livres-mobile .titres-livre-wrapper .ui-accordion .titre-auteur-header .titre-livre {
    font-size: 18px;
  }
}
@media (max-width: 520px) {
  /*#navbar #block-edl-main-edl-block-login .mon-compte a {
		margin: 0px 0 0 0;
	}*/
  body .ui-tooltip {
    visibility: hidden;
  }
}
@media (max-width: 768px) {
  .node-type-auteur .auteurs .dropdown-toggle:before {
    display: none !important;
  }
}
/*@media (min-width: 768px) {
	.not-front .custom-page-header .page-header {
		font-size: 28px;
		padding-top: 20px;
	}
}*/

@media (min-width: 768px) and (max-width: 1024px) {
  body .ui-tooltip {
    visibility: hidden;
  }
  /*#parallax-0 .parallax-image {
		background-size: contain !important;
	}*/
  #parallax-2 #block-edl-main-edl-block-semaine-home .col-sm-4.case-rouge .balayage-gauche:hover::before, #parallax-2 #block-edl-main-edl-block-semaine-home .col-sm-4.case-rouge-orange .balayage-gauche:hover::before, #parallax-2 #block-edl-main-edl-block-semaine-home .col-sm-4.case-orange .balayage-gauche:hover::before, #parallax-3 #block-views-collection-block-1 .view-collection .views-row .views-field-field-collection-photo a:hover::before, #parallax-1 #block-views-recherche-home-block-2 .balayage-gauche:hover::before, #parallax-4 .col-sm-4.block-facebook-pull .balayage-gauche:hover::before, .page-les-collections .view-collection .result-group-row .image-row .collection-photo a:hover::before {
    width: 0;
  }
  .page-taxonomy .region-content #block-system-main, .page-taxonomy .region-sidebar-second #block-system-main {
    padding-top: 0 !important;
  }
  #navbar #block-edl-main-edl-block-login .form-block-login .link-container a {
    font-size: 13px;
    margin-bottom: 12px;
  }
  #navbar #block-edl-main-edl-block-login .form-block-login {
    height: auto;
  }
}

.page-taxonomy-term .text-center {
  clear: both;
}
/**SITE MAP**/

ul.site-map-menu > li > a {
  color: #000;
  line-height: 30px;
  font-size: 16px;
  font-family: 'FrutigerLtStd-Roman';
}
ul.site-map-menu > li.expanded > a {
  font-size: 20px;
  color: #3774a9;
  line-height: initial;
  font-family: 'FrutigerLtStd-Roman';
  text-transform: uppercase;
  margin-top: 15px;
}
ul.site-map-menu > li.expanded > a:hover, ul.site-map-menu > li > a:hover {
  color: #a09d9d;
}
ul.site-map-menu li a {
  display: inline-block;
}
/**Roll pictos fiche ouvrage**/

.node-type-livre .main-container .col-sm-9 #block-edl-livre-edl-livre-infos .infos-wrapper .livre-icons-wrapper .livre-share .lien-partage {
  top: -42px;
  left: 9px;
}
.page-evenement .col-sm-9 .views-row .details-evenement .details-lower .lien-partage {
  top: -42px;
  left: 9px;
}
.node-type-auteur .main-container .col-sm-9 #block-system-main .content-lower .lien-partage, .page-evenement-actualites .view-actualites .actu-container .actu-right .actu-lower .lien-partage {
  top: -42px;
  left: -75px;
}
/*à mettre quand le picto d'achat sera actif*/

/*
	.node-type-livre .main-container .col-sm-9 #block-edl-livre-edl-livre-infos .infos-wrapper .livre-icons-wrapper .livre-share .lien-partage{
	top:-42px;
	left:66px;
}*/

.node-type-livre .main-container .col-sm-9 #block-edl-livre-edl-livre-infos .infos-wrapper .livre-icons-wrapper .livre-share .lien-partage .addthis_sharing_toolbox, .node-type-auteur .main-container .col-sm-9 #block-system-main .content-lower .lien-partage .addthis_sharing_toolbox, .page-evenement-actualites .view-actualites .actu-container .actu-right .actu-lower .lien-partage .addthis_sharing_toolbox, .page-evenement .col-sm-9 .views-row .details-evenement .details-lower .lien-partage .addthis_sharing_toolbox {
  float: right;
  padding-left: 5px;
}
.node-type-livre .main-container .col-sm-9 #block-edl-livre-edl-livre-infos .infos-wrapper .livre-icons-wrapper .livre-share .lien-partage .texte-partage, .node-type-auteur .main-container .col-sm-9 #block-system-main .content-lower .lien-partage .text-partage, .page-evenement-actualites .view-actualites .actu-container .actu-right .actu-lower .lien-partage .text-partage, .page-evenement .col-sm-9 .views-row .details-evenement .details-lower .lien-partage .text-partage {
  padding-top: 4px;
}
.node-type-livre .main-container .col-sm-9 #block-edl-livre-edl-livre-infos .infos-wrapper .livre-icons-wrapper .livre-liste, .node-type-livre .main-container .col-sm-9 #block-edl-livre-edl-livre-infos .infos-wrapper .livre-icons-wrapper .livre-panier, .node-type-livre .main-container .col-sm-9 #block-edl-livre-edl-livre-infos .infos-wrapper .livre-icons-wrapper .livre-ajouter-liste {
  position: relative;
}
.node-type-livre .main-container .col-sm-9 #block-edl-livre-edl-livre-infos .infos-wrapper .livre-icons-wrapper .livre-ajouter-liste-deconnecte {
  position: relative;
}
.node-type-livre .main-container .col-sm-9 #block-edl-livre-edl-livre-infos .infos-wrapper .livre-icons-wrapper .livre-liste .livre-liste-roll {
  position: absolute;
  left: -27px;
  top: -32px;
  background: #e9e9e9;
  padding: 0 14px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  -o-border-radius: 5px;
  display: none;
}
.node-type-livre .main-container .col-sm-9 #block-edl-livre-edl-livre-infos .infos-wrapper .livre-icons-wrapper .livre-share .livre-share-roll {
  position: absolute;
  left: 57px;
  top: -32px;
  background: #e9e9e9;
  padding: 0 14px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  -o-border-radius: 5px;
  display: none;
}
.node-type-livre .main-container .col-sm-9 #block-edl-livre-edl-livre-infos .infos-wrapper .livre-icons-wrapper .livre-liste .livre-liste-roll {
  width: 180px;
  left: -67px;
}
.node-type-livre .main-container .col-sm-9 #block-edl-livre-edl-livre-infos .infos-wrapper .livre-icons-wrapper .livre-liste .livre-liste-roll:after, .node-type-livre .main-container .col-sm-9 #block-edl-livre-edl-livre-infos .infos-wrapper .livre-icons-wrapper .livre-share .livre-share-roll:after, .node-type-livre .main-container .col-sm-9 #block-edl-livre-edl-livre-infos .infos-wrapper .livre-icons-wrapper .livre-panier .livre-panier-roll:after, .node-type-livre .main-container .col-sm-9 #block-edl-livre-edl-livre-infos .infos-wrapper .livre-icons-wrapper .livre-ajouter-liste .livre-ajouter-liste-roll:after, .node-type-livre .main-container .col-sm-9 #block-edl-livre-edl-livre-infos .infos-wrapper .livre-icons-wrapper .livre-ajouter-liste-deconnecte .livre-ajouter-liste-roll:after {
  width: 0;
  height: 0;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  border-top: 12px solid #e9e9e9;
  position: absolute;
  content: "";
  bottom: -8px;
  left: 39%;
}
.node-type-livre .main-container .col-sm-9 #block-edl-livre-edl-livre-infos .infos-wrapper .livre-icons-wrapper .livre-liste .livre-liste-roll .texte-partage, .node-type-livre .main-container .col-sm-9 #block-edl-livre-edl-livre-infos .infos-wrapper .livre-icons-wrapper .livre-share .livre-share-roll .texte-partage, .node-type-livre .main-container .col-sm-9 #block-edl-livre-edl-livre-infos .infos-wrapper .livre-icons-wrapper .livre-panier .livre-panier-roll .texte-partage, .node-type-livre .main-container .col-sm-9 #block-edl-livre-edl-livre-infos .infos-wrapper .livre-icons-wrapper .livre-ajouter-liste .livre-ajouter-liste-roll .texte-partage, .node-type-livre .main-container .col-sm-9 #block-edl-livre-edl-livre-infos .infos-wrapper .livre-icons-wrapper .livre-ajouter-liste-deconnecte .livre-ajouter-liste-roll .texte-partage {
  font-family: "FrutigerLTStd-Light";
  font-size: 14px;
  line-height: 23px;
  letter-spacing: 0px;
  color: black;
}
.node-type-livre .main-container .col-sm-9 #block-edl-livre-edl-livre-infos .infos-wrapper .livre-icons-wrapper .livre-panier .livre-panier-roll {
  position: absolute;
  left: -44px;
  top: -32px;
  background: #e9e9e9;
  padding: 0 14px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  -o-border-radius: 5px;
  width: 124px;
  display: none;
}
.node-type-livre .main-container .col-sm-9 #block-edl-livre-edl-livre-infos .infos-wrapper .livre-icons-wrapper .livre-ajouter-liste .livre-ajouter-liste-roll, .node-type-livre .main-container .col-sm-9 #block-edl-livre-edl-livre-infos .infos-wrapper .livre-icons-wrapper .livre-ajouter-liste-deconnecte .livre-ajouter-liste-roll {
  position: absolute;
  left: -56px;
  top: -32px;
  background: #e9e9e9;
  padding: 0 14px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  -o-border-radius: 5px;
  width: 160px;
  /*display:none;*/
}
.node-type-livre .main-container .col-sm-9 #block-edl-livre-edl-livre-infos .infos-wrapper .livre-icons-wrapper .livre-ajouter-liste .livre-ajouter-liste-roll, .node-type-livre .main-container .col-sm-9 #block-edl-livre-edl-livre-infos .infos-wrapper .livre-icons-wrapper .livre-ajouter-liste-deconnecte .livre-ajouter-liste-roll {
  display: none;
}
.node-type-livre .main-container .col-sm-9 #block-edl-livre-edl-livre-infos .infos-wrapper .livre-icons-wrapper .livre-ajouter-liste:hover .livre-ajouter-liste-roll, .node-type-livre .main-container .col-sm-9 #block-edl-livre-edl-livre-infos .infos-wrapper .livre-icons-wrapper .livre-ajouter-liste-deconnecte:hover .livre-ajouter-liste-roll, .node-type-livre .main-container .col-sm-9 #block-edl-livre-edl-livre-infos .infos-wrapper .livre-icons-wrapper .livre-panier:hover .livre-panier-roll, .node-type-livre .main-container .col-sm-9 #block-edl-livre-edl-livre-infos .infos-wrapper .livre-icons-wrapper .livre-share:hover .livre-share-roll, .node-type-livre .main-container .col-sm-9 #block-edl-livre-edl-livre-infos .infos-wrapper .livre-icons-wrapper .livre-liste:hover .livre-liste-roll {
  display: block;
}
.node-type-livre .addthis_sharing_toolbox a.at-share-btn .at-icon-wrapper, .node-type-auteur .addthis_sharing_toolbox a.at-share-btn .at-icon-wrapper, .page-evenement-actualites .addthis_sharing_toolbox a.at-share-btn .at-icon-wrapper, .page-evenement .col-sm-9 .views-row .details-evenement .details-lower .lien-partage .at-icon-wrapper {
  background-color: transparent !important;
}
.node-type-livre .addthis_sharing_toolbox a.at-share-btn .at-icon-wrapper svg, .node-type-auteur .addthis_sharing_toolbox a.at-share-btn .at-icon-wrapper svg, .page-evenement-actualites .addthis_sharing_toolbox a.at-share-btn .at-icon-wrapper svg, .page-evenement .addthis_sharing_toolbox a.at-share-btn .at-icon-wrapper svg, .page-evenement .col-sm-9 .views-row .details-evenement .details-lower .lien-partage svg, .page--evenement .col-sm-9 .views-row .details-evenement .details-lower .lien-partage svg {
  display: none !important;
}
.node-type-livre .main-container .col-sm-9 #block-edl-livre-edl-livre-infos .infos-wrapper .livre-icons-wrapper .livre-share .lien-partage .addthis_sharing_toolbox a, .node-type-auteur .main-container .col-sm-9 #block-system-main .content-lower .lien-partage .addthis_sharing_toolbox a, .page-evenement-actualites .view-actualites .actu-container .actu-right .actu-lower .lien-partage .addthis_sharing_toolbox a, .page-evenement .col-sm-9 .views-row .details-evenement .details-lower .lien-partage .addthis_sharing_toolbox a {
  margin: 0 0 0 15px !important;
}
.node-type-livre .main-container .col-sm-9 #block-edl-livre-edl-livre-infos .infos-wrapper .livre-icons-wrapper .livre-share .lien-partage .addthis_sharing_toolbox a.at-svc-facebook span, .node-type-auteur .main-container .col-sm-9 #block-system-main .content-lower .lien-partage .addthis_sharing_toolbox a.at-svc-facebook span, .page-evenement-actualites .view-actualites .actu-container .actu-right .actu-lower .lien-partage .addthis_sharing_toolbox a.at-svc-facebook span, .page-evenement .col-sm-9 .views-row .details-evenement .details-lower .lien-partage .addthis_sharing_toolbox a.at-svc-facebook span {
  width: 15px !important;
}
.node-type-livre .main-container .col-sm-9 #block-edl-livre-edl-livre-infos .infos-wrapper .livre-icons-wrapper .livre-share .lien-partage .addthis_sharing_toolbox .at-svc-email span, .node-type-auteur .main-container .col-sm-9 #block-system-main .content-lower .lien-partage .addthis_sharing_toolbox .at-svc-email span, .page-evenement-actualites .view-actualites .actu-container .actu-right .actu-lower .lien-partage .addthis_sharing_toolbox .at-svc-email span, .page-evenement .col-sm-9 .views-row .details-evenement .details-lower .lien-partage .addthis_sharing_toolbox .at-svc-email span {
  background-position: -46px 0 !important;
}
.node-type-livre .main-container .col-sm-9 #block-edl-livre-edl-livre-infos .infos-wrapper .livre-icons-wrapper .livre-share .lien-partage .addthis_sharing_toolbox .at-svc-email:hover span, .node-type-auteur .main-container .col-sm-9 #block-system-main .content-lower .lien-partage .addthis_sharing_toolbox .at-svc-email:hover span, .page-evenement-actualites .view-actualites .actu-container .actu-right .actu-lower .lien-partage .addthis_sharing_toolbox .at-svc-email:hover span, .page-evenement .col-sm-9 .views-row .details-evenement .details-lower .lien-partage .addthis_sharing_toolbox .at-svc-email:hover span {
  background-position: -46px -26px !important;
}
.node-type-livre .main-container .col-sm-9 #block-edl-livre-edl-livre-infos .infos-wrapper .livre-icons-wrapper .livre-share .lien-partage .addthis_sharing_toolbox .at-svc-twitter:hover span, .node-type-auteur .main-container .col-sm-9 #block-system-main .content-lower .lien-partage .addthis_sharing_toolbox .at-svc-twitter:hover span, .page-evenement-actualites .view-actualites .actu-container .actu-right .actu-lower .lien-partage .addthis_sharing_toolbox .at-svc-twitter:hover span, .page-evenement .col-sm-9 .views-row .details-evenement .details-lower .lien-partage .addthis_sharing_toolbox .at-svc-twitter:hover span {
  background-position: -18px -24px !important;
}
.node-type-livre .main-container .col-sm-9 #block-edl-livre-edl-livre-infos .infos-wrapper .livre-icons-wrapper .livre-share .lien-partage .addthis_sharing_toolbox .at-svc-facebook:hover span, .node-type-auteur .main-container .col-sm-9 #block-system-main .content-lower .lien-partage .addthis_sharing_toolbox .at-svc-facebook:hover span, .page-evenement-actualites .view-actualites .actu-container .actu-right .actu-lower .lien-partage .addthis_sharing_toolbox .at-svc-facebook:hover span, .page-evenement .col-sm-9 .views-row .details-evenement .details-lower .lien-partage .addthis_sharing_toolbox .at-svc-facebook:hover span {
  background-position: -0px -24px !important;
}
.node-type-livre .main-container .col-sm-9 #block-edl-livre-edl-livre-infos .infos-wrapper .livre-icons-wrapper .livre-ajouter-liste .livre-ajouter-liste-roll, .node-type-livre .main-container .col-sm-9 #block-edl-livre-edl-livre-infos .infos-wrapper .livre-icons-wrapper .livre-ajouter-liste-deconnecte .livre-ajouter-liste-roll {
  background-color: #e9e9e9 !important;
}
.node-type-livre .main-container .col-sm-9 #block-edl-livre-edl-livre-infos .infos-wrapper .livre-icons-wrapper .livre-ajouter-liste .livre-ajouter-liste-roll .texte-partage, .node-type-livre .main-container .col-sm-9 #block-edl-livre-edl-livre-infos .infos-wrapper .livre-icons-wrapper .livre-ajouter-liste-deconnecte .livre-ajouter-liste-roll .texte-partage {
  /*color : white !important;*/
}
.node-type-livre.catalogue-2 .livre-ajouter-liste:hover, .node-type-livre.catalogue-2 .livre-ajouter-liste-deconnecte:hover {
  background-color: #e9e9e9 !important;
}
#header #edit-title {
  background-color: white !important;
}
#sliding-popup {
  bottom: 0 !important;
  position: fixed !important;
  top: auto !important;
}
.page-evenement-actualites .view-actualites h3 span {
  float: none;
  background: none;
  padding: 0;
  margin: 0;
  display: inline-block;
  color: #337ab7;
  text-transform: none;
}
.page-evenement-actualites .view-actualites h3.active, .page-evenement-actualites .view-actualites h3:hover,
.page-evenement-actualites .view-actualites h3:hover span {
  background: #e9e9e9;
  cursor: default;
  display: inline-block;
}
.page-evenement-actualites .view-actualites .actu-container .actu-right .actu-lower .picto-partage {
  width: 30px !important;
  height: 32px !important;
}
.page-evenement-actualites .view-actualites .actu-container .actu-right .actu-lower .lien-partage, .page-evenement .col-sm-9 .views-row .details-evenement .details-lower .lien-partage, .page--evenement .col-sm-9 .views-row .details-evenement .details-lower .lien-partage {
  width: 175px !important;
}
.page-evenement .col-sm-9 .views-row .details-evenement .details-lower .picto-partage {
  width: 30px !important;
  height: 32px !important;
  background-position: -42px 0;
}
.region .pagination li {
  display: inline;
  float: left !important;
  height: 19px;
  margin: 10px 25px 20px 0 !important;
  width: 19px;
}
/*.page-user-cancel #user-cancel-confirm-form #edit-submit {
  float: left;
  margin: 50px;
}
.page-user-cancel #user-cancel-confirm-form #edit-cancel {
  float: left;
  margin: 50px;
  background-color: #bbb;
  border-color: #eee;
  color: #fff;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  text-align: center;
  vertical-align: middle;
  white-space: nowrap;
  padding: 10px 15px;
}
.page-user .tabs--primary {
  display: none !important;
}
.page-user .region-content {
  !*display : none;*!
}
.page-user-register .tabs--primary, .page-user-cancel .tabs--primary {
  display: block;
}
.page-user-register .region-content, .page-user-cancel .region-content {
  display: block;
}*/
/***********************/

/** Added style by FA **/

/***********************/

/** Glyphicons Bootstrap **/

@font-face {
  font-family: 'Glyphicons Halflings';
  src: url(fonts/glyphicons-halflings-regular.eot);
  src: url(fonts/glyphicons-halflings-regular.eot?#iefix) format('embedded-opentype'), url(fonts/glyphicons-halflings-regular.woff2) format('woff2'), url(fonts/glyphicons-halflings-regular.woff) format('woff'), url(fonts/glyphicons-halflings-regular.ttf) format('truetype'), url(fonts/glyphicons-halflings-regular.svg#glyphicons_halflingsregular) format('svg')
}
.glyphicon {
  position: relative;
  top: 1px;
  display: inline-block;
  font-family: 'Glyphicons Halflings';
  font-style: normal;
  font-weight: 400;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale
}
.glyphicon-asterisk:before {
  content: "\002a"
}
.glyphicon-plus:before {
  content: "\002b"
}
.glyphicon-eur:before, .glyphicon-euro:before {
  content: "\20ac"
}
.glyphicon-minus:before {
  content: "\2212"
}
.glyphicon-cloud:before {
  content: "\2601"
}
.glyphicon-envelope:before {
  content: "\2709"
}
.glyphicon-pencil:before {
  content: "\270f"
}
.glyphicon-glass:before {
  content: "\e001"
}
.glyphicon-music:before {
  content: "\e002"
}
.glyphicon-search:before {
  content: "\e003"
}
.glyphicon-heart:before {
  content: "\e005"
}
.glyphicon-star:before {
  content: "\e006"
}
.glyphicon-star-empty:before {
  content: "\e007"
}
.glyphicon-user:before {
  content: "\e008"
}
.glyphicon-film:before {
  content: "\e009"
}
.glyphicon-th-large:before {
  content: "\e010"
}
.glyphicon-th:before {
  content: "\e011"
}
.glyphicon-th-list:before {
  content: "\e012"
}
.glyphicon-ok:before {
  content: "\e013"
}
.glyphicon-remove:before {
  content: "\e014"
}
.glyphicon-zoom-in:before {
  content: "\e015"
}
.glyphicon-zoom-out:before {
  content: "\e016"
}
.glyphicon-off:before {
  content: "\e017"
}
.glyphicon-signal:before {
  content: "\e018"
}
.glyphicon-cog:before {
  content: "\e019"
}
.glyphicon-trash:before {
  content: "\e020"
}
.glyphicon-home:before {
  content: "\e021"
}
.glyphicon-file:before {
  content: "\e022"
}
.glyphicon-time:before {
  content: "\e023"
}
.glyphicon-road:before {
  content: "\e024"
}
.glyphicon-download-alt:before {
  content: "\e025"
}
.glyphicon-download:before {
  content: "\e026"
}
.glyphicon-upload:before {
  content: "\e027"
}
.glyphicon-inbox:before {
  content: "\e028"
}
.glyphicon-play-circle:before {
  content: "\e029"
}
.glyphicon-repeat:before {
  content: "\e030"
}
.glyphicon-refresh:before {
  content: "\e031"
}
.glyphicon-list-alt:before {
  content: "\e032"
}
.glyphicon-lock:before {
  content: "\e033"
}
.glyphicon-flag:before {
  content: "\e034"
}
.glyphicon-headphones:before {
  content: "\e035"
}
.glyphicon-volume-off:before {
  content: "\e036"
}
.glyphicon-volume-down:before {
  content: "\e037"
}
.glyphicon-volume-up:before {
  content: "\e038"
}
.glyphicon-qrcode:before {
  content: "\e039"
}
.glyphicon-barcode:before {
  content: "\e040"
}
.glyphicon-tag:before {
  content: "\e041"
}
.glyphicon-tags:before {
  content: "\e042"
}
.glyphicon-book:before {
  content: "\e043"
}
.glyphicon-bookmark:before {
  content: "\e044"
}
.glyphicon-print:before {
  content: "\e045"
}
.glyphicon-camera:before {
  content: "\e046"
}
.glyphicon-font:before {
  content: "\e047"
}
.glyphicon-bold:before {
  content: "\e048"
}
.glyphicon-italic:before {
  content: "\e049"
}
.glyphicon-text-height:before {
  content: "\e050"
}
.glyphicon-text-width:before {
  content: "\e051"
}
.glyphicon-align-left:before {
  content: "\e052"
}
.glyphicon-align-center:before {
  content: "\e053"
}
.glyphicon-align-right:before {
  content: "\e054"
}
.glyphicon-align-justify:before {
  content: "\e055"
}
.glyphicon-list:before {
  content: "\e056"
}
.glyphicon-indent-left:before {
  content: "\e057"
}
.glyphicon-indent-right:before {
  content: "\e058"
}
.glyphicon-facetime-video:before {
  content: "\e059"
}
.glyphicon-picture:before {
  content: "\e060"
}
.glyphicon-map-marker:before {
  content: "\e062"
}
.glyphicon-adjust:before {
  content: "\e063"
}
.glyphicon-tint:before {
  content: "\e064"
}
.glyphicon-edit:before {
  content: "\e065"
}
.glyphicon-share:before {
  content: "\e066"
}
.glyphicon-check:before {
  content: "\e067"
}
.glyphicon-move:before {
  content: "\e068"
}
.glyphicon-step-backward:before {
  content: "\e069"
}
.glyphicon-fast-backward:before {
  content: "\e070"
}
.glyphicon-backward:before {
  content: "\e071"
}
.glyphicon-play:before {
  content: "\e072"
}
.glyphicon-pause:before {
  content: "\e073"
}
.glyphicon-stop:before {
  content: "\e074"
}
.glyphicon-forward:before {
  content: "\e075"
}
.glyphicon-fast-forward:before {
  content: "\e076"
}
.glyphicon-step-forward:before {
  content: "\e077"
}
.glyphicon-eject:before {
  content: "\e078"
}
.glyphicon-chevron-left:before {
  content: "\e079"
}
.glyphicon-chevron-right:before {
  content: "\e080"
}
.glyphicon-plus-sign:before {
  content: "\e081"
}
.glyphicon-minus-sign:before {
  content: "\e082"
}
.glyphicon-remove-sign:before {
  content: "\e083"
}
.glyphicon-ok-sign:before {
  content: "\e084"
}
.glyphicon-question-sign:before {
  content: "\e085"
}
.glyphicon-info-sign:before {
  content: "\e086"
}
.glyphicon-screenshot:before {
  content: "\e087"
}
.glyphicon-remove-circle:before {
  content: "\e088"
}
.glyphicon-ok-circle:before {
  content: "\e089"
}
.glyphicon-ban-circle:before {
  content: "\e090"
}
.glyphicon-arrow-left:before {
  content: "\e091"
}
.glyphicon-arrow-right:before {
  content: "\e092"
}
.glyphicon-arrow-up:before {
  content: "\e093"
}
.glyphicon-arrow-down:before {
  content: "\e094"
}
.glyphicon-share-alt:before {
  content: "\e095"
}
.glyphicon-resize-full:before {
  content: "\e096"
}
.glyphicon-resize-small:before {
  content: "\e097"
}
.glyphicon-exclamation-sign:before {
  content: "\e101"
}
.glyphicon-gift:before {
  content: "\e102"
}
.glyphicon-leaf:before {
  content: "\e103"
}
.glyphicon-fire:before {
  content: "\e104"
}
.glyphicon-eye-open:before {
  content: "\e105"
}
.glyphicon-eye-close:before {
  content: "\e106"
}
.glyphicon-warning-sign:before {
  content: "\e107"
}
.glyphicon-plane:before {
  content: "\e108"
}
.glyphicon-calendar:before {
  content: "\e109"
}
.glyphicon-random:before {
  content: "\e110"
}
.glyphicon-comment:before {
  content: "\e111"
}
.glyphicon-magnet:before {
  content: "\e112"
}
.glyphicon-chevron-up:before {
  content: "\e113"
}
.glyphicon-chevron-down:before {
  content: "\e114"
}
.glyphicon-retweet:before {
  content: "\e115"
}
.glyphicon-shopping-cart:before {
  content: "\e116"
}
.glyphicon-folder-close:before {
  content: "\e117"
}
.glyphicon-folder-open:before {
  content: "\e118"
}
.glyphicon-resize-vertical:before {
  content: "\e119"
}
.glyphicon-resize-horizontal:before {
  content: "\e120"
}
.glyphicon-hdd:before {
  content: "\e121"
}
.glyphicon-bullhorn:before {
  content: "\e122"
}
.glyphicon-bell:before {
  content: "\e123"
}
.glyphicon-certificate:before {
  content: "\e124"
}
.glyphicon-thumbs-up:before {
  content: "\e125"
}
.glyphicon-thumbs-down:before {
  content: "\e126"
}
.glyphicon-hand-right:before {
  content: "\e127"
}
.glyphicon-hand-left:before {
  content: "\e128"
}
.glyphicon-hand-up:before {
  content: "\e129"
}
.glyphicon-hand-down:before {
  content: "\e130"
}
.glyphicon-circle-arrow-right:before {
  content: "\e131"
}
.glyphicon-circle-arrow-left:before {
  content: "\e132"
}
.glyphicon-circle-arrow-up:before {
  content: "\e133"
}
.glyphicon-circle-arrow-down:before {
  content: "\e134"
}
.glyphicon-globe:before {
  content: "\e135"
}
.glyphicon-wrench:before {
  content: "\e136"
}
.glyphicon-tasks:before {
  content: "\e137"
}
.glyphicon-filter:before {
  content: "\e138"
}
.glyphicon-briefcase:before {
  content: "\e139"
}
.glyphicon-fullscreen:before {
  content: "\e140"
}
.glyphicon-dashboard:before {
  content: "\e141"
}
.glyphicon-paperclip:before {
  content: "\e142"
}
.glyphicon-heart-empty:before {
  content: "\e143"
}
.glyphicon-link:before {
  content: "\e144"
}
.glyphicon-phone:before {
  content: "\e145"
}
.glyphicon-pushpin:before {
  content: "\e146"
}
.glyphicon-usd:before {
  content: "\e148"
}
.glyphicon-gbp:before {
  content: "\e149"
}
.glyphicon-sort:before {
  content: "\e150"
}
.glyphicon-sort-by-alphabet:before {
  content: "\e151"
}
.glyphicon-sort-by-alphabet-alt:before {
  content: "\e152"
}
.glyphicon-sort-by-order:before {
  content: "\e153"
}
.glyphicon-sort-by-order-alt:before {
  content: "\e154"
}
.glyphicon-sort-by-attributes:before {
  content: "\e155"
}
.glyphicon-sort-by-attributes-alt:before {
  content: "\e156"
}
.glyphicon-unchecked:before {
  content: "\e157"
}
.glyphicon-expand:before {
  content: "\e158"
}
.glyphicon-collapse-down:before {
  content: "\e159"
}
.glyphicon-collapse-up:before {
  content: "\e160"
}
.glyphicon-log-in:before {
  content: "\e161"
}
.glyphicon-flash:before {
  content: "\e162"
}
.glyphicon-log-out:before {
  content: "\e163"
}
.glyphicon-new-window:before {
  content: "\e164"
}
.glyphicon-record:before {
  content: "\e165"
}
.glyphicon-save:before {
  content: "\e166"
}
.glyphicon-open:before {
  content: "\e167"
}
.glyphicon-saved:before {
  content: "\e168"
}
.glyphicon-import:before {
  content: "\e169"
}
.glyphicon-export:before {
  content: "\e170"
}
.glyphicon-send:before {
  content: "\e171"
}
.glyphicon-floppy-disk:before {
  content: "\e172"
}
.glyphicon-floppy-saved:before {
  content: "\e173"
}
.glyphicon-floppy-remove:before {
  content: "\e174"
}
.glyphicon-floppy-save:before {
  content: "\e175"
}
.glyphicon-floppy-open:before {
  content: "\e176"
}
.glyphicon-credit-card:before {
  content: "\e177"
}
.glyphicon-transfer:before {
  content: "\e178"
}
.glyphicon-cutlery:before {
  content: "\e179"
}
.glyphicon-header:before {
  content: "\e180"
}
.glyphicon-compressed:before {
  content: "\e181"
}
.glyphicon-earphone:before {
  content: "\e182"
}
.glyphicon-phone-alt:before {
  content: "\e183"
}
.glyphicon-tower:before {
  content: "\e184"
}
.glyphicon-stats:before {
  content: "\e185"
}
.glyphicon-sd-video:before {
  content: "\e186"
}
.glyphicon-hd-video:before {
  content: "\e187"
}
.glyphicon-subtitles:before {
  content: "\e188"
}
.glyphicon-sound-stereo:before {
  content: "\e189"
}
.glyphicon-sound-dolby:before {
  content: "\e190"
}
.glyphicon-sound-5-1:before {
  content: "\e191"
}
.glyphicon-sound-6-1:before {
  content: "\e192"
}
.glyphicon-sound-7-1:before {
  content: "\e193"
}
.glyphicon-copyright-mark:before {
  content: "\e194"
}
.glyphicon-registration-mark:before {
  content: "\e195"
}
.glyphicon-cloud-download:before {
  content: "\e197"
}
.glyphicon-cloud-upload:before {
  content: "\e198"
}
.glyphicon-tree-conifer:before {
  content: "\e199"
}
.glyphicon-tree-deciduous:before {
  content: "\e200"
}
.glyphicon-cd:before {
  content: "\e201"
}
.glyphicon-save-file:before {
  content: "\e202"
}
.glyphicon-open-file:before {
  content: "\e203"
}
.glyphicon-level-up:before {
  content: "\e204"
}
.glyphicon-copy:before {
  content: "\e205"
}
.glyphicon-paste:before {
  content: "\e206"
}
.glyphicon-alert:before {
  content: "\e209"
}
.glyphicon-equalizer:before {
  content: "\e210"
}
.glyphicon-king:before {
  content: "\e211"
}
.glyphicon-queen:before {
  content: "\e212"
}
.glyphicon-pawn:before {
  content: "\e213"
}
.glyphicon-bishop:before {
  content: "\e214"
}
.glyphicon-knight:before {
  content: "\e215"
}
.glyphicon-baby-formula:before {
  content: "\e216"
}
.glyphicon-tent:before {
  content: "\26fa"
}
.glyphicon-blackboard:before {
  content: "\e218"
}
.glyphicon-bed:before {
  content: "\e219"
}
.glyphicon-apple:before {
  content: "\f8ff"
}
.glyphicon-erase:before {
  content: "\e221"
}
.glyphicon-hourglass:before {
  content: "\231b"
}
.glyphicon-lamp:before {
  content: "\e223"
}
.glyphicon-duplicate:before {
  content: "\e224"
}
.glyphicon-piggy-bank:before {
  content: "\e225"
}
.glyphicon-scissors:before {
  content: "\e226"
}
.glyphicon-bitcoin:before, .glyphicon-btc:before, .glyphicon-xbt:before {
  content: "\e227"
}
.glyphicon-jpy:before, .glyphicon-yen:before {
  content: "\00a5"
}
.glyphicon-rub:before, .glyphicon-ruble:before {
  content: "\20bd"
}
.glyphicon-scale:before {
  content: "\e230"
}
.glyphicon-ice-lolly:before {
  content: "\e231"
}
.glyphicon-ice-lolly-tasted:before {
  content: "\e232"
}
.glyphicon-education:before {
  content: "\e233"
}
.glyphicon-option-horizontal:before {
  content: "\e234"
}
.glyphicon-option-vertical:before {
  content: "\e235"
}
.glyphicon-menu-hamburger:before {
  content: "\e236"
}
.glyphicon-modal-window:before {
  content: "\e237"
}
.glyphicon-oil:before {
  content: "\e238"
}
.glyphicon-grain:before {
  content: "\e239"
}
.glyphicon-sunglasses:before {
  content: "\e240"
}
.glyphicon-text-size:before {
  content: "\e241"
}
.glyphicon-text-color:before {
  content: "\e242"
}
.glyphicon-text-background:before {
  content: "\e243"
}
.glyphicon-object-align-top:before {
  content: "\e244"
}
.glyphicon-object-align-bottom:before {
  content: "\e245"
}
.glyphicon-object-align-horizontal:before {
  content: "\e246"
}
.glyphicon-object-align-left:before {
  content: "\e247"
}
.glyphicon-object-align-vertical:before {
  content: "\e248"
}
.glyphicon-object-align-right:before {
  content: "\e249"
}
.glyphicon-triangle-right:before {
  content: "\e250"
}
.glyphicon-triangle-left:before {
  content: "\e251"
}
.glyphicon-triangle-bottom:before {
  content: "\e252"
}
.glyphicon-triangle-top:before {
  content: "\e253"
}
.glyphicon-console:before {
  content: "\e254"
}
.glyphicon-superscript:before {
  content: "\e255"
}
.glyphicon-subscript:before {
  content: "\e256"
}
.glyphicon-menu-left:before {
  content: "\e257"
}
.glyphicon-menu-right:before {
  content: "\e258"
}
.glyphicon-menu-down:before {
  content: "\e259"
}
.glyphicon-menu-up:before {
  content: "\e260"
}
/** page collection **/

.page-taxonomy-term .main-container.container #block-system-main .bloc-presentation .bloc-centre {
  height: inherit;
  padding-bottom: 1px;
}
.page-taxonomy-term .main-container.container #block-system-main .bloc-presentation .bloc-centre .fleche {
  margin: 20px auto 20px;
}
.page-taxonomy-term .bandeau-bloc {
  right: 35px !important;
  left: inherit !important;
}
.page-taxonomy-term .main-container.container #block-system-main .bloc-livres {
  padding: 0px 0px 30px 0;
}
.page-taxonomy-term .main-container.container #block-system-main .bloc-livres .block-title, .page-taxonomy-term .main-container.container #block-system-main .bloc-livres .titre-bloc {
  text-align: center;
  color: #fff;
  text-transform: uppercase;
  margin: 0 0 30px;
  padding: 27px;
  font-family: "FrutigerLTStd-Roman";
  font-size: 30px;
  line-height: 30px;
  letter-spacing: 0px;
  position: relative;
  top: 0;
  left: auto;
  float: none;
  background: #f0b830;
  font-weight: bold;
}
.page-les-collections .view-collection .result-group-row .description-row .collection-description .description-value {
  width: 93%;
  margin: auto;
}
.bloc-collection-contenu-libre {
  text-align: center;
}
.bloc-collection-contenu-libre .glyphicon {
  font-size: 40px;
}
/** couverture livre **/

.node-type-livre .main-container aside.col-sm-3 .region-sidebar-first #block-edl-livre-edl-livre-image .toolbar-couverture .recompense-autres-wrapper .btn-voir-rec {
  opacity: 0.7;
}
.node-type-livre .main-container aside.col-sm-3 .region-sidebar-first #block-edl-livre-edl-livre-image .toolbar-couverture .recompense-autres-wrapper:hover .btn-voir-rec {
  cursor: pointer;
}
/** titre pages **/

.page-catalogue .custom-page-header {
  padding: 0;
  margin: 45px 0 40px;
  display: block;
  width: 100%;
  height: 84px;
  /*background: url("/sites/all/themes/edl/img/hachure.png") repeat 0 0 transparent;*/
}
/*.page-catalogue .custom-page-header .page-header {
	margin: 0 0 0 22px;
	padding: 0 0 0 20px;
	background-color: transparent;
	box-shadow: none;
	border: none;
	border-radius: 0;
	height: 70px;
	width: 370px;
	text-transform: uppercase;
	color: #fff;
	font-family: "FrutigerLTStd-Roman";
	font-size: 40px;
	line-height: 70px;
	letter-spacing: 0px;
	background-color: #337ab7;
	position: relative;
	top: -25px;
	!*font-weight: bold;*!
}*/

/** fil d'ariane **/

.breadcrumb li {
  margin-left: 12px;
}
.breadcrumb li.first {
	margin-left: 0px;
}

.breadcrumb li, .breadcrumb li a {
  font-size: 14px;
}
/*

	.lien-catalogue>a, .form-item-recherche-collection-1 label, .form-item-recherche-collection-2 label {
	display:inline-block;
	font-family: "FrutigerLTStd-Roman";
	font-size:16px;
	font-weight:bold;
	color:#fff !important;
	text-decoration:none;
	text-transform:uppercase;
	text-align:center;
	text-shadow:1px 1px 0px #07526e;
	padding-top:6px;
	margin-left:auto;
	margin-right:auto;
	left:30px;
	position:relative;
	cursor:pointer;
	border: none !important;
	background: #f0b830;
	border-radius: 5px;
	box-shadow: inset 0px 0px 0px #f49a22, 0px 3px 0px 0px #AB6D10, 0px 5px 4px #999;
	}

	.lien-catalogue>a:hover, .form-item-recherche-collection-1 label:hover, .form-item-recherche-collection-2 label:hover {
	top:2px;
	box-shadow: inset 0px 0px 0px #f49a22, 0px 1px 0px 0px #AB6D10, 0px 2px 2px #999;
	}
	.lien-catalogue>a:active {
	top:2px;
	box-shadow: inset 0px 0px 0px #f49a22, 0px 1px 0px 0px #AB6D10, 0px 2px 2px #999;
	}

	.lien-catalogue>a:before, .form-item-recherche-collection-1 label:before, .form-item-recherche-collection-2 label:before {
	font-family:'Glyphicons Halflings';
	font-size: 16px;
	line-height: 1em;
	font-weight: normal;
	color: #fff;
	content:"\e258";
	display:block;
	float:left;
	margin-right:7px;
	text-shadow: 1px 1px 2px #07526e;

	}
	.lien-catalogue>a:hover:before, .form-item-recherche-collection-1 label:hover:before, .form-item-recherche-collection-1.cocher label:before, .form-item-recherche-collection-2 label:hover:before, .form-item-recherche-collection-2.cocher label:before {
	content:"\e259";
	}
	.lien-catalogue>a:before, .form-item-recherche-collection-1 label:before, .form-item-recherche-collection-2 label:before{
	content:"\e258";
}*/

.lien-catalogue > a, .form-item-recherche-collection-1 label, .form-item-recherche-collection-2 label {
  display: inline-block;
  font-family: "FrutigerLTStd-Roman";
  font-size: 16px;
  font-weight: bold;
  text-decoration: none;
  text-transform: uppercase;
  text-align: center;
  padding-top: 6px;
  margin-left: auto;
  margin-right: auto;
  left: 30px;
  position: relative;
  cursor: pointer;
  background: none;
  border-radius: 16px !important;
}
.lien-catalogue > a {
  color: #5bc0de !important;
  border: 1px solid #5bc0de !important;
}
.lien-catalogue > a:hover, .lien-catalogue > a:active {
  background: #5bc0de !important;
}
.lien-catalogue > a:before {
  color: #5bc0de;
}
.lien-catalogue > a:hover, .form-item-recherche-collection-1 label:hover, .form-item-recherche-collection-2 label:hover {
  color: #fff !important;
}
.form-item-recherche-collection-1 label {
  border-color: #428bca !important;
  color: #428bca !important;
}
.form-item-recherche-collection-1.cocher label, .form-item-recherche-collection-1 label:hover, .form-item-recherche-collection-1 label:hover:before {
  background: #428bca !important;
}
.form-item-recherche-collection-2 label {
  border-color: #26ae90 !important;
  color: #26ae90 !important;
}
.form-item-recherche-collection-2.cocher label, .form-item-recherche-collection-2 label:hover, .form-item-recherche-collection-2 label:hover:before {
  background: #26ae90 !important;
}
.lien-catalogue > a:before, .form-item-recherche-collection-1 label:before, .form-item-recherche-collection-2 label:before {
  font-family: 'Glyphicons Halflings';
  font-size: 16px;
  line-height: 1em;
  font-weight: normal;
  content: "\e258";
  display: block;
  float: left;
  margin-right: 7px;
}
.form-item-recherche-collection-1.cocher label, .form-item-recherche-collection-2.cocher label {
  color: #fff !important;
}
.lien-catalogue > a:hover:before, .form-item-recherche-collection-1 label:hover:before, .form-item-recherche-collection-1.cocher label:before, .form-item-recherche-collection-2 label:hover:before, .form-item-recherche-collection-2.cocher label:before {
  content: "\e259";
  color: #fff;
}
.lien-catalogue > a:before, .form-item-recherche-collection-1 label:before, .form-item-recherche-collection-2 label:before {
  content: "\e258";
}
/** Footer **/

.footer-bottom #block-menu-menu-sites-partenaires .block-title {
  text-transform: inherit;
  margin: 0 4px 0 0 !important;
}
.footer-bottom #block-menu-menu-sites-partenaires .block-title::after {
  content: "  :  "
}
/** BTN **/

.btn_opusdesign {
  /*float:left;*/
  background: #ef9500;
  padding: 15px 30px !important;
  border-radius: 6px;
  color: #fff !important;
  cursor: pointer;
  display: block;
  overflow: hidden;
  position: relative;
  vertical-align: middle;
  white-space: normal;
  z-index: 1;
  height: 100%;
  width: 100%;
}
.btn_opusdesign:before {
  background: #ff2204;
  bottom: 0;
  position: absolute;
  left: 0;
  top: 0;
  width: 0;
  z-index: -1;
  height: 100%;
  display: block;
  content: "";
  -webkit-transition: width 0.3s ease-in-out 0.1s;
  -moz-transition: width 0.3s ease-in-out 0.1s;
  -o-transition: width 0.3s ease-in-out 0.1s;
  -ms-transition: width 0.3s ease-in-out 0.1s;
  transition: width 0.3s ease-in-out 0.1s;
}
.btn_opusdesign:hover:before {
  width: 100%;
}
/*
	AJOUT DE MARIE (LIBEO)
*/

/*.page-node-7292 .custom-page-header, .page-node-7867 .custom-page-header, .page-node-7875 .custom-page-header, .page-node-7869 .custom-page-header, .page-thematique-liste .custom-page-header {
	padding: 0;
	margin: 45px 0 40px;
	display: block;
	width: 100%;
	height: 84px;
	background: url("/sites/all/themes/edl/img/hachure.png") repeat 0 0 transparent;
}*/

/*.page-node-7292 .custom-page-header .page-header, .page-thematique-liste .custom-page-header .page-header {
	margin: 0 0 0 22px;
	padding: 0 0 0 20px;
	background-color: transparent;
	box-shadow: none;
	border: none;
	border-radius: 0;
	height: 70px;
	width: 58%;
	text-transform: uppercase;
	color: #fff;
	font-family: "FrutigerLTStd-Roman";
	font-size: 40px;
	line-height: 70px;
	letter-spacing: 0px;
	background-color: #296483;
	position: relative;
	top: -25px;
}*/

/*.page-node-7867 .custom-page-header .page-header {
	margin: 0 0 0 22px;
	padding: 0 0 0 20px;
	background-color: transparent;
	box-shadow: none;
	border: none;
	border-radius: 0;
	height: 70px;
	width: 38%;
	text-transform: uppercase;
	color: #fff;
	font-family: "FrutigerLTStd-Roman";
	font-size: 40px;
	line-height: 70px;
	letter-spacing: 0px;
	background-color: #296483;
	position: relative;
	top: -25px;
}*/

/*.page-node-7875 .custom-page-header .page-header {
	margin: 0 0 0 22px;
	padding: 0 0 0 20px;
	background-color: transparent;
	box-shadow: none;
	border: none;
	border-radius: 0;
	height: 70px;
	width: 33%;
	text-transform: uppercase;
	color: #fff;
	font-family: "FrutigerLTStd-Roman";
	font-size: 40px;
	line-height: 70px;
	letter-spacing: 0px;
	background-color: #296483;
	position: relative;
	top: -25px;
}*/

/*.page-node-7869 .custom-page-header .page-header {
	margin: 0 0 0 22px;
	padding: 0 0 0 20px;
	background-color: transparent;
	box-shadow: none;
	border: none;
	border-radius: 0;
	height: 70px;
	width: 30%;
	text-transform: uppercase;
	color: #fff;
	font-family: "FrutigerLTStd-Roman";
	font-size: 40px;
	line-height: 70px;
	letter-spacing: 0px;
	background-color: #296483;
	position: relative;
	top: -25px;
}*/

/*.page-catalogue .custom-page-header .page-header {
	margin: 0 0 0 22px;
	padding: 0 0 0 20px;
	background-color: transparent;
	box-shadow: none;
	border: none;
	border-radius: 0;
	height: 70px;
	width: 52%;
	text-transform: uppercase;
	color: #fff;
	font-family: "FrutigerLTStd-Roman";
	font-size: 40px;
	line-height: 70px;
	letter-spacing: 0px;
	background-color: #ff2204;
	position: relative;
	top: -25px;
}*/

.texte-foreign {
  font-family: "FrutigerLTStd-Light";
  font-size: 16px;
  color: black;
  margin: 0 auto 5px auto;
  padding: 0;
  width: 88.19327731092437%;
  line-height: normal;
}
.texte-foreign-gris {
  width: 50.42016806722689%;
  padding: 20px 2.46212121212121%;
  margin: 50px auto;
  font: normal 16px 'FrutigerLTStd-Roman';
  background: #e9e9e9;
  text-align: center;
  color: #8f8c8c;
  line-height: normal;
}
.texte-foreign-gris h2 {
  text-transform: uppercase;
  color: #8f8c8c;
  font: normal 24px 'FrutigerLTStd-Light';
  margin-top: 0;
}
.texte-foreign-gris a {
  color: #296483;
  font: normal 16px 'FrutigerLTStd-Roman';
  text-decoration: none;
}
.clear {
  clear: both;
}
.ct-contact-gris {
  width: 71.075758%;
  padding: 20px 2.46212121212121%;
  margin: 0 auto;
  color: #7c7c7c !important;
  font: normal 15px 'FrutigerLTStd-Roman';
  background: #e9e9e9;
}
.ct-contact {
  width: 71.075758%;
  padding: 20px 0;
  margin: 0 auto;
  color: #7c7c7c !important;
  font: normal 15px 'FrutigerLTStd-Roman';
}
.ct-contact-gris h2 {
  text-transform: uppercase;
  color: #7c7c7c !important;
  font: normal 15px 'FrutigerLTStd-Roman';
  margin-top: 0;
}
.ct-contact a, .ct-contact-gris a {
  color: #000 !important;
  text-decoration: none;
  display: inline-block;
}
.ct-contact-gris h3, .ct-contact h3 {
  text-transform: uppercase;
  color: #7c7c7c;
  font: normal 24px 'FrutigerLTStd-Light';
  margin-top: 0;
  margin-bottom: 20px;
}
.ct-contact.manuscrits p {
  font-size: 14px;
}
.ct-contact.manuscrits span {
  font-family: 'FrutigerLTStd-Bold';
}
.bt-creez {
  width: 26.767676767676768%;
  max-width: 212px;
  margin-top: 20px;
}
.bt-creez a {
  width: 100%;
  text-align: center;
  text-transform: uppercase;
  background: #c00418;
  color: #fff !important;
  font: normal 16px 'FrutigerLTStd-Roman';
  line-height: 33px;
}
.infos-legales-contact {
  color: #7c7c7c !important;
  font: normal 12px 'FrutigerLTStd-Roman';
  padding-top: 60px;
}
.ct-qui {
  width: 88.19327731092437%;
  margin: 0 auto;
  color: #000;
  font: normal 16px 'FrutigerLTStd-Light';
}
.ct-qui img.logo {
  float: right;
  margin-left: 40px;
  margin-bottom: 15px;
}
.texte-foreign a.bouton, .ct-qui a.bouton {
  width: 24.369747899159663%;
  display: block;
  color: #fff;
  font-size: 16px;
  line-height: 33px;
  text-align: center;
  text-decoration: none;
  font-family: "FrutigerLTStd-Roman";
  background: #c00418;
  margin: 20px auto 46px auto;
  text-transform: uppercase;
}
.texte-foreign a:hover.bouton, .ct-qui a:hover.bouton {
  background-color: #c00418;
  color: #fff;
}
.ct-qui .titre-videos {
  color: #000;
  font: normal 24px 'FrutigerLTStd-Light';
}
.ct-qui .videos {
  width: 69.7979797979798%;
  margin: 0 auto;
}
/*classe videoWrapper pour rendre les vidéos responsive*/

.ct-qui .videoWrapper {
  position: relative;
  padding-bottom: 56.25%;
  /* 16:9 */
  padding-top: 25px;
  height: 0;
  margin-bottom: 30px;
}
.videoWrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.page-node-7292 .titre, .page-thematique-liste .titre {
  color: #000;
  font-style: normal;
  font-size: 24px;
  font-weight: 300;
  line-height: 120%;
  margin: 0 0 1.25em;
  text-rendering: optimizelegibility;
  word-wrap: break-word;
  text-transform: uppercase;
  font-family: "FrutigerLTStd-Light";
}
.page-node-7292 .myButton {
  width: 24.369747899159663%;
  display: block;
  color: #fff;
  font-size: 16px;
  line-height: 33px;
  text-align: center;
  text-decoration: none;
  font-family: "FrutigerLTStd-Roman";
  background: #c00418;
  margin-bottom: 46px;
  text-transform: uppercase;
}
.page-node-7292 .myButton:hover {
  background-color: #c00418;
  color: #fff;
}
.page-node-7292 .previews ul {
  list-style: outside none none;
  text-align: center;
}
.page-node-7292 .les-images {
  float: left;
  width: 166px;
  margin-right: 100px;
  position: relative;
  margin-bottom: 50px;
}
.page-node-7292 .les-images a.bt-dl {
  display: block;
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: rgba(79, 113, 127, 80);
  font-family: "FrutigerLTStd-Roman";
  font-size: 14px;
  color: #fff;
  line-height: 28px;
  padding: 0 7px;
}
.page-node-7292 .les-images a.bt-dl img {
  float: right;
  margin-top: 5px;
}
.page-node-7292 .img-catalogue {
  box-shadow: 7px 7px 13px rgba(3, 3, 3, .3);
  height: 234px;
  width: 166px;
}
@media (max-width: 480px) {
  .ct-contact-gris .contact-etranger {
    margin-top: 37px;
  }
  .ct-contact-gris {
    width: 95.07575757575758%;
    padding: 20px 4.666666666666667%;
    margin: 20px auto 0 auto;
    color: #7c7c7c;
    font: normal 14px 'FrutigerLTStd-Roman';
    background: #e9e9e9;
  }
  .ct-contact {
    width: 95.07575757575758%;
    padding: 20px 0;
    margin: 0 auto;
    color: #7c7c7c;
    font: normal 14px 'FrutigerLTStd-Roman';
  }
  .ct-contact {
    padding: 28px 0;
  }
  .ct-contact .centrer {
    text-align: center;
  }
  .ct-contact h3 {
    font-size: 18px;
  }
  .ct-contact.manuscrits {
    padding: 0;
  }
  .ct-contact.manuscrits p {
    font-size: 13px;
  }
  .bt-creez {
    width: 74.16666666666666%;
    max-width: none;
    margin: 25px auto 0 auto;
  }
  .bt-creez a {
    width: 100%;
  }
  .infos-legales-contact {
    color: #7c7c7c;
    font: normal 10px 'FrutigerLTStd-Roman';
    padding-top: 27px;
  }
  .ct-panier {
    width: 100%;
  }
  .page-node-7292 .myButton {
    width: 87.369748% !important;
  }
  .page-node-7292 .les-images {
    float: none;
    margin-left: auto;
    margin-right: auto;
  }
  .texte-foreign-gris {
    width: 88.19327731092437%;
  }
  .ct-qui img.logo {
    width: 100%;
  }
  .ct-qui a.bouton, .texte-foreign a.bouton {
    width: 100% !important;
  }
  .ct-qui .videos {
    width: 100%;
    margin-top: 20px;
  }
}
/* Surcharge MDR */

#navbar .header-container.searching .searchbar-result-container {
  opacity: 1 !important;
  -moz-opacity: 1 !important;
  -khtml-opacity: 1 !important;
  -ms-filter: alpha(opacity=100);
  filter: alpha(opacity=100);
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(opacity=100)";
}
.searchbar-result-container .views-row .views-field-field-livre-format .field-name-field-prix, .searchbar-result-container .views-row .views-field-field-livre-format .field-name-field-parution, .searchbar-result-container .views-row .views-field-field-livre-format .field-name-field-disponibilite, .searchbar-result-container .views-row .views-field-field-livre-format .field-name-field-isbn, .searchbar-result-container .views-row .views-field-field-livre-format .field-name-field-duration, .searchbar-result-container .views-row .views-field-field-livre-format .field-name-field-couverture .field-label, .searchbar-result-container .views-row .views-field-field-livre-format .field-name-field-format, .searchbar-result-container .views-row .views-field-field-livre-format .field-name-field-couv-pas-prise-en-compte {
  display: none;
}
.searchbar-result-container .views-row .views-field-field-livre-format img {
  height: 40px;
  width: auto;
}
.searchbar-result-container .views-row .views-field-field-livre-format, .searchbar-result-container .views-row .views-field-field-livre-format .field-collection-item-field-livre-format {
  width: 65px;
  float: left;
}
.searchbar-result-container .views-row .views-field-title, .searchbar-result-container .views-row .views-field-field-livre-collection, .searchbar-result-container .views-row .views-field-field-type {
  padding-top: 15px;
}
.searchbar-result-container .views-row .views-field-title {
  max-width: 600px;
}
.searchbar-result-container .views-row:hover {
  background-color: #ccc;
}
.searchbar-result-container .views-row {
  position: relative;
}
.searchbar-result-container .views-row .views-field-field-livre-format-1 {
  background: #fff none repeat scroll 0 0;
  border: 8px solid #ba3b66;
  border-radius: 5px;
  bottom: 70px;
  display: none;
  left: 30px;
  position: absolute;
  z-index: 100;
}
.searchbar-result-container .views-row .views-field-field-livre-format-1::after {
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  border-top: 12px solid #ba3b66;
  bottom: -20px;
  content: "";
  height: 0;
  left: 44%;
  position: absolute;
  width: 0;
}
.searchbar-result-container .views-row:hover .views-field-field-livre-format-1 {
  display: block;
  z-index: 2000;
}
.searchbar-result-container .views-row .views-field-field-livre-format-1 .field-name-field-prix, .searchbar-result-container .views-row .views-field-field-livre-format-1 .field-name-field-parution, .searchbar-result-container .views-row .views-field-field-livre-format-1 .field-name-field-disponibilite, .searchbar-result-container .views-row .views-field-field-livre-format-1 .field-name-field-isbn, .searchbar-result-container .views-row .views-field-field-livre-format-1 .field-name-field-duration, .searchbar-result-container .views-row .views-field-field-livre-format-1 .field-name-field-couverture .field-label, .searchbar-result-container .views-row .views-field-field-livre-format-1 .field-name-field-format, .searchbar-result-container .views-row .views-field-field-livre-format-1 .field-name-field-couv-pas-prise-en-compte {
  display: none;
}
.searchbar-result-container .views-row .views-field-field-livre-format-1 img {
  width: 120px;
  height: auto;
}
.view-recherche-home .views-row .views-field-field-livre-format .field-name-field-prix, .view-recherche-home .views-row .views-field-field-livre-format .field-name-field-parution, .view-recherche-home .views-row .views-field-field-livre-format .field-name-field-disponibilite, .view-recherche-home .views-row .views-field-field-livre-format .field-name-field-isbn, .view-recherche-home .views-row .views-field-field-livre-format .field-name-field-duration, .view-recherche-home .views-row .views-field-field-livre-format .field-name-field-couverture .field-label, .view-recherche-home .views-row .views-field-field-livre-format .field-name-field-format, .view-recherche-home .views-row .views-field-field-livre-format .field-name-field-couv-pas-prise-en-compte {
  display: none;
}
.view-recherche-home .views-row .views-field-field-livre-format img {
  height: 40px;
  width: auto;
}
.view-recherche-home .views-row .views-field-field-livre-format, .view-recherche-home .views-row .views-field-field-livre-format .field-collection-item-field-livre-format {
  width: 65px;
  float: left;
}
.view-recherche-home .views-row .views-field-title, .view-recherche-home .views-row .views-field-field-livre-collection, .view-recherche-home .views-row .views-field-field-type {
  padding-top: 13px;
}
.view-recherche-home .views-row .views-field-title {
  max-width: 600px;
}
.view-recherche-home .views-row:hover {
  background-color: rgba(204, 204, 204, 0.6);
}
.view-recherche-home .views-row {
  position: relative;
}
.view-recherche-home .views-row .views-field-field-livre-format-1 {
  background: #fff none repeat scroll 0 0;
  border: 8px solid #ba3b66;
  border-radius: 5px;
  bottom: 70px;
  display: none;
  left: 30px;
  position: absolute;
  z-index: 100;
}
.view-recherche-home .views-row .views-field-field-livre-format-1::after {
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  border-top: 12px solid #ba3b66;
  bottom: -20px;
  content: "";
  height: 0;
  left: 44%;
  position: absolute;
  width: 0;
}
.view-recherche-home .views-row:hover .views-field-field-livre-format-1 {
  display: block;
  z-index: 2000;
}
.view-recherche-home .views-row .views-field-field-livre-format-1 .field-name-field-prix, .view-recherche-home .views-row .views-field-field-livre-format-1 .field-name-field-parution, .view-recherche-home .views-row .views-field-field-livre-format-1 .field-name-field-disponibilite, .view-recherche-home .views-row .views-field-field-livre-format-1 .field-name-field-isbn, .view-recherche-home .views-row .views-field-field-livre-format-1 .field-name-field-duration, .view-recherche-home .views-row .views-field-field-livre-format-1 .field-name-field-couverture .field-label, .view-recherche-home .views-row .views-field-field-livre-format-1 .field-name-field-format, .view-recherche-home .views-row .views-field-field-livre-format-1 .field-name-field-couv-pas-prise-en-compte {
  display: none;
}
.view-recherche-home .views-row .views-field-field-livre-format-1 img {
  width: 120px;
  height: auto;
}
.parallax-section-0 .view-recherche-home.view-display-id-block .view-content .loader {
  background: url("../img/ajax-loader-video.gif") no-repeat 50% 50% transparent !important;
  width: 32px !important;
  height: 32px !important;
  margin: 50px 6px 0 0 !important;
  display: block;
}
#parallax-0.searching2:after {
  background: #fff url("../img/ajax-loader2.gif") no-repeat scroll 50% 30% !important;
}
#navbar .header-container.searching .searchbar-result-container.searching2 {
  background: #ebe7e7 url("../img/ajax-loader2.gif") no-repeat scroll 50% 30% !important;
}
.bonus-description .telecharger {
  background-color: white !important;
  color: #aaa !important;
  margin-top: 10px !important;
  padding-bottom: 10px !important;
}
#parallax-4 #block-views-bonus-home-block.col-sm-4 .views-row .field-content .bonus-description {
  bottom: 15px !important;
}
.page-recherche-videos .custom-page-header {
  display: none;
}
.page-recherche-videos .view-recherche-video.view-display-id-page .view-filters {
  margin-top: 10px !important;
}
.page-recherche-videos #views-exposed-form-recherche-home-block-3 {
  display: none;
}
#block-block-5 {
  background: #e9e9e9 none repeat scroll 0 0;
  padding: 20px;
  cursor: pointer;
  display: block;
  overflow: hidden;
  position: relative;
  vertical-align: middle;
  white-space: normal;
  width: 100%;
  z-index: 1;
}
.flex-direction-nav .flex-disabled {
  display: none !important;
}
.page-theme .pager {
  display: none !important;
}
.view-id-recherche_video {
  margin-bottom: 200px;
}
.roll_btn_detaille {
  display: none;
  background: #e9e9e9 none repeat scroll 0 0;
  border-radius: 5px;
  left: -56px;
  padding: 0 14px;
  position: absolute;
  top: -32px;
  width: 180px;
  background-color: #f4f1ee !important;
}
.roll_btn_detaille .texte-partage {
  color: black;
  font-family: "FrutigerLTStd-Light";
  font-size: 14px;
  letter-spacing: 0;
  line-height: 23px;
}
.roll_btn_detaille::after {
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  border-top: 12px solid #f4f1ee;
  bottom: -8px;
  content: "";
  height: 0;
  left: 39%;
  position: absolute;
  width: 0;
}
#btn_tab0:hover .roll_btn_detaille {
  display: block;
}
#btn_tab1:hover .roll_btn_detaille {
  display: block;
}
.bouton-affichage .nav-tabs li a, .bouton-affichage .nav-tabs li a:hover {
  text-indent: 0px !important;
}
.page-evenement .actu_desc ul, .page-evenement .actu_desc ol, .page-evenement .view-actualites ul, .page-evenement .view-actualites ol {
  margin-left: 30px;
  list-style: inherit !important;
  margin-bottom: 15px;
}
.page-catalogue-nouveautes .livre-container .nouvelle_edition, .page-catalogue-nouveautes-semaine .livre-container .nouvelle_edition, .page-catalogue .livre-container .nouvelle_edition {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 8;
}
.page-catalogue-nouveautes .livre-container:hover .nouvelle_edition, .page-catalogue .livre-container:hover .nouvelle_edition {
  display: none;
  -webkit-animation: fadeout 2s;
  /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadeout 2s;
  /* Firefox < 16 */
  -ms-animation: fadeout 2s;
  /* Internet Explorer */
  -o-animation: fadeout 2s;
  /* Opera < 12.1 */
  animation: fadeout 2s;
}
@keyframes fadeout {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Firefox < 16 */

@-moz-keyframes fadeout {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Safari, Chrome and Opera > 12.1 */

@-webkit-keyframes fadeout {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Internet Explorer */

@-ms-keyframes fadeout {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Opera < 12.1 */

@-o-keyframes fadeout {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.page-taxonomy #presentation .bloc-presentation-wrapper .bloc-centre {
  background: none;
  background-color: white !important;
  opacity: 0.9 !important;
  -moz-opacity: 0.9 !important;
  -khtml-opacity: 0.9 !important;
  -ms-filter: alpha(opacity=90);
  filter: alpha(opacity=90);
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(opacity=90)";
  padding: 5px;
}

.page-taxonomy-term .main-container.container #block-system-main .bloc-livres .block-title, .page-taxonomy-term .main-container.container #block-system-main .bloc-livres .titre-bloc {
  background: #337ab7 none repeat scroll 0 0 !important;
}
#parallax-0 {
  overflow: visible !important;
}
#parallax-0 .region-content {
  top: 35% !important;
}
.page-taxonomy-term .main-container.container #block-system-main .bloc-contact .bloc-centre-contact {
  height: auto;
}
.page-taxonomy-term .main-container.container #block-system-main .bloc-contact .bloc-centre-contact {
  height: auto !important;
  padding: 10px;
}
.page-taxonomy-term .main-container.container #block-system-main .bloc-contact .bloc-contact-inner {
  margin: auto !important;
}
.view-recherche-home .views-row .views-field-field-livre-auteurs, .searchbar-result-container .views-row .views-field-field-livre-auteurs {
  margin: 12px 0 0 20px;
  float: left;
  clear: none;
}
.view-recherche-home .views-row .views-field-field-livre-auteurs .field-content:before {
  content: "(";
  display: inline-block;
  float: left;
}
.view-recherche-home .views-row .views-field-field-livre-auteurs .field-content:after {
  content: ")";
  display: inline-block;
  float: left;
}
.view-recherche-home .views-row .views-field-field-livre-auteurs ul {
  margin-bottom: 2px;
  float: left;
}
/*}*/

.view-recherche-home .views-row .views-field-field-livre-auteurs .field-label, .searchbar-result-container .views-row .views-field-field-livre-auteurs .field-label {
  display: none;
}
.view-recherche-home .views-row .views-field-field-livre-auteurs .field-name-field-auteur-qualite, .searchbar-result-container .views-row .views-field-field-livre-auteurs .field-name-field-auteur-qualite {
  display: none;
}
.view-recherche-home .views-row .views-field-field-livre-auteurs ul, .searchbar-result-container .views-row .views-field-field-livre-auteurs ul {
  display: flex;
}
.view-recherche-home .views-row .views-field-field-livre-auteurs ul *, .searchbar-result-container .views-row .views-field-field-livre-auteurs ul * {
  float: left;
}
.view-recherche-home .views-row .views-field-field-livre-auteurs .field-content li::after, .searchbar-result-container .views-row .views-field-field-livre-auteurs .field-content li::after {
  content: '\00A0-\00A0';
}
.view-recherche-home .views-row .views-field-field-livre-auteurs .field-content li.last::after, .searchbar-result-container .views-row .views-field-field-livre-auteurs .field-content li.last::after {
  content: '';
}
.page-auteur-list #block-views-exp-auteur-list-page #edit-alphabet2-wrapper {
  display: none;
}
.bouton-affichage .show_list, .bouton-affichage .show_item {
  color: transparent !important;
}
#sliding-popup .popup-content #popup-text p {
  font-weight: normal !important;
}

.page-taxonomy-term .main-container.container #block-system-main .bloc-presentation .bloc-centre .logo-site {
  background: transparent url("/sites/all/themes/edl/img/logo-edl-collection.png") no-repeat scroll 0 0;
  display: block;
  height: 40px;
  margin: 0 auto 18px;
  padding: 0;
  width: 240px;
}
#edit-field-userid-paperus--2, #edit-field-userid-paperus--3, #edit-field-userid-paperus--4, #edit-field-userid-paperus--5, #edit-field-userid-paperus--6 {
  display: none;
}
.page-mon-compte .alert-danger, .page-mon-compte .alert-success {
  display: none;
}
/*.page-user-password .region-content {
  display: block;
}*/
/* Page Albums filmes */

.livre-container.catalogue_albums_filmes {
  background: #cfeaef none repeat scroll 0 0 !important;
}
.livre-container.catalogue_albums_filmes .livre-image-content img {
  box-shadow: none;
}
.node-type-livre .curr-livre-container .class-auteur-albums-filmes {
  color: silver;
  font-size: 18px;
}
.node-type-livre .curr-livre-container .class-auteur-albums-filmes a {
  display: inline;
  color: silver;
  font-size: 18px;
}
/* Tunnel d'achat */

.clear {
  clear: both;
}
.ct-contact-gris {
  width: 95.07575757575758%;
  padding: 20px 2.46212121212121%;
  margin: 0 auto;
  color: #7c7c7c;
  font: normal 15px 'FrutigerLTStd-Roman';
  background: #e9e9e9;
}
.ct-contact {
  width: 95.07575757575758%;
  padding: 20px 0;
  margin: 0 auto;
  color: #7c7c7c;
  font: normal 15px 'FrutigerLTStd-Roman';
}
.ct-contact-gris h2 {
  text-transform: uppercase;
  color: #7c7c7c;
  font: normal 15px 'FrutigerLTStd-Roman';
  margin-top: 0;
}
.ct-contact a, .ct-contact-gris a {
  color: #000;
  text-decoration: none;
  display: inline-block;
}
.ct-contact-gris h3, .ct-contact h3 {
  text-transform: uppercase;
  color: #7c7c7c;
  font: normal 24px 'FrutigerLTStd-Light';
  margin-top: 0;
  margin-bottom: 20px;
}
.ct-contact.manuscrits p {
  font-size: 14px;
}
.ct-contact.manuscrits span {
  font-family: 'FrutigerLTStd-Bold';
}
.bt-creez {
  width: 26.767676767676768%;
  max-width: 212px;
  margin-top: 20px;
}
.bt-creez a {
  width: 100%;
  text-align: center;
  text-transform: uppercase;
  background: #c00418;
  color: #fff;
  font: normal 16px 'FrutigerLTStd-Roman';
  line-height: 33px;
}
.infos-legales-contact {
  color: #7c7c7c;
  font: normal 12px 'FrutigerLTStd-Roman';
  padding-top: 60px;
}
/*.ct-panier,
.commerce_coupon {
  !*width: 43.40659340659341%;*!
  !*790px*!
  width: 790px;
  padding: 0;
  margin: 0 auto;
}
.commerce_coupon #edit-coupon {
  width: 100%;
}
.commerce_coupon .form-type-textfield {
  width: 30%;
  display: inline-block;
  margin-right: 10px;
}
.commerce_coupon #edit-coupon-add,
#commerce-checkout-form-checkout #edit-continue {
  background: #c00418 none repeat scroll 0 0 !important;
  border: medium none !important;
  border-radius: 0 !important;
  color: white;
  padding: 7px 10px;
  margin-right: 10px;
}
.chemin-panier .colonne:nth-child(odd) {
  padding: 0 3.64%;
}
.chemin-panier .colonne {
  float: left;
  padding-bottom: 32px;
}
.chemin-panier .fleche {
  visibility: hidden;
}
.un-ouvrage-panier, .un-ouvrage-panier-entete {
  width: 100%;
  letter-spacing: -0.3em;
}
.un-ouvrage-panier {
  padding: 15px 0 19px 0;
  border-bottom: 1px solid #a09d9d;
}
.un-ouvrage-panier .partie-1, .un-ouvrage-panier-entete .partie-1 {
  display: inline-block;
  vertical-align: middle;
  letter-spacing: normal;
  width: 50.63291139240506%;
}
.un-ouvrage-panier-entete .filet {
  border-top: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
}
.un-ouvrage-panier .partie-1 .ct-ss-parties, .un-ouvrage-panier .partie-2 .ct-ss-parties {
  letter-spacing: -0.3em;
}
.un-ouvrage-panier .partie-1 .ct-ss-parties .ss-partie-1 {
  display: inline-block;
  vertical-align: middle;
  letter-spacing: normal;
  width: 33.75%;
}
.un-ouvrage-panier .partie-1 .ct-ss-parties .ss-partie-2 {
  display: inline-block;
  vertical-align: middle;
  letter-spacing: normal;
  width: 66.25%;
}
.un-ouvrage-panier .partie-1 .ct-ss-parties .ss-partie-2 p:last-child {
  margin: 0;
}
.un-ouvrage-panier .partie-2, .un-ouvrage-panier-entete .partie-2 {
  !*float: left;*!
  display: inline-block;
  vertical-align: middle;
  letter-spacing: normal;
  !*width: 340px;*!
  width: 40.037974683544306%;
}
.un-ouvrage-panier .partie-3, .un-ouvrage-panier-entete .partie-3 {
  display: inline-block;
  vertical-align: middle;
  letter-spacing: normal;
  width: 9.329113924050633%;
  text-align: center;
}
.page-cart #edit-actions {
  display: none;
}
.texte-panier-entete {
  font: normal 14px 'FrutigerLTStd-Roman';
  color: #a09d9d;
  text-transform: uppercase;
  text-align: center;
  line-height: 33px;
}
.un-ouvrage-panier-entete .partie-2 .entete-prix, .ouvrage-panier-prix {
  float: left;
  width: 29.411764705882355%;
}
.un-ouvrage-panier-entete .partie-2 .entete-quantite, .ouvrage-panier-quantite {
  float: left;
  width: 29.411764705882355%;
}
.un-ouvrage-panier-entete .partie-2 .entete-total, .ouvrage-panier-total {
  float: left;
  width: 41.17647058823529%;
}
.un-ouvrage-panier .partie-1 img {
  max-width: 89.62962962962962%;
  border: solid 1px #010101;
}
.format-panier {
  color: #c00418;
  font: normal 13px 'FrutigerLTStd-Bold';
}
.titre-ouvrage-panier {
  color: #a09d9d;
  font: normal 14px 'FrutigerLTStd-Roman';
  text-transform: uppercase;
}
.auteur-panier {
  color: #a09d9d;
  font: normal 14px 'FrutigerLTStd-Roman';
}
.collection-panier {
  color: #000;
  font: normal 12px 'FrutigerLTStd-Roman';
}
.ouvrage-panier-prix, .ouvrage-panier-quantite, .ouvrage-panier-total {
  display: inline-block;
  vertical-align: middle;
  letter-spacing: normal;
  text-align: center;
}
.ouvrage-panier-prix {
  color: #6d6d6d;
  font: normal 17px 'FrutigerLTStd-Roman';
}
.ouvrage-panier-quantite {
  color: #6d6d6d;
  font: normal 14px 'FrutigerLTStd-Roman';
}
.ouvrage-panier-quantite a {
  display: inline-block;
}
.ouvrage-panier-quantite input {
  width: 25px;
  border: 1px solid #c5c5c5;
  text-align: center;
  vertical-align: top;
  font: normal 12px 'FrutigerLTStd-Roman';
  color: #545e6a;
}
.ouvrage-panier-total {
  color: #c00418;
  font: normal 17px 'FrutigerLTStd-Roman';
}
.bas-panier {
  width: 84.81012658227847%;
  margin: 28px auto;
}
.code-promo {
  float: left;
  width: 43.28358208955223%;
  color: #9c9c9c;
  font: normal 15px 'FrutigerLTStd-Roman';
}
.code-promo input {
  border: 1px solid #c5c5c5;
  border-radius: 5px;
  font: normal 12px 'FrutigerLTStd-Roman';
  color: #545e6a;
  display: inline-block;
  width: 34%;
  margin-left: 2%;
}
.prix-total {
  float: left;
  text-align: right;
  width: 44.77611940298509%;
  color: #a09d9d;
  font: normal 15px 'FrutigerLTStd-Bold';
}
.recap-panier .prix-total {
  float: left;
  text-align: right;
  width: 49.77611940298509%;
  color: #a09d9d;
  font: normal 15px 'FrutigerLTStd-Bold';
}
.recap-panier .code-promo {
  float: left;
  width: 38.28358208955223%;
  color: #9c9c9c;
  font: normal 15px 'FrutigerLTStd-Roman';
}
.prix-total-chiffres {
  float: left;
  text-align: right;
  width: 11.940298507462686%;
  color: #a09d9d;
  font: normal 15px 'FrutigerLTStd-Bold';
}
.prix-total p, .prix-total-chiffres p {
  color: #c00418;
  font: normal 18px 'FrutigerLTStd-Bold';
  text-transform: uppercase;
  margin-top: 15px;
}
.boutons a, .boutons button {
  display: inline-block;
  width: 32.333333%;
  font: normal 16px 'FrutigerLTStd-Roman';
  color: #fff;
  text-decoration: none;
  text-transform: uppercase;
  text-align: center;
  line-height: 33px;
}
.boutons a.continuer, .boutons button.continuer {
  background: #a09d9d;
  padding-bottom: 3px;
}
.boutons a.recalculer, .boutons button#edit-submit {
  background: #c7c5c5;
  margin: 0 0.9%;
}
.boutons a.valider, .boutons button#edit-checkout, .boutons a.valider, .boutons button#edit-checkout-no-idUserPaperus {
  background: #c00418;
}
.boutons button#edit-submit {
  background: #a09d9d none repeat scroll 0 0 !important;
  border: medium none !important;
  border-radius: 0 !important;
  padding: 0 !important;
  padding-bottom: 3px !important;
  padding-top: 0 !important;
}
.boutons button#edit-checkout {
  background: #c00418 none repeat scroll 0 0 !important;
  border: medium none !important;
  border-radius: 0 !important;
  padding: 0 !important;
  padding-bottom: 3px !important;
  padding-top: 0 !important;
}
.boutons button#edit-checkout-no-idUserPaperus {
  background: #c00418 none repeat scroll 0 0 !important;
  border: medium none !important;
  border-radius: 0 !important;
  padding: 0 !important;
  padding-bottom: 3px !important;
  padding-top: 0 !important;
}
.ct-panier h3 {
  text-transform: uppercase;
  color: #7c7c7c;
  font: normal 24px 'FrutigerLTStd-Light';
  margin-top: 0;
  margin-bottom: 20px;
  text-align: center;
}
.texte-panier {
  color: #7c7c7c;
  font: normal 16px 'FrutigerLTStd-Light';
  text-align: center;
  margin-top: 0;
  padding: 0 0 15px 0;
}
input.champ-panier-connexion {
  border: 1px solid #d3d1d1;
  border-radius: 5px;
  font: normal 18px 'FrutigerLTStd-Light';
  font-style: italic;
  color: #c7c5c5;
  display: block;
  width: 49.36708860759494%;
  margin: 9px auto;
  padding: 10px 9px;
}
.texte-checkbox {
  color: #8f8c8c;
  font: normal 14px 'FrutigerLTStd-Light';
  text-align: center;
  margin-top: 0;
  padding: 0 0 15px 0;
}
.texte-checkbox-adresse {
  width: 49.36708860759494%;
  margin: 0 auto;
  color: #8f8c8c;
  font: normal 14px 'FrutigerLTStd-Light';
  padding: 0 0 15px 0;
}
.texte-checkbox-adresse input:nth-child(2) {
  margin-left: 27px;
}
.ct-panier textarea {
  border: 1px solid #d3d1d1;
  border-radius: 5px;
  font: normal 18px 'FrutigerLTStd-Light';
  font-style: italic;
  color: #c7c5c5;
  display: block;
  width: 49.36708860759494%;
  margin: 9px auto;
  padding: 10px 9px;
}*/
a.bt-connexion {
  display: block;
  width: 212px;
  font: normal 16px 'FrutigerLTStd-Roman';
  color: #fff;
  text-decoration: none;
  text-transform: uppercase;
  text-align: center;
  line-height: 33px;
  background: #c00418;
  margin: 0 auto;
}
a.bt-valider {
  display: block;
  width: 212px;
  font: normal 16px 'FrutigerLTStd-Roman';
  color: #fff;
  text-decoration: none;
  text-transform: uppercase;
  text-align: center;
  line-height: 33px;
  background: #c00418;
  margin: 45px auto;
}
.texte-oubli {
  color: #8f8c8c;
  font: normal 13px 'FrutigerLTStd-Roman';
  text-align: center;
  margin-top: 0;
  padding: 28px 0 53px 0;
}
.texte-oubli a {
  text-decoration: underline;
  color: #8f8c8c;
  display: inline-block;
}
.creation-compte {
  width: 62.151898734177216%;
  margin: 0 auto;
  background: #e9e9e9;
  padding: 25px 0;
}
.cgv-panier {
  text-align: right;
  color: #000;
  font: normal 15px 'FrutigerLTStd-Light';
  padding-bottom: 20px;
}
.cgv-panier a {
  text-decoration: underline;
  display: inline-block;
  color: #000;
}
.cgv-panier input[type="checkbox"] {
  margin-right: 5px;
}
.autres-paiement {
  color: #414141;
  font: normal 15px 'FrutigerLTStd-Light';
  text-align: center;
  width: 31.582278481012654%;
  margin: 0 auto;
}
.autres-paiement a {
  background: url(images/fl-autres-paiements.gif) no-repeat left center;
  color: #c00418;
  padding-left: 33px;
}
.texte-conf-cmde {
  color: #7c7c7c;
  font: normal 14px 'FrutigerLTStd-Roman';
}
.popin-paiements {
  width: 720px;
  padding: 62px 54px 40px 54px;
}
.popin-paiements h3 {
  text-transform: uppercase;
  color: #7c7c7c;
  font: normal 24px 'FrutigerLTStd-Light';
  margin-top: 0;
  margin-bottom: 20px;
  text-align: center;
}
.texte-paiements {
  color: #7c7c7c;
  font: normal 16px 'FrutigerLTStd-Light';
}
/*@media (min-width: 480px) and (max-width: 1024px) {
  .ct-panier {
    width: 98.046875%;
    padding: 0;
    margin: 0 auto;
  }
  .chemin-panier .fleche {
    visibility: hidden;
    display: none;
  }
  .chemin-panier .colonne:nth-child(odd) {
    padding: 0 8.19%;
  }
  .autres-paiement {
    width: 25.582278%;
  }
}*/
/*@media (min-width: 480px) and (max-width: 1024px) and (orientation: portrait) {
  .chemin-panier .colonne:nth-child(odd) {
    padding: 0 2.5%;
  }
  .boutons a.recalculer {
    margin: 0 0.8%;
  }
  .recap-panier .prix-total {
    float: left;
    text-align: right;
    width: 51.77611940298509%;
    color: #a09d9d;
    font: normal 15px 'FrutigerLTStd-Bold';
  }
  .recap-panier .code-promo {
    float: left;
    width: 36.28358208955223%;
    color: #9c9c9c;
    font: normal 15px 'FrutigerLTStd-Roman';
  }
  .autres-paiement {
    width: 33.582278%;
  }
}*/
/*@media (max-width: 480px) {
  .ct-contact, .ct-contact-gris {
    font-size: 14px;
  }
  .ct-contact-gris .contact-etranger {
    margin-top: 37px;
  }
  .ct-contact-gris {
    padding: 20px 4.666666666666667%;
  }
  .ct-contact {
    padding: 28px 0;
  }
  .ct-contact .centrer {
    text-align: center;
  }
  .ct-contact h3 {
    font-size: 18px;
  }
  .ct-contact.manuscrits {
    padding: 0;
  }
  .ct-contact.manuscrits p {
    font-size: 13px;
  }
  .bt-creez {
    width: 74.16666666666666%;
    max-width: none;
    margin: 25px auto 0 auto;
  }
  .bt-creez a {
    width: 100%;
  }
  .infos-legales-contact {
    color: #7c7c7c;
    font: normal 10px 'FrutigerLTStd-Roman';
    padding-top: 27px;
  }
  .ct-panier {
    width: 100%;
  }
  .chemin-panier .fleche {
    float: left;
    visibility: visible;
    width: 25.71428571428571%;
    text-align: center;
    padding-top: 8px;
  }
  .chemin-panier .fleche img {
    width: 16px;
  }
  .chemin-panier .bouton {
    float: left;
    width: 48.214285714285715%;
    text-align: center;
  }
  .un-ouvrage-panier .partie-1, .un-ouvrage-panier-entete .partie-1 {
    float: none;
    width: 100%;
  }
  .un-ouvrage-panier .partie-1 {
    padding-bottom: 19px;
  }
  .un-ouvrage-panier .partie-2, .un-ouvrage-panier-entete .partie-2 {
    float: left;
    width: 93.82303839732889%;
  }
  .un-ouvrage-panier .partie-3, .un-ouvrage-panier-entete .partie-3 {
    float: left;
    !*width: 50px;*!
    width: 6.17696160267111%;
  }
  .un-ouvrage-panier .partie-3 img {
    margin-top: 13px;
  }
  .un-ouvrage-panier-entete .partie-2 .entete-prix, .ouvrage-panier-prix {
    float: left;
    width: 26.868327402135233%;
  }
  .un-ouvrage-panier-entete .partie-2 .entete-quantite, .ouvrage-panier-quantite {
    float: left;
    width: 38.9281138790035584%;
  }
  .un-ouvrage-panier-entete .partie-2 .entete-total, .ouvrage-panier-total {
    float: left;
    width: 33.985765124555165%;
  }
  .un-ouvrage-panier .partie-1 img {
    max-width: 95.74468085106383%;
  }
  .ouvrage-panier-total, .ouvrage-panier-prix, .ouvrage-panier-quantite {
    padding-top: 13px;
  }
  .ouvrage-panier-total, .ouvrage-panier-prix {
    font-size: 14px;
  }
  .bas-panier {
    width: 100%;
    margin: 28px auto;
  }
  .code-promo {
    float: none;
    width: 100%;
    font: normal 14px 'FrutigerLTStd-Roman';
  }
  .code-promo input {
    margin-left: 9%;
  }
  .prix-total {
    width: 62.5%;
    margin-top: 27px;
    font-size: 14px;
  }
  .recap-panier .prix-total {
    float: left;
    text-align: right;
    width: 51.77611940298509%;
    color: #a09d9d;
    font: normal 15px 'FrutigerLTStd-Bold';
  }
  .recap-panier .code-promo {
    float: left;
    width: 12.28358208955223%;
    color: #9c9c9c;
    font: normal 15px 'FrutigerLTStd-Roman';
  }
  .prix-total-chiffres {
    width: 35.5%;
    margin-top: 27px;
    font-size: 14px;
  }
  .prix-total p, .prix-total-chiffres p {
    font-size: 15px;
  }
  .boutons a {
    display: block;
    width: 45.71875%;
    font: normal 14px 'FrutigerLTStd-Roman';
    margin: 0 auto;
    padding: 5px 0;
  }
  .boutons a.recalculer {
    margin: 11px auto;
  }
  .ct-panier h3 {
    font: normal 18px 'FrutigerLTStd-Light';
    margin-bottom: 12px;
  }
  .texte-panier {
    font: normal 14px 'FrutigerLTStd-Light';
    padding: 0 0 5px 0;
  }
  input.champ-panier-connexion {
    border: 1px solid #d3d1d1;
    border-radius: 5px;
    font: normal 12px 'FrutigerLTStd-Light';
    font-style: italic;
    color: #c7c5c5;
    display: block;
    width: 100%;
    margin: 9px auto;
    padding: 10px 9px;
  }
  .ct-panier textarea {
    width: 100%;
    margin: 9px auto;
    padding: 10px 9px;
    font: normal 12px 'FrutigerLTStd-Light';
  }
  .creation-compte {
    width: 100%;
    padding: 15px 2%;
  }
  .texte-checkbox-adresse {
    width: 100%;
  }
  .cgv-panier {
    text-align: left;
  }
  .popin-paiements {
    width: 100%;
    padding: 62px 15px 40px 15px;
  }
}*/
/*.cart-empty-page {
  text-align: center;
}*/
/*cacher lettre aux librairies*/
.account_newsletter .form-item-newsletters-1055,
.account_newsletter .form-item-newsletters-2124 {
  display: none !important;
}
.page-mon-compte-connexion h3 {
  color: #7c7c7c;
  font: 24px "FrutigerLTStd-Light";
  margin-bottom: 20px;
  margin-top: 0;
  text-align: center;
  text-transform: uppercase;
}
/*.page-mon-compte-connexion #user-login input {
  border: 1px solid #d3d1d1;
  border-radius: 5px;
  color: #c7c5c5;
  display: block;
  font: italic 18px "FrutigerLTStd-Light";
  margin: 9px auto;
  padding: 7px 9px;
  width: 49.3671%;
}*/
/*.page-mon-compte-connexion #user-login button#edit-submit {
  background: #c00418 none repeat scroll 0 0 !important;
  border: medium none !important;
  border-radius: 0 !important;
  padding: 0 !important;
  padding-bottom: 3px !important;
  padding-top: 0 !important;
  font: 16px/33px "FrutigerLTStd-Roman";
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  width: 212px;
  margin-top: 20px;
  margin-bottom: 20px;
}*/
/*.page-mon-compte-connexion #user-login {
  text-align: center;
}*/
/*.page-mon-compte-connexion .oublie_mdp {
  text-align: center;
  margin: 20px auto 50px;
  color: #8f8c8c;
  font: 13px "FrutigerLTStd-Roman";
  margin-top: 0;
  padding: 28px 0 53px;
}*/
/*.page-mon-compte-connexion .oublie_mdp a {
  display: inline;
  color: #8f8c8c;
  text-decoration: underline;
}*/
/*.page-checkout- #commerce-checkout-form-checkout #edit-commerce-payment legend {
  display: none;
}
.page-checkout- #commerce-checkout-form-checkout #edit-commerce-payment .panel-body #edit-commerce-payment-payment-method input {
  display: none;
}
.page-checkout- #commerce-checkout-form-checkout #edit-commerce-payment {
  text-align: center;
}
.page-checkout- #commerce-checkout-form-checkout #payment-details {
  text-align: center;
}
.page-checkout- #commerce-checkout-form-checkout #payment-details #edit-commerce-payment-payment-details-commerce-paylinebymonext-contract {
  max-width: 100% !important;
}
.page-checkout- #commerce-checkout-form-checkout #payment-details .form-item-commerce-payment-payment-details-commerce-paylinebymonext-contract {
  float: none !important;
}
.page-checkout-complete #edit-checkout-completion-message .checkout-completion-message {
  text-align: center;
}
.page-checkout-complete #edit-checkout-completion-message .checkout-completion-message p a {
  display: inline;
}*/
/* Page mes commandes */

.page-mon-compte-mes-commandes .ligne_produit {
  overflow: auto;
  clear: both;
  padding: 0px 40px 20px;
  color: #8f8c8c;
  font-size: 15px;
}
.page-mon-compte-mes-commandes .ligne_produit .date {
  font-style: italic;
  color: #7c7c7c;
  border-top: 1px solid #7c7c7c;
  border-bottom: 1px solid #7c7c7c;
  padding: 7px 0;
  margin: 0px 0 15px;
  clear: both;
}
.page-mon-compte-mes-commandes .ligne_produit .ligne {
  clear: both;
}
.page-mon-compte-mes-commandes .ligne_produit .ligne .image img {
  width: 80%;
}
.page-mon-compte-mes-commandes .ligne_produit .ligne .infos .titre {
  color: black;
  font-size: 18px;
  border-bottom: 1px solid #7c7c7c;
  padding: 0 0 7px;
}
.page-mon-compte-mes-commandes .ligne_produit .ligne .infos .collection {
  font-size: 16px;
  border-bottom: 1px solid #7c7c7c;
  padding: 7px 0;
  margin-bottom: 12px;
}
.page-mon-compte-mes-commandes .ligne_produit .ligne .infos .collection .titre_collection {
  color: #00ced6;
  text-transform: uppercase;
  font-weight: bold;
}
.page-mon-compte-mes-commandes .ligne_produit .ligne .infos .description {
  color: black;
}
.page-mon-compte-mes-commandes .ligne_produit .voir_vod {
  margin-top: 20px;
  clear: both;
}
.page-mon-compte-mes-commandes #product_in_cart {
  margin-top: 15px;
}
.node-type-livre .play_VOD {
  position: absolute;
  text-align: center;
  width: 100%;
  top: 50%;
  display: none;
}
.node-type-livre .image-container.couverture-albums-filmes img {
  box-shadow: none !important;
}
.btn_redirection_compte {
  color: #fff;
  display: inline-block;
  font: 16px/33px "FrutigerLTStd-Roman";
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  width: 32.3333%;
  background: #c00418 none repeat scroll 0 0 !important;
  border: medium none !important;
  border-radius: 0 !important;
  padding: 0 0 3px !important;
}
.btn_redirection_compte a, .btn_redirection_compte a:hover {
  color: white !important;
}
.div_colorbox a {
  display: inline;
}
.div_colorbox a.close {
  display: none;
}
.alert-success {
  display: none !important;
}
.alert-warning {
  display: none !important;
}
.page-theme .region-content #block-system-main .filter-top-level {
  border-bottom: 1px solid #000;
  padding: 0 0 15px;
  margin: 0 0 15px;
  height: 3em;
}
.page-theme .region-content #block-system-main .filter-top-level a {
  float: left;
  margin: 0 28px 0 0;
  text-transform: uppercase;
  font-family: "FrutigerLTStd-Roman";
  font-size: 15px;
  line-height: 15px;
  letter-spacing: 0px;
  color: black;
  padding: 8px 10px;
  position: relative;
  border: 1px solid #fff;
}
.page-theme .region-content #block-system-main .filter-top-level a:hover {
  border: 1px solid #000;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  -o-border-radius: 5px;
}
.page-theme .region-content #block-system-main .filter-top-level .recherche-catalogue {
  float: right;
  margin: 3px 0 0 0;
}
.page-theme .region-content #block-system-main .low-filter-container {
  clear: both;
  width: 900px;
  margin: 10px 0 0 0;
}
.page-theme .region-content #block-system-main .low-filter-container a {
  float: left;
  margin: 0 25px 5px 0;
  color: #3774a9;
  padding: 8px 10px;
  font-family: "FrutigerLTStd-Roman";
  font-size: 15px;
  line-height: 15px;
  letter-spacing: 0px;
  border: 1px solid #fff;
}
.page-theme .region-content #block-system-main .low-filter-container a {
  background: #ececec;
  font-size: 13px !important;
  border-radius: 4px !important;
  padding: 4px 10px !important;
  margin: 0 10px 5px 0 !important;
}
.page-theme .region-content #block-system-main .low-filter-container a:hover {
  background: #062f45;
  color: #fff !important;
}
.page-theme .region-content #block-system-main .filter-top-level a#filter-age:after, .page-theme .region-content #block-system-main .filter-top-level a#filter-collection:after {
  right: -16px;
}
.page-theme .region-content #block-system-main .filter-top-level a.active, .page-theme .region-content #block-system-main .filter-top-level a:hover, .page-theme .region-content #block-system-main .filter-top-level a.active, .page-theme .region-content #block-system-main .filter-top-level a:hover {
  border: 1px solid #000;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  -o-border-radius: 5px;
}
.page-theme .region-content #block-system-main .filter-top-level .recherche-catalogue, .page-theme .region-content #block-system-main .filter-top-level .recherche-catalogue {
  float: right;
  margin: 3px 0 0 0;
}
.page-theme .region-content #block-system-main .filter-top-level .recherche-catalogue .form-type-textfield, .page-theme .region-content #block-system-main .filter-top-level .recherche-catalogue .form-type-textfield {
  float: left;
  margin: 0;
}
.page-theme .region-content #block-system-main .low-filter-container, .page-theme .region-content #block-system-main .low-filter-container {
  clear: both;
  width: 900px;
  margin: 10px 0 0 0;
}
@media (min-width: 768px) and (max-width: 1023px) {
  .page-theme .region-content #block-system-main .low-filter-container, .page-theme .region-content #block-system-main .low-filter-container {
    width: 665px;
  }
}
.page-theme .region-content #block-system-main .low-filter-container:after, .page-theme .region-content #block-system-main .low-filter-container:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}
.page-theme .region-content #block-system-main .low-filter-container a, .page-theme .region-content #block-system-main .low-filter-container a {
  float: left;
  margin: 0 25px 5px 0;
  color: #3774a9;
  padding: 8px 10px;
  font-family: "FrutigerLTStd-Roman";
  font-size: 15px;
  line-height: 15px;
  letter-spacing: 0px;
  border: 1px solid #fff;
}
.page-theme .region-content #block-system-main .low-filter-container a.active, .page-theme .region-content #block-system-main .low-filter-container a:hover, .page-theme .region-content #block-system-main .low-filter-container a.active, .page-theme .region-content #block-system-main .low-filter-container a:hover {
  border: 1px solid #3774a9;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  -o-border-radius: 5px;
}
@media (min-width: 768px) and (max-width: 1023px) {
  .page-theme .region-content #block-system-main .filter-top-level a#filter-age, .page-theme .region-content #block-system-main .filter-top-level a#filter-collection, .page-theme .region-content #block-system-main .filter-top-level a#filter-collection {
    margin: 0 18px 0 0;
  }
}
.page-theme .region-content #block-system-main .filter-top-level a#filter-age:after, .page-theme .region-content #block-system-main .filter-top-level a#filter-collection:after, .page-theme .region-content #block-system-main .filter-top-level a#filter-age:after, .page-taxonomy .page-theme .region-content #block-system-main .filter-top-level a#filter-collection:after {
  content: "";
  display: block;
  background: url("../img/dropdown-catalogue.png") no-repeat 0 0 transparent;
  width: 14px;
  height: 7px;
  position: absolute;
  top: 13px;
  right: -20px;
}
@media (min-width: 768px) and (max-width: 1023px) {
  .page-theme .region-content #block-system-main .filter-top-level a#filter-age:after, .page-theme .region-content #block-system-main .filter-top-level a#filter-collection:after, .page-theme .region-content #block-system-main .filter-top-level a#filter-age:after, .page-theme .region-content #block-system-main .filter-top-level a#filter-collection:after {
    right: -16px;
  }
}
#edl-thematique-search-form .form-type-textfield input {
  border-radius: 15px;
  border: 1px solid black;
  background: #fff;
  height: 27px;
  width: 205px;
  box-shadow: none;
  color: black;
  font-family: "FrutigerLTStd-Light";
  font-size: 14px;
  line-height: 27px;
  letter-spacing: 0px;
  margin: 0;
  padding: 0 12px;
}
@media (max-width: 767px) {
  #edl-thematique-search-form .form-type-textfield input {
    height: 50px;
    font-family: "FrutigerLTStd-Light";
    font-size: 26px;
    line-height: 50px;
    letter-spacing: 0px;
    width: 100%;
  }
}
#edl-thematique-search-form .form-submit {
  display: inline-block;
  width: 25px;
  height: 25px;
  background:transparent url("../img/loupe-noir.png") no-repeat 0 0 ;
  cursor: pointer;
  box-shadow: none;
  border: none;
  border-radius: 0;
  text-indent: -9999px;
  margin: 0 0 0 8px;
}
@media (max-width: 767px) {
  #edl-thematique-search-form .form-submit {
    width: 48px;
    height: 48px;
    background: url("../img/loupe-noir-mobile.png") no-repeat 0 0 transparent;
  }
}

#cboxOverlay {
  opacity: 0.7 !important;
}
.node-type-livre.catalogue-20 #block-views-autres-lecture-block .block-title, .node-type-livre.catalogue-20 .left-content .press-cat, .node-type-livre.catalogue-20 .event-overlay, .node-type-livre.catalogue-20 .press-overlay, .node-type-livre.catalogue-20 .mob-slides-livres .flex-direction-nav li a {
  background-color: #37beb7;
}
.page-catalogue .texte_intro {
  margin-bottom: 40px;
}
.page-catalogue .bouton-affichage {
  position: static !important;
}
.page-auteur-list .region-content #block-edl-auteur-edl-auteur-liste-alphabetique .alphabet {
  margin: 14px 11px 0 0 !important;
}
.page-auteur-list .region-content .fleche-auteur {
  margin: 14px 20px 0 -35px !important;
}
.actu-desc a {
  display: inline;
}
.accroche-part a, .accroche-full a {
  display: inline !important;
}
#biographie-auteur a, #biographie-complet a {
  display: inline !important;
}
/* Accordeon thematique */

.page-thematique-liste .cd-accordion-menu {
  width: 80%;
  margin: 0 auto;
}
.cd-accordion-menu ul {
  padding: 10px 0 20px;
}
.cd-accordion-menu ul ul {
  padding: 10px 0;
}
.cd-accordion-menu li.no-children {
  border-bottom: 1px solid #e5e5e5;
}
.cd-accordion-menu li.background_silver label {
  background-color: #e9e9e9;
}
.cd-accordion-menu li.background_silver li label {
  background-color: transparent !important;
}
.cd-accordion-menu input[type=checkbox] {
  /* hide native checkbox */
  position: absolute;
  opacity: 0;
}
.cd-accordion-menu label, .cd-accordion-menu a {
  position: relative;
  display: block;
  padding: 3px 0px 2px 24px;
  font-size: 1.8rem;
  font-weight: normal;
  color: #333;
}
.cd-accordion-menu li li label, .cd-accordion-menu li li a {
  font-weight: normal;
  color: #333;
}
.cd-accordion-menu li li label {
  padding-left: 64px;
}
.cd-accordion-menu li li li label, .cd-accordion-menu li li li a {
  font-weight: normal;
  color: #333;
}
.cd-accordion-menu li li li label {
  padding-left: 104px;
}
.cd-accordion-menu a {
  display: inline;
  padding: 0px;
}
.cd-accordion-menu ul {
  /* by default hide all sub menus */
  display: none;
}
.cd-accordion-menu input[type=checkbox]:checked + label + ul, .cd-accordion-menu input[type=checkbox]:checked + label:nth-of-type(n) + ul {
  /* use label:nth-of-type(n) to fix a bug on safari (<= 8.0.8) with multiple adjacent-sibling selectors*/
  /* show children when item is checked */
  display: block;
}
.page-thematique-liste .dossier_ferme {
  background-image: url("../img/dossier_ferme.png");
}
.page-thematique-liste .dossier_ouvert {
  background-image: url("../img/dossier_ouvert.png");
}
.page-thematique-liste .dossier_theme {
  background-image: url("../img/dossier_theme.png");
}
.page-thematique-liste .label_n1 {
  /*font-style : italic;*/
  font-size: 1.2em;
}
.page-thematique-liste .has-children .label_n2 {
  color: #6f6f6f;
  font-style: italic;
  font-size: 1.2em;
}
.page-thematique-liste .has-children .label_n2 a {
  font-size: 1.2em;
}
.page-thematique-liste .no-children .label_n2 {
  font-size: 1em;
}
.page-thematique-liste .no-children .label_n3 {
  font-size: 1em;
}
.page-thematique-liste .no-children .label_n3 a {
  font-size: 1.2em;
}
.page-thematique-liste .no-children .label_n2 a, .page-thematique-liste .label_n3 a {
  color: #3774a9 !important;
}
.page-thematique-liste .has-children .label_n3 {
  font-style: italic;
}
.texte_error {
  color: red;
  font-weight: bold;
  padding: 10px 20px;
}
.formulaire_add_evenement {
  padding: 40px;
}
.formulaire_add_evenement input[type=text] {
  width: 350px;
}
.formulaire_add_evenement textarea {
  width: 350px;
}
.formulaire_add_evenement input[type=text].datepicker {
  width: 150px;
}
/* BONUS VIDEO FICHE LIVRE*/

.node-type-livre #block-edl-livre-edl-livre-bonus-video .header-bonus {
  display: none;
  background: #a7003b;
  padding: 13px 0;
  cursor: pointer;
}
.node-type-livre #block-edl-livre-edl-livre-bonus-video .header-bonus.fermer-bonus .title-header {
  background: url("../img/acc-blan-big-mobile.png") no-repeat 95% center transparent;
}
.node-type-livre #block-edl-livre-edl-livre-bonus-video .header-bonus .title-header {
  padding: 0 0 0 20px;
  color: #fff;
  font-family: "FrutigerLTStd-Roman";
  font-size: 23px;
  line-height: 23px;
  letter-spacing: 0;
  background: url("../img/acc-blan-big-mobile-up.png") no-repeat 95% center transparent;
}
@media (max-width: 767px) {
  .node-type-livre #block-edl-livre-edl-livre-bonus-video .header-bonus {
    display: block;
  }
}
.node-type-livre #block-edl-livre-edl-livre-bonus-video .media-auteur, .node-type-livre #block-edl-livre-edl-livre-bonus-video .bonus-auteur {
  border-bottom: 1px solid silver;
  margin: 0 0 10px;
  padding: 0 0 10px;
}
.node-type-livre.catalogue-1 #block-edl-livre-edl-livre-bonus-video .media-auteur, .node-type-livre.catalogue-1 #block-edl-livre-edl-livre-bonus-video .bonus-auteur {
  border-bottom: 1px solid #febc1e;
}
.node-type-livre.catalogue-2 #block-edl-livre-edl-livre-bonus-video .media-auteur, .node-type-livre.catalogue-2 #block-edl-livre-edl-livre-bonus-video .bonus-auteur {
  border-bottom: 1px solid #8ab5dd;
}
.node-type-livre.catalogue-3 #block-edl-livre-edl-livre-bonus-video .media-auteur, .node-type-livre.catalogue-3 #block-edl-livre-edl-livre-bonus-video .bonus-auteur {
  border-bottom: 1px solid #ef8427;
}
.node-type-livre.catalogue-4 #block-edl-livre-edl-livre-bonus-video .media-auteur, .node-type-livre.catalogue-4 #block-edl-livre-edl-livre-bonus-video .bonus-auteur {
  border-bottom: 1px solid #39b6b8;
}
.node-type-livre.catalogue-5 #block-edl-livre-edl-livre-bonus-video .media-auteur, .node-type-livre.catalogue-5 #block-edl-livre-edl-livre-bonus-video .bonus-auteur {
  border-bottom: 1px solid #3774a9;
}
.node-type-livre.catalogue-6 #block-edl-livre-edl-livre-bonus-video .media-auteur, .node-type-livre.catalogue-6 #block-edl-livre-edl-livre-bonus-video .bonus-auteur {
  border-bottom: 1px solid #97a719;
}
.node-type-livre.catalogue-7 #block-edl-livre-edl-livre-bonus-video .media-auteur, .node-type-livre.catalogue-7 #block-edl-livre-edl-livre-bonus-video .bonus-auteur {
  border-bottom: 1px solid #d4021d;
}
.node-type-livre.catalogue-8 #block-edl-livre-edl-livre-bonus-video .media-auteur, .node-type-livre.catalogue-8 #block-edl-livre-edl-livre-bonus-video .bonus-auteur {
  border-bottom: 1px solid #8a0b32;
}
.node-type-livre.catalogue-9 #block-edl-livre-edl-livre-bonus-video .media-auteur, .node-type-livre.catalogue-9 #block-edl-livre-edl-livre-bonus-video .bonus-auteur {
  border-bottom: 1px solid #005b78;
}
@media (max-width: 767px) {
  .node-type-livre #block-edl-livre-edl-livre-bonus-video .media-auteur, .node-type-livre #block-edl-livre-edl-livre-bonus-video .bonus-auteur {
    border: none;
    padding: 0;
    position: relative;
  }
}
.node-type-livre #block-edl-livre-edl-livre-bonus-video .titre-video, .node-type-livre #block-edl-livre-edl-livre-bonus-video .lien-download a {
  margin: 8px 0 0 0;
  font-family: "FrutigerLTStd-Light";
  font-size: 16px;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: center;
}
.node-type-livre.catalogue-1 #block-edl-livre-edl-livre-bonus-video .titre-video, .node-type-livre.catalogue-1 #block-edl-livre-edl-livre-bonus-video .lien-download a {
  bcolor: #febc1e;
}
.node-type-livre.catalogue-2 #block-edl-livre-edl-livre-bonus-video .titre-video, .node-type-livre.catalogue-2 #block-edl-livre-edl-livre-bonus-video .lien-download a {
  color: #8ab5dd;
}
.node-type-livre.catalogue-3 #block-edl-livre-edl-livre-bonus-video .titre-video, .node-type-livre.catalogue-3 #block-edl-livre-edl-livre-bonus-video .lien-download a {
  color: #ef8427;
}
.node-type-livre.catalogue-4 #block-edl-livre-edl-livre-bonus-video .titre-video, .node-type-livre.catalogue-4 #block-edl-livre-edl-livre-bonus-video .lien-download a {
  color: #39b6b8;
}
.node-type-livre.catalogue-5 #block-edl-livre-edl-livre-bonus-video .titre-video, .node-type-livre.catalogue-5 #block-edl-livre-edl-livre-bonus-video .lien-download a {
  color: #3774a9;
}
.node-type-livre.catalogue-6 #block-edl-livre-edl-livre-bonus-video .titre-video, .node-type-livre.catalogue-6 #block-edl-livre-edl-livre-bonus-video .lien-download a {
  color: #97a719;
}
.node-type-livre.catalogue-7 #block-edl-livre-edl-livre-bonus-video .titre-video, .node-type-livre.catalogue-7 #block-edl-livre-edl-livre-bonus-video .lien-download a {
  color: #d4021d;
}
.node-type-livre.catalogue-8 #block-edl-livre-edl-livre-bonus-video .titre-video, .node-type-livre.catalogue-8 #block-edl-livre-edl-livre-bonus-video .lien-download a {
  color: #8a0b32;
}
.node-type-livre.catalogue-9 #block-edl-livre-edl-livre-bonus-video .titre-video, .node-type-livre.catalogue-8 #block-edl-livre-edl-livre-bonus-video .lien-download a {
  color: #005b78;
}
@media (max-width: 767px) {
  .node-type-livre #block-edl-livre-edl-livre-bonus-video .titre-video, .node-type-livre #block-edl-livre-edl-livre-bonus-video .lien-download a {
    position: absolute;
    bottom: 10px;
    left: 10px;
    font-family: "FrutigerLTStd-Light";
    font-size: 28px;
    line-height: 28px;
    letter-spacing: 0px;
    color: #fff;
  }
}
@media (max-width: 479px) {
  .node-type-livre #block-edl-livre-edl-livre-bonus-video .titre-video, .node-type-livre #block-edl-livre-edl-livre-bonus-video .lien-download a {
    font-family: "FrutigerLTStd-Light";
    font-size: 24px;
    line-height: 24px;
    letter-spacing: 0px;
  }
}
@media (max-width: 360px) {
  .node-type-livre #block-edl-livre-edl-livre-bonus-video .titre-video, .node-type-livre #block-edl-livre-edl-livre-bonus-video .lien-download a {
    font-family: "FrutigerLTStd-Light";
    font-size: 20px;
    line-height: 20px;
    letter-spacing: 0px;
    bottom: 5px;
  }
}
.node-type-livre #block-edl-livre-edl-livre-bonus-video .auteur-video .thumb-video, .node-type-livre #block-edl-livre-edl-livre-bonus-video .auteur-video .thumb-video-upload, .node-type-livre #block-edl-livre-edl-livre-bonus-video .auteur-video .image-wrapper, .node-type-livre #block-edl-livre-edl-livre-bonus-video .auteur-image .thumb-video, .node-type-livre #block-edl-livre-edl-livre-bonus-video .auteur-image .thumb-video-upload, .node-type-livre #block-edl-livre-edl-livre-bonus-video .auteur-image .image-wrapper {
  position: relative;
}
.node-type-livre #block-edl-livre-edl-livre-bonus-video .auteur-video .thumb-video .mobile, .node-type-livre #block-edl-livre-edl-livre-bonus-video .auteur-video .thumb-video .img-mobile, .node-type-livre #block-edl-livre-edl-livre-bonus-video .auteur-video .thumb-video-upload .mobile, .node-type-livre #block-edl-livre-edl-livre-bonus-video .auteur-video .thumb-video-upload .img-mobile, .node-type-livre #block-edl-livre-edl-livre-bonus-video .auteur-video .image-wrapper .mobile, .node-type-livre #block-edl-livre-edl-livre-bonus-video .auteur-video .image-wrapper .img-mobile, .node-type-livre #block-edl-livre-edl-livre-bonus-video .auteur-image .thumb-video .mobile, .node-type-livre #block-edl-livre-edl-livre-bonus-video .auteur-image .thumb-video .img-mobile, .node-type-livre #block-edl-livre-edl-livre-bonus-video .auteur-image .thumb-video-upload .mobile, .node-type-livre #block-edl-livre-edl-livre-bonus-video .auteur-image .thumb-video-upload .img-mobile, .node-type-livre #block-edl-livre-edl-livre-bonus-video .auteur-image .image-wrapper .mobile, .node-type-livre #block-edl-livre-edl-livre-bonus-video .auteur-image .image-wrapper .img-mobile {
  display: none;
}
@media (max-width: 767px) {
  .node-type-livre #block-edl-livre-edl-livre-bonus-video .auteur-video .thumb-video .mobile, .node-type-livre #block-edl-livre-edl-livre-bonus-video .auteur-video .thumb-video .img-mobile, .node-type-livre #block-edl-livre-edl-livre-bonus-video .auteur-video .thumb-video-upload .mobile, .node-type-livre #block-edl-livre-edl-livre-bonus-video .auteur-video .thumb-video-upload .img-mobile, .node-type-livre #block-edl-livre-edl-livre-bonus-video .auteur-video .image-wrapper .mobile, .node-type-livre #block-edl-livre-edl-livre-bonus-video .auteur-video .image-wrapper .img-mobile, .node-type-livre #block-edl-livre-edl-livre-bonus-video .auteur-image .thumb-video .mobile, .node-type-livre #block-edl-livre-edl-livre-bonus-video .auteur-image .thumb-video .img-mobile, .node-type-livre #block-edl-livre-edl-livre-bonus-video .auteur-image .thumb-video-upload .mobile, .node-type-livre #block-edl-livre-edl-livre-bonus-video .auteur-image .thumb-video-upload .img-mobile, .node-type-livre #block-edl-livre-edl-livre-bonus-video .auteur-image .image-wrapper .mobile, .node-type-livre #block-edl-livre-edl-livre-bonus-video .auteur-image .image-wrapper .img-mobile {
    display: block;
    width: 100%;
    height: auto;
  }
}
.node-type-livre #block-edl-livre-edl-livre-bonus-video .auteur-video .thumb-video span, .node-type-livre #block-edl-livre-edl-livre-bonus-video .auteur-video .thumb-video-upload span, .node-type-livre #block-edl-livre-edl-livre-bonus-video .auteur-video .image-wrapper span, .node-type-livre #block-edl-livre-edl-livre-bonus-video .auteur-image .thumb-video span, .node-type-livre #block-edl-livre-edl-livre-bonus-video .auteur-image .thumb-video-upload span, .node-type-livre #block-edl-livre-edl-livre-bonus-video .auteur-image .image-wrapper span {
  transition: all 0.3s ease 0s;
  display: block;
  position: absolute;
}
.node-type-livre #block-edl-livre-edl-livre-bonus-video .auteur-video .thumb-video .overlay-video-auteur, .node-type-livre #block-edl-livre-edl-livre-bonus-video .auteur-video .thumb-video .overlay-image-auteur, .node-type-livre #block-edl-livre-edl-livre-bonus-video .auteur-video .thumb-video-upload .overlay-video-auteur, .node-type-livre #block-edl-livre-edl-livre-bonus-video .auteur-video .thumb-video-upload .overlay-image-auteur, .node-type-livre #block-edl-livre-edl-livre-bonus-video .auteur-video .image-wrapper .overlay-video-auteur, .node-type-livre #block-edl-livre-edl-livre-bonus-video .auteur-video .image-wrapper .overlay-image-auteur, .node-type-livre #block-edl-livre-edl-livre-bonus-video .auteur-image .thumb-video .overlay-video-auteur, .node-type-livre #block-edl-livre-edl-livre-bonus-video .auteur-image .thumb-video .overlay-image-auteur, .node-type-livre #block-edl-livre-edl-livre-bonus-video .auteur-image .thumb-video-upload .overlay-video-auteur, .node-type-livre #block-edl-livre-edl-livre-bonus-video .auteur-image .thumb-video-upload .overlay-image-auteur, .node-type-livre #block-edl-livre-edl-livre-bonus-video .auteur-image .image-wrapper .overlay-video-auteur, .node-type-livre #block-edl-livre-edl-livre-bonus-video .auteur-image .image-wrapper .overlay-image-auteur {
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: url("../img/opacity-video.png") repeat 0 0 transparent;
  opacity: 1 !important;
}
.node-type-livre #block-edl-livre-edl-livre-bonus-video .auteur-video .thumb-video .picto-video-auteur, .node-type-livre #block-edl-livre-edl-livre-bonus-video .auteur-video .thumb-video-upload .picto-video-auteur, .node-type-livre #block-edl-livre-edl-livre-bonus-video .auteur-video .image-wrapper .picto-video-auteur, .node-type-livre #block-edl-livre-edl-livre-bonus-video .auteur-image .thumb-video .picto-video-auteur, .node-type-livre #block-edl-livre-edl-livre-bonus-video .auteur-image .thumb-video-upload .picto-video-auteur, .node-type-livre #block-edl-livre-edl-livre-bonus-video .auteur-image .image-wrapper .picto-video-auteur {
  background: url("../img/video-icon-acteur.png") no-repeat 0 0 transparent;
  height: 42px;
  left: 42%;
  top: 28%;
  width: 42px;
  opacity: 0.75 !important;
}
@media (max-width: 767px) {
  .node-type-livre #block-edl-livre-edl-livre-bonus-video .auteur-video .thumb-video .picto-video-auteur, .node-type-livre #block-edl-livre-edl-livre-bonus-video .auteur-video .thumb-video-upload .picto-video-auteur, .node-type-livre #block-edl-livre-edl-livre-bonus-video .auteur-video .image-wrapper .picto-video-auteur, .node-type-livre #block-edl-livre-edl-livre-bonus-video .auteur-image .thumb-video .picto-video-auteur, .node-type-livre #block-edl-livre-edl-livre-bonus-video .auteur-image .thumb-video-upload .picto-video-auteur, .node-type-livre #block-edl-livre-edl-livre-bonus-video .auteur-image .image-wrapper .picto-video-auteur {
    left: 45%;
    top: 33%;
  }
}
@media (max-width: 360px) {
  .node-type-livre #block-edl-livre-edl-livre-bonus-video .auteur-video .thumb-video .picto-video-auteur, .node-type-livre #block-edl-livre-edl-livre-bonus-video .auteur-video .thumb-video-upload .picto-video-auteur, .node-type-livre #block-edl-livre-edl-livre-bonus-video .auteur-video .image-wrapper .picto-video-auteur, .node-type-livre #block-edl-livre-edl-livre-bonus-video .auteur-image .thumb-video .picto-video-auteur, .node-type-livre #block-edl-livre-edl-livre-bonus-video .auteur-image .thumb-video-upload .picto-video-auteur, .node-type-livre #block-edl-livre-edl-livre-bonus-video .auteur-image .image-wrapper .picto-video-auteur {
    left: 41%;
    top: 22%;
  }
}
.node-type-livre #block-edl-livre-edl-livre-bonus-video .auteur-video .thumb-video span, .node-type-livre #block-edl-livre-edl-livre-bonus-video .auteur-video .thumb-video-upload span, .node-type-livre #block-edl-livre-edl-livre-bonus-video .auteur-video .image-wrapper span, .node-type-livre #block-edl-livre-edl-livre-bonus-video .auteur-image .thumb-video span, .node-type-livre #block-edl-livre-edl-livre-bonus-video .auteur-image .thumb-video-upload span, .node-type-livre #block-edl-livre-edl-livre-bonus-video .auteur-image .image-wrapper span {
  background: url("../img/video-icon-acteur.png") no-repeat 0 0 transparent;
  height: 42px;
  left: 42%;
  top: 28%;
  width: 42px;
  opacity: 0.75 !important;
}
.node-type-livre #block-edl-livre-edl-livre-bonus-video .video-player {
  text-align: center;
}
@media (max-width: 767px) {
  #block-edl-main-edl-main-video h2.block-title {
    padding: 0px 30px 5px 5px
  }
  #block-edl-main-edl-main-video .video-infos {
    bottom: 0;
    position: absolute;
    padding: 10px;
    background-color: rgba(255, 255, 255, 0.6);
    width: 100%;
    height: 100%;
  }
  #block-edl-main-edl-main-video .video-infos .video-link {
    height: 100%;
  }
  #block-edl-main-edl-main-video .video-infos .video-link .video-title {
    bottom: 20px;
    position: absolute;
    font-weight: bold;
  }
  #block-edl-main-edl-main-video .video-infos .video-link .video-desc {
    position: absolute;
    bottom: 0;
  }
  .view-bonus-home .field-content img {
    height: 185px !important;
  }
  .view-bonus-home .bonus-description {
    left: 0px !important;
    height: 100% !important;
    top: 0px;
  }
  .view-bonus-home .bonus-description a {
    height: 100% !important;
  }
  .view-bonus-home .bonus-description a .bonus-picto {
    top: 30%;
    position: absolute;
    left: 40%;
  }
  .view-bonus-home .bonus-description .telechager {
    position: absolute !important;
    bottom: 0 !important;
    left: 0 !important;
    color: silver !important;
    z-index: 1000 !important;
    display: block !important;
    text-align: center !important;
    width: 100% !important;
    background-color: rgba(255, 255, 255, 0.6) !important;
    height: auto !important;
  }
  .page-catalogue .livre-container .livre-image-content img {
    max-height: 230px;
  }
  .page-catalogue .auteur-detail-wrapper .image-livre img {
    max-height: 230px;
  }
  .page-evenement-actualites .view-actualites .actu-container .actu-left {
    float: none;
  }
  .page-evenement-actualites .view-actualites .actu-container .actu-right {
    float: none;
    width: 100%;
  }
}
/**
	* Modifications Mathieu ROMEUF
*/

/** Fiche Auteur **/

.node-type-auteur .main-container .col-sm-9 #block-system-main .content-lower .email-auteur {
  color: #a09d9d;
  cursor: pointer;
  float: left;
  font-family: "FrutigerLTStd-Roman";
  font-size: 14px;
  letter-spacing: 0;
  line-height: 14px;
  margin: 0 30px 0 0;
  padding: 8px 0 0;
}
.node-type-auteur .main-container .col-sm-9 #block-system-main .content-lower .email-auteur a {
  color: #a09d9d;
}
.node-type-auteur .main-container .col-sm-9 #block-system-main .content-lower .email-auteur a:hover {
  color: #a7003b;
}
.page-catalogue-albums .livre-container .nouvelle_edition, .page-catalogue-romans .livre-container .nouvelle_edition {
  bottom: 0;
  position: absolute;
  right: 0;
  z-index: 8;
}
.front #block-system-main {
  display: none;
}
.view-empty a {
  display: inline !important;
}
.node-type-livre #parallax-3 {
  display: block !important;
}
#parallax-0 #block-system-main {
  display: none;
}

#block-edl-bonus-ws-edl-bonus-livre .slider-bonus-livres .flex-viewport {
  height: auto !important;
}
.node-type-livre #parallax-3 .region-parallax-3 .view-bonus-fiche-livre .view-content .flex-viewport li .views-row .left-content {
  border: none !important;
  text-align: right;
}
.node-type-livre #parallax-3 .region-parallax-3 .view-bonus-fiche-livre .view-content .flex-viewport li .views-row .left-content img {
  height: 100% !important;
  width: auto !important;
}
.view-edl-flag-bookmarks-tab .favorisBlock {
  display: flex;
  flex-flow: row wrap;
}
.view-edl-flag-bookmarks-tab .favorisBlock .favorisInfo {
  display: flex;
  flex-direction: column;
}
.view-edl-flag-bookmarks-tab .favorisBlock .favorisAuteurs,
.view-edl-flag-bookmarks-tab .favorisBlock .favorisResume {
  display: inline-block;
  max-width: 800px;
  padding: 10px;
}
.view-edl-flag-bookmarks-tab .favorisAuteurs .field-name-field-auteur .field-label,
.view-edl-flag-bookmarks-tab .favorisAuteurs .field-name-field-auteur-qualite .field-label {
  display: none;
}
.view-edl-flag-bookmarks-tab .favorisAuteurs .field-name-field-auteur,
.view-edl-flag-bookmarks-tab .favorisAuteurs .field-name-field-auteur-qualite,
.view-edl-flag-bookmarks-tab .favorisAuteurs .content {
  display: inline-block;
}
