@font-face {
	font-family: 'frutigerregular';
	src: url('fonts/frutiger.eot');
	src: url('fonts/frutiger.eot?#iefix') format('embedded-opentype'),
		url('fonts/frutiger.woff2') format('woff2'),
		url('fonts/frutiger.woff') format('woff'),
		url('fonts/frutiger.ttf') format('truetype'),
		url('fonts/frutiger.svg#frutigerregular') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'FrutigerLTStd-Bold';
	src: url('fonts/FrutigerLTStd-Bold_gdi.eot');
	src: url('fonts/FrutigerLTStd-Bold_gdi.eot?#iefix') format('embedded-opentype'),
		url('fonts/FrutigerLTStd-Bold_gdi.woff') format('woff'),
		url('fonts/FrutigerLTStd-Bold_gdi.ttf') format('truetype'),
		url('fonts/FrutigerLTStd-Bold_gdi.otf') format('opentype'),
		url('fonts/FrutigerLTStd-Bold_gdi.svg#FrutigerLTStd-Bold') format('svg');
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	unicode-range: U+0020-25CA;
}

@font-face {
	font-family: 'FrutigerLTStd-Light';
	src: url('fonts/FrutigerLTStd-Light_gdi.eot');
	src: url('fonts/FrutigerLTStd-Light_gdi.eot?#iefix') format('embedded-opentype'),
		url('fonts/FrutigerLTStd-Light_gdi.woff') format('woff'),
		url('fonts/FrutigerLTStd-Light_gdi.ttf') format('truetype'),
		url('fonts/FrutigerLTStd-Light_gdi.otf') format('opentype'),
		url('fonts/FrutigerLTStd-Light_gdi.svg#FrutigerLTStd-Light') format('svg');
	font-weight: 300;
	font-style: normal;
	font-stretch: normal;
	unicode-range: U+0020-25CA;
}

@font-face {
	font-family: 'FrutigerLTStd-Roman';
	src: url('fonts/FrutigerLTStd-Roman_gdi.eot');
	src: url('fonts/FrutigerLTStd-Roman_gdi.eot?#iefix') format('embedded-opentype'),
		url('fonts/FrutigerLTStd-Roman_gdi.woff') format('woff'),
		url('fonts/FrutigerLTStd-Roman_gdi.ttf') format('truetype'),
		url('fonts/FrutigerLTStd-Roman_gdi.otf') format('opentype'),
		url('fonts/FrutigerLTStd-Roman_gdi.svg#FrutigerLTStd-Roman') format('svg');
	font-weight: 400;
	font-style: normal;
	font-stretch: normal;
	unicode-range: U+0020-25CA;
}

@font-face {
	font-family: 'FrutigerCE-Italic';
	src: url('fonts/FrutigerCE-Italic_gdi.eot');
	src: url('fonts/FrutigerCE-Italic_gdi.eot?#iefix') format('embedded-opentype'),
		url('fonts/FrutigerCE-Italic_gdi.woff') format('woff'),
		url('fonts/FrutigerCE-Italic_gdi.ttf') format('truetype'),
		url('fonts/FrutigerCE-Italic_gdi.otf') format('opentype'),
		url('fonts/FrutigerCE-Italic_gdi.svg#FrutigerCE-Italic') format('svg');
	font-weight: 400;
	font-style: italic;
	font-stretch: normal;
	unicode-range: U+0020-25CA;
}

@font-face {
	font-family: 'FrutigerCE-LightItalic';
	src: url('fonts/FrutigerCE-LightItalic_gdi.eot');
	src: url('fonts/FrutigerCE-LightItalic_gdi.eot?#iefix') format('embedded-opentype'),
		url('fonts/FrutigerCE-LightItalic_gdi.woff') format('woff'),
		url('fonts/FrutigerCE-LightItalic_gdi.ttf') format('truetype'),
		url('fonts/FrutigerCE-LightItalic_gdi.otf') format('opentype'),
		url('fonts/FrutigerCE-LightItalic_gdi.svg#FrutigerCE-LightItalic') format('svg');
	font-weight: 300;
	font-style: italic;
	font-stretch: normal;
	unicode-range: U+0020-25CA;
}

@font-face {
	font-family: 'Raleway-Medium';
	src: url('fonts/Raleway-Medium_gdi.eot');
	src: url('fonts/Raleway-Medium_gdi.eot?#iefix') format('embedded-opentype'),
		url('fonts/Raleway-Medium_gdi.woff') format('woff'),
		url('fonts/Raleway-Medium_gdi.ttf') format('truetype'),
		url('fonts/Raleway-Medium_gdi.otf') format('opentype'),
		url('fonts/Raleway-Medium_gdi.svg#Raleway-Medium') format('svg');
	font-weight: 500;
	font-style: normal;
	font-stretch: normal;
	unicode-range: U+0020-2212;
}

@font-face {
	font-family: 'Titan-One';
	src: url('fonts/TitanOne-Regular.eot');
	src: url('fonts/TitanOne-Regular.woff') format('woff'),
		url('fonts/TitanOne-Regular.woff2') format('woff2'),
		url('fonts/TitanOne-Regular.ttf') format('truetype'),
		url('fonts/TitanOne-Regular.otf') format('opentype');
	font-weight: 400;
	font-style: normal;
	font-stretch: normal;
	unicode-range: U+0020-2212;
}