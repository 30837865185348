////*
// Layout
// Header
// Filters
// search
// Content
///*/

// Layout
.page-catalogue, .page-boutique {
  .main-container {
    margin-bottom: ($grid-gutter-width*2);
  }
  .roll_btn_detaille { // #Todo utiliser Bootsrap Tooltips
    left: -70px;
    top: -55px;
  }
}
//
// header
//
.page-catalogue, .page-boutique {
  .breadcrumb {
    padding: 20px;
    @include container-style();
  }
  .page-header { // Fix class scss files & second.css
    border: none;
  }
}
.shop-header {
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 140px;
  img {
    width: 100%;
    @media (min-width: $screen-sm-min) {
      margin-bottom: 25px;
    }
  }
  @media (min-width: $screen-sm-min) {
    min-height: 240px;
    background-size: 100% 100%;
  }
  /*@media (min-width: $screen-md) {
    height: 540px;
  }*/
}
.shop-header-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 5px 10px;
  background-color: rgba(255, 255, 255, .8);
  @media (min-width: $screen-sm-min) {
    padding: 10px 15px;
  }
  @media (min-width: $screen-md-min) {
    padding: 15px 30px;;
  }
}
.shop-header-title {
  margin: 0;
  text-transform: uppercase;
  font-size: 3rem;
  color: $gray-light;
  font-family: FrutigerLTStd-Bold;
  @media (min-width: $screen-sm-min) {
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 3.6rem;
  }
}
.shop-header-intro {
  font-size: 1.4rem;
  @media screen and (max-width: $screen-sm) {
    display: none;
  }
}
//
// filters
//
#filtersProducts-top {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 0 auto;
  background-color: #fff;
  padding: 0 1rem;
  @media screen and (min-width: $screen-sm-min) {
    margin-bottom: 25px;
   /* position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 85px;
    width: auto;*/
    border-radius: 4px 4px 0 0;
  }
  &.nav-pills > li.active > a, &.nav-pills > li.active > a:focus, &.nav-pills > li.active > a:hover {
    background-color: $blue;
    color: #fff;
  }
  &.nav-pills > li > a {
    display: flex;
    align-items: center;
    padding: 5px;
    font-family: $frutiger-light;
    font-size: 1.2rem;
    background-color: $gray-lightest;
    color: $gray-light;
    &:hover {
      background-color: $gray-lighter;
      color: #fff;
    }
    @media (min-width: 375px) {
      font-size: 1.4rem;
    }
    @media (min-width: $screen-sm-min) {
      padding: 5px $grid-gutter-width;
      font-size: 1.8rem;
    }
  }
  &.nav-pills > .active > a > .badge {
    color: #5ab9e0; // #Todo ajouter comme variable ?
  }
}
.filtersProducts-icon {
  filter: brightness(.5);
}
.filtersProducts-top-item {
  white-space: nowrap;
  text-transform: uppercase;
  margin-left: .5rem;
  margin-right: .5rem;
  @media (min-width: $screen-sm-min) {
    margin-left: 1rem;
    margin-right: 1rem;
  }
  .badge {
    display: none;
    justify-content: center;
    align-items: center;
    width: 25px;
    height: 25px;
    line-height: 30px;
    margin-left: $grid-gutter-width !important;
    padding: 3px;
    border-radius: 50%;
    font-size: 1rem;
    color: $gray-light;
    background-color: #fff;
    @media (min-width: $screen-sm-min) {
      display: inline-flex;
    }
  }
}
.bouton-affichage {
  border: none; // #Fix class layout.scss
}
// Filters products
.filters-label {
  color: #6b6b6b;
  text-transform: uppercase;
  margin-right: 40px;
  @media (max-width: $screen-xs-max) {
    display: none;
  }
}
.filtersProducts {
  display: inline-block;
  margin: 0 auto;
  border-bottom: 1px solid $gray-lighter;
  padding: 5px 0 20px 0;
  @media (min-width: $screen-sm-min) {
    padding: ($grid-gutter-width/2) $grid-gutter-width;
    border-top: 1px solid $gray-lighter;
  }
  form {
    margin-bottom: 0;
  }
  &:after {
    @include clearfix();
  }
  a {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    @include font-style($frutiger-bold,
            1.2rem,
            30px,
            0px);
    color: $gray-light;
    padding: 4px 5px;
    position: relative;
    @media (min-width: $screen-sm) {
      display: flex;
      margin: 0 ($grid-gutter-width*2) 0 0;
      padding: 4px 10px;
      font-size: 1.4rem;
    }
    &#filter-genre,
    &#filter-age,
    &#filter-collection,
    &#filter-prix {
      &:after {
        content: "";
        display: block;
        background: url("../img/icones/arrow-gray.svg") no-repeat 0 0 transparent;
        width: 8px;
        height: 15px;
        margin-left: 5px;
        transition: $transition;
        @media (min-width: $screen-sm-min) {
          width: 10px;
          height: 20px;
          margin-left: 10px;
        }
      }
      &[aria-expanded='true']:after {
        transform: rotate(90deg);
      }
    }
    &.active,
    &:hover {
      background-color: $gray-lightest;
      @include border-radius(5px);
    }
  }
  .filters {
    display: flex;
    justify-content: space-around;
    align-items: center;
    @media (min-width: $screen-sm-min) {
      justify-content: center;
    }
  }
}
#block-edl-catalogue-edl-filtre-catalogue {
  position: relative;
  .filter-container {
    text-align: center;
    background-color: #fff;
    &:after {
      @include clearfix();
    }
  }
  .low-filter-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: $grid-gutter-width;
    &:after {
      @include clearfix();
    }
    a {
      float: left;
      // margin: 0 25px 5px 0;
      margin: 0 10px 10px 0;
      color: $gray-light;
      padding: 8px 10px;
      @include font-style($frutiger-roman,
              15px,
              15px,
              0px);
      border-radius: 4px;
      border: 1px solid $gray-lighter;
      &.active,
      &:hover {
        border: 1px solid #337ab7;
        background: #337ab7;
        color: #fff;
        @include border-radius(5px);
      }
    }
  }
  .bouton-affichage {
    margin: 0;
    padding: 0;
    @media (max-width: $screen-sm-max) {
      flex-shrink: 0;
    }
    .nav-tabs {
      li {
        margin: 0 0 0 10px;
        &.active {
          a,
          a:hover {
            background-position: -39px 0;
          }
        }
        a,
        a:hover {
          background-image: url("../img/tab-catalogue.png");
          background-position: 0 0;
          width: 30px;
          height: 30px;
        }
      }
    }
  }
  .selected_filter {
    margin-top: $grid-gutter-width;
  }
}
.selected_filter a {
  color: #9e9e9e;
  margin-right: 25px;
  padding-bottom: 3px;
  padding-left: 15px;
  padding-top: 3px;
  float: left;
  background-image: url("../img/close-rouge.png");
  background-repeat: no-repeat;
  background-size: 10px;
  background-position: left;
  &:hover {
    color: #d43f3a;
  }
}
.selected_filter .libelle_filtre {
  color: #9e9e9e !important;
  margin-right: 10px;
  padding-bottom: 3px;
  padding-top: 3px;
  float: left;
}
//
// Search
//
.shop-search {
  position: relative;
  max-width: 50%;
  padding: 4px;
  @media (min-width: $screen-sm-min) {
    max-width: 220px;
    margin-right: 30px;
  }
  .form-group {
    margin-bottom: 0;
  }
  .form-type-textfield {
    float: left;
    input {
      border-radius: 15px;
      border: 1px solid $gray-light;
      background: #fff;
      height: 29px;
      box-shadow: none;
      color: $gray-light;
      @include font-style($frutiger-light, 14px, 29px, 0px);
      margin: 0;
      padding: 0 12px;
    }
  }
  #edl-catalogue-search-form .form-submit {
    position: absolute;
    top: 11px;
    right: 10px;
    width: 14px;
    height: 14px;
    background: url("../img/loupe-noir.png") no-repeat 0 0 transparent;
    background-size: contain;
    cursor: pointer;
    @include default-disable;
    text-indent: -9999px;
  }
}
//
// Content
//
.page-catalogue, .flexslider-products, .page-boutique, .node-type-boutique-accueil { // #Todo à généraliser
  &.page-catalogue-nouveautes {
    @media (max-width: $m1) {
      #block-edl-catalogue-edl-catalogue-mobile {
        margin: 20px 0 0 0;
        #tab1 {
          border-top: 1px solid #447daf;
          margin: 0 20px 0;
          padding: 20px 0 0 0;
        }
      }
    }
    .livre-container {
      .livre-infos {
        a {
          height: auto;
        }
      }
    }
  }
  #tab0 {
    .alert-livre {
      padding: 8px 0 5px;
      display: inline-block;
      a {
        background-image: url("../img/alerte-mail-lists.png");
        height: 33px !important;
        background-position: 0 -168px;
        color: #3774a9;
      }
    }
  }
  #block-edl-catalogue-edl-filtre-nouveautes {
    @media (max-width: $m1) {
      padding: 0 20px;
    }
    @media (max-width: $xm) {
      padding: 0 10px;
    }
    a {
      margin: 0 7px;
      display: inline-block;
      vertical-align: bottom;
      &.link-cal {
        @include border-radius-custom(15px,
                15px,
                0,
                0);
        width: 82px;
        @media (max-width: $xm) {
          margin: 0 2px;
        }
      }
      &.prev-link {
        background: url("../img/prev-nouveautes.png") no-repeat 0 0 transparent;
        text-indent: -9999px;
        width: 16px;
        height: 32px;
        margin: 0 5px 12px 0;
      }
      .cal-container {
        text-align: center;
        .month-year {
          padding: 7px 0 5px;
          color: #fff;
          height: 50px;
          .cal-month {
            @include font-style($frutiger-roman,
                    20px,
                    20px,
                    0px);
            margin: 0 0 2px;
            display: block;
          }
          .cal-year {
            @include font-style($frutiger-roman,
                    15px,
                    15px,
                    0px);
          }
        }
        .cal-count {
          background: #e9e9e9;
          color: #858282;
          @include font-style($frutiger-roman,
                  11px,
                  17px,
                  0px);
          height: 17px;
          width: 100%;
          display: block;
          @media (max-width: $m1) {
            height: 20px;
            line-height: 20px;
          }
        }
      }
      &.link-first {
        background: #e50f16;
        &.active {
          .cal-container {
            .cal-count {
              background: #e50f16;
              color: #fff;
            }
          }
        }
      }
      &.link-second {
        background: #ff4e00;
        &.active {
          .cal-container {
            .cal-count {
              background: #ff4e00;
              color: #fff;
            }
          }
        }
      }
      &.link-third {
        background: #fe9b1e;
        &.active {
          .cal-container {
            .cal-count {
              background: #fe9b1e;
              color: #fff;
            }
          }
        }
      }
    }
  }

  #block-system-main {
    padding: 12px 0 0 0;
  }
  .row-catalogue {
    &:after {
      @include clearfix();
    }
    @media (max-width: $m1) {
      max-width: 640px;
      width: 100%;
      margin: 0 auto;
    }
  }

}
.empty-result {
  flex: 1;
  text-align: center;
}