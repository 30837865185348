// Flexslider fullwidth -
.flexslider-fullwidth {
  border: none;
  margin-bottom: 0;
  .flexslider-item {
    position: relative;
  }
  .content {
    height: 50vh;
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-left: ($grid-gutter-width*2);
    padding-bottom: ($grid-gutter-width*3);
    @media (min-width: $screen-sm) {
      margin-left: ($grid-gutter-width*4);
    }
    @media (min-width: $screen-lg) {
      margin-left: 80px;
    }
  }
  img {
    filter: brightness(85%);
  }
  .btn {
    position: relative;
  }
  .flex-direction-nav {
    display: none;
    position: absolute;
    width: 100%;
    top: 50%;
    // bottom: $grid-gutter-width;
    left: 0;
    right: 0;
    transform: translateY(50%);
    li a {
      opacity: 1 !important;
      height: 64px;
      width: 64px;
      text-indent: -9999px;
      background-repeat: no-repeat;
      // background-image: url(../img/fleche-autre-lecturer.png);
      background-image: url(../img/icones/arrow-gray-white.svg);
      text-align: left;
      top: 0px;
      &.flex-prev {
        left: 14px; // Fix size icon
        transform: rotate(180deg);
      }
      &.flex-next {
        right: 0;
      }
    }
    @media (min-width: $screen-sm) {
      display: block
    }
  }
  .flex-control-nav {
    bottom: 30px;
  }
  .flex-control-paging li a {
    display: none;
  }
}


