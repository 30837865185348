.block-edl-newsletters {

    .newsletter-hr-top,
    .newsletter-hr-bottom {
        margin: 32px 0;
    }

    .form-control {
        height: 48px;
        border-radius: 8px;
    }

    .form-group {
        margin: 0;
    }

    .form-item-newsletter-id {
        margin-top: 32px;
    }

    .form-item-mail input {
        background-color: $evenement;
    }

    .checkbox input[type=checkbox] {
        position: initial;
        margin-left: 0;
    }

    #edit-privacy-agreement {
        transform: scale(1.8);
        margin-left: 5px;

        &+label {
            padding-left: 12px;
        }
    }

    .checkbox-description {
        flex-basis: 100%;
        margin-top: 12px;
    }

    .checkbox-extra,
    .checkbox-extra-link {
        font-size: 12px;
        color: $queen-blue;
    }


    .checkbox-extra {
        &::after {
            content: '\00a0 \00a0 •';
        }
    }


    .form-item-newsletter-id-2,
    .form-item-newsletter-id-4 {
        margin-top: 32px;
    }

    .checkbox-extra-link {
        text-decoration: underline;
        margin-left: 7px;
        display: inline-block;
    }

    .form-type-checkbox {
        display: flex;
        flex-wrap: nowrap;

        & label {
            padding-left: 8px;
            font-family: $frutiger-bold;
            font-size: 16px;
        }
    }

    .switch-wrapper {
        position: relative;
        display: inline-block;
        width: 40px;
        height: 24px;

        &+label {
            flex: 1;
        }
    }

    /* Cacher la checkbox native */
    .switch-wrapper input {
        opacity: 0;
        width: 0;
        height: 0;
    }

    /* Style du slider */
    .switch-slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        transition: 0.4s;
        border-radius: 34px;
    }

    .switch-slider:before {
        position: absolute;
        content: "";
        height: 16px;
        width: 16px;
        left: 4px;
        bottom: 4px;
        background-color: white;
        transition: 0.4s;
        border-radius: 50%;
    }

    /* Lorsque la checkbox est cochée */
    .switch-wrapper input:checked+.switch-slider {
        background-color: $case-2;
    }

    .switch-wrapper input:checked+.switch-slider:before {
        transform: translateX(16px);
    }

    .form-item-privacy-agreement {
        display: flex;
        align-items: flex-start;
        margin: 32px 0 0 0;

        .help-block {
            font-family: $frutiger-roman;
            font-size: 12px;
            margin: 0 0 0 16px;
        }

        a {
            color: rgb(114.75, 114.75, 114.75);
            text-decoration: underline;
        }
    }

    .form-submit {
        margin: 32px 0 58px 0;
        width: 100%;
        background-color: $queen-blue;
        color: #FFFFFF;
        font-family: $frutiger-bold;
        font-size: 16px;
        transition: background-color 0.3s ease;

        &:hover {
            background-color: $indigo;
            color: #fff;
        }
    }

}

@media (min-width: 576px) {
    .block-edl-newsletters {
        .form-control {
            max-width: 474px;
        }

        .form-submit {
            max-width: 200px;
        }
    }
}

@media (min-width: 1200px) {
    .block-edl-newsletters {

        .newsletter-hr-top,
        .newsletter-hr-bottom {
            margin: 40px 0;
        }

        .checkbox-description {
            max-width: 652px;
        }

        .form-group {
            margin-top: 40px;
        }
    }
}