// Base
// #Todo A cleaner
//
/*boostrap container*/
html {
  scroll-padding-top: 40px;
  overflow-x: hidden;
}

body {
  font-family: "FrutigerLTStd-Roman";
  font-size: 16px;
  overflow-x: hidden;
  padding: 0;
  margin: 0;
}

ul,
ol,
.dropdown-menu {
  padding: 0;
  margin: 0;
  list-style: none;
}

a {
  background: transparent;
  outline: none;
  text-decoration: none;
  @include tag-a();
}

a:active,
a:hover,
a:focus {
  outline: 0;
  text-decoration: none;
}

a:focus,
a:active,
button::-moz-focus-inner,
input[type="reset"]::-moz-focus-inner,
input[type="button"]::-moz-focus-inner,
input[type="submit"]::-moz-focus-inner,
select::-moz-focus-inner,
input[type="file"]>input[type="button"]::-moz-focus-inner {
  border: 0;
  outline: 0;
}

// Couleurs page concours temporaire
.pink-new,
.pink-new h2,
.pink-new h3,
.pink-new h4,
.pink-new h5,
.pink-new a {
  color: $pink-new !important;
}

.blue-new,
.blue-new h2,
.blue-new h3,
.blue-new h4,
.blue-new h5,
.blue-new a {
  color: $blue-new !important;
}

.bg-gray {
  background-color: $gray-lightest;
}

// General
.no-gutters {
  padding-left: 0;
  padding-right: 0;
}

@media (min-width: $screen-sm-min) {
  .row-eq-height {
    display: flex;
    flex-wrap: wrap;
    flex-basis: auto;
    width: 100%;
  }
}

@media (min-width: $screen-md-min) {
  .row-eq-height-md {
    display: flex;
    flex-wrap: wrap;
    flex-basis: auto;
    //width: 100%;
  }
}

.is-fullwidth {
  width: 100%;
}

// Loader page
.loader-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow-y: hidden;
  z-index: 8;
  background: #fff;
}

// Arrows

.arrow-up-right:after {
  content: " ";
  position: absolute;
  bottom: 100%;
  /* At the top of the tooltip */
  right: 10px;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent white transparent;
}

// Button
.btn-pink {
  color: #fff !important;
  background-color: $pink;
  border-color: $pink;

  &:hover,
  &:focus,
  &:active {
    color: #fff !important;
    background-color: $pink-dark;
    border-color: $pink-dark;

    a {
      color: #fff !important;
    }
  }
}

.rounded-author-image {
  border-radius: 50%;
  width: 155px !important;
  height: 155px;
  object-fit: cover;
  display: block;
  margin: auto;
}