// Fonds de couleurs par âge - Page recherche
.bg-0-3{
  background-color: $catalogue-2 !important;
}
.bg-2-4{
  background-color: $catalogue-3 !important;
}
.bg-3-5{
  background-color: $catalogue-4 !important;
}
.bg-6-8{
  background-color: $catalogue-5 !important;
}
.bg-8-11{
  background-color: $catalogue-6 !important;
}
.bg-9-12{
  background-color: $catalogue-7 !important;
}
.bg-12-16{
  background-color: $catalogue-8 !important;
}
.bg-16{
  background-color: $catalogue-9 !important;
}


// Page taxonomy
.page-taxonomy .region-content .livre-container.catalogue-1, .page-taxonomy .region-sidebar-second .livre-container.catalogue-1 {
  background: #febc1e;
}
.page-taxonomy .region-content .livre-container.catalogue-2, .page-taxonomy .region-sidebar-second .livre-container.catalogue-2 {
  background: #8ab5dd;
}
.page-taxonomy .region-content .livre-container.catalogue-3, .page-taxonomy .region-sidebar-second .livre-container.catalogue-3 {
  background: #ef8427;
}
.page-taxonomy .region-content .livre-container.catalogue-4, .page-taxonomy .region-sidebar-second .livre-container.catalogue-4 {
  background: #39b6b8;
}
.page-taxonomy .region-content .livre-container.catalogue-5, .page-taxonomy .region-sidebar-second .livre-container.catalogue-5 {
  background: #3774a9;
}
.page-taxonomy .region-content .livre-container.catalogue-6, .page-taxonomy .region-sidebar-second .livre-container.catalogue-6 {
  background: #97a719;
}
.page-taxonomy .region-content .livre-container.catalogue-7, .page-taxonomy .region-sidebar-second .livre-container.catalogue-7 {
  background: #d4021d;
}
.page-taxonomy .region-content .livre-container.catalogue-8, .page-taxonomy .region-sidebar-second .livre-container.catalogue-8 {
  background: #8a0b32;
}
.page-taxonomy .region-content .livre-container.catalogue-9, .page-taxonomy .region-sidebar-second .livre-container.catalogue-9 {
  background: #005b78;
}
.page-taxonomy .region-content .livre-container, .page-taxonomy .region-sidebar-second .livre-container {
  background: #e9e9e9;
}
.page-taxonomy .region-content .livre-container, .page-taxonomy .region-sidebar-second .livre-container .livre-image-content img {
  border: 6px solid white;
}
