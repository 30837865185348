/*on site*/


/*accordion h3*/
@mixin accordion-title($background-title) {
    background-color: $background-title;
    height: 55px;
    width: 100%;
    background-repeat: no-repeat;
    background-image: url("../img/scroll-mobile.png");
    background-position: 96% 20px;
    @include default-disable();
    text-transform: uppercase;
    @include font-style($frutiger-roman, 26px, 55px, 0px);
    padding: 0 0 0 22px;
    margin: 0;
    color: #fff;
}

/*gradient-site*/
@mixin gradient-actu() {
    &:after {
        @include gradient-color;
        @include position-absolute(175%, 0);
        @include transform(translateX(-20%));
        @include transition-gradient();

    }
}

/*balayage*/
@mixin balayage-gauche($background, $background-hover) {
    background: $background;
    cursor: pointer;
    display: block;
    overflow: hidden;
    position: relative;
    vertical-align: middle;
    white-space: normal;
    z-index: 1;
    height: auto;
    width: 100%;
    @include balayage-before($background-hover);

    &:hover {
        @include balayage-hover();
    }
}

@mixin balayage-before($background-hover) {
    &:before {
        background: $background-hover;
        bottom: 0;
        @include position-absolute(0, -1);
        @include transition(width, 0.3s, ease-in-out, 0.1s);
    }
}

@mixin balayage-hover() {
    &:before {
        width: 100%;
    }
}

/*end balayage*/

@mixin block-title($width, $color, $background) {
    width: $width;
    color: $color;
    background: $background;
}

@mixin default-disable() {
    /* background-color: none;*/
    // invalide
    background-color: transparent;
    box-shadow: none;
    border: none;
    border-radius: 0;
}

@mixin collection-active() {
    @each $current-position in $collections-page {
        $i: index($collections-page, $current-position);

        &.collection-#{$i} {
            background: url("../img/collection/mobile/#{$current-position}_rollover.png") no-repeat 0 0 transparent;
        }
    }
}

@mixin collection-page() {
    @each $current-position in $collections-page {
        $i: index($collections-page, $current-position);

        &.collection-#{$i} {
            background: url("../img/collection/mobile/#{$current-position}.png") no-repeat 0 0 transparent;

            &:hover {
                background: url("../img/collection/mobile/#{$current-position}_rollover.png") no-repeat 0 0 transparent;
            }
        }
    }
}

// #Todo Vérifier si les mixins collections doivent être supprimés.
@mixin collection-lists() {
    @each $current-position in $collections {
        $i: index($collections, $current-position);

        &.collection-#{$i} {
            background: url("../img/collection/#{$current-position}.png") no-repeat 0 0 transparent;

            &:hover {
                background: url("../img/collection/#{$current-position}_rollover.png") no-repeat 0 0 transparent;
            }
        }
    }
}

/*border-auteur*/
@mixin border-auteur() {
    margin: 0 0 10px;
    padding: 0 0 10px;
    border-bottom: 1px solid #f3a8c7;
}

/*placeholder*/
/*note: must include !important + seperate webkit*/
@mixin placeholder-text-wrapper ($font-family-p, $color-p, $font-size-p) {
    font-family: $font-family-p !important;
    color: $color-p !important;
    font-size: $font-size-p !important;
    opacity: 1 !important;
}

@mixin placeholder-text($font-family-p, $color-p, $font-size-p) {
    input::-webkit-input-placeholder {
        @include placeholder-text-wrapper($font-family-p, $color-p, $font-size-p);
    }

    input:-moz-placeholder {
        @include placeholder-text-wrapper($font-family-p, $color-p, $font-size-p);
    }

    input:-ms-input-placeholder {
        @include placeholder-text-wrapper($font-family-p, $color-p, $font-size-p);
    }

    input::-moz-placeholder {
        @include placeholder-text-wrapper($font-family-p, $color-p, $font-size-p);
    }
}

@mixin view-row-search() {
    .views-row {
        padding: 6px 0;
        border-top: 1px solid #a8a7a1;

        &:after {
            @include clearfix;
        }

        &.views-row-last {
            border-bottom: 1px solid #a8a7a1;
        }

        .views-field-title {
            float: left;

            @media (max-width:$m1) {
                text-align: left;
                width: 75%;
            }

            a {
                @include font-style($frutiger-light, 18px, 18px, 0px);
                color: $noir;
            }
        }

        .views-field-field-auteur-qualite,
        .views-field-field-livre-collection,
        .views-field-field-type-evenement {
            float: right;

            @media (max-width:$m1) {
                text-align: right;
                width: 25%;
            }

            div {
                @include font-style($frutiger-bold, 14px, 17px, 0px);
                color: $menu-2;
            }
        }

        .views-field-field-auteur-qualite {
            div {
                color: $menu-2;
            }
        }

        .views-field-field-livre-collection {
            div {
                color: $menu-1;
            }
        }

        .views-field-field-type-evenement {
            div {
                color: $menu-3;
            }
        }
    }

    .view-footer {
        margin: 40px auto 0;
        text-align: center;

        @media (max-width:$m1) {
            margin: 20px auto 0;
        }

        p {
            margin: 0;

            a {
                color: $bg-hover;
                @include font-style($frutiger-bold, 15px, 15px, 0px);
                text-transform: uppercase;
            }
        }
    }
}

@mixin arrow-dropdown($left) {
    background-image: url("../img/arrow.png");
    background-position: top center;
    background-repeat: no-repeat;
    content: " ";
    height: 10px;
    width: 21px;
    position: absolute;
    left: $left;
    top: -10px;
}

@mixin position-fix () {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
}

@mixin position-absolute($width-position-abso, $z-index-abso) {
    position: absolute;
    left: 0;
    top: 0;
    width: $width-position-abso;
    z-index: $z-index-abso;
    height: 100%;
    display: block;
    content: "";
}

@mixin parallax-after() {
    &:after {
        content: "";
        display: block;
        height: 100%;
        left: 0;
        top: 0;
        transition: background-color 0.5s ease 0s;
        width: 100%;
    }
}

@mixin color-menu($menu-color) {
    .dropdown-menu {
        >li {
            >a {
                &.active {
                    color: $menu-color;
                    background: none;

                    &:hover {
                        background: none;
                    }
                }

                &:hover {
                    background: $menu-color;
                }
            }
        }
    }
}


/*general*/
@mixin opacity($opacity) {
    opacity: $opacity !important;
    -moz-opacity: $opacity !important;
    -khtml-opacity: $opacity !important;
    -ms-filter: alpha(opacity=#{round($opacity * 100)});
    filter: unquote("alpha(opacity=#{round($opacity * 100)})");
    -ms-filter:"progid:DXImageTransform.Microsoft.Alpha(opacity=#{round($opacity * 100)})";
}

@mixin inline-block() {
    display: -moz-inline-box;
    display: inline-block;
    *display: inline;
    zoom: 1;
}

@mixin clearfix() {
    content: ".";
    display: block;
    clear: both;
    visibility: hidden;
    line-height: 0;
    height: 0;
}

@mixin ellipsis() {
    text-overflow: ellipsis;
    display: block;
    overflow: hidden;
    white-space: nowrap;
}

@mixin border-radius($radius) {
    -webkit-border-radius: $radius;
    -moz-border-radius: $radius;
    border-radius: $radius;
    -o-border-radius: $radius;
}

@mixin border-radius-custom($radius1, $radius2 , $radius3 , $radius4) {
    -webkit-border-radius: $radius1 $radius2 $radius3 $radius4;
    -moz-border-radius: $radius1 $radius2 $radius3 $radius4;
    border-radius: $radius1 $radius2 $radius3 $radius4;
    -o-border-radius: $radius1 $radius2 $radius3 $radius4;
}

@mixin filter-grayscale($grayscale) {
    -webkit-filter: grayscale($grayscale);
    filter: grayscale($grayscale);
}

@mixin filter-custom() {
    -webkit-filter: grayscale(100%) opacity(0.5);
    filter: grayscale(100%) opacity(0.5);
}

@mixin transform-custom() {
    -webkit-transform: scale(1.2) rotate(5deg);
    -ms-transform: scale(1.2) rotate(5deg);
    -o-transform: scale(1.2) rotate(5deg);
    transform: scale(1.2) rotate(5deg);
    -moz-transform: scale(1.2) rotate(5deg);
}

@mixin box-shadow($def) {
    -webkit-box-shadow: $def;
    -moz-box-shadow: $def;
    box-shadow: $def;
    -o-box-shadow: $def;
}

@mixin background-size() {
    background-size: cover;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
}

@mixin background-size-collection() {
    background-size: 100% 100% !important;
    -webkit-background-size: 100% 100% !important;
    -moz-background-size: 100% 100% !important;
    -o-background-size: 100% 100% !important;
}

@mixin retina() {
    @media only screen {

        @media (min-resolution: 124dpi),
        (-webkit-min-device-pixel-ratio: 1.3),
        (min--moz-device-pixel-ratio: 1.3),
        (-o-min-device-pixel-ratio: 4/3),
        (min-device-pixel-ratio: 1.3),
        (min-resolution: 1.3dppx) {
            @include use-backgrounds(url($sprite-retina), $sprite-size, url($input-background-retina), url($input-background-focus-retina), $input-background-size);
        }
    }
}

/*font*/
@mixin font-style($family, $size, $line-height, $spacing) {
    font-family: $family;
    font-size: $size;
    line-height: $line-height;
    letter-spacing: $spacing;
}


// TRANSFORMATIONS & TRANSITION & BLUR
// --------------------------------------------------

@mixin transform($transform...) {
    -webkit-transform: $transform;
    -ms-transform: $transform;
    -o-transform: $transform;
    transform: $transform;
    -moz-transform: $transform;
}

@mixin transition($properties, $ani-start, $properties-effect, $ani-end) {
    -webkit-transition: $properties $ani-start $properties-effect $ani-end;
    -moz-transition: $properties $ani-start $properties-effect $ani-end;
    -o-transition: $properties $ani-start $properties-effect $ani-end;
    -ms-transition: $properties $ani-start $properties-effect $ani-end;
    transition: $properties $ani-start $properties-effect $ani-end;
}

@mixin blur($blur) {
    -webkit-filter: blur($blur);
    filter: blur($blur);
    -moz-filter: blur($blur);
    -ms-filter: blur($blur);
    -o-filter: blur($blur);
    filter: progid:DXImageTransform.Microsoft.Blur(PixelRadius='15');
}

@mixin tag-a() {
    -webkit-transition: .2s cubic-bezier(0.25, .46, .45, .94);
    transition: .2s cubic-bezier(0.25, .46, .45, .94);
    -webkit-transition-property: background-color, color;
    transition-property: background-color, color;
}

@mixin animation-arrow() {
    -webkit-animation: arrow-ani 2s infinite;
    -moz-animation: arrow-ani 2s infinite;
    -o-animation: arrow-ani 2s infinite;
    animation: arrow-ani 2s infinite;
}

// TRIANGLES
// --------------------------------------------------

@mixin make-triangle-up ($triangleWidth, $color: rgba(255, 255, 255, 0.5)) {
    width: 0;
    height: 0;
    border-bottom: $triangleWidth/2 solid $color;
    border-left: $triangleWidth/2 solid transparent;
    border-right: $triangleWidth/2 solid transparent;
}

@mixin make-triangle-down ($triangleWidth, $color: rgba(255, 255, 255, 0.5)) {
    width: 0;
    height: 0;
    border-top: $triangleWidth/2 solid $color;
    border-left: $triangleWidth/2 solid transparent;
    border-right: $triangleWidth/2 solid transparent;
}

// ASPECT RATIO
// --------------------------------------------------

@mixin aspect-ratio($x, $y, $selector: img) {
    position: relative;
    padding: 0 0 percentage($y/$x) 0;
    height: 0;

    #{$selector} {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

@mixin square-aspect-ratio {
    position: relative;
    padding: 0 0 0 100%;
    width: 0;

    div {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}


// GRADIENTS
// --------------------------------------------------

@mixin gradient-color () {
    background: rgba(0, 0, 0, 0) linear-gradient(to right, $gradient-color 0%, transparent 100%) repeat scroll 0 0;
    background: rgba(0, 0, 0, 0) -o-linear-gradient(to right, $gradient-color 0%, transparent 100%) repeat scroll 0 0;
    background: rgba(0, 0, 0, 0) -webkit-linear-gradient(to right, $gradient-color 0%, transparent 100%) repeat scroll 0 0;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#{ie-hex-str($gradient-color)}', endColorstr='#{ie-hex-str(#fff)}', GradientType=1); // IE9 and down
}

@mixin gradient-auteur ($percentage-high) {
    background: rgba(0, 0, 0, 0) linear-gradient(to left, #c4587c 0%, transparent $percentage-high) repeat scroll 0 0;
    background: rgba(0, 0, 0, 0) -o-linear-gradient(to left, #c4587c 0%, transparent $percentage-high) repeat scroll 0 0;
    background: rgba(0, 0, 0, 0) -webkit-linear-gradient(to left, #c4587c 0%, transparent $percentage-high) repeat scroll 0 0;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#{ie-hex-str($gradient-color)}', endColorstr='#{ie-hex-str(#c4587c)}', GradientType=1); // IE9 and down
}

@mixin gradient-horizontal-reverse($start-color, $end-color: transparent, $start-percent: 0%, $end-percent: 100%) {
    background-image: -webkit-linear-gradient(right, $start-color $start-percent, $end-color $end-percent); // Safari 5.1-6, Chrome 10+
    background-image: -o-linear-gradient(right, $start-color $start-percent, $end-color $end-percent); // Opera 12
    background-image: linear-gradient(to left, $start-color $start-percent, $end-color $end-percent); // Standard, IE10, Firefox 16+, Opera 12.10+, Safari 7+, Chrome 26+
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#{ie-hex-str($start-color)}', endColorstr='#{ie-hex-str($end-color)}', GradientType=1); // IE9 and down
}

@mixin gradient-vertical-reverse($start-color: $decoBorder, $end-color: transparent, $start-percent: 0%, $end-percent: 70%) {
    background-image: -webkit-linear-gradient(bottom, $start-color $start-percent, $end-color $end-percent); // Safari 5.1-6, Chrome 10+
    background-image: -o-linear-gradient(bottom, $start-color $start-percent, $end-color $end-percent); // Opera 12
    background-image: linear-gradient(to top, $start-color $start-percent, $end-color $end-percent); // Standard, IE10, Firefox 16+, Opera 12.10+, Safari 7+, Chrome 26+
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#{ie-hex-str($start-color)}', endColorstr='#{ie-hex-str($end-color)}', GradientType=0); // IE9 and down
}

/*catalogue color theme*/
@mixin catalogue-color-theme($color, $position-arrow-left, $position-arrow-top, $slider-left, $slider-right, $position-partager-left, $position-partager-right) {
    .main-container .col-sm-9 #block-edl-livre-edl-livre-infos .les-auteurs-livre p a:hover,
    .collection-title,
    #parallax-2 .region-parallax-2 .view-presse-blog .views-row .left-content:hover .press-cat,
    .mob-slides-livres .views-field-title a,
    .mob-slides-livres .titre-livre a,
    .current-collection .alert-livre a,
    #tab0 .alert-livre a

    /*#purchase-list a*/
        {
        color: $color;
    }

    .titre-livre,
    /*.livre-age ,*/
    .ce-terme a,
    .accroche-part h2,
    .accroche-full h2,
    .link-event h2,
    #parallax-1 .region-parallax-1 .block-title,
    #parallax-2 .region-parallax-2 .block-title,
    #parallax-3 .region-parallax-3 .block-title,
    //.type-event ,
    .link-event a,
    .press-media,
    .press-link a,
    .author-item a,
    .livre-age {
        color: $color;
    }

    .toggle-accroche a {
        background-position: $position-arrow-left $position-arrow-top;
    }

    #block-views-autres-lecture-block .block-title,
    .left-content .press-cat,
    .event-overlay,
    .press-overlay,
    .mob-slides-livres .flex-direction-nav li a,
    .current-collection .cross:before,
    .current-collection .cross:after,
    .current-collection .cross.open:before,
    .current-collection .cross.open:after,
    .slide-collection

    /*.livre-age,
        .livre-genre*/
        {
        background-color: $color;
    }

    .ui-accordion-header .ui-icon {
        background-position: $position-arrow-left $position-arrow-top;
    }

    #presse {

        .flex-prev,
        .flex-next {
            background-position: $slider-left $slider-right;
        }
    }

    .livre-icons-wrapper .livre-share .picto-partage {
        background-position: $position-partager-left $position-partager-right;
    }
}

@mixin container-style() {
    @media (min-width: 768px) {
        width: 735px;
        margin-left: auto;
        margin-right: auto;
    }

    @media (min-width: 992px) {
        width: 995px;
    }

    @media (min-width: 1440px) {
        width: 1200px;
    }
}