.page-bibliotheque-ideale {
    .breadcrumb {
        @include container-style();
    }
}

.view-bibliotheque-ideale {
    padding-bottom: 30px;
    @include container-style();

    .view-header {
        font-size: 16px;
    }

    .view-filters {
        border-top: 1px solid #d8d8d8;
        border-bottom: 1px solid #d8d8d8;
        margin: 40px auto;
        padding: 10px 0;
        width: fit-content;
    }

    #edit-submit-bibliotheque-ideale {
        background-color: $queen-blue;
        color: #FFFFFF;
        transition: background-color 0.3s ease;

        &:hover {
            background-color: $indigo;
            color: #fff;
        }
    }

    .item-list {
        >ul {
            display: grid;
            grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
            gap: 2rem;
            margin-top: 2rem;
        }

        img {
            max-height: 220px;
        }

        .book-title {
            margin-top: 10px;
            margin-bottom: 5px;
            font-family: FrutigerLTStd-Bold;
            font-size: 1.6rem;
            line-height: 2rem;
            letter-spacing: 0;

            a {
                color: #333;
            }
        }

        .book-card {
            height: 100%;
            display: flex;
            flex-direction: column;
        }

        .book-cover {
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px solid #d8d8d8;
            height: 230px;
            overflow: hidden;
        }

        .book-authors {
            @include font-style($frutiger-bold, 14px, 20px, 0);
            color: #6b6b6b;

            .field-content {
                display: flex;
                gap: 2px;
            }

            .field-name-field-auteur-qualite {
                display: none;
            }
        }

        .book-collection,
        .book-description {
            margin-top: 10px;
        }

        .book-description {
            margin-bottom: 20px;
        }

        .book-age,
        .book-collection,
        .book-price {
            display: flex;
            gap: 4px;
            color: #6b6b6b;
            font-size: 1.3rem;
        }

        .btn-read-more {
            margin: auto auto 0 auto;
            display: block;
            width: fit-content;
            @include font-style($frutiger-bold, 12px, 20px, 0);
        }
    }
}