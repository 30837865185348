.view-articlesseo {
    padding-bottom: 30px;

    .item-list {
        >ul {
            display: grid;
            grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
            gap: 2rem;
            margin-top: 2rem;
        }

        li {
            border: 1px solid #d8d8d8;
            padding: 20px;
        }

        h2 {
            margin: 0 0 20px;
            color: #000;
            @include font-style($frutiger-bold, 20px, 24px, 0);
        }

        .article-seo-card {
            height: 100%;
            display: flex;
            flex-direction: column;
        }

        .article-seo-content {
            margin-top: auto;
        }

        .article-seo-resume {
            margin-bottom: 20px;
        }

        .btn-read-more {
            display: block;
            margin: auto;
            width: fit-content;
        }
    }
}