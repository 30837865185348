// Margin
.mb10 {
  margin-bottom: 10px;
}
.mb20 {
  margin-bottom: 20px;
}
.mb40 {
  margin-bottom: 40px;
}
.mb60 {
  margin-bottom: 60px;
}
.mt10 {
  margin-top: 10px;
}
.mt20 {
  margin-top: 20px;
}
.mt40 {
  margin-top: 40px;
}
.mt60 {
  margin-top: 60px;
}