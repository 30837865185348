.block-edl-recherche-avance{
	float: left;
	.form-group{
		margin-bottom: 0;
	}
	.form-control, .form-control:focus{
		border-top: none;
		border-right:none;
		border-bottom:none;
		border-left:1px solid rgba(0,0,0,0.1) !important;
		/*box-shadow: 0 0 4px rgba(0,0,0,0.1);*/
		box-shadow: none;
		border-radius: 0;
	}
	select{
		-webkit-appearance: none;
		background: url('/sites/all/themes/edl/img/dropdown-catalogue.png') no-repeat right;
		padding-right: 20px;
	}
}
.search-filters-block{
	width: 100%;
	background-color: #ffffff;
	box-shadow: 0 0 4px rgba(0,0,0,0.1);
	z-index:5;
	@media (min-width: $screen-md-min){
		position: fixed;
	}
}
@media (min-width: $screen-md-min) {
	.menu-fixed .search-filters-block {
		top: 55px;
	}
}

#edit-types{
	display: none;
}
