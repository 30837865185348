// Interface utilisateur
//

/* Formulaire d'inscription */

.login-wrapper {
  margin-top: 10%;
  margin-bottom: 10%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.login-bg {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.page-user {
  @media (min-width: $screen-sm-min) {
    .custom-page-header {
      margin-bottom: 0;
      padding-top: 0;
    }
  }
  .region-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    @media (min-width: $screen-sm-min) {
      border-left: 2px solid $gray-lighter;
      padding-left: 8rem;
    }
    @media (min-width: $screen-lg-min) {
      margin-right: 6rem;
    }
  }
  .ui-helper-hidden-accessible {
    display: none; //#Todo check gestion des messages
  }
}
.login-logo {
  display: none;
  @media (min-width: $screen-sm-min) {
    display: block;
    margin-right: 6rem;
  }
  @media (min-width: $screen-lg-min) {
    margin-right: 8rem;
  }
}
.login-header {
  margin-top: 2rem;
  text-align: center;
  a {
    color: $blue;
  }
  @media (min-width: 340px) {
    p {
      font-size: 1.8rem;
    }
  }
}
#user-register-form, #user-login, .login-footer, #user-pass {
  margin: .5rem;
  /*padding: 3rem;*/
  @media (min-width: $screen-sm-min) {
    max-width: 380px;
    margin: 4rem auto 1rem auto;
  }
  .form-group, .g-recaptcha {
    margin-bottom: 3rem;
  }
  label {
    font-family: $frutiger-bold;
    font-weight: unset;
  }
  input {
    box-shadow: none;
    border-top: none;
    border-left: none;
    border-right: none;
    border-radius: 0;
    border-width: 2px;
  }
  input:focus {
    border-color: $blue;
  }
  .form-submit {
    display: block;
    margin: 0 auto;
    padding: 1rem 3rem;
    color: #fff;
    background-color: $blue;
    border-color: $blue;
    &:hover, &:focus, &:active {
      color: #fff;
      background-color: darken($blue, 20%);
      border-color: darken($blue, 20%);
      a {
        color: #fff;
      }
    }
  }
}
.login-footer {
  text-align: center;
  a {
    color: $blue;
    &:hover {
      text-decoration: underline;
    }
  }
  @media (max-width: $screen-sm-max) {
    margin-top: 3rem;
    text-align: center;
  }
}
p.pwdRequirement {
  font-size: 1.3rem;
}
// Tunnel d'achat
// Submit button
.ct-panier .form-submit, .checkout-continue, #commerce-checkout-coupon-ajax-wrapper .form-submit, .payment-login #user-login .form-submit {
  padding: 1rem 3rem;
  color: #fff;
  background-color: $pink;
  border-color: $pink;
  &:hover, &:focus, &:active {
    color: #fff;
    background-color: darken($pink-dark, 20%);
    border-color: darken($pink-dark, 20%);
    outline: none;
    a {
      color: #fff;
    }
  }
}
// Etapes / step
.stepper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 6rem;
  font-size: 1.8rem;
  text-align: center;
  @media (min-width: $screen-md-min) {
    text-align: left;
  }
}
.step {
  margin-right: 1rem;
  &:last-child {
    margin-right: 0;
  }
}
.step.is-current {
  color: $pink;
}
.step-count {
  border: 1px solid $gray-base;
  border-radius: 50%;
  width: 28px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto 1rem auto;
  @media (min-width: $screen-md-min) {
    display: inline-flex;
    margin: 0;
  }
}
.is-current .step-count {
  border-color: $pink;
}
.step-final {
  margin: 0 auto 1rem auto;
  display: block;
  @media (min-width: $screen-md-min) {
    display: inline;
    margin: 0;
  }
  svg {
    stroke: $gray-base;
  }
}
.is-current .step-final {
  svg {
    stroke: $pink;
  }
}
// panier
.ct-panier {
  margin-bottom: 8rem;
}
.page-cart .commerce_coupon:not(.bottom) {
  display: none;
}
.page-cart #edit-actions {
  display: none; // Fix doublon ?
}
.partie-1.filet.texte-panier-entete {
  font-size: 1.8rem;
  margin-bottom: 3rem;
}
.un-ouvrage-panier {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 3rem;
  margin-bottom: 3rem;
  border-bottom: 1px solid $gray-lighter;
  .partie-1 {
    flex: 2;
  }
  .partie-2 {
    flex: 1;
  }
}
.ct-ss-parties {
  display: flex;
  justify-content: flex-start;
}
.ss-partie-1 {
  img {
    max-width: 60px;
    @media (min-width: $screen-sm-min) {
      max-width: 120px;
    }
  }
}
.ss-partie-2 {
  margin-left: 1rem;
  @media (min-width: $screen-sm-min) {
    margin-left: 3rem;
  }
}
.format-panier {
  margin-bottom: 2rem;
  font-family: $frutiger-bold;
  font-size: 1.4rem;
  color: $pink;
}
.titre-ouvrage-panier {
  font-family: $frutiger-bold;
  font-size: 1.6rem;
}
.auteur-panier, .collection-panier {
  display: inline-block;
  color: $gray-light;
}
.collection-panier {
  margin-left: 1rem;
}
.ct-panier .delete-line-item {
  padding: .5rem 1.5rem;
  font-size: 1.4rem;
}
// Total
.prix-total {
  text-align: right;
  font-family: $frutiger-bold;
  font-size: 1.8rem;
  text-transform: uppercase;
}
.prix-total-chiffres {
  text-align: right;
  font-size: 1.8rem;
}
.commerce-line-item-actions {
  margin-top: 3rem;
  a {
    margin-right: 3rem;
    font-family: $frutiger-bold;
    color: $gray-base;
    &:hover {
      color: $pink;
    }
  }
}
//
// Login
.payment-login {
  .page-header { // Doublon avec titre page
    display: none;
  }
  a {
    color: $pink;
  }
  label {
    display: none;
  }
  .login-footer {
    margin-bottom: 6rem;
  }
}
//
// Je règle mon panier
#commerce-checkout-form-checkout {
  .table > thead > tr > th {
    border-bottom: 0;
  }
  .panel {
    margin-bottom: 3rem;
    border: none;
    box-shadow: none;
  }
  .panel-heading {
    margin-bottom: 0;
    border: none;
    background-color: transparent;
  }
  .panel-title {
    font-size: 2rem;
  }
  label {
    font-family: $frutiger-bold;
    font-weight: unset;
  }
  input {
    box-shadow: none;
    border-radius: 0;
    border-width: 2px;
  }
  input:focus {
    border-color: $pink;
  }
}
#commerce-checkout-coupon-ajax-wrapper {
  .panel-heading {
    display: none;
  }
  .panel-body {
    max-width: 320px;
    float: right;
  }
  .form-submit {
    float: right;
    padding: .5rem 1.5rem;
  }
}
.form-item-terms-conditions-commerce-agree-terms-pane-field {
  a {
    color: $pink;
  }
}
#edit-commerce-payment {
  @media (min-width: $screen-sm-min) {
    .panel-body {
      padding-left: 3rem;
    }
  }
}
#edit-terms-conditions {
  padding: 3rem;
  text-align: center;
  background-color: #f9f9f9;
}
// Submit
#edit-buttons .panel-body {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-start;
  align-items: center;
  padding-right: 0;
}
.button-operator {
  display: none;
}
.checkout-buttons .checkout-cancel {
  margin-right: 3rem;
  font-family: $frutiger-bold;
  color: $gray-base;
  &:hover {
    color: $pink;
  }
}
//
// Confirrmation commande
#edit-checkout-completion-message {
  text-align: center;
  margin-top: 3rem;
  margin-bottom: 20rem;
  font-size: 1.8rem;
  a {
    color: $pink;
  }
}
/* Mon compte */
.page-mon-compte div.form-item-status, .page-mon-compte div.form-item-roles {
  display: none;
}
.page-mon-compte .account_col1 {
  width: 45%;
  float: left;
  display: inline-block;
  margin-right: 5%;
}
.page-mon-compte .account_col2 {
  width: 45%;
  display: inline-block;
}
.page-mon-compte .stitre {
  color: #8f8c8c;
  font-size: 1.4em;
  text-align: center;
  margin-bottom: 1em;
}
.page-mon-compte #zedl-account-custom-profile-mail-password,
.page-mon-compte .account_newsletter, .page-mon-compte #zedl-account-custom-profile-adresse {
  background-color: #e9e9e9;
  text-align: center;
  padding: $spacer 10px;
  @media (min-width: $screen-sm-min) {
    padding: ($spacer*2);
  }
}
.page-mon-compte .region-content select, .page-mon-compte .region-content input[type="text"], .page-mon-compte .region-content input[type="password"], .page-mon-compte .region-content textarea {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}
.page-mon-compte .region-content .form-type-radios {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0px;
}
.page-mon-compte .region-content input[type="radio"] {
  margin: 0px 20px 0 0px;
}
.page-mon-compte .region-content .form-type-radio {
  display: inline-block;
  margin-right: 20px;
  margin-bottom: 0px;
}
.page-mon-compte .region-content .form-radios {
  text-align: left;
}
.page-mon-compte .region-content .form-submit {
  background-color: #a09d9d;
  border: none;
  padding-left: 5em;
  padding-right: 5em;
  border-radius: 5px;
  color: #fff;
  text-transform: uppercase;
}
.page-mon-compte .region-content .account_newsletter .catpro .form-submit {
  background-color: #a09d9d;
  border: none;
  padding-left: 5em;
  padding-right: 5em;
  border-radius: 5px;
  color: #fff;
  text-transform: uppercase;
}
.page-mon-compte #zedl-account-custom-profile-catpro-centreinteret {
  background-color: #e9e9e9;
  text-align: center;
  padding: 30px;
  display: none;
}
.page-mon-compte #zedl-account-custom-profile-catpro-centreinteret label {
  color: #8f8c8c;
  font-size: 1.4em;
  margin-bottom: 1em;
  font-weight: normal;
  text-transform: uppercase;
  line-height: 1.7em;
  display: none;
}
.page-mon-compte #zedl-account-custom-profile-catpro {
  background-color: #e9e9e9;
  text-align: center;
  padding: 30px;
}
.page-mon-compte #zedl-account-custom-profile-catpro label {
  color: #8f8c8c;
  font-size: 1.4em;
  margin-bottom: 1em;
  font-weight: normal;
  text-transform: uppercase;
  line-height: 1.7em;
}
.page-mon-compte .title-block {
  color: #8f8c8c;
  font-size: 1.4em;
  margin-bottom: 1em;
  font-weight: normal;
  text-transform: uppercase;
}
.page-mon-compte .account_block_librairies {
  background-color: #e9e9e9;
  text-align: center;
  padding: 30px;
  margin-top: 5px;
  display: none;
}
.page-mon-compte .account_block_librairies .form-item-field-librairies-preferees-und label {
  color: #8f8c8c;
  font-size: 1.4em;
  margin-bottom: 1em;
  font-weight: normal;
  text-transform: uppercase;
  display: none;
}
.page-mon-compte #pointeur {
  float: right;
  width: auto;
  margin-top: 35px;
}
.page-mon-compte .account_supp {
  clear: both;
  text-align: center;
  padding-top: 5em;
  margin-bottom: 2em;
}
.page-mon-compte .account_supp .form-submit {
  background-color: #c00418;
  padding: 0 0.5em 0 0.5em;
}
.page-mon-compte .infos-cnil {
  color: #7c7c7c;
  font-size: 0.9em;
  margin-bottom: $spacer;
}
.page-mon-compte .main-container .nav-tabs {
  border: 0;
  margin-bottom: 2em;
}
.page-mon-compte .main-container .nav-tabs > li {
  margin-right: 2em;
  @media (max-width: $screen-sm-max) {
    width: 100% !important;
    margin-bottom: $spacer;
  }
}
.page-mon-compte .main-container .nav-tabs > li a {
  background-color: #e9e9e9;
  color: #b4b2b2;
  padding: 0.3em 1em 0.3em 1em;
  border: 0px;
  line-height: normal;
  border-radius: 5px;
  text-transform: uppercase;
  font-size: 1.2em;
  min-width: 12em;
  text-align: center;
}
.page-mon-compte .main-container .nav-tabs > li.active a {
  background-color: #c00418;
  color: #fff;
}
.page-mon-compte .region-content .account_newsletter {
  text-align: center;
  color: #7c7c7c;
}
.page-mon-compte-titres-favoris .stitre, .page-mon-compte-newsletter .stitre, .page-mon-compte-mes-commandes .stitre {
  border-bottom: 1px solid #c00418;
  padding-bottom: 1em;
  margin-bottom: 2em;
  text-transform: uppercase;
}
.page-mon-compte .region-content .account_newsletter {
  text-align: center;
  color: #7c7c7c;
}
.page-mon-compte .region-content .account_newsletter .form-submit {
  background-color: #c00418;
}
.page-mon-compte .region-content .account_newsletter input[type="checkbox"] {
  margin: 0px 20px 0 0px;
}
.page-mon-compte .region-content .account_newsletter .form-type-checkbox {
  display: inline-block;
  margin-right: 20px;
  margin-bottom: 0px;
}
.page-mon-compte .region-content .account_newsletter .form-checkboxes {
  margin-top: 2em;
  margin-bottom: 1em;
}
.page-mon-compte-titres-favoris .views-row {
  margin-top: 1em;
  padding-bottom: 1em;
}
.page-mon-compte-titres-favoris .sous-menus-favoris {
  margin-bottom: 1em;
}
.page-mon-compte-titres-favoris .views-field-timestamp {
  border-top: 1px solid #7c7c7c;
  border-bottom: 1px solid #7c7c7c;
  color: #7c7c7c;
  font-style: italic;
  padding-top: 0.4em;
  padding-bottom: 0.2em;
  margin-bottom: 1em;
  background: url("../img/account_mini_plus.png") no-repeat 2px;
  padding-left: 2em;
}
.page-mon-compte-titres-favoris .views-field-title {
  font-size: 1.2em;
  font-weight: 600;
  color: #39b6b8;
  float: left;
}
.page-mon-compte-titres-favoris .views-field-ops {
  text-align: right;
}
.page-mon-compte-titres-favoris .views-field-ops a {
  color: #a09d9d;
}
.page-mon-compte-titres-favoris .views-field-field-livre-genre {
  border-top: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
  color: #3774a9;
  padding-top: 0.4em;
  padding-bottom: 0.2em;
  margin-bottom: 1em;
  font-weight: 600;
}
.page-mon-compte-titres-favoris .stitre {
  margin-bottom: 1em;
}
.page-mon-compte-titres-favoris .sous-menus-favoris {
  margin-bottom: 2em;
}
.page-mon-compte-titres-favoris .sous-menus-favoris div {
  text-align: center;
}
.page-mon-compte-titres-favoris .sous-menus-favoris div a {
  color: #7c7c7c;
}
/* Permet de retrouver le lien sur le titre d'ouvrage, sinon, le lien du flag est au dessus */

.page-mon-compte-titres-favoris .views-field-ops span.flag-wrapper {
  position: static;
}
@media (max-width: $screen-sm-max) {
  .field-name-field-couverture {
    img {
      width: 100% !important;
      height: auto;
    }
  }
}
/* Version imprimable */

.page-mon-compte-titres-favoris-print .views-field-title {
  float: none;
}
.page-mon-compte-titres-favoris-print footer > div, .page-mon-compte-titres-favoris-print #admin-menu, .page-mon-compte-titres-favoris-print #skip-link, .page-mon-compte-titres-favoris-print footer + div, .page-mon-compte-titres-favoris-print #backtotop {
  display: none;
}
.page-mon-compte-titres-favoris-print body h1, .page-mon-compte-titres-favoris-print body > div {
  padding: 0.5em;
}
.page-mon-compte-titres-favoris-print body div.main-container {
  max-width: 100%;
  width: 100%;
}
.page-mon-compte-titres-favoris .views-field-timestamp {
  background: none;
  padding-left: 0;
}
/* Page mes commandes */

.page-mon-compte-mes-commandes .ligne_produit {
  overflow: auto;
  clear: both;
  padding: 0px 0px 20px;
  color: #8f8c8c;
  font-size: 15px;
  @media (min-width: $screen-md-min) {
    padding: 0px 40px 20px;
  }
}
.page-mon-compte-mes-commandes .ligne_produit .date {
  font-style: italic;
  color: #7c7c7c;
  border-top: 1px solid #7c7c7c;
  border-bottom: 1px solid #7c7c7c;
  padding: 7px 0;
  margin: 0px 0 15px;
  clear: both;
}
.page-mon-compte-mes-commandes .ligne_produit .ligne {
  clear: both;
}
.page-mon-compte-mes-commandes .ligne_produit .ligne .image img {
  width: 80%;
}
.page-mon-compte-mes-commandes .ligne_produit .ligne .infos .titre {
  color: black;
  font-size: 18px;
  border-bottom: 1px solid #7c7c7c;
  padding: 0 0 7px;
}
.page-mon-compte-mes-commandes .ligne_produit .ligne .infos .collection {
  font-size: 16px;
  border-bottom: 1px solid #7c7c7c;
  padding: 7px 0;
  margin-bottom: 12px;
}
.page-mon-compte-mes-commandes .ligne_produit .ligne .infos .collection .titre_collection {
  color: #00ced6;
  text-transform: uppercase;
  font-weight: bold;
}
.page-mon-compte-mes-commandes .ligne_produit .ligne .infos .description {
  color: black;
}
.page-mon-compte-mes-commandes .ligne_produit .voir_vod {
  margin-top: 20px;
  clear: both;
}