.node-type-page-heros {
    background-color: #f1f1f1;

    h1,
    h2,
    h3 {
        margin: 0;
    }

    .main-container {
        margin: auto;
        padding: 20px 20px 127px;
        max-width: 1440px;
    }

    .banner {
        img {
            object-fit: contain;
            width: 100%;
            height: auto;
        }
    }

    .body-wrapper {
        background-color: #FFFFFF;
        max-width: 100%;
        margin: auto;
        padding: 25px 30px;
        position: relative;
        z-index: 1;
        display: flex;
        flex-direction: column;
        gap: 20px;

        img {
            width: 100%;
        }

        &__category {
            @include font-style($frutiger-roman, 26px, 24px, 0);
            margin: 0;
            text-transform: uppercase;
        }

        &__title {
            @include font-style($frutiger-bold, 40px, 40px, 0);
            margin: 0;
            text-transform: uppercase;
        }

        &__univers {
            @include font-style($frutiger-roman, 20px, 24px, 0);
        }

        &__pre-introduction {
            @include font-style($frutiger-bold, 16px, 20px, 0);
        }

        &__introduction {
            @include font-style($frutiger-roman, 16px, 20px, 0);
        }

        &__section {
            border-top: 1px solid #BDBDBD;
            padding: 33px 0 20px 0;

            h2 {
                @include font-style($frutiger-bold, 26px, 24px, 0);
                text-transform: uppercase
            }

            .subtitle {
                @include font-style($frutiger-roman, 16px, 20px, 0);
                margin-top: 20px;
            }

            .grid-container {
                display: grid;
                grid-template-columns: repeat(auto-fit, minmax(150px, 196px));
                gap: 24px;
                margin-top: 40px;
                justify-content: center;
            }

            .item p {
                text-align: center;
            }

            h3 {
                @include font-style($frutiger-bold, 16px, 20px, 0);
                text-align: center;
                margin-top: 12px;
            }
        }
    }

    .radius-image {
        border-radius: 12px;
    }

    .simon-youtube {
        width: 100%;
        margin-top: 5px;
    }
}

@media (min-width: 576px) {
    .node-type-page-heros {
        .body-wrapper {
            .grid-container {
                justify-content: start;
            }
        }
    }
}

@media (min-width: 768px) {
    .node-type-page-heros {
        .body-wrapper {
            margin: -140px auto 0 auto;
            padding: 63px 77px;
            max-width: calc(100% - 122px);
        }
    }
}

@media (min-width: 1200px) {
    .node-type-page-heros {
        .body-wrapper {
            max-width: 1010px;
        }

        .main-container {
            padding: 60px 20px 127px;
        }
    }
}