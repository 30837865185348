/*page video*/

// #Todo A cleaner
.page-recherche-videos #navbar .header-container .navbar-collapse .nav li a.dropdown-toggle.active-trail:before {
  border-bottom: 8px solid #fff !important;
  bottom: 0;
  color: #fff !important;
  content: "";
  display: block;
  left: 0;
  position: absolute;
  width: 100%;
  z-index: 9999; }
@media (min-width: 767px) {
    .page-recherche-videos #navbar .header-container .navbar-collapse .nav li a.dropdown-toggle.active-trail {
    border: none !important;
    padding: 0 !important;
    color: #ccc4c3 !important; } }
.page-recherche-videos div.breadcrumb {
  background: #b3b3b3;
  margin: 0;
  padding: 10px 0; }
  @media (max-width: 767px) {
      .page-recherche-videos div.breadcrumb {
      display: block;
      padding: 20px 0 20px 20px; } }
.page-recherche-videos div.breadcrumb ol {
    max-width: 1190px;
    width: 100%;
    margin: 0 auto;
    padding: 0;
    background: #b3b3b3; }
    @media (max-width: 767px) {
        .page-recherche-videos div.breadcrumb ol {
        display: block; } }
    @media (min-width: 1024px) and (max-width: 1190px) {
        .page-recherche-videos div.breadcrumb ol {
        max-width: 1024px;
        padding: 0 10px; } }
    @media (min-width: 768px) and (max-width: 1023px) {
        .page-recherche-videos div.breadcrumb ol {
        max-width: 768px;
        padding: 0 10px; } }
.page-recherche-videos div.breadcrumb ol li, .page-recherche-videos div.breadcrumb ol a {
      color: #535353;
      font-family: "FrutigerLTStd-Roman";
      font-size: 13px;
      line-height: 13px;
      letter-spacing: 0px;
      text-transform: uppercase; }
      @media (max-width: 767px) {
          .page-recherche-videos div.breadcrumb ol li, .page-recherche-videos div.breadcrumb ol a {
          color: #000;
          font-family: "FrutigerLTStd-Light";
          font-size: 38px;
          line-height: 38px;
          letter-spacing: 0px; }
          .page-recherche-videos div.breadcrumb ol li.first, .page-recherche-videos div.breadcrumb ol a.first {
            display: none; } }
.page-recherche-videos div.breadcrumb ol li a, .page-recherche-videos div.breadcrumb ol a a {
        background: transparent url("../img/arrow-breadcrumb-video.png") no-repeat scroll 97% 2px; }
.page-recherche-videos .region-content {
  background: #000; }
.page-recherche-videos .region-content #block-system-main, .page-recherche-videos .region-content #block-views-recherche-video-block-1, .page-recherche-videos .region-content #block-views-recherche-video-block-2 {
    max-width: 1088px;
    width: 100%;
    margin: 0 auto;
    padding: 0; }
    @media (min-width: 1024px) and (max-width: 1190px) {
        .page-recherche-videos .region-content #block-system-main, .page-recherche-videos .region-content #block-views-recherche-video-block-1, .page-recherche-videos .region-content #block-views-recherche-video-block-2 {
        max-width: 1020px; } }
    @media (min-width: 768px) and (max-width: 1023px) {
        .page-recherche-videos .region-content #block-system-main, .page-recherche-videos .region-content #block-views-recherche-video-block-1, .page-recherche-videos .region-content #block-views-recherche-video-block-2 {
        max-width: 725px; } }
    @media (max-width: 767px) {
        .page-recherche-videos .region-content #block-system-main, .page-recherche-videos .region-content #block-views-recherche-video-block-1, .page-recherche-videos .region-content #block-views-recherche-video-block-2 {
        max-width: 100%; } }
.page-recherche-videos .region-content #block-system-main .view-filters, .page-recherche-videos .region-content #block-views-recherche-video-block-1 .view-filters, .page-recherche-videos .region-content #block-views-recherche-video-block-2 .view-filters {
      margin: 55px auto 45px;
      text-align: center;
      width: 412px; }
      @media (max-width: 767px) {
          .page-recherche-videos .region-content #block-system-main .view-filters, .page-recherche-videos .region-content #block-views-recherche-video-block-1 .view-filters, .page-recherche-videos .region-content #block-views-recherche-video-block-2 .view-filters {
          width: 90%;
          margin: 30px auto 50px; } }
.page-recherche-videos .region-content #block-system-main .view-filters .filter-title, .page-recherche-videos .region-content #block-views-recherche-video-block-1 .view-filters .filter-title, .page-recherche-videos .region-content #block-views-recherche-video-block-2 .view-filters .filter-title {
        color: #fff;
        text-transform: uppercase;
        font-family: "FrutigerLTStd-Light";
        font-size: 22px;
        line-height: 22px;
        letter-spacing: 0px;
        margin: 0 0 15px 0; }
        @media (max-width: 767px) {
            .page-recherche-videos .region-content #block-system-main .view-filters .filter-title, .page-recherche-videos .region-content #block-views-recherche-video-block-1 .view-filters .filter-title, .page-recherche-videos .region-content #block-views-recherche-video-block-2 .view-filters .filter-title {
            display: none; } }
.page-recherche-videos .region-content #block-system-main .view-filters .views-exposed-widgets, .page-recherche-videos .region-content #block-views-recherche-video-block-1 .view-filters .views-exposed-widgets, .page-recherche-videos .region-content #block-views-recherche-video-block-2 .view-filters .views-exposed-widgets {
        margin: 0; }
.page-recherche-videos .region-content #block-system-main .view-filters .views-exposed-widgets #edit-titre-video-wrapper, .page-recherche-videos .region-content #block-views-recherche-video-block-1 .view-filters .views-exposed-widgets #edit-titre-video-wrapper, .page-recherche-videos .region-content #block-views-recherche-video-block-2 .view-filters .views-exposed-widgets #edit-titre-video-wrapper {
          padding: 0;
          float: left; }
          @media (max-width: 767px) {
              .page-recherche-videos .region-content #block-system-main .view-filters .views-exposed-widgets #edit-titre-video-wrapper, .page-recherche-videos .region-content #block-views-recherche-video-block-1 .view-filters .views-exposed-widgets #edit-titre-video-wrapper, .page-recherche-videos .region-content #block-views-recherche-video-block-2 .view-filters .views-exposed-widgets #edit-titre-video-wrapper {
              width: 85%; } }
.page-recherche-videos .region-content #block-system-main .view-filters .views-exposed-widgets #edit-titre-video-wrapper .form-type-textfield input::-webkit-input-placeholder, .page-recherche-videos .region-content #block-views-recherche-video-block-1 .view-filters .views-exposed-widgets #edit-titre-video-wrapper .form-type-textfield input::-webkit-input-placeholder, .page-recherche-videos .region-content #block-views-recherche-video-block-2 .view-filters .views-exposed-widgets #edit-titre-video-wrapper .form-type-textfield input::-webkit-input-placeholder {
            font-family: "FrutigerLTStd-Light" !important;
            color: #a09d9d !important;
            font-size: 16px !important;
            opacity: 1 !important; }
.page-recherche-videos .region-content #block-system-main .view-filters .views-exposed-widgets #edit-titre-video-wrapper .form-type-textfield input:-moz-placeholder, .page-recherche-videos .region-content #block-views-recherche-video-block-1 .view-filters .views-exposed-widgets #edit-titre-video-wrapper .form-type-textfield input:-moz-placeholder, .page-recherche-videos .region-content #block-views-recherche-video-block-2 .view-filters .views-exposed-widgets #edit-titre-video-wrapper .form-type-textfield input:-moz-placeholder {
            font-family: "FrutigerLTStd-Light" !important;
            color: #a09d9d !important;
            font-size: 16px !important;
            opacity: 1 !important; }
.page-recherche-videos .region-content #block-system-main .view-filters .views-exposed-widgets #edit-titre-video-wrapper .form-type-textfield input:-ms-input-placeholder, .page-recherche-videos .region-content #block-views-recherche-video-block-1 .view-filters .views-exposed-widgets #edit-titre-video-wrapper .form-type-textfield input:-ms-input-placeholder, .page-recherche-videos .region-content #block-views-recherche-video-block-2 .view-filters .views-exposed-widgets #edit-titre-video-wrapper .form-type-textfield input:-ms-input-placeholder {
            font-family: "FrutigerLTStd-Light" !important;
            color: #a09d9d !important;
            font-size: 16px !important;
            opacity: 1 !important; }
.page-recherche-videos .region-content #block-system-main .view-filters .views-exposed-widgets #edit-titre-video-wrapper .form-type-textfield input::-moz-placeholder, .page-recherche-videos .region-content #block-views-recherche-video-block-1 .view-filters .views-exposed-widgets #edit-titre-video-wrapper .form-type-textfield input::-moz-placeholder, .page-recherche-videos .region-content #block-views-recherche-video-block-2 .view-filters .views-exposed-widgets #edit-titre-video-wrapper .form-type-textfield input::-moz-placeholder {
            font-family: "FrutigerLTStd-Light" !important;
            color: #a09d9d !important;
            font-size: 16px !important;
            opacity: 1 !important; }
.page-recherche-videos .region-content #block-system-main .view-filters .views-exposed-widgets #edit-titre-video-wrapper .form-type-textfield input, .page-recherche-videos .region-content #block-views-recherche-video-block-1 .view-filters .views-exposed-widgets #edit-titre-video-wrapper .form-type-textfield input, .page-recherche-videos .region-content #block-views-recherche-video-block-2 .view-filters .views-exposed-widgets #edit-titre-video-wrapper .form-type-textfield input {
            background: #fff;
            background-color: none;
            box-shadow: none;
            border: none;
            border-radius: 0;
            color: #a09d9d;
            -webkit-border-radius: 5px;
            -moz-border-radius: 5px;
            border-radius: 5px;
            -o-border-radius: 5px;
            width: 370px;
            height: 40px;
            font-family: "FrutigerLTStd-Light";
            font-size: 16px;
            line-height: 40px;
            letter-spacing: 0px;
            font-style: italic; }
            @media (max-width: 767px) {
                .page-recherche-videos .region-content #block-system-main .view-filters .views-exposed-widgets #edit-titre-video-wrapper .form-type-textfield input, .page-recherche-videos .region-content #block-views-recherche-video-block-1 .view-filters .views-exposed-widgets #edit-titre-video-wrapper .form-type-textfield input, .page-recherche-videos .region-content #block-views-recherche-video-block-2 .view-filters .views-exposed-widgets #edit-titre-video-wrapper .form-type-textfield input {
                -webkit-border-radius: 50px;
                -moz-border-radius: 50px;
                border-radius: 50px;
                -o-border-radius: 50px;
                width: 100%; } }
.page-recherche-videos .region-content #block-system-main .view-filters .views-exposed-widgets .views-submit-button, .page-recherche-videos .region-content #block-views-recherche-video-block-1 .view-filters .views-exposed-widgets .views-submit-button, .page-recherche-videos .region-content #block-views-recherche-video-block-2 .view-filters .views-exposed-widgets .views-submit-button {
          float: right;
          padding: 0; }
.page-recherche-videos .region-content #block-system-main .view-filters .views-exposed-widgets .views-submit-button button, .page-recherche-videos .region-content #block-views-recherche-video-block-1 .view-filters .views-exposed-widgets .views-submit-button button, .page-recherche-videos .region-content #block-views-recherche-video-block-2 .view-filters .views-exposed-widgets .views-submit-button button {
            display: inline-block;
            width: 32px;
            height: 32px;
            background: url("../img/main-loupe.png") no-repeat 0 0 transparent;
            cursor: pointer;
            margin: 5px 0 0 0;
            border: none;
            text-indent: -9999px; }
.page-recherche-videos .region-content #block-system-main .view-content, .page-recherche-videos .region-content #block-views-recherche-video-block-1 .view-content, .page-recherche-videos .region-content #block-views-recherche-video-block-2 .view-content {
      padding: 0;
      margin: 0 0 40px 0;
      max-width: 100%;
      position: relative; }
      @media (max-width: 767px) {
          .page-recherche-videos .region-content #block-system-main .view-content, .page-recherche-videos .region-content #block-views-recherche-video-block-1 .view-content, .page-recherche-videos .region-content #block-views-recherche-video-block-2 .view-content {
          padding: 0 20px; } }
.page-recherche-videos .region-content #block-system-main .view-content:after, .page-recherche-videos .region-content #block-views-recherche-video-block-1 .view-content:after, .page-recherche-videos .region-content #block-views-recherche-video-block-2 .view-content:after {
        content: ".";
        display: block;
        clear: both;
        visibility: hidden;
        line-height: 0;
        height: 0; }
.page-recherche-videos .region-content #block-system-main .view-content .liste-video-wrapper, .page-recherche-videos .region-content #block-views-recherche-video-block-1 .view-content .liste-video-wrapper, .page-recherche-videos .region-content #block-views-recherche-video-block-2 .view-content .liste-video-wrapper {
        position: relative;
        float: left;
        margin: 0 6px 6px 0; }
        @media (max-width: 767px) {
            .page-recherche-videos .region-content #block-system-main .view-content .liste-video-wrapper, .page-recherche-videos .region-content #block-views-recherche-video-block-1 .view-content .liste-video-wrapper, .page-recherche-videos .region-content #block-views-recherche-video-block-2 .view-content .liste-video-wrapper {
            margin: 0 0 20px;
            float: none; } }
        @media (min-width: 768px) and (max-width: 1023px) {
            .page-recherche-videos .region-content #block-system-main .view-content .liste-video-wrappe.page-recherche-videosdeo .region-content #block-views-recherche-video-block-1 .view-content .liste-video-wrapper, .page-recherche-videos .region-content #block-views-recherche-video-block-2 .view-content .liste-video-wrapper {
            width: 32%; } }
.page-recherche-videos .region-content #block-system-main .view-content .liste-video-wrapper .picto-share, .page-recherche-videos .region-content #block-views-recherche-video-block-1 .view-content .liste-video-wrapper .picto-share, .page-recherche-videos .region-content #block-views-recherche-video-block-2 .view-content .liste-video-wrapper .picto-share {
          display: none;
          position: absolute;
          top: 15px;
          left: 20px;
          width: 25px;
          height: 39px;
          text-indent: -9999px;
          z-index: 10;
          -webkit-transition: opacity 0.4s ease 0s;
          -moz-transition: opacity 0.4s ease 0s;
          -o-transition: opacity 0.4s ease 0s;
          -ms-transition: opacity 0.4s ease 0s;
          transition: opacity 0.4s ease 0s;
          background: url("../img/icon-partager-video.png") no-repeat 0 0 transparent; }
.page-recherche-videos .region-content #block-system-main .view-content .liste-video-wrapper .lien-partage, .page-recherche-videos .region-content #block-views-recherche-video-block-1 .view-content .liste-video-wrapper .lien-partage, .page-recherche-videos .region-content #block-views-recherche-video-block-2 .view-content .liste-video-wrapper .lien-partage {
          position: absolute;
          z-index: 10;
          left: -37px;
          top: -31px;
          background: #e9e9e9;
          padding: 1px 14px;
          -webkit-border-radius: 5px;
          -moz-border-radius: 5px;
          border-radius: 5px;
          -o-border-radius: 5px; }
          @media (max-width: 767px) {
              .page-recherche-videos .region-content #block-system-main .view-content .liste-video-wrapper .lien-partag.page-recherche-videosdeo .region-content #block-views-recherche-video-block-1 .view-content .liste-video-wrapper .lien-partage, .page-recherche-videos .region-content #block-views-recherche-video-block-2 .view-content .liste-video-wrapper .lien-partage {
              left: 0;
              width: 100%;
              border-radius: 0;
              padding: 15px;
              top: -67px; } }
.page-recherche-videos .region-content #block-system-main .view-content .liste-video-wrapper .lien-partage:after, .page-recherche-videos .region-content #block-views-recherche-video-block-1 .view-content .liste-video-wrapper .lien-partage:after, .page-recherche-videos .region-content #block-views-recherche-video-block-2 .view-content .liste-video-wrapper .lien-partage:after {
            width: 0;
            height: 0;
            border-left: 12px solid transparent;
            border-right: 12px solid transparent;
            border-top: 12px solid #e9e9e9;
            position: absolute;
            content: "";
            bottom: -8px;
            left: 39%; }
            @media (max-width: 767px) {
                .page-recherche-videos .region-content #block-system-main .view-content .liste-video-wrapper .lien-partage:after, .page-recherche-videos .region-content #block-views-recherche-video-block-1 .view-content .liste-video-wrapper .lien-partage:after, .page-recherche-videos .region-content #block-views-recherche-video-block-2 .view-content .liste-video-wrapper .lien-partage:after {
                left: 19px; } }
.page-recherche-videos .region-content #block-system-main .view-content .liste-video-wrapper .lien-partage .texte-partage, .page-recherche-videos .region-content #block-views-recherche-video-block-1 .view-content .liste-video-wrapper .lien-partage .texte-partage, .page-recherche-videos .region-content #block-views-recherche-video-block-2 .view-content .liste-video-wrapper .lien-partage .texte-partage {
            float: left;
            margin: 0 9px 0 0;
            font-family: "FrutigerLTStd-Light";
            font-size: 14px;
            line-height: 23px;
            letter-spacing: 0px;
            color: black; }
            @media (max-width: 767px) {
                .page-recherche-videos .region-content #block-system-main .view-content .liste-video-wrapper .lien-partage .texte-partage, .page-recherche-videos .region-content #block-views-recherche-video-block-1 .view-content .liste-video-wrapper .lien-partage .texte-partage, .page-recherche-videos .region-content #block-views-recherche-video-block-2 .view-content .liste-video-wrapper .lien-partage .texte-partage {
                font-family: "FrutigerLTStd-Light";
                font-size: 23px;
                line-height: 36px;
                letter-spacing: 0px;
                margin: 0 20px 0 0; } }
.page-recherche-videos .region-content #block-system-main .view-content .liste-video-wrapper .lien-partage .addthis_sharing_toolbox, .page-recherche-videos .region-content #block-views-recherche-video-block-1 .view-content .liste-video-wrapper .lien-partage .addthis_sharing_toolbox, .page-recherche-videos .region-content #block-views-recherche-video-block-2 .view-content .liste-video-wrapper .lien-partage .addthis_sharing_toolbox {
            float: left; }
.page-recherche-videos .region-content #block-system-main .view-content .liste-video-wrapper .lien-partage .addthis_sharing_toolbox .addthis_button_facebook, .page-recherche-videos .region-content #block-views-recherche-video-block-1 .view-content .liste-video-wrapper .lien-partage .addthis_sharing_toolbox .addthis_button_facebook, .page-recherche-videos .region-content #block-views-recherche-video-block-2 .view-content .liste-video-wrapper .lien-partage .addthis_sharing_toolbox .addthis_button_facebook {
              max-width: 9px;
              max-height: 23px;
              margin: 0 7px 0 0;
              background: transparent !important;
              float: left; }
              @media (max-width: 767px) {
                  .page-recherche-videos .region-content #block-system-main .view-content .liste-video-wrapper .lien-partage .addthis_sharing_toolbox .addthis_button_facebook, .page-recherche-videos .region-content #block-views-recherche-video-block-1 .view-content .liste-video-wrapper .lien-partage .addthis_sharing_toolbox .addthis_button_facebook, .page-recherche-videos .region-content #block-views-recherche-video-block-2 .view-content .liste-video-wrapper .lien-partage .addthis_sharing_toolbox .addthis_button_facebook {
                  max-width: 20px; } }
.page-recherche-videos .region-content #block-system-main .view-content .liste-video-wrapper .lien-partage .addthis_sharing_toolbox .addthis_button_facebook span, .page-recherche-videos .region-content #block-views-recherche-video-block-1 .view-content .liste-video-wrapper .lien-partage .addthis_sharing_toolbox .addthis_button_facebook span, .page-recherche-videos .region-content #block-views-recherche-video-block-2 .view-content .liste-video-wrapper .lien-partage .addthis_sharing_toolbox .addthis_button_facebook span {
                background-repeat: no-repeat;
                background-image: url("../img/social-icon-partager.png");
                background-position: 0 0;
                background-color: transparent !important;
                width: 9px;
                height: 23px;
                background-size: auto auto !important; }
                @media (max-width: 767px) {
                    .page-recherche-videos .region-content #block-system-main .view-content .liste-video-wrapper .lien-partage .addthis_sharing_toolbox .addthis_button_facebook span, .page-recherche-videos .region-content #block-views-recherche-video-block-1 .view-content .liste-video-wrapper .lien-partage .addthis_sharing_toolbox .addthis_button_facebook span,.page-recherche-videoso .region-content #block-views-recherche-video-block-2 .view-content .liste-video-wrapper .lien-partage .addthis_sharing_toolbox .addthis_button_facebook span {
                    width: 17px;
                    height: 35px;
                    background-image: url("../img/social-icon-partager-mobile.png");
                    background-position: 0 0;
                    margin: 0 20px 0 0; } }
.page-recherche-videos .region-content #block-system-main .view-content .liste-video-wrapper .lien-partage .addthis_sharing_toolbox .addthis_button_twitter, .page-recherche-videos .region-content #block-views-recherche-video-block-1 .view-content .liste-video-wrapper .lien-partage .addthis_sharing_toolbox .addthis_button_twitter, .page-recherche-videos .region-content #block-views-recherche-video-block-2 .view-content .liste-video-wrapper .lien-partage .addthis_sharing_toolbox .addthis_button_twitter {
              max-width: 23px;
              max-height: 23px;
              margin: 0;
              background: transparent !important;
              float: left; }
              @media (max-width: 767px) {
                  .page-recherche-videos .region-content #block-system-main .view-content .liste-video-wrapper .lien-partage .addthis_sharing_toolbox .addthis_button_twitte.page-recherche-videosdeo .region-content #block-views-recherche-video-block-1 .view-content .liste-video-wrapper .lien-partage .addthis_sharing_toolbox .addthis_button_twitter, .page-recherche-videos .region-content #block-views-recherche-video-block-2 .view-content .liste-video-wrapper .lien-partage .addthis_sharing_toolbox .addthis_button_twitter {
                  max-width: 40px; } }
.page-recherche-videos .region-content #block-system-main .view-content .liste-video-wrapper .lien-partage .addthis_sharing_toolbox .addthis_button_twitter span, .page-recherc.page-recherche-videosent #block-views-recherche-video-block-1 .view-content .liste-video-wrapper .lien-partage .addthis_sharing_toolbox .addthis_button_twitter span, .page-recherche-videos .region-content #block-views-recherche-video-block-2 .view-content .liste-video-wrapper .lien-partage .addthis_sharing_toolbox .addthis_button_twitter span {
                margin: 0;
                background-repeat: no-repeat;
                background-image: url("../img/social-icon-partager.png");
                background-position: -18px 0;
                background-color: transparent !important;
                width: 23px;
                height: 23px;
                background-size: auto auto !important; }
                @media (max-width: 767px) {
                    .page-recherche-videos .region-content #block-system-main .view-content .liste-video-wrapper .lien-partage .addthis_sharing_toolbox .addthis_button_twitter spa.page-recherche-videosdeo .region-content #block-views-recherche-video-block-1 .view-content .liste-video-wrapper .lien-partage .addthis_sharing_toolbox .addthis_button_twitter span, .page-recherche-videos .region-content #block-views-recherche-video-block-2 .view-content .liste-video-wrapper .lien-partage .addthis_sharing_toolbox .addthis_button_twitter span {
                    width: 31px;
                    height: 35px;
                    background-image: url("../img/social-icon-partager-mobile.png");
                    background-position: -58px 0;
                    margin: 0 20px 0 0; } }
.page-recherche-videos .region-content #block-system-main .view-content .liste-video-wrapper .lien-partage .addthis_sharing_toolbox .addthis_button_email, .page-recherche-videos .region-content #block-views-recherche-video-block-1 .view-content .liste-video-wrapper .lien-partage .addthis_sharing_toolbox .addthis_button_email, .page-recherche-videos .region-content #block-views-recherche-video-block-2 .view-content .liste-video-wrapper .lien-partage .addthis_sharing_toolbox .addthis_button_email {
              max-width: 21px;
              max-height: 23px;
              background: transparent !important;
              float: left; }
.page-recherche-videos .region-content #block-system-main .view-content .liste-video-wrapper .lien-partage .addthis_sharing_toolbox .addthis_button_email span, .page-recherc.page-recherche-videosent #block-views-recherche-video-block-1 .view-content .liste-video-wrapper .lien-partage .addthis_sharing_toolbox .addthis_button_email span, .page-recherche-videos .region-content #block-views-recherche-video-block-2 .view-content .liste-video-wrapper .lien-partage .addthis_sharing_toolbox .addthis_button_email span {
                background-repeat: no-repeat;
                background-image: url("../img/social-icon-partager.png");
                background-position: -44px 0;
                background-size: auto auto !important;
                background-color: transparent !important;
                width: 21px;
                height: 23px; }
                @media (max-width: 767px) {
                  .page-recherche-videos .region-content #block-system-main .view-content .liste-video-wrapper .lien-partage .addthis_sharing_toolbox .addthis_button_email span, .page-recherche-videos .region-content #block-views-recherche-video-block-1 .view-content .liste-video-wrapper .lien-partage .addthis_sharing_toolbox .addthis_button_email span, .page-recherche-videos .region-content #block-views-recherche-video-block-2 .view-content .liste-video-wrapper .lien-partage .addthis_sharing_toolbox .addthis_button_email span {
                    width: 30px;
                    height: 35px;
                    background-image: url("../img/social-icon-partager-mobile.png");
                    background-position: -130px 0; } }
        .page-recherche-videos .region-content #block-system-main .view-content .liste-video-wrapper.last, .page-recherche-videos .region-content #block-views-recherche-video-block-1 .view-content .liste-video-wrapper.last, .page-recherche-videos .region-content #block-views-recherche-video-block-2 .view-content .liste-video-wrapper.last {
          margin: 0 0 6px 0; }
          @media (min-width: 768px) and (max-width: 1023px) {
            .page-recherche-videos .region-content #block-system-main .view-content .liste-video-wrapper.last, .page-recherche-videos .region-content #block-views-recherche-video-block-1 .view-content .liste-video-wrapper.last, .page-recherche-videos .region-content #block-views-recherche-video-block-2 .view-content .liste-video-wrapper.last {
              margin: 0 6px 6px 0; } }
        .page-recherche-videos .region-content #block-system-main .view-content .liste-video-wrapper:hover .first_wrapper .hover-video, .page-recherche-videos .region-content #block-views-recherche-video-block-1 .view-content .liste-video-wrapper:hover .first_wrapper .hover-video, .page-recherche-videos .region-content #block-views-recherche-video-block-2 .view-content .liste-video-wrapper:hover .first_wrapper .hover-video {
          opacity: 1 !important;
          -moz-opacity: 1 !important;
          -khtml-opacity: 1 !important;
          -ms-filter: alpha(opacity=100);
          filter: alpha(opacity=100);
          -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(opacity=100)"; }
        .page-recherche-videos .region-content #block-system-main .view-content .liste-video-wrapper.active-picto-share .picto-share, .page-recherche-videos .region-content #block-views-recherche-video-block-1 .view-content .liste-video-wrapper.active-picto-share .picto-share, .page-recherche-videos .region-content #block-views-recherche-video-block-2 .view-content .liste-video-wrapper.active-picto-share .picto-share {
          display: block;
          opacity: 0 !important;
          -moz-opacity: 0 !important;
          -khtml-opacity: 0 !important;
          -ms-filter: alpha(opacity=0);
          filter: alpha(opacity=0);
          -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(opacity=0)"; }
          .page-recherche-videos .region-content #block-system-main .view-content .liste-video-wrapper.active-picto-share .picto-share.ouvert, .page-recherche-videos .region-content #block-views-recherche-video-block-1 .view-content .liste-video-wrapper.active-picto-share .picto-share.ouvert, .page-recherche-videos .region-content #block-views-recherche-video-block-2 .view-content .liste-video-wrapper.active-picto-share .picto-share.ouvert {
            opacity: 1 !important;
            -moz-opacity: 1 !important;
            -khtml-opacity: 1 !important;
            -ms-filter: alpha(opacity=100);
            filter: alpha(opacity=100);
            -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(opacity=100)"; }
        .page-recherche-videos .region-content #block-system-main .view-content .liste-video-wrapper.active-picto-share:hover .picto-share, .page-recherche-videos .region-content #block-views-recherche-video-block-1 .view-content .liste-video-wrapper.active-picto-share:hover .picto-share, .page-recherche-videos .region-content #block-views-recherche-video-block-2 .view-content .liste-video-wrapper.active-picto-share:hover .picto-share {
          opacity: 1 !important;
          -moz-opacity: 1 !important;
          -khtml-opacity: 1 !important;
          -ms-filter: alpha(opacity=100);
          filter: alpha(opacity=100);
          -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(opacity=100)"; }
        .page-recherche-videos .region-content #block-system-main .view-content .liste-video-wrapper .first_wrapper, .page-recherche-videos .region-content #block-views-recherche-video-block-1 .view-content .liste-video-wrapper .first_wrapper, .page-recherche-videos .region-content #block-views-recherche-video-block-2 .view-content .liste-video-wrapper .first_wrapper {
          position: relative;
          z-index: 3; }
          @media (min-width: 1024px) and (max-width: 1190px) {
            .page-recherche-videos .region-content #block-system-main .view-content .liste-video-wrapper .first_wrapper .thumb img, .page-recherche-videos .region-content #block-views-recherche-video-block-1 .view-content .liste-video-wrapper .first_wrapper .thumb img, .page-recherche-videos .region-content #block-views-recherche-video-block-2 .view-content .liste-video-wrapper .first_wrapper .thumb img {
              width: 335px; } }
          @media (min-width: 768px) and (max-width: 1023px) {
            .page-recherche-videos .region-content #block-system-main .view-content .liste-video-wrapper .first_wrapper .thumb img, .page-recherche-videos .region-content #block-views-recherche-video-block-1 .view-content .liste-video-wrapper .first_wrapper .thumb img, .page-recherche-videos .region-content #block-views-recherche-video-block-2 .view-content .liste-video-wrapper .first_wrapper .thumb img {
              width: 100%;
              height: 175px; } }
          @media (max-width: 767px) {
            .page-recherche-videos .region-content #block-system-main .view-content .liste-video-wrapper .first_wrapper .thumb img, .page-recherche-videos .region-content #block-views-recherche-video-block-1 .view-content .liste-video-wrapper .first_wrapper .thumb img, .page-recherche-videos .region-content #block-views-recherche-video-block-2 .view-content .liste-video-wrapper .first_wrapper .thumb img {
              width: 100%;
              height: auto; } }
          .page-recherche-videos .region-content #block-system-main .view-content .liste-video-wrapper .first_wrapper .hover-video, .page-recherche-videos .region-content #block-views-recherche-video-block-1 .view-content .liste-video-wrapper .first_wrapper .hover-video, .page-recherche-videos .region-content #block-views-recherche-video-block-2 .view-content .liste-video-wrapper .first_wrapper .hover-video {
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
            opacity: 0 !important;
            -moz-opacity: 0 !important;
            -khtml-opacity: 0 !important;
            -ms-filter: alpha(opacity=0);
            filter: alpha(opacity=0);
            -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(opacity=0)";
            -webkit-transition: all 0.3s ease 0s;
            -moz-transition: all 0.3s ease 0s;
            -o-transition: all 0.3s ease 0s;
            -ms-transition: all 0.3s ease 0s;
            transition: all 0.3s ease 0s;
            width: 100%;
            z-index: 1;
            background: url("../img/video-overlay-hover.png") repeat 0 0 transparent; }
            .page-recherche-videos .region-content #block-system-main .view-content .liste-video-wrapper .first_wrapper .hover-video a.lien-video, .page-recherche-videos .region-content #block-views-recherche-video-block-1 .view-content .liste-video-wrapper .first_wrapper .hover-video a.lien-video, .page-recherche-videos .region-content #block-views-recherche-video-block-2 .view-content .liste-video-wrapper .first_wrapper .hover-video a.lien-video {
              display: block;
              width: 100%;
              height: 100%; }
              .page-recherche-videos .region-content #block-system-main .view-content .liste-video-wrapper .first_wrapper .hover-video a.lien-video .hover-video-wrapper, .page-recherche-videos .region-content #block-views-recherche-video-block-1 .view-content .liste-video-wrapper .first_wrapper .hover-video a.lien-video .hover-video-wrapper, .page-recherche-videos .region-content #block-views-recherche-video-block-2 .view-content .liste-video-wrapper .first_wrapper .hover-video a.lien-video .hover-video-wrapper {
                display: table;
                padding: 0 15px 20px 25px;
                width: 100%;
                height: 205px; }
                @media (max-width: 767px) {
                  .page-recherche-videos .region-content #block-system-main .view-content .liste-video-wrapper .first_wrapper .hover-video a.lien-video .hover-video-wrapper, .page-recherche-videos .region-content #block-views-recherche-video-block-1 .view-content .liste-video-wrapper .first_wrapper .hover-video a.lien-video .hover-video-wrapper, .page-recherche-videos .region-content #block-views-recherche-video-block-2 .view-content .liste-video-wrapper .first_wrapper .hover-video a.lien-video .hover-video-wrapper {
                    display: block;
                    height: 100%;
                    position: relative;
                    width: 100%; } }
                @media (min-width: 768px) and (max-width: 1023px) {
                  .page-recherche-videos .region-content #block-system-main .view-content .liste-video-wrapper .first_wrapper .hover-video a.lien-video .hover-video-wrapper, .page-recherche-videos .region-content #block-views-recherche-video-block-1 .view-content .liste-video-wrapper .first_wrapper .hover-video a.lien-video .hover-video-wrapper, .page-recherche-videos .region-content #block-views-recherche-video-block-2 .view-content .liste-video-wrapper .first_wrapper .hover-video a.lien-video .hover-video-wrapper {
                    height: 177px; } }
                @media (min-width: 1024px) and (max-width: 1190px) {
                  .page-recherche-videos .region-content #block-system-main .view-content .liste-video-wrapper .first_wrapper .hover-video a.lien-video .hover-video-wrapper, .page-recherche-videos .region-content #block-views-recherche-video-block-1 .view-content .liste-video-wrapper .first_wrapper .hover-video a.lien-video .hover-video-wrapper, .page-recherche-videos .region-content #block-views-recherche-video-block-2 .view-content .liste-video-wrapper .first_wrapper .hover-video a.lien-video .hover-video-wrapper {
                    height: 200px; } }
                .page-recherche-videos .region-content #block-system-main .view-content .liste-video-wrapper .first_wrapper .hover-video a.lien-video .hover-video-wrapper .hover-video-inner, .page-recherche-videos .region-content #block-views-recherche-video-block-1 .view-content .liste-video-wrapper .first_wrapper .hover-video a.lien-video .hover-video-wrapper .hover-video-inner, .page-recherche-videos .region-content #block-views-recherche-video-block-2 .view-content .liste-video-wrapper .first_wrapper .hover-video a.lien-video .hover-video-wrapper .hover-video-inner {
                  vertical-align: bottom;
                  display: table-cell; }
                  @media (max-width: 767px) {
                    .page-recherche-videos .region-content #block-system-main .view-content .liste-video-wrapper .first_wrapper .hover-video a.lien-video .hover-video-wrapper .hover-video-inner, .page-recherche-videos .region-content #block-views-recherche-video-block-1 .view-content .liste-video-wrapper .first_wrapper .hover-video a.lien-video .hover-video-wrapper .hover-video-inner, .page-recherche-videos .region-content #block-views-recherche-video-block-2 .view-content .liste-video-wrapper .first_wrapper .hover-video a.lien-video .hover-video-wrapper .hover-video-inner {
                      display: block;
                      height: 100%;
                      left: 20px;
                      position: absolute;
                      top: 60%;
                      vertical-align: bottom;
                      width: 90%; } }
                  .page-recherche-videos .region-content #block-system-main .view-content .liste-video-wrapper .first_wrapper .hover-video a.lien-video .hover-video-wrapper .hover-video-inner .title, .page-recherche-videos .region-content #block-views-recherche-video-block-1 .view-content .liste-video-wrapper .first_wrapper .hover-video a.lien-video .hover-video-wrapper .hover-video-inner .title, .page-recherche-videos .region-content #block-views-recherche-video-block-2 .view-content .liste-video-wrapper .first_wrapper .hover-video a.lien-video .hover-video-wrapper .hover-video-inner .title {
                    float: left;
                    color: #fff;
                    font-family: "FrutigerLTStd-Roman";
                    font-size: 14px;
                    line-height: 16px;
                    letter-spacing: 0px;
                    width: 80%;
                    margin: 5px 0 0 0; }
                    @media (max-width: 767px) {
                      .page-recherche-videos .region-content #block-system-main .view-content .liste-video-wrapper .first_wrapper .hover-video a.lien-video .hover-video-wrapper .hover-video-inner .title, .page-recherche-videos .region-content #block-views-recherche-video-block-1 .view-content .liste-video-wrapper .first_wrapper .hover-video a.lien-video .hover-video-wrapper .hover-video-inner .title, .page-recherche-videos .region-content #block-views-recherche-video-block-2 .view-content .liste-video-wrapper .first_wrapper .hover-video a.lien-video .hover-video-wrapper .hover-video-inner .title {
                        width: 75%;
                        font-family: "FrutigerLTStd-Roman";
                        font-size: 28px;
                        line-height: 30px;
                        letter-spacing: 0px; } }
                    @media (min-width: 768px) and (max-width: 1023px) {
                      .page-recherche-videos .region-content #block-system-main .view-content .liste-video-wrapper .first_wrapper .hover-video a.lien-video .hover-video-wrapper .hover-video-inner .title, .page-recherche-videos .region-content #block-views-recherche-video-block-1 .view-content .liste-video-wrapper .first_wrapper .hover-video a.lien-video .hover-video-wrapper .hover-video-inner .title, .page-recherche-videos .region-content #block-views-recherche-video-block-2 .view-content .liste-video-wrapper .first_wrapper .hover-video a.lien-video .hover-video-wrapper .hover-video-inner .title {
                        width: 68%; } }
                    @media (max-width: 479px) {
                      .page-recherche-videos .region-content #block-system-main .view-content .liste-video-wrapper .first_wrapper .hover-video a.lien-video .hover-video-wrapper .hover-video-inner .title, .page-recherche-videos .region-content #block-views-recherche-video-block-1 .view-content .liste-video-wrapper .first_wrapper .hover-video a.lien-video .hover-video-wrapper .hover-video-inner .title, .page-recherche-videos .region-content #block-views-recherche-video-block-2 .view-content .liste-video-wrapper .first_wrapper .hover-video a.lien-video .hover-video-wrapper .hover-video-inner .title {
                        font-family: "FrutigerLTStd-Roman";
                        font-size: 18px;
                        line-height: 20px;
                        letter-spacing: 0px; } }
                  .page-recherche-videos .region-content #block-system-main .view-content .liste-video-wrapper .first_wrapper .hover-video a.lien-video .hover-video-wrapper .hover-video-inner .play-video, .page-recherche-videos .region-content #block-views-recherche-video-block-1 .view-content .liste-video-wrapper .first_wrapper .hover-video a.lien-video .hover-video-wrapper .hover-video-inner .play-video, .page-recherche-videos .region-content #block-views-recherche-video-block-2 .view-content .liste-video-wrapper .first_wrapper .hover-video a.lien-video .hover-video-wrapper .hover-video-inner .play-video {
                    float: right;
                    display: block;
                    width: 57px;
                    height: 57px;
                    background: url("../img/play-page-video.png") no-repeat 0 0 transparent; }
        .page-recherche-videos .region-content #block-system-main .view-content .liste-video-wrapper #ajax-video-grand, .page-recherche-videos .region-content #block-views-recherche-video-block-1 .view-content .liste-video-wrapper #ajax-video-grand, .page-recherche-videos .region-content #block-views-recherche-video-block-2 .view-content .liste-video-wrapper #ajax-video-grand {
          position: absolute;
          top: 0;
          width: 716px;
          height: 407px;
          display: none;
          z-index: 3;
          -webkit-transition: all 0.3s ease 0s;
          -moz-transition: all 0.3s ease 0s;
          -o-transition: all 0.3s ease 0s;
          -ms-transition: all 0.3s ease 0s;
          transition: all 0.3s ease 0s; }
          @media (max-width: 767px) {
            .page-recherche-videos .region-content #block-system-main .view-content .liste-video-wrapper #ajax-video-grand, .page-recherche-videos .region-content #block-views-recherche-video-block-1 .view-content .liste-video-wrapper #ajax-video-grand, .page-recherche-videos .region-content #block-views-recherche-video-block-2 .view-content .liste-video-wrapper #ajax-video-grand {
              width: 100%;
              height: 100%; } }
          @media (min-width: 1024px) and (max-width: 1190px) {
            .page-recherche-videos .region-content #block-system-main .view-content .liste-video-wrapper #ajax-video-grand, .page-recherche-videos .region-content #block-views-recherche-video-block-1 .view-content .liste-video-wrapper #ajax-video-grand, .page-recherche-videos .region-content #block-views-recherche-video-block-2 .view-content .liste-video-wrapper #ajax-video-grand {
              height: 406px;
              width: 676px; } }
          @media (min-width: 768px) and (max-width: 1023px) {
            .page-recherche-videos .region-content #block-system-main .view-content .liste-video-wrapper #ajax-video-grand, .page-recherche-videos .region-content #block-views-recherche-video-block-1 .view-content .liste-video-wrapper #ajax-video-grand, .page-recherche-videos .region-content #block-views-recherche-video-block-2 .view-content .liste-video-wrapper #ajax-video-grand {
              height: 355px;
              width: 470px; } }
          .page-recherche-videos .region-content #block-system-main .view-content .liste-video-wrapper #ajax-video-grand.overlayed, .page-recherche-videos .region-content #block-views-recherche-video-block-1 .view-content .liste-video-wrapper #ajax-video-grand.overlayed, .page-recherche-videos .region-content #block-views-recherche-video-block-2 .view-content .liste-video-wrapper #ajax-video-grand.overlayed {
            display: block;
            z-index: 10; }
          .page-recherche-videos .region-content #block-system-main .view-content .liste-video-wrapper #ajax-video-grand iframe, .page-recherche-videos .region-content #block-system-main .view-content .liste-video-wrapper #ajax-video-grand video, .page-recherche-videos .region-content #block-views-recherche-video-block-1 .view-content .liste-video-wrapper #ajax-video-grand iframe, .page-recherche-videos .region-content #block-views-recherche-video-block-1 .view-content .liste-video-wrapper #ajax-video-grand video, .page-recherche-videos .region-content #block-views-recherche-video-block-2 .view-content .liste-video-wrapper #ajax-video-grand iframe, .page-recherche-videos .region-content #block-views-recherche-video-block-2 .view-content .liste-video-wrapper #ajax-video-grand video {
            width: 100%;
            height: 100%;
            border: none; }
        .page-recherche-videos .region-content #block-system-main .view-content .liste-video-wrapper.col-video1 #ajax-video-grand, .page-recherche-videos .region-content #block-system-main .view-content .liste-video-wrapper.col-video2 #ajax-video-grand, .page-recherche-videos .region-content #block-views-recherche-video-block-1 .view-content .liste-video-wrapper.col-video1 #ajax-video-grand, .page-recherche-videos .region-content #block-views-recherche-video-block-1 .view-content .liste-video-wrapper.col-video2 #ajax-video-grand, .page-recherche-videos .region-content #block-views-recherche-video-block-2 .view-content .liste-video-wrapper.col-video1 #ajax-video-grand, .page-recherche-videos .region-content #block-views-recherche-video-block-2 .view-content .liste-video-wrapper.col-video2 #ajax-video-grand {
          left: 0; }
        .page-recherche-videos .region-content #block-system-main .view-content .liste-video-wrapper.col-video3 #ajax-video-grand, .page-recherche-videos .region-content #block-views-recherche-video-block-1 .view-content .liste-video-wrapper.col-video3 #ajax-video-grand, .page-recherche-videos .region-content #block-views-recherche-video-block-2 .view-content .liste-video-wrapper.col-video3 #ajax-video-grand {
          right: 0; }
        .page-recherche-videos .region-content #block-system-main .view-content .liste-video-wrapper.col-video3 .picto-share, .page-recherche-videos .region-content #block-views-recherche-video-block-1 .view-content .liste-video-wrapper.col-video3 .picto-share, .page-recherche-videos .region-content #block-views-recherche-video-block-2 .view-content .liste-video-wrapper.col-video3 .picto-share {
          left: -337px; }
          @media (min-width: 768px) and (max-width: 1023px) {
            .page-recherche-videos .region-content #block-system-main .view-content .liste-video-wrapper.col-video3 .picto-share, .page-recherche-videos .region-content #block-views-recherche-video-block-1 .view-content .liste-video-wrapper.col-video3 .picto-share, .page-recherche-videos .region-content #block-views-recherche-video-block-2 .view-content .liste-video-wrapper.col-video3 .picto-share {
              left: -220px; } }
        .page-recherche-videos .region-content #block-system-main .view-content .liste-video-wrapper.col-video3 .lien-partage, .page-recherche-videos .region-content #block-views-recherche-video-block-1 .view-content .liste-video-wrapper.col-video3 .lien-partage, .page-recherche-videos .region-content #block-views-recherche-video-block-2 .view-content .liste-video-wrapper.col-video3 .lien-partage {
          left: -395px; }
          @media (min-width: 768px) and (max-width: 1023px) {
            .page-recherche-videos .region-content #block-system-main .view-content .liste-video-wrapper.col-video3 .lien-partage, .page-recherche-videos .region-content #block-views-recherche-video-block-1 .view-content .liste-video-wrapper.col-video3 .lien-partage, .page-recherche-videos .region-content #block-views-recherche-video-block-2 .view-content .liste-video-wrapper.col-video3 .lien-partage {
              left: -278px; } }
.page-recherche-videos .videos-overlay {
  background: #000;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 4;
  left: 0;
  top: 0;
  opacity: 0.7 !important;
  -moz-opacity: 0.7 !important;
  -khtml-opacity: 0.7 !important;
  -ms-filter: alpha(opacity=70);
  filter: alpha(opacity=70);
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(opacity=70)";
  display: none;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  -ms-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  cursor: pointer; }
  .page-recherche-videos .videos-overlay.overlayed {
    display: block; }
