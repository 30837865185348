/*general color*/
/*menu-color*/
/*block 3 hp*/
/*balayage a gauche hover*/
/*fiche livre*/
/*accordion*/
/*font*/
/*lists collection photo*/
/*catalogue color lists*/
/*responsive size */
/*website size*/
/*landscape tablette*/
/* tablette*/
/*portrait tablette*/
/* mobile */
/*landscape mobile*/
/*portrait mobile*/
/*mobile large*/
/*mobile medium*/
/*mobile small*/
/*mobile medium 2*/
/*mobile header*/
/*font size chart (px to em)*/
/*on site*/
/*accordion h3*/
/*gradient-site*/
/*balayage*/
/*end balayage*/
/*border-auteur*/
/*placeholder*/
/*note: must include !important + seperate webkit*/
/*general*/
/*font*/
/*catalogue color theme*/
body {
  line-height: 1.3; }

.page-mon-compte-titres-favoris .view-header .message {
  margin: -1rem 0 1rem;
  padding: 0 0 1rem;
  text-align: center;
  border-bottom: 1px solid #ccc; }
  .page-mon-compte-titres-favoris .view-header .message p {
    margin: auto;
    max-width: 80rem; }

.form-block-login .link-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 1rem -1rem; }
  .form-block-login .link-container a {
    margin: 0 0.2rem !important;
    padding: 0.5rem !important;
    min-height: 2rem;
    line-height: 1.2 !important;
    font-size: 1.2rem !important;
    color: #fff !important;
    display: block;
    background: #296483; }
    .form-block-login .link-container a:hover {
      background: #173849; }

.search-form .panel-default > .panel-heading {
  margin: 0 -1px;
  width: calc(100% + 2px);
  border: 1px solid #ddd; }

.search-form .form-type-checkbox {
  display: inline-block;
  margin-right: 1rem; }

.search-form .radio input[type="radio"], .search-form .radio-inline input[type="radio"], .search-form .checkbox input[type="checkbox"], .search-form .checkbox-inline input[type="checkbox"] {
  margin-left: 0; }

.search-result p {
  font-size: 1.2em;
  line-height: 1.5; }

.block-search input[type=text] {
  border-radius: 8px !important; }

.block-search button {
  width: 50px;
  background: url(/sites/all/themes/edl/img/loupe.png) no-repeat center center transparent !important;
  cursor: pointer;
  border: none;
  text-indent: -9999px; }

#navbar .header-container .logo-mobile {
  margin: 0 10px; }

#navbar .header-container .region-header-mobile #block-views-recherche-home-block-5 .view-filters form {
  width: 100%; }
  #navbar .header-container .region-header-mobile #block-views-recherche-home-block-5 .view-filters form input[type=text] {
    width: 90%; }

#navbar .header-container .region-header-mobile #block-views-recherche-home-block-5 .block-search {
  width: 100%; }

.view-recherche-home .views-row:hover {
  background: none; }

#navbar .header-container .navbar-collapse .nav > li > a {
  color: #969090;
  line-height: 55px;
  @media (min-width: 1024px) {
    color: #ccc4c3;
  }
  font-family: "FrutigerLTStd-Light";
  font-size: 16px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  margin: 0;
  padding: 0;
  box-shadow: none;
  border: none;
  border-radius: 0;
  background: none; }

@media (max-width: 767px) {
  #navbar .header-container .navbar-collapse .nav > li > a {
    font-size: 30px;
    line-height: 30px;
    letter-spacing: 0.5px; } }

@media (min-width: 768px) and (max-width: 1023px) {
  #navbar .header-container .navbar-collapse .nav > li > a {
    font-size: 30px;
    line-height: 30px;
    letter-spacing: 0.5px; } }

@media (min-width: 767px) {
  #navbar .header-container .navbar-collapse .nav > li > a.active-trail:before {
    border-bottom: 8px solid #fff !important;
    bottom: 0;
    color: #fff !important;
    content: "";
    display: block;
    left: 0;
    position: absolute;
    width: 100%;
    z-index: 9999; } }

#navbar .header-container .navbar-collapse .nav > li > a.dropdown-toggle:hover {
  color: #fff;
  box-shadow: none;
  border: none;
  border-radius: 0;
  background: none; }