// Font  #Todo temporaire ?
//@import url('https://fonts.googleapis.com/css?family=Arvo');
// Card recherche (depreciated)
.page-recherche-avancee,
.article-wrapper {
  .card-wrapper {
    position: relative;
    margin: 15px;
  }

  .picture-wrapper {
    width: 100%;
    position: relative;
    text-align: center;
    z-index: 5;

    img {
      width: 100%;
      height: auto;
    }
  }

  .card-hover {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: all .4s;

    &:hover {
      opacity: 1;
      background: linear-gradient(rgba(255, 255, 255, 0.45), rgba(255, 255, 255, 0.45));
      cursor: pointer;
    }
  }

  .btn-readmore {
    background-color: $blue-new;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -20px;
    margin-left: -65px;
    width: 140px;
    text-align: center;
    transition: all .4s;
    display: inline-block;
    color: #fff;
    padding: 15px;
  }

  .card-type {
    position: absolute;
    background-color: $pink-new;
    color: #fff;
    text-transform: uppercase;
    top: -18px;
    left: -18px;
    padding: 15px;
    border: 8px solid #fff;
    z-index: 10;

    a {
      color: #fff;
    }
  }

  .card-infos {
    margin: -50px 20px 0;
    padding: 10px 20px 20px;
    box-shadow: $box-shadow;
    position: relative;
    background-color: #fff;
    color: $gray-light;
    z-index: 10;

    h3 {
      color: $gray-light;
    }
  }

  .infos-link {
    border-top: 1px solid $gray-lighter;
    margin-top: 15px;
    padding-top: 15px;
    display: block;
  }

  // Styles page de base
  .article-wrapper {
    h2 {
      font-variant: small-caps;
    }

    h3 {
      font-size: 1.25em;
      text-transform: uppercase;
      display: table;
      white-space: nowrap;
      width: 100%;
      background-color: #fff;
      z-index: 5;

      &:after {
        background-clip: padding;
        background-image: linear-gradient(transparent 49%, #ddd 50%, #ddd 51%, transparent 51%);
        content: "";
        display: table-cell;
        width: 100%;
        border-left: 15px solid transparent;
      }
    }
  }

  .subtitle {
    font-family: Arvo, serif;
    text-transform: uppercase;
    font-size: 1.625em;
    line-height: 26px;
  }

  // styles temporaires a intégrer
  .not-front .custom-page-header {
    h1.page-header {
      &:first-letter {
        font-weight: bold;
      }
    }

    @media (min-width: 768px) {
      h1.page-header {
        padding-top: 0;
        font-size: 3em;

        &:first-letter {
          font-size: 4rem;
        }
      }
    }
  }
}

// Card catalogue, ...
.card-wrapper-thumb {
  position: relative;
  display: inline-block;
  margin: 0 0 30px 0;

  .card-cover {
    height: 260px;

    img {
      max-height: 245px;
      max-width: 100%;
    }
  }

  &:hover .card-thumb-overlay {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }
}

.product-item {
  @media (max-width: 768px) {
    margin-bottom: 30px;
  }
}

.product-item-thumb {
  text-align: center;
}

.card-thumb-overlay {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 260px;
  padding: 20px 15px;
  background-color: #f4f1ee;
  color: $gray-light;
  text-align: right;

  .card-thumb-header {
    font-family: $frutiger-bold;
    font-size: 1.3rem;
    text-transform: uppercase;
    color: $pink;
  }

  a {
    width: 100%;
    color: $gray-light;
  }

  .card-title {
    float: right;
    font-size: 1.5rem;
    line-height: 1.6rem;
  }

  .card-infos {
    clear: right;
    margin-bottom: 15px;
    font-size: 1.2rem;
    text-transform: capitalize;
  }

  .card-price {
    margin-top: $grid-gutter-width;
  }
}

.card-thumb-footer {
  display: flex;
  justify-content: center;

  .cross {
    width: 18px;
    height: 18px;

    &:before {
      height: 5%;
    }

    &:after {
      width: 5%;
    }
  }
}

.card-wrapper {
  display: flex;
  flex-direction: column;

  @media (min-width: $screen-sm) {
    height: 100%;
  }

  &:hover {
    .btn-purchase {
      visibility: visible;
    }
  }

  .btn-favorite {
    display: inline-block;
    padding: 0 0 0 ($grid-gutter-width/2);
  }
}

.card-cover {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 230px;
  overflow: hidden;
  text-align: center;
  border: 1px solid $gray-lighter;
  padding: 5px;
  margin-bottom: ($grid-gutter-width/2);

  img {
    max-height: 220px;
    transition: $transition;

    &:hover {
      transform: scale(1.1);
      transition: $transition;
    }
  }
}

.card-message {
  position: absolute;
  left: 40px;
  right: 0;
  bottom: 0;
  padding: 6px 4px;
  background-color: rgba(255, 255, 255, 0.8);
  color: $pink;
  font-family: $frutiger-bold;
  text-transform: uppercase;
  font-size: 1.2rem;
  line-height: 1.5;
}

.card-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: $pink;
  font-size: 1.2rem;
  font-family: $frutiger-bold;
  text-transform: uppercase;
}

.card-title {
  margin-top: 10px;
  margin-bottom: 5px;
  @include font-style($frutiger-bold, 1.6rem, 2rem, 0px);

  a {
    color: inherit;
  }
}

.card-infos {
  margin-bottom: 10px;
  font-size: 1.3rem;
  color: #6b6b6b;
}

.card-author {
  font-family: $frutiger-bold;
  font-size: 1.4rem;
  margin-bottom: 10px;
}

.card-price {
  font-size: 1.4rem;
}

.card-content {
  float: right;
}

.card-readmore {
  font-family: $frutiger-bold;
  color: inherit;
  margin-top: ($grid-gutter-width/2);
  text-align: center;
  margin-top: auto;
}

.card-footer {
  clear: right;
  height: 40px;
  text-align: center;

  @media (min-width: $screen-lg) {
    .btn-purchase {
      visibility: hidden;
    }
  }
}

#products-wrapper {
  row-gap: 30px;
}

// Card node (une journée avec ...)
.card-node {
  @media (min-width: $screen-sm-min) {
    height: 380px;
  }

  @media (min-width: $screen-md-min) {
    height: 340px;
  }
}