.node-type-page-hero-banner {
    .custom-page-hero-banner {
        margin: 0 0 12px 0;
    }

    h1 {
        color: $queen-blue;
        font-family: $frutiger-bold;
        font-size: 20px;
        text-align: start;
        margin: 0;
    }

    .field-name-body {
        @include font-style($frutiger-roman, 14px, 20px, 0px);
    }

}

@media (min-width: 768px) {
    .node-type-page-hero-banner {
        .container {
            width: 735px;
        }

        h1 {
            font-size: 40px;
        }

        .field-name-body {
            @include font-style($frutiger-roman, 16px, 20px, 0px);
        }
    }
}

@media (min-width: 992px) {
    .node-type-page-hero-banner {
        .container {
            width: 955px;
        }
    }
}

@media (max-width: 1440px) {
    // sauf page newsletters
    .node-type-page-hero-banner:not(.page-node-262220):not(.page-node-262218) {
        .hero-banner {
            img {
                width: 100%;
                height: 200px;
                object-fit: cover;
            }
        }
    }
}

@media (min-width: 1440px) {
    .node-type-page-hero-banner {
        .container {
            width: 1200px;
            padding: 0;
        }

        .hero-banner {
            img {
                display: block;
                margin: auto;
            }
        }
    }
}