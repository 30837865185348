//
// Header top
//

// hide favorite list not-logged
.not-logged {
  .lien_favoris {
    display: none;
  }
}

@media (min-width: $screen-sm-min) and (max-width: $tl) {

  // Fix tablet menu
  #header-espaces-login {
    flex-direction: column;
  }

  body.admin-menu.adminimal-menu {
    margin-top: 26px !important;
  }

}

.admin-menu.menu-fixed {
  #navbar {
    .header-container {
      top: 56px;

      @media (min-width: $site-size) {
        top: 30px;
      }
    }
  }
}

.top-most-header {
  background: $bluefonce;
  min-height: 42px;
  padding: 5px 0;

  #block-edl-main-edl-block-vod {
    margin-right: 6px;
    padding-right: 6px;

    @media (min-width: $screen-sm-min) {
      margin-right: 10px;
      padding-right: 10px;
      border-right: 1px solid darken($bluefonce, 6%);
    }

    @media (min-width: $screen-md-min) {
      margin-right: 20px;
      padding-right: 20px;
    }

    @media (max-width: $screen-xs-max) {
      img {
        max-width: 80px;
      }
    }
  }

  .block-text-resize {
    float: left;
    position: relative;
    padding: 0;

    .acces-titre {
      color: #fff;
      text-transform: uppercase;
      @include font-style($frutiger-bold, 12px, 42px, 0.5px);
    }

    .btn-resize {
      position: absolute;
      left: -15px;
      top: 42px;
      width: 100%;
      background: $maronpale;
      min-width: 615px;
      padding: 0 14px;
      z-index: 9998;
      margin: 0;
      height: 55px;

      &:before {
        @include arrow-dropdown(8%);
      }

      p {
        margin: 20px 0 0 0;
        text-transform: uppercase;
        @include font-style($frutiger-bold, 16px, 16px, 0.5px);
        color: $noir;
        float: left;
      }

      a {
        float: left;
        margin: 8px 10px 0;
        @include font-style($frutiger-bold, 32px, 32px, 0.5px);
        color: $noir;

        sup {
          @include font-style($frutiger-bold, 30px, 30px, 0.5px);
          color: $noir;
          top: -9px;
        }

        &#text_resize_increase,
        &#text_resize_decrease {
          padding: 6px 0 0 0;
        }

        &#text_resize_increase {
          margin: 8px 0 0 15px;
        }

        &#text_resize_decrease {
          sup {
            top: -10px;
            margin: 0 0 0 3px;
          }
        }

        &#contraste {
          background: url("../img/contraste.png") no-repeat 0 0 transparent;
          width: 36px;
          height: 41px;
          text-indent: -9999px;
        }

        &#text_resize_reset {
          background: url("../img/reload-gris.png") no-repeat 0 0 transparent;
          width: 24px;
          height: 22px;
          text-indent: -9999px;
          margin: 15px 10px 0;
        }
      }
    }
  }

  #header-espaces-login {
    @media (min-width: $screen-sm-min) {
      display: flex;
      justify-content: flex-end;
    }

    #block-menu-menu-espaces-menu {
      float: right;

      @media (min-width: $screen-sm-min) {
        float: left;
        padding-right: 10px;
        border-right: 1px solid darken($bluefonce, 6%);
      }

      @media (min-width: $screen-md-min) {
        padding-right: 20px;
      }

      .nav {
        >li {
          &.open {
            >a {
              background-color: transparent;
              border-color: transparent;
            }
          }

          >a {
            padding: 0;
            color: $bluepale;
            @include font-style($frutiger-light, 11px, 42px, 0.5px);
            text-transform: uppercase;

            @media (min-width: $screen-xs-max) {
              font-size: 14px;
            }

            &:hover,
            &:focus {
              background: none;
              color: #fff;
            }

            @media (max-width: $screen-xs-max) {
              display: none;
            }
          }

          .dropdown-menu {
            padding: 0;
            margin: 0;
            top: 42px;
          }
        }
      }
    }
  }
}

// Button Vod
#navbar #block-edl-main-edl-block-vod {
  a {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #75b1d2;
    font-family: "FrutigerLTStd-Light";
    font-size: 11px;
    letter-spacing: 0.5px;
    line-height: 42px;
    padding: 0;
    text-transform: uppercase;

    @media (min-width: $screen-xs-max) {
      font-size: 14px;
    }

    &:hover {
      color: white;
    }
  }

  img {
    margin-left: 8px;
  }
}

// Button subscription
.btn-subscription {
  float: none;
  display: block;
  line-height: 32px;
  text-align: right;
  color: $yellow;
  text-transform: uppercase;

  &:hover {
    color: $bluepale;
  }

  img {
    margin-left: 10px;
    display: none; // #Todo supprimer span dans pages.tpl
  }

  @media (min-width: $screen-md-min) {
    display: inline;
    line-height: 42px;
    padding-right: 20px;
  }
}

.region-top-most {
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (min-width: $screen-sm-min) {
    justify-content: flex-end;
    padding-left: 20px;
    border-left: 1px solid darken($bluefonce, 6%);
  }

  @media (max-width: $screen-md-min) {
    .region-top-most {
      display: block;
    }
  }
}

#navbar .top-most-header #header-espaces-login #block-menu-menu-espaces-menu .nav>li .dropdown-menu {
  top: 32px;
}

//
#navbar {
  @include default-disable;
  min-height: auto;
  margin: 0;
  z-index: 9;

  /*header bottom*/
  .header-container {
    background: $bluefonce;
    padding: 20px 20px 14px 20px;
    position: relative;


    .col-sm-12 {
      &:after {
        @include clearfix();
      }
    }

    &.search-active {
      .region-recherche {
        #block-views-recherche-home-block-3 {
          @include transform(translateY(0px));
        }
      }
    }

    .container {
      &::before {
        content: '';
      }

      @media (max-width: $m1) {
        max-width: 100%;
      }
    }

    .navbar-header {
      float: left;

      &::before,
      &::after {
        content: none;
      }

      @media (max-width: $screen-xs-max) {
        // position: relative;
        width: 100%;
      }

      .navbar-header-right-side {
        display: flex;
      }

      .logo {
        width: 150px;
        margin: 0;

        @media (min-width: 768px) {
          width: 300px;
        }

        &:hover {
          img {
            &.logo-active {
              @include opacity(1);
            }

            &.logo-inactive {
              @include opacity(0);
            }
          }
        }

        img {
          // position: absolute;
          // top: 50%;
          // left: 0;
          // transform: translateY(-50%);
          width: 100%;
          height: auto;
          @include transition(opacity, 0.5s, ease, 0s);

          &.logo-active {
            @include opacity(0);
          }
        }

      }

      .navbar-toggle {
        @media (max-width: $screen-md-min) {
          background: url("../img/menu-mobile.png") no-repeat center;
          background-color: #2d2d2d;
          background-size: 70%;
          width: 55px;
          height: 55px;
          position: absolute;
          right: 0;
          top: 0;
          margin: 0;
          padding: 0;
          display: block;
          border-color: #2d2d2d;
          border-top: none;
          border-bottom: none;
          border-right: none;
          border-radius: 0;

          .icon-bar {
            display: none;
          }
        }

        @media (max-width: $screen-xs-max) {
          right: -7px;
        }

        &.highlighted {
          background: url("../img/icon-close-mobile.png") no-repeat center $maron;
          background-size: 70%;
        }
      }
    }

    .searchbar-toggle {
      float: left;
      width: 70px;
      height: 70px;
      border-left: 2px solid #626262;
      border-right: 2px solid #626262;
      border-top: none;
      border-bottom: none;
      background: url("../img/main-loupe.png") no-repeat 50% 50% transparent;
      text-align: center;
      display: block;

      &.highlighted {
        border: none;
        background-image: url("../img/icon-close-mobile.png");
        background-color: #3774a9;
        background-position: 50% 50%;
        background-repeat: no-repeat;
      }
    }

    .logo-mobile-wrapper {
      @media (max-width: $hm) {
        float: left;
        margin: 0 0 0 4px;
      }

      @media (min-width: $mmm) and (max-width: $hm) {
        width: 160px;
      }

      @media (max-width: $xm) {
        width: 37%;
        margin: 3px 0 0 4px;
      }
    }

    .logo-mobile {
      margin: 13px 0 0 20px;
      padding: 0;

      @media (max-width: $hm) {
        display: block;
        float: none !important;
        height: auto;
        margin: 0;
        padding: 0;
        width: 100%;

        img {
          height: auto;
          width: 100%;
        }
      }
    }

    .region-header-mobile {
      float: right;

      @media (max-width: $hm) {
        float: none;
        margin: 0 auto;
        text-align: center;
        width: 42px;
      }

      #block-views-recherche-home-block-5 {
        height: 100%;
        left: 0;
        position: absolute;
        top: 100%;
        width: 100%;

        .view-filters {
          height: 83px;
          background: #3774a9;
          position: relative;
          z-index: 1;

          form {
            padding: 15px 20px;

            .views-exposed-widget {
              float: none;
              padding: 0;

              .form-type-textfield {
                @include placeholder-text($frutiger-light, #6c6b6b, 16px);

                input {
                  height: 48px;
                  background: #fff;
                  @include default-disable;
                  color: #6c6b6b;
                  width: 100%;
                  @include font-style($frutiger-light, 28px, 48px, 0px);
                }
              }
            }
          }
        }

        .view-content {
          background-color: #ebe7e7;
          display: block;
          height: 100%;
          left: 0;
          @include opacity(0.8);
          position: fixed;
          width: 100%;
          top: 70px;

          .wrapper-content {
            margin: 110px auto 0;
            width: 95%;
            height: auto;
            padding: 0 5px;
            @include view-row-search();
          }
        }

        .view-empty {
          height: auto;
          margin: 50px auto 0;
          max-width: 800px;
          padding: 0 5px;
          width: 100%;
          text-align: center;

          p {
            margin: 0;
            padding: 0;
            color: $noir;
            text-align: center;
            @include font-style($frutiger-light, 18px, 18px, 0px);
          }
        }

        .image-wrapper {
          display: none;
        }
      }
    }

    .navbar-collapse {
      float: left;
      padding: 0 0 0 30px;

      @media (min-width: $tp) and (max-width: $t) {
        padding: 0 0 0 15px;
      }

      @media (min-width: $tl) and (max-width: $site-size) {
        padding: 0 0 0 20px;
      }

      @media (max-width: $screen-sm-max) {
        background: $maron;
        float: none;
        left: 0;
        padding: ($spacer*2) 0;
        position: absolute;
        top: 55px;
        width: 100%;
        border: none;
        z-index: 9999;
      }

      .nav {
        >li {
          float: left;
          margin: 0 15px 0 0;

          @media (min-width: $screen-sm-max) {
            &.last.expanded {
              margin: 0;
            }
          }

          @media (min-width: $tp) and (max-width: $t) {
            margin: 0 9px 0 0;
          }

          @media (max-width: $screen-sm-max) {
            float: none;
            margin: 20px 0 0 20px;
          }

          @media (min-width: $screen-xl) {
            margin: 0 25px 0 0;
          }

          &.open {
            a.dropdown-toggle {
              color: #fff;
              @include default-disable();
              background: none;

              &.active-trail {
                border: none !important;
                padding: 0 !important;
              }
            }
          }

          &.catalogue {
            @include color-menu($menu-1);

            @media (max-width: $screen-sm-max) {
              .dropdown-toggle {
                color: #3774a9 !important;
              }
            }
          }

          &.auteurs {
            @include color-menu($menu-2);

            @media (max-width: $screen-sm-max) {
              .dropdown-toggle {
                color: #a7003b !important;
              }
            }
          }

          &.vnement,
          &.vnements {
            @include color-menu($menu-3);

            @media (max-width: $screen-sm-max) {
              .dropdown-toggle {
                color: #e64824 !important;
              }
            }
          }

          &.ressources {
            @include color-menu($menu-4);

            @media (max-width: $screen-sm-max) {
              .dropdown-toggle {
                color: #711e80 !important;
              }
            }
          }

          &.espaces-professionnels,
          &.animations-et-formations {
            @media (max-width: $m1) {
              .dropdown-toggle {
                color: #296483 !important;
              }
            }
          }

          a.dropdown-toggle {
            color: $maron;
            @include font-style($frutiger-light, 30px, 30px, 0.5px);
            text-transform: uppercase;
            margin: 0;
            padding: 0;
            @include default-disable();
            background: none;

            @media (min-width: $screen-md-min) {
              @include font-style($frutiger-light, 14px, 55px, 0.5px);
            }

            @media (min-width: $screen-xl) {
              font-size: 16px;
            }

            &.active-trail {
              &:before {
                @media (min-width: $screen-md-min) {
                  border-bottom: 8px solid #fff !important;
                  bottom: 0;
                  color: #fff !important;
                  content: "";
                  display: block;
                  left: 0;
                  position: absolute;
                  width: 100%;
                  z-index: 9999;
                }
              }
            }

            &:hover {
              color: #fff;
              @include default-disable();
              background: none;
            }
          }

          .dropdown-menu {
            left: 0;
            top: 42px;

            &:before {
              @include arrow-dropdown(10%);
            }
          }
        }
      }
    }

    .region-recherche {
      float: right;

      // Recherche mobile
      @media (max-width: $screen-xs-max) {
        // float: left;
        // margin-right: 55px;

        #header {
          margin: 15px 0 0 0;
        }
      }

      &:after {
        @include clearfix;
      }

      #block-views-recherche-home-block-3 {
        float: left;
        position: static;
        left: 0;
        top: 0;
        width: 100%;
        @include transition(transform, 0.4s, ease, 0.1s);

        .view-filters {
          #header {
            margin: 15px 0 0 0;

            .views-exposed-widgets {
              margin: 0;

              .views-exposed-widget {
                padding: 0;
                float: none;

                .views-widget {
                  float: left;
                  margin: 0 5px 0 0;
                  @include placeholder-text($frutiger-light, $noir, 14px);

                  input {
                    border-radius: 15px;
                    border: none;
                    background: $bgsearch;
                    height: 25px;
                    /*width: 240px;*/
                    box-shadow: none;
                    color: $noir;
                    @include font-style($frutiger-light, 14px, 25px, 0px);
                    margin: 0;
                    padding: 0 12px;

                    @media (min-width: $tl) and (max-width: $site-size) {
                      width: 175px;
                    }

                    &:focus,
                    &:active {
                      background: #fff;
                      color: $noir;
                    }
                  }

                  @media (max-width: $screen-xs-max) {
                    // width: 65%;

                    input {
                      float: left;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .searchbar-result-container {
      display: none;

      .image-wrapper {
        @include transition(transform, 0.5s, ease, 0s);
        @include opacity(0);
        @include transform(translateY(-450px));
        float: right;

        .img-close {
          text-indent: -9999px;
          display: block;
          width: 40px;
          height: 40px;
          background: url("../img/close-noir.png") no-repeat 0 0 transparent;
        }
      }
    }

    &.searching {
      .searchbar-result-container {
        position: fixed;
        top: 96px;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #ebe7e7;
        @include opacity(0.8);
        display: block;

        @media (max-width: $m1) {
          display: none;
        }

        .searchbar-result-inner {
          margin: 0 auto 15px;
          max-width: 1200px;
          width: 100%;
          position: relative;

          .image-wrapper {
            @include opacity(1);
            @include transform(translateY(0px));

            .img-close {
              cursor: pointer;
            }
          }

          .view-content {
            .views-row-1 {
              border-top: none;
            }
          }

          .view-empty {
            height: auto;
            margin: 50px auto 0;
            max-width: 800px;
            padding: 0 5px;
            width: 100%;
            text-align: center;

            p {
              margin: 0;
              padding: 50px 0 0 0;
              color: $noir;
              text-align: center;
              @include font-style($frutiger-light, 18px, 18px, 0px);
            }
          }
        }
      }
    }
  }
}

/*dropdown menu boostrap */
.dropdown-menu {
  @include default-disable;
  background: $maronpale;
  padding: 4px 0 0 0;
  margin: 13px 0 0 0;
  left: -50px;


  &:before {
    @include arrow-dropdown(45%);

    @media (max-width: $screen-sm-max) {
      display: none;
    }
  }

  >li {
    border-top: 1px solid #fff;

    >a {
      padding: 8px 10px;
      color: $noir;
      margin: 0;
      @include font-style($frutiger-roman, 12px, 12px, 0.5px);
      text-transform: uppercase;

      @media (max-width: $screen-sm-max) {
        color: $noir !important;
        @include font-style($frutiger-roman, 18px, 18px, 0.5px);
        white-space: normal;
      }

      &:hover {
        color: #fff;
        background: $espace;
      }
    }
  }
}

/* Recherche top */
.block-edl-recherche-avance {
  float: left;
  margin-right: 2rem;
}

/*block social*/
#block-edl-main-edl-block-rs {
  display: inline-flex;
  float: right;

  @media (min-width: $screen-sm-min) {
    align-items: center;
    min-height: 42px;
  }

  .bloc-reseaux-sociaux {
    >div {
      float: left;
      display: flex;
      justify-content: center;

      @media (min-width: $screen-sm-min) {
        margin: 0 0 0 7px;
      }

      &.logo-facebook {
        a {
          background-position: 0 -167px;

          &:hover {
            background-position: 0 0;
          }
        }
      }

      &.logo-twitter {
        a {
          background-position: -35px -167px;

          &:hover {
            background-position: -35px 0;
          }
        }
      }

      &.logo-instagram {
        a {
          background-position: -71px -167px;

          &:hover {
            background-position: -71px 0;
          }
        }
      }

      &.logo-youtube {
        a {
          background-position: -107px -167px;

          &:hover {
            background-position: -107px 0;
          }
        }
      }

      a {
        display: inline-block;
        width: 32px;
        height: 32px;
        background-repeat: no-repeat;
        background-image: url("../img/social-header.png");
        @include transition(all, 0.2s, ease, 0s);
      }
    }

  }
}

// Boutons top-most-header

#block-menu-menu-espaces-menu {
  float: left;
  position: relative;
}

// Login
#block-edl-main-edl-block-login,
#block-edl-main-edl-block-login--2 {

  /*  @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
      margin-top: 12px; //Fix vertical align
    }*/
  @media (min-width: $screen-sm-min) {
    float: left;
  }

  // @media (max-width: $screen-xs-max) {
  //   position: absolute;
  //   top: 12px;
  //   right: 65px;
  // }

  .form-block-login {
    position: absolute;
    top: 100%;
    left: -15px;
    width: 100%;
    background: $maronpale;
    margin: 9px 0 0 0;
    width: 235px;
    height: 240px;
    padding: 22px 10px 20px;
    z-index: 9998;

    @media (max-width: $m1) {
      right: 0;
      left: auto;
    }

    &:before {
      @include arrow-dropdown(45%);

      @media (max-width: $m1) {
        @include arrow-dropdown(85%);
      }
    }

    .identifier {
      text-align: center;
      color: $bluefonce;
      @include font-style($frutiger-roman, 16px, 16px, 0.5px);
    }

    .form-item {
      margin: 0 0 10px;
      @include placeholder-text($frutiger-light, $textmaron, 18px);

      input {
        height: 32px;
        background: #fff;
        @include default-disable;
        color: $textmaron;
        @include font-style($frutiger-light, 18px, 32px, 0px);
      }
    }

    .form-submit {
      background: #c00418;
      @include default-disable;
      display: block;
      width: 100%;
      padding: 10px 0;
      margin: 0;
      text-align: center;
      color: #fff;
      @include font-style($frutiger-roman, 16px, 16px, 0.5px);
      text-transform: uppercase;
    }

    .link-container {
      text-align: center;
      margin: 20px 0 0 0;

      a {
        text-transform: uppercase;
        color: $noir;
        @include font-style($frutiger-roman, 10px, 10px, 0.5px);
        margin: 0 0 2px;
      }
    }
  }
}

.bloc-login {
  display: flex;
  align-items: center;
  height: 100%;

  .text-icon-login-block {
    display: none;
  }

  .dropdown-account,
  .bt_deconnect,
  .lien_favoris,
  .lien_panier,
  .mon-compte {
    margin-left: 16px; //8px;
  }

  .user-profile-image {
    width: 25px;
    height: auto;
    object-fit: cover;
    border-radius: 50%;
  }

  //== dropdown account
  .dropdown-account {
    width: 25px;
    height: 25px;

    .dropdown-toggle {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 25px;
      border-radius: 50%;
    }

    .dropdown-menu {
      left: -70px;
      background-color: #fff;
      box-shadow: rgba(0, 0, 0, .5) 2px 2px 6px;

      @media ($screen-md-min) {
        margin-top: 15px;
        left: auto;
        right: -15px;

        &:before {
          left: auto;
          right: 18px;
        }
      }

      >li {
        >a {
          text-transform: inherit;
          font-family: $frutiger-bold;
          font-size: 1.4rem !important;

          &:hover {
            color: $gray-base;
            background-color: $gray-lighter;
          }
        }
      }
    }

    .logout {
      color: rgb(191, 5, 23) !important; // #Todo même couleur que panier et favoris => passer en variables ?
    }
  }

  .dropdown-account.open {
    .dropdown-menu {
      &:before {
        display: inline-block;
        height: 0;
        width: 0;
        border-right: 9px solid transparent;
        border-bottom: 11px solid #fff;
        border-left: 12px solid transparent;
        background-image: none;
      }
    }
  }

  .mon-compte {
    a {
      color: #ccc4c3;
    }
  }

  .bt_deconnect {
    display: inline-block;

    a {
      color: #ccc4c3;
      font-family: $frutiger-light;
      font-size: 11px;
      line-height: 14px;
      letter-spacing: 0.5px;
      height: 26px;
      text-transform: uppercase;

      @media (min-width: $screen-md-min) {
        font-size: 13px;
      }

      &:hover {
        color: #fff;
      }
    }
  }

  .lien_favoris,
  .lien_panier {
    display: inline-block;
    position: relative;
    margin-top: 4px; //#Fix Mauvaise découpe icônes
    cursor: pointer;
  }

  .lien_favoris .nbFavoris,
  .lien_panier .nbProduitsPanier {
    position: absolute;
    right: 5px;
    bottom: 2px;
    color: white;
    font-size: 12px;
    font-weight: bold;
    z-index: 100;
    background: rgb(191, 5, 23);
    border-radius: 50%;
    width: 20px;
    height: 20px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .lien_favoris .hidden.nbFavoris {
    display: none;
  }

  .lien_panier.lien_panier_supp .nbProduitsPanier {
    font-size: 11px;
    right: 15px;
  }
}

// Sprite header
.header-cta {
  background: url(/sites/all/themes/edl/img/icones/sprite-header.png) no-repeat;
  width: 25px;
  height: 25px;
  display: inline-block !important;
}

.header-cta-cart {
  background-position: 0 0;
}

.header-cta-fav {
  background-position: -24px 0;
}

.header-cta-login {
  background-position: 100% 0;

  @media (min-width: $screen-sm-min) {
    margin-right: 7px;
  }
}

.header-cta-search {
  background-position: -50px 0;
  cursor: pointer;

  &:hover {
    opacity: .8;
  }
}

// Hover favorites & cart
.lien_favoris:hover,
.lien_panier:hover {
  .tooltips-content {
    max-height: 60px;
  }

  .arrow-up-right:after {
    opacity: 1;
  }
}

.tooltips-animated {
  position: absolute;
  right: 0;
  top: 39px;
  width: 320px;
  cursor: help;

  &.arrow-up-right:after {
    opacity: 0;
  }

  .tooltips-content {
    max-height: 0;
    overflow-y: hidden;
    background-color: #fff;
    line-height: 1.6;
    font-size: 95%;
    box-sizing: border-box;
    box-shadow: 0 2px 4px rgba(0, 0, 0, .18);
    transition: all .5s ease;
  }

  p {
    margin-bottom: 0;
    padding: 10px;
    text-transform: capitalize;
    color: #000;
  }
}

.svg-icons-navbar-mobile {
  display: block;
}

.svg-icons-navbar-desktop {
  display: none;
}

@media (min-width:768px) {
  #navbar {
    .header-container {
      padding: 30px 20px 30px 20px;

      .container {
        width: 735px;
        margin-left: auto;
        margin-right: auto;
      }
    }

    .main-menu-container {
      width: 735px;
      margin-left: auto;
      margin-right: auto;
    }

    .svg-icons-navbar-mobile {
      display: none;
    }

    .svg-icons-navbar-desktop {
      display: block;
    }
  }

  .navbar-header-right-side {
    gap: 54px;
  }

  .bloc-login {
    gap: 46px;

    .text-icon-login-block {
      display: block;
      color: #FFF;
      @include font-style($frutiger-roman, 10px, 10px, 1px);
      margin-top: 4px;
    }

    .lien_panier .nbProduitsPanier {
      right: -5px;
      bottom: -7px;
    }
  }

  .bloc-login {
    .user-profile-image {
      width: 40px;
    }
  }
}

@media (min-width: 992px) {
  #navbar {
    .header-container .container,
    .main-menu-container {
      width: 955px;
    }
  }
}


@media (min-width: 1440px) {
  #navbar {
    .header-container .container,
    .main-menu-container {
      width: 1200px;
    }
  }
}