/*page tous les auteurs*/

.page-auteur-list {
  /*form*/
  #views-exposed-form-auteur-list-block-1 {
    margin: 0 0 35px;
    padding: 0 20px 35px;
    border-bottom: 1px solid #868686;
    #edit-nom-wrapper {
      width: 89%;
      @media (min-width: $ml) and (max-width: $mm) {
        width: 85%;
      }
      @media (max-width:$ms) {
        width: 75%;
      }
    }
  }
  .region-content {
    margin: 0 auto;
    position: relative;
    max-width: 1040px;
    width: 100%;
    .fleche-auteur {
      float: left;
      background: url("../img/arrow-lexique.png") no-repeat 0 3px transparent;
      width: 13px;
      height: 30px;
      margin: 0 20px 0 -35px;
      padding: 0;
      @media (max-width: $m1) {
        display: none;
      }
    }
    #block-edl-auteur-edl-auteur-liste-alphabetique {
      float: left;
      padding: 0 0 15px 0;
      border-bottom: 1px solid #868686;
      margin: 0 0 20px;
      width: 802px;
      @media (min-width: $tl) and (max-width: $site-size) {
        width: 755px;
      }
      @media (min-width:$tp) and (max-width:$t) {
        float: none;
        margin: 0 0 20px;
        padding: 0;
        width: 100%;
        border: none;
      }
      @media (max-width:$m1) {
        width: 100%;
        border: none;
        float: none;
      }
      .alphabet {
        float: left;
        margin: 0 11px 0 0;
        @media (min-width: $ml) and (max-width: $m) {
          margin: 0 25px 10px 0;
        }
        @media (max-width:$ms) {
          margin: 0 25px 5px 0;
        }
        @media (min-width:$tp) and (max-width:$t) {
          margin: 0 10px 0 0;
        }
        a {
          text-transform: uppercase;
          @include font-style($frutiger-roman,
                  28px,
                  28px,
                  0px);
          color: $noir;
          @media (min-width: $ml) and (max-width: $m) {
            @include font-style($frutiger-roman, 40px, 40px, 0px);
          }
          @media (max-width:$ms) {
            @include font-style($frutiger-roman,
                    30px,
                    30px,
                    0px);
          }
          &.active {
            color: $menu-2;
          }
          &.inactive {
            color: #d1d1d1;
          }
        }
      }
    }
    #block-views-exp-auteur-list-page {
      /*@media (max-width: $m1) {
        display: none;
      }*/
      @media (min-width:$tp) and (max-width:$t) {
        float: none;
        margin: 0 auto;
        text-align: center;
        width: 250px;
        border: none;
      }
      float: left;
      /*padding: 0 0 16px 0;*/
      padding-top: 16px;
      border-bottom: 1px solid #868686;
      /*margin: 0 0 20px;*/
    }
    #block-system-main,
    #block-views-auteur-list-block-1 {
      &.block-system {
        /*@media (max-width: $m1) {
          display: none;
        }*/
        @media (min-width:$tp) and (max-width:$t) {
          border-top: 1px solid #000;
          margin: 20px 0 0;
          padding: 20px 0 0;
        }
      }
      clear: both;
      .view-auteur-list {
        .view-content {
          &:after {
            @include clearfix();
          }
          @media (max-width:$m1) {
            margin: 0 5%;
          }
          .views-row {
            float: left;
            /*width: 140px;*/
            width:16.666%;
            /*margin: 0 10px 0 0;*/
            height: 250px;
            overflow: hidden;
            margin: 0 auto;
            @media (min-width: $tl) and (max-width: $site-size) {
              width: 133px;
            }
            @media (min-width:$tp) and (max-width:$t) {
              width: 139px;
            }
            @media (max-width:$m1) {
              width: 44%;
              max-width: 210px;
              margin: 0;
              height: auto;
              img {
                width: 100%;
                height: auto;
              }
            }
            &.views-row-odd {
              @media (max-width: $m1) {
                float: left;
                margin: 0 5% 0 0;
              }
            }
            &.views-row-even {
              @media (max-width: $m1) {
                float: right;
              }
            }
            &.next-line {
              margin: 0;
            }
            .views-field-field-auteur-photo {
              img {
                @include filter-grayscale(100%);
                @include transition(all,
                        0.8s,
                        ease,
                        0s);
                &:hover {
                  @include filter-grayscale(0%);
                }
              }
            }
            .views-field-title {
              margin: 0;
              padding: 0;
              height: 70px;
              overflow: hidden;
              a {
                padding: 8px 0;
                color: $menu-2;
                @include font-style($frutiger-roman,
                        16px,
                        16px,
                        0px);
              }
            }
          }
        }
        .pager {
          display: none;
          clear: both;
        }
      }
    }
  }
}

// Mobile
@media  (max-width: $screen-sm){
  // Champ recherche
  .views-widget-filter-field_auteur_nom_value{
    width:80%;
  }
}
