//==  Form Newsletter
.formOptin-block {
    padding-bottom: 10px;
}

.formOptin-wrapper {
    display: flex;
    align-items: center;
    width: 100%;
    background: #f1f1f1;

    @media (min-width: $screen-sm-max) {
        height: 100%;
    }
}

#formOptin {
    padding-top: 15px;
    background-color: #f1f1f1;

    @media (max-width: $screen-sm-max) {
        height: 290px !important;
        margin-bottom: 15px;
        margin-top: 5px;
    }

    @media (min-width: 768px) and (max-width: 1024px) {
        height: 270px !important;
    }

    @media (min-width: 992px) and (max-width: 1025px) {
        height: 290px !important;
    }
}

/*parallax image*/
/*#block-views-accueil-block*/
#home-intro {
    position: fixed;
    width: 100%;
}

.img-info {
    position: absolute;
    right: 10px;
    bottom: 3px;
    z-index: 1;
    font-size: 1rem;

    @media (min-width: $screen-md-min) {
        left: 30px;
        top: auto;
        bottom: 15px;
        font-size: 1.2rem;
    }
}

.parallax-container {
    position: relative;
    z-index: 1;
    background-color: #fff;
}

#parallax-0 {
    position: relative;
    width: 100%;
    z-index: 1;
    padding: 0;
    @include transition(transform, 0.4s, ease, 0s);
    @include transition(height, 0.4s, ease, 0s);
    height: 40vh;

    @media (min-width: $screen-md-min) {
        height: 70vh !important;
    }

    .parallax-image {
        z-index: 0;
        width: 100%;
        background-size: cover !important;
        @include transition(all, 0.3s, ease, 0s);
        height: 40vh;

        @media (min-width: $screen-md-min) {
            height: 70vh !important;
        }
    }

}

// carouselIntro - slider
.heroHomepage {
    position: relative;

    img {
        width: 100%;
        height: auto;
    }

    .carousel-indicators {
        display: none;
    }
}

.heroHomepage-content {
    position: absolute;
    bottom: 40px;
    right: 0;
    padding: 1rem 1.5rem;
    max-width: 97%;
    background: rgba(255, 255, 255, .7);
    border-radius: 2px;
}

.heroHomepage-title {
    margin-top: 0;
    font-size: 2rem;
    text-transform: uppercase;
    color: #333;
}

.heroHomepage-subtitle {
    font-family: $frutiger-regular;
    font-size: 1.5rem;
    color: #333;
}

.heroHomepage-icon {
    float: right;
}

.heroHomepage-copyright {
    position: absolute;
    left: 20px;
    bottom: 10px;
}

@media (min-width: $screen-sm-min) {
    .heroHomepage .carousel-indicators {
        display: block;
        bottom: 10px;

        li {
            border-color: $gray-base;

            &.active {
                background-color: $gray-base;
            }
        }
    }

    .heroHomepage-content {
        max-width: 700px;
        margin-right: 10px;
    }

    .heroHomepage-title {
        font-size: 2.2rem;
    }

    .heroHomepage-subtitle {
        font-size: 1.8rem;
    }
}

@media (min-width: $screen-lg-min) {
    .heroHomepage-content {
        padding: 1.5rem 3rem;
    }

    .heroHomepage-title {
        font-size: 3rem;
    }

    .heroHomepage-subtitle {
        font-size: 2rem;
    }
}

@media (min-width: $screen-xl) {
    .heroHomepage-title {
        font-size: 3.6rem;
    }

    .heroHomepage-subtitle {
        font-size: 2.3rem;
    }
}

// Block title - fix
.front {

    #block-edl-main-edl-main-evenement,
    #block-views-nodequeue-1-block,
    #block-edl-main-edl-main-video,
    #block-edl-audio-edl-audio-soundcloud-home {
        .block-title {
            position: absolute;
            top: 10px;
            left: 20px;
        }
    }
}

/*parallax actu+evenement*/
#parallax-1 {
    position: relative;
    background: #fff;
    padding-top: 20px;

    /*block actualities*/
    #block-views-nodequeue-1-block {
        position: relative;
        height: 205px;
        margin-bottom: $spacer;

        .block-title {
            @include block-title(100px, $rouge, #fff);
        }

        .flex-control-nav {
            bottom: 24px;
            left: 95px;
            text-align: left;
            width: auto;

            li {
                margin: 0 6px 0 0;

                a {
                    @include default-disable();
                    width: 10px;
                    height: 10px;
                    background-repeat: no-repeat;
                    background-image: url("../img/puce-slider.png");
                    background-position: 0 0;
                    background-color: transparent;

                    &.flex-active {
                        background-position: -15px 0;
                    }
                }
            }
        }

        .flex-direction-nav {
            li {
                a {
                    @include opacity(1);
                    height: 42px;
                    width: 20px;
                    margin: 0;
                    text-indent: -9999px;
                    background-repeat: no-repeat;
                    background-image: url("../img/nav-slider.png");
                    text-align: left;
                    top: 78px;

                    &.flex-prev {
                        left: 17px;
                        background-position: 0 0;
                    }

                    &.flex-next {
                        left: 65px;
                        right: auto;
                        background-position: -48px 0;
                    }
                }
            }
        }

        .slides {
            li {
                .gradient-gauche {
                    position: relative;
                    width: 100%;
                    display: block;
                    height: 205px;

                    &:hover {
                        .slider-content-container {
                            &:after {
                                @include transform(translateX(0px));
                            }
                        }
                    }

                    .slider-content-container {
                        overflow: hidden;
                        position: relative;
                        z-index: 1;

                        img {
                            @media (min-width: $tl) and (max-width: $site-size) {
                                width: 100%;
                            }
                        }

                        &:after {
                            @include gradient-color();
                            content: "";
                            display: block;
                            height: 100%;
                            left: 0;
                            position: absolute;
                            top: 0;
                            @include transform(translateX(-20%));
                            @include transition(transform, 1s, ease, 0s);
                            width: 175%;
                            z-index: 0;
                        }

                        .slider-right-container {
                            position: absolute;
                            top: 60px;
                            left: 95px;
                            width: 405px;
                            height: 100px;
                            overflow: hidden;
                            z-index: 3;

                            @media (max-width: $m1) {
                                width: 67%;
                                height: 98px;
                            }

                            @media (max-width: $ms) {
                                top: 50px;
                            }

                            .homeslider-title {
                                margin: 0 0 18px;
                                color: #fff;
                                @include font-style($frutiger-light, 30px, 30px, 0px);

                                @media (max-width: $ms) {
                                    margin: 0 0 5px;
                                    @include font-style($frutiger-light, 24px, 24px, 0px);
                                }
                            }

                            .homeslider-desc {
                                color: #fff;
                                @include font-style($frutiger-light, 16px, 16px, 0px);

                                @media (max-width: $ms) {
                                    @include font-style($frutiger-light, 14px, 16px, 0px);
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    //
    // Actualites
    .homeslider-image {
        img {
            width: 100%;
            height: auto;
        }
    }

    //
    // Bloc Evenement
    //
    #block-edl-main-edl-main-evenement {
        position: relative;
        height: 205px;
        margin-bottom: $spacer;

        .block-title {
            width: 100px;
            color: white;
            background: #fe0404;
        }
    }

    #block-views-recherche-home-block-2 {
        position: relative;
        width: 390px;
        height: 205px;

        @media (min-width: $tp) and (max-width: $t) {
            width: 100%;
        }

        @media (max-width: $m1) {
            margin: 10px 0 0 0;
        }

        &:hover {
            .block-title {
                @include block-title(100px, $rouge, #fff);
            }
        }

        .block-title {
            @include block-title(100px, #fff, $rouge);
        }

        .balayage-gauche {
            @include balayage-gauche($evenement, $bg-hover);

            &:hover {
                .views-field-title {
                    .field-content {
                        color: #fff;
                    }
                }

                .flechete {
                    background-position: 0 -34px;
                }
            }
        }

        .view-content {
            padding: 45px 45px 10px 10px;

            @media (max-width: $m1) {
                padding: 45px 10px 10px;
            }

            .views-field-field-visuel {
                float: left;
                margin: 0 30px 0 0;

                @media (max-width: $m1) {
                    margin: 0 25px 0 0;
                }
            }

            .views-field-title {
                .field-content {
                    color: $rouge-even;
                    @include font-style($frutiger-light, 22px, 22px, 0px);

                    @media (max-width: $m1) {
                        @include font-style($frutiger-light, 32px, 32px, 0px);
                    }

                    @media (max-width: $ms) {
                        @include font-style($frutiger-light, 25px, 25px, 0px);
                    }
                }
            }

            .views-field-field-evenement-description {
                .field-content {
                    margin: 15px 0;
                    color: $noir;
                    @include font-style($frutiger-light, 16px, 16px, 0px);

                    @media (max-width: $m1) {
                        @include font-style($frutiger-light, 20px, 20px, 0px);
                        margin: 5px 0;
                    }

                    @media (max-width: $ms) {
                        @include font-style($frutiger-light, 15px, 15px, 0px);
                        margin: 7px 0;
                    }
                }
            }

            .flechete {
                background-position: 0 0;
            }
        }
    }
}

/*parallax 3 blocs*/
#parallax-2 {
    #block-edl-main-edl-block-semaine-home {
        .edl-col {
            height: 100px;
            text-align: center;
            margin-bottom: $spacer;

            /*block nouveautes*/
            &.case-rouge {
                .balayage-gauche {
                    @include balayage-gauche($case-1, $bg-hover);
                }
            }

            /*block dedicaces*/
            &.case-rouge-orange {
                .balayage-gauche {
                    @include balayage-gauche($case-2, $bg-hover);
                }
            }

            /*block envie de lire*/
            &.case-orange {
                .balayage-gauche {
                    @include balayage-gauche($case-3, $bg-hover);
                }
            }

            .balayage-gauche {
                .link-text-container {
                    vertical-align: middle;
                    display: table-cell;
                    height: 100px;
                    width: 45%;

                    span {
                        display: block;
                        color: #fff;

                        @media (max-width: $m1) {
                            display: inline-block;
                        }

                        &.main-text {
                            @include font-style($frutiger-light, 42px, 42px, 0px);

                            @media (max-width: $mm) {
                                @include font-style($frutiger-light, 35px, 35px, 0px);
                            }

                            @media (max-width: $ml) {
                                @include font-style($frutiger-light, 25px, 25px, 0px);
                            }

                            @media (max-width: $mmm) {
                                @include font-style($frutiger-light, 20px, 20px, 0px);
                            }
                        }

                        &.sub-text {
                            @include font-style($frutiger-light, 20px, 20px, 0px);
                            font-style: italic;

                            @media (max-width: $m1) {
                                @include font-style($frutiger-light, 42px, 42px, 0px);
                                font-style: normal;
                                margin: 0 0 0 10px;
                            }

                            @media (max-width: $mm) {
                                @include font-style($frutiger-light, 35px, 35px, 0px);
                            }

                            @media (max-width: $ml) {
                                @include font-style($frutiger-light, 25px, 25px, 0px);
                            }

                            @media (max-width: $mmm) {
                                @include font-style($frutiger-light, 20px, 20px, 0px);
                            }

                        }
                    }
                }

            }
        }
    }
}

#parallax-3 {
    padding-bottom: 6rem;
}

/*new block mobile*/
#block-views-collection-block-2,
#block-views-bonus-home-block-2 {
    height: auto !important;

    .ui-accordion-header {
        .ui-accordion-header-icon {
            display: none;
        }
    }

    .ui-accordion-content {
        height: auto !important;
        @include default-disable();
        padding: 0;
    }
}

#block-views-collection-block-2 {
    margin: 10px 0 0 0;

    .ui-accordion-header {
        @include accordion-title($bg-hover);
    }

    .ui-accordion-content {

        .views-row {
            &.views-row-odd {
                background: $evenement;
            }

            &.views-row-even {
                background: $bg-balayage;
            }

            a {
                padding: 10px 5px 10px 40px;
                @include font-style($frutiger-roman, 26px, 26px, 0px);
                color: $noir;

                @media (max-width: $ms) {
                    padding: 10px 5px 10px 20px;
                    @include font-style($frutiger-roman, 22px, 22px, 0px);
                }
            }
        }
    }
}

#block-views-bonus-home-block-2 {
    .ui-accordion-header {
        @include accordion-title($accordion);
    }

    .views-row {
        padding: 10px;

        .field-content {
            position: relative;
            display: block;

            img {
                width: 100%;
                height: auto;
            }

            .bonus-description {
                bottom: 38px;
                left: 10px;
                position: absolute;
                width: 100%;

                @media (max-width: $mmm) {
                    bottom: 33px;
                }

                .bonus-picto {
                    margin: 0 auto 1%;

                    @media (max-width: $mmm) {
                        background-size: 100% 100%;
                        width: 48px;
                        height: 48px;
                    }
                }

                .telechager {
                    @include font-style($frutiger-light, 32px, 32px, 0px);
                    color: #fff;

                    @media (max-width: $ms) {
                        @include font-style($frutiger-light, 25px, 25px, 0px);
                    }

                    @media (max-width: $mmm) {
                        @include font-style($frutiger-light, 17px, 17px, 0px);
                    }
                }
            }

            .voir {
                text-align: center;
                margin: 5px 0 0 0;
                @include font-style($frutiger-light, 24px, 24px, 0px);
                color: $noir;

                @media (max-width: $ms) {
                    @include font-style($frutiger-light, 20px, 20px, 0px);
                }
            }
        }
    }
}


#navbar .header-container .navbar-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    float: none;
}

#main-menu ul {
    position: relative;
}

#main-menu .main-menu-second {
    margin-top: 20px;

    li {
        border-radius: 8px;

        a {
            @include font-style($frutiger-bold, 16px, 20px, 0);
        }
    }
}

#main-menu .main-menu-first {
    ul.nav {
        &>li {
            border-bottom: 1px solid #75B1D2;
            display: flex;
            justify-content: space-between;

            >a {
                display: flex;
                width: 100%;
            }
        }

        &>li {
            &.dropdown {
                >a {
                    &::after {
                        width: 24px;
                        height: 24px;
                        display: inline-block;
                        content: '';
                        -webkit-mask: url('../img/arrow_forward.svg') no-repeat 50% 50%;
                        mask: url('../img/arrow_forward.svg') no-repeat 50% 50%;
                        -webkit-mask-size: cover;
                        mask-size: cover;
                        font-size: 26px;
                        transform: rotate(90deg);
                        background-color: #000;
                        margin-left: auto;
                    }

                    &:hover {
                        &::after {
                            background-color: $queen-blue;
                        }
                    }
                }
            }

            &.open {
                >a {
                    &::after {
                        background-color: $queen-blue;
                        transform: rotate(-90deg);
                    }
                }
            }
        }
    }
}

#main-menu ul>li.dropdown:hover:after {
    color: #3F719F;
}

#main-menu ul>li>.dropdown-menu {
    display: none;
}

.menu>li.open>.dropdown-menu {
    display: block;
    height: 100%;
}

#main-menu ul>li>.dropdown-menu li {
    border: 0;
    display: list-item !important;
}

#main-menu ul>li>.dropdown-menu li a {
    text-transform: initial;
    @include font-style($frutiger-roman, 16px, 20px, 0);
    font-weight: 400 !important;
}

#main-menu ul>li a {
    color: black;
    @include font-style($frutiger-roman, 24px, 24px, 0);
    padding: 20px 0px;
}

#main-menu ul.dropdown-menu {
    li:first-child a {
        padding: 0px 0px 10px;
    }

    li:not(:first-child, :last-child) a {
        padding: 10px 0px 10px;
    }

    li:last-child a {
        padding: 10px 0px 20px;
    }
}

#main-menu ul>li.livres-jeux---2 a {
    padding: 0px 0px 20px 0px;
}

#main-menu .main-menu-first li.open a,
#main-menu ul>li a:hover,
#main-menu ul>li:hover>a,
.nav .open>a,
.nav .open>a:focus,
.nav .open>a:hover {
    color: #3F719F;
    background: transparent;
    font-weight: bold;
}

#main-menu .nav>li>a:focus {
    background-color: transparent;
}

#main-menu .main-menu-first li.open::after {
    background-color: $queen-blue;
}

#main-menu .main-menu-second li {
    font-size: 16px;
    background-color: white;
    border: 0;
    margin-bottom: 12px;
}

#main-menu .main-menu-second li a {
    font-size: 16px;
    font-weight: bold;
    padding: 8px 0px 8px 12px;
}

#main-menu {
    display: none;
    width: 100%;
    background-color: #F1F1F1;
}

/* Afficher le menu lorsque la classe 'active' est présente */
#main-menu.active {
    display: flex;
    padding: 20px;
    justify-content: space-between;
    -webkit-box-shadow: 0px 5px 12px 0px rgba(0, 0, 0, 0.5);
    box-shadow: 0px 5px 12px 0px rgba(0, 0, 0, 0.5);
    margin-top: 1px;
}

/* Style du bouton hamburger */
#menu-toggle {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 50px;
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
    box-sizing: border-box;
    position: relative;
}

/* Barres du bouton hamburger */
#menu-toggle .icon-bar {
    width: 20px;
    height: 2px;
    background-color: white;
    transition: all 0.3s ease;
    margin: 2px 0;
}

.menu-text {
    font-size: 10px;
    color: white;
    margin-top: 5px;
    display: none;
}

.visually-hidden {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
}

/* État actif du bouton */
#menu-toggle[aria-expanded="true"] .icon-bar:nth-child(1) {
    transform: rotate(45deg) translate(8px, 8px);
}

#menu-toggle[aria-expanded="true"] .icon-bar:nth-child(2) {
    opacity: 0;
}

#menu-toggle[aria-expanded="true"] .icon-bar:nth-child(3) {
    transform: rotate(-45deg) translate(5px, -5px);
}

#menu-toggle[aria-expanded="true"] .menu-text {
    display: none;
}

#menu-toggle[aria-expanded="true"] .icon-bar {
    margin: 3.5px 0;
}

#main-menu li.open>ul {
    display: block;
    border-radius: 0;
    background: transparent;
    padding: 0;
    margin: 0;
    list-style: disc;
    padding: 0 90px;
}

#edit-title {
    max-width: 220px !important;
}

#block-views-livre-mise-en-avant-block {
    margin-top: 20px;

    span {
        color: #000;
    }
}

.view-livre-mise-en-avant .views-row {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #F1F1F1;
}

.view-livre-mise-en-avant .views-row .views-field-nothing {
    padding: 0 40px;
}

.view-livre-mise-en-avant .views-row .views-field-nothing h1 {
    @include font-style($frutiger-bold, 20px, 24px, 0);
    margin-top: 12px;
}

.view-livre-mise-en-avant .views-row .views-field-nothing h3 {
    font-weight: bold;
    font-size: 32px;
}

.view-livre-mise-en-avant .views-row .views-field-nothing {
    .auteur {
        @include font-style($frutiger-roman, 16px, 20px, 0);
        margin-bottom: 20px;
        display: block;
    }

    .resume {
        @include font-style($frutiger-roman, 14px, 20px, 0);
    }

}

.view-livre-mise-en-avant .views-row .views-field-nothing span.lien {
    width: 100%;
    text-align: center;
    display: block;
    background: #3F719F;
    border-radius: 8px;
    transition: opacity 0.3s ease-in-out;
    /* Animation fluide sur l'opacité */
}

.view-livre-mise-en-avant .views-row .views-field-nothing span.lien:hover {
    opacity: 0.8;
    /* Réduction de l'opacité au survol */
}

.view-livre-mise-en-avant .views-row .views-field-nothing span.lien a {
    color: white;
    display: flex;
    justify-content: center;
    gap: 10px;
    align-items: center;
    padding: 8px 5px;
}

.view-livre-mise-en-avant .views-row .views-field-nothing span.lien a:after {
    content: url(../img/arrow_circle_right.png);
}

#block-edl-main-edl-block-semaine-home .bloc-semaine-home {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 70px;
}

#block-edl-main-edl-block-semaine-home .bloc-semaine-home {
    & img {
        margin-bottom: 20px;
    }
}

#block-edl-main-edl-block-semaine-home .bloc-semaine-home>* {
    flex: 1;
}

#block-edl-main-edl-block-semaine-home .bloc-semaine-home:before {
    content: '';
    background-color: #D9D9D9;
    height: 1px;
    width: 140px;
}

#block-edl-main-edl-block-semaine-home .bloc-semaine-home:after {
    content: '';
    background-color: #D9D9D9;
    height: 1px;
    width: 140px;
}

#block-edl-main-edl-block-semaine-home .bloc-semaine-home>a {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#parallax-2 #block-edl-main-edl-block-semaine-home .balayage-gauche .edl-col {
    transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
    ;
    /* Ajoute une transition fluide */
}

#parallax-2 #block-edl-main-edl-block-semaine-home .balayage-gauche .edl-col:hover {
    transform: scale(1.05);
    /* Agrandit légèrement au survol */
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    /* Effet d'ombre au survol */
}

#parallax-2 #block-edl-main-edl-block-semaine-home .balayage-gauche .link-text-container {
    transition: transform 0.2s ease-in-out, color 0.2s ease-in-out;
}

#parallax-2 #block-edl-main-edl-block-semaine-home .balayage-gauche:hover .link-text-container {
    transform: scale(1.05);
    /* Zoom du texte */
    color: #C00418;
    /* Change la couleur au survol (optionnel) */
}

#block-edl-main-edl-block-semaine-home .bloc-semaine-home>a span {
    color: #000;
}

#parallax-2 #block-edl-main-edl-block-semaine-home .balayage-gauche .edl-col.case-rouge {
    background: #C00418;
    background-image: url(../img/nouveautes.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 70px;
    cursor: pointer;
    display: block;
    overflow: hidden;
    position: relative;
    vertical-align: middle;
    white-space: normal;
    z-index: 1;
    width: 120px;
    height: 120px;
    border-radius: 60px;
}

#parallax-2 #block-edl-main-edl-block-semaine-home .balayage-gauche .edl-col.case-rouge-orange {
    background: #FF4E00;
    background-image: url(../img/albums.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 50px;
    cursor: pointer;
    display: block;
    overflow: hidden;
    position: relative;
    vertical-align: middle;
    white-space: normal;
    z-index: 1;
    width: 120px;
    height: 120px;
    border-radius: 60px;
}

#parallax-2 #block-edl-main-edl-block-semaine-home .balayage-gauche .edl-col.case-orange {
    background: #FD7A08;
    background-image: url(../img/romans.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 70px;
    cursor: pointer;
    display: block;
    overflow: hidden;
    position: relative;
    vertical-align: middle;
    white-space: normal;
    z-index: 1;
    width: 120px;
    height: 120px;
    border-radius: 60px;
}

#parallax-2 #block-edl-main-edl-block-semaine-home .balayage-gauche .edl-col.case-jaune {
    background: #FFB200;
    background-image: url(../img/jeux&jouets.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 70px;
    cursor: pointer;
    display: block;
    overflow: hidden;
    position: relative;
    vertical-align: middle;
    white-space: normal;
    z-index: 1;
    width: 120px;
    height: 120px;
    border-radius: 60px;
}

.front .main-container .block:not(.block-views-livre-mise-en-avant-block) {
    margin-top: 40px;
}

.front .main-container {
    padding-bottom: 40px;
}

.front .block h2:not(.view-actualites-title-wrapper > h2) {
    margin: 0 0 16px;
    color: #3F719F;
    @include font-style($frutiger-bold, 20px, 28px, 0);
    text-transform: initial;
}

.view-actualites-title-wrapper {
    margin: 0 0 16px;

    h2 {
        margin: 0;
        @include font-style($frutiger-bold, 20px, 28px, 0);
        color: #3F719F;
    }
}

#block-views-actualites-home .view-actualites {
    display: flex;
    flex-direction: column;
}

#block-views-actualites-home .view-actualites .more-link {
    text-decoration: underline $queen-blue;
    text-align: center;
    margin-top: 16px;
    @include font-style($frutiger-bold, 14px, 20px, 0);
}

.more-link-desktop {
    display: none;
}

.more-link-mobile {
    display: block;
}

#block-views-actualites-home .view-content {
    display: flex;
    gap: 20px;
    justify-content: space-between;
}

#block-views-actualites-home .view-content .views-row {
    flex: 0 33%;
    display: flex;
    flex-direction: column;
    background: #F1F1F1;
    padding: 16px;
    position: relative;
    transition: opacity 0.3s ease-in-out;
    /* Animation fluide sur l'opacité */
    border-radius: 8px;
}

#block-views-actualites-home .view-content .views-row:hover {
    opacity: 0.8;
    /* Réduction de l'opacité au survol */
}

#block-views-actualites-home .view-content .views-field-field-actu-visuel img {
    width: 100%;
    max-height: 224px;
    object-fit: cover;
}

#block-views-actualites-home .view-content .views-field-title {
    margin: 16px 0 8px 0;
    @include font-style($frutiger-bold, 16px, 22px, 0);
}

#block-views-actualites-home .view-content .views-field-title a {
    font-weight: bold;
    color: black;
    font-size: 16px;
}

#block-views-actualites-home .view-content .views-field-nothing a {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 2;
    /* S'assure que le lien couvre tout */
}

#block-block-9 {
    display: flex;

    & .accroche {
        @include font-style($frutiger-roman, 14px, 20px, 0);
        margin: 0;
    }
}

#block-block-9 .gauche {
    flex: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: rgb(38, 63, 73);
    border-radius: 10px 0 0 10px;
    color: white;
    justify-content: center;
    padding: 0 40px;
    text-align: center;
}


#block-block-9 .gauche h3 {
    text-transform: uppercase;
    @include font-style($frutiger-roman, 17px, 20px, 0);
    margin-top: 0;
}

#block-block-9 .gauche img {
    max-width: 151px;
    margin-bottom: 16px;
}

#block-block-9 .droite img {
    width: 100%;
    position: relative;
    border-radius: 0 10px 10px 0;
    height: 100%;
    object-fit: cover;
}

#block-block-9 .droite {
    flex: 50%;
    max-width: 100%;
    position: relative;
    border-radius: 0 10px 10px 0;
}

#block-block-9 .droite:after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    /* Hauteur du dégradé : à ajuster selon l'effet voulu */
    height: 15%;

    /* Dégradé du transparent (en haut) vers la couleur du fond (en bas) */
    background: linear-gradient(to bottom,
            rgba(255, 255, 255, 0) 0%,
            #263F49 80%);
    /* 
     Remplacez #f2f2f2 par la couleur grise de votre bloc 
     (ou un autre code couleur correspondant à votre design).
  */
}

#block-block-9 .gauche span.lien {
    @include font-style($frutiger-bold, 16px, 24px, 0);
    width: 100%;
    text-align: center;
    display: block;
    background: #DC3428;
    border-radius: 8px;
    margin: 16px 0 20px;
    font-weight: bold;
    transition: opacity 0.3s ease-in-out;
    /* Animation fluide sur l'opacité */
}

#block-block-9 .gauche span.lien:hover {
    opacity: 0.8;
    /* Réduction de l'opacité au survol */
}

#block-block-9 .gauche span.lien a {
    color: white;
    display: flex;
    justify-content: center;
    gap: 10px;
    align-items: center;
    padding: 8px 18px;
}

#block-block-9 .gauche span.lien a:after {
    content: url(../img/arrow_circle_right.png);
}

#block-block-10 .age {
    display: flex;
    justify-content: space-between;
    @include font-style($titan-one, 20px, 30px, 0)
}

#block-block-10 .age a {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
    color: white;
    text-align: center;
    padding: 0 15px;
    line-height: 35px;
    transition: transform 0.1s ease-in-out, box-shadow 0.3s ease-in-out;
    /* Animation fluide */
}

#block-block-10 .age .swiper-slide {
    transition: transform 0.1s ease-in-out, box-shadow 0.3s ease-in-out;

    &:hover {
        transform: scale(1.02);
        /* Effet de zoom */
        box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
        /* Effet d'ombre au survol */
    }
}

#block-block-10 .age a {
    transition: transform 220ms ease-in-out;
}

#block-block-10 .age a:hover {
    transform: scale(1.05);
    /* Zoom du texte au survol */
}

#block-block-10 .age .zero-trois {
    background: #FFB200;
}

#block-block-10 .age .trois-six {
    background: #FD7A08;
}

#block-block-10 .age .six-huit {
    background: #FF4E00;
}

#block-block-10 .age .huit-onze {
    background: #C00418;
}

#block-block-10 .age .onze-treize {
    background: #622C8F;
}

#block-block-10 .age .treize {
    background: #185287;
}

#block-block-11 {
    display: flex;
}

#block-block-11 .gauche {
    flex: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #f1f1f1;
    border-radius: 10px 0 0 10px;
    color: black;
    justify-content: space-between;
    padding: 0 80px;
    text-align: center;
}


#block-block-11 .gauche h3 {
    color: #3F719F;
    @include font-style($frutiger-bold, 20px, 28px, 0);
    margin: 0;
}

#block-block-11 .gauche .accroche {
    margin: 8px 0 0;
    @include font-style($frutiger-roman, 14px, 20px, 0);
}

#block-block-11 .gauche img {
    width: 220px;
    margin: -5px 0 15px;
}

#block-block-11>div img {
    width: 100%;
    position: relative;
    border-radius: 0 10px 10px 0;
    height: 260px;
    object-fit: cover;
}

#block-block-11 .droite {
    flex: 50%;
    max-width: 100%;
    position: relative;
    border-radius: 0 10px 10px 0;
}

#block-block-11 .droite:after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 15%;
    background: linear-gradient(to bottom,
            rgba(255, 255, 255, 0) 0%,
            #F1F1F1 80%);
}

#block-block-11 .gauche span.lien {
    width: 100%;
    text-align: center;
    display: block;
    background: #DC3428;
    border-radius: 8px;
    margin-top: 16px;
    transition: opacity 0.3s ease-in-out;
    /* Animation fluide sur l'opacité */
}

#block-block-11 .gauche span.lien:hover {
    opacity: 0.8;
    /* Réduction de l'opacité au survol */
}

#block-block-11 .gauche span.lien a {
    color: white;
    display: flex;
    justify-content: center;
    gap: 10px;
    align-items: center;
    padding: 8px 5px;
    @include font-style($frutiger-roman, 16px, 24px, 0);
}

#block-block-11 .gauche span.lien a:after {
    content: url(../img/arrow_circle_right.png);
}

#block-views-page-h-ros-block .view-page-h-ros>div {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
}

#block-views-page-h-ros-block .view-page-h-ros .views-field-field-vignette img {
    transition: transform 0.2s ease-in-out;
}

#block-views-page-h-ros-block .view-page-h-ros .views-field-field-vignette img:hover {
    transform: scale(1.05);
    /* Effet de zoom */
}

.footer-top {
    background-color: #062f45 !important;
}

.footer-top a {
    color: white;
}

.footer-top .row>div {
    display: flex;
    justify-content: space-between;

    strong {
        font-weight: normal;
        display: contents;
    }
}

.footer-top .row>div>section {
    flex: 1;
    border-left: 1px solid #5D6371;
    padding: 0 40px 0 40px;
    margin: 20px 0 10px;
}

.footer-top .row>div>section:first-child {
    border-left: 0;
}

footer .contact {
    background: initial;
    position: initial;
    height: auto;
    top: -13px;
    padding: initial;
    right: 0;
    display: flex;
    align-items: center;
}

footer .block>span {
    font-size: 12px !important;
    color: white !important;
    margin: 15px 0 !important;
    display: block;
}

footer .nav {
    display: flex;
    flex-direction: column;
}

footer .nav a {
    padding: 10px 0;
    font-size: 12px;
}

footer .nav a:hover {
    background: none;
    font-weight: bold;
}

footer #block-menu-menu-lien-footer-top .nav a {
    font-size: 16px;
}

footer #block-menu-menu-lien-footer-top .nav a:hover {
    background: none;
    font-weight: bold;
}

footer .footer-middle {
    background: #062f45;
    border-top: 1px solid #5D6371;
}

footer .footer-middle .row>div {
    background: #062f45;
    display: flex;
    justify-content: space-between;
    color: white;
}

footer .footer-middle ul.nav {
    flex-direction: row;
}

footer .footer-middle ul.nav li {
    border-right: 1px solid white;
}

footer .footer-middle ul.nav li:last-child {
    border-right: 0;
}

footer .footer-middle ul.nav li a {
    color: white;
    padding: 0 20px;
}

footer #block-menu-block-2 {
    position: absolute;
    bottom: 0;
    border: 0;
}

footer #block-menu-block-2 ul.nav {
    flex-direction: row;
    gap: 20px;
    align-items: center;
}

footer #block-menu-block-2 li.facebook a {
    content: url('../img/FB.png');
    font-size: 0
}

footer #block-menu-block-2 li.x a {
    content: url('../img/X.png');
    font-size: 0
}

footer #block-menu-block-2 li.instagram a {
    content: url('../img/Instagram.png');
    font-size: 0
}

footer #block-menu-block-2 li.youtube a {
    content: url('../img/Youtube.png');
    font-size: 0
}

.bloc-login .lien_favoris {
    display: none;
}

#block-edl-recherche-avance-edl-recherche-bloc-top.visible {
    transform: translateX(0) !important;
}

.bloc-login .lien_panier,
.bloc-login .dropdown-account,
.bloc-login .mon-compte,
.lien_recherche {
    margin: 0;
    display: flex;
    color: white;
    flex-direction: column;
    align-items: center;
    font-size: 10px;
    width: auto;
    height: auto;
    justify-content: center;
}

#navbar .header-container .region-recherche {
    display: flex;
}

#navbar .block-edl-recherche-avance {
    margin: 0;
    position: absolute;
    bottom: -50px;
    right: 0;
    background-color: $bluefonce;
    padding: 0 20px;
    transition: transform 320ms ease-in-out;
    transform: translateX(9999px);
}

.header-cta-search {
    background-image: url('../img/search.svg');
    background-position: initial;
    background-size: contain;
}

.bloc-login .lien_panier .nbProduitsPanier {
    width: 10px;
    height: 10px;
    font-size: 9px;
}

.bloc-login #dropdownMenu1 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    background-color: transparent;
    border: none;
    height: 40px;
}

.bloc-login .bt_deconnect,
.bloc-login .dropdown-account,
.bloc-login .lien_panier,
.bloc-login .mon-compte {
    margin-left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.swiper {
    flex-direction: column;
}

.age .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
    border-radius: 12px;
}

.age .swiper-slide a {
    display: block;
    width: 100%;
    height: 100%;
}

.swiper-pagination-progressbar {
    position: initial !important;
    width: 50% !important;
    margin: 30px auto 0;
    background: #F1F1F1 !important;
    border-radius: 8px;
}

.swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
    background-color: #3F719F !important;
    border-radius: 8px;
}

@media (max-width:992px) {
    .main-menu-sub {
        display: none;
    }

    #main-menu {
        .main-menu-container {
            width: 100%;
        }
    }

    #navbar .block-edl-recherche-avance {
        width: 100%;

        .views-exposed-widget {
            display: flex;
            justify-content: center;
        }
    }

    .view-livre-mise-en-avant .views-row {
        flex-direction: column;
    }

    .view-livre-mise-en-avant .views-row .views-field-field-couverture img {
        height: 320px;
        width: 100%;
        object-fit: cover;
    }

    .view-livre-mise-en-avant .views-row .views-field-nothing {
        padding: 0 12px;
    }

    .view-livre-mise-en-avant .views-row .views-field-nothing h3 {
        font-size: 22px;
    }

    .view-livre-mise-en-avant .views-row .views-field-nothing span.lien {
        margin-bottom: 12px;
    }

    #block-edl-main-edl-block-semaine-home .bloc-semaine-home {
        gap: 20px;
    }

    #parallax-2 #block-edl-main-edl-block-semaine-home .balayage-gauche>div {
        width: 64px !important;
        height: 64px !important;
        background-size: 40px !important;
        text-align: center;
    }

    #block-edl-main-edl-block-semaine-home .bloc-semaine-home::before,
    #block-edl-main-edl-block-semaine-home .bloc-semaine-home::after {
        display: none;
    }

    #block-edl-main-edl-block-semaine-home .bloc-semaine-home>a span {
        font-size: 14px;
    }

    #block-edl-main-edl-block-semaine-home .bloc-semaine-home {
        & img {
            width: 64px;
            margin-bottom: 12px;
        }
    }

    #block-block-9 {
        flex-direction: column;
    }

    #block-block-9 .gauche {
        border-radius: 0 0 8px 8px;
        padding: 0 20px;
        order: 2;
    }

    #block-block-9 .droite img {
        height: 200px;
        border-radius: 8px 8px 0 0;
    }

    #block-block-10 .age a {
        font-size: 20px;
        line-height: 20px;
        padding: 0;
    }

    #block-views-page-h-ros-block .view-page-h-ros>div img {
        max-width: 320px;
    }

    #block-block-11 {
        flex-direction: column;
    }

    #block-block-11 .gauche {
        border-radius: 0 0 8px 8px;
        padding: 0 20px 20px;
        order: 2;
    }

    #block-block-11>div img {
        height: 200px;
        border-radius: 8px 8px 0 0;
    }

    #block-block-11 .droite::before {
        display: none;
    }

    .front .main-container .block {
        padding: 0 20px;
    }

    #block-block-12 {
        display: none;
    }

    footer .footer-top #block-menu-menu-lien-footer-top {
        padding: 0;
    }

    .footer-top .row>div {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        padding: 0 20px;
    }

    .footer-top .row>div>section {
        border-left: 0;
        padding: 0;
        margin: 5px 0;
        border-top: 1px solid #3F719F;
    }

    footer .footer-middle .row>div {
        flex-direction: column;
        align-items: center;
    }

    footer .footer-middle ul.nav {
        flex-wrap: wrap;
        justify-content: center;
        gap: 15px;
    }

    #block-block-13 {
        order: 2;
        margin-top: 30px;
    }

    footer .footer-top {
        padding: 0;
    }

    footer .footer-middle {
        background: #062f45;
        border-top: 0;
        padding: 0;
    }

    footer .footer-middle .row>div {
        flex-direction: column;
        align-items: center;
        border-top: 1px solid #3F719F;
        padding: 20px 0;
    }

    .view-actualites .swiper-slide {
        height: auto;
    }

    #block-views-actualites-home .view-content .views-field-field-actu-visuel img {
        width: 100%;
        height: 160px;
    }

    #block-views-actualites-home .view-content .views-row {
        padding: 12px;
    }

    #block-views-actualites-home .view-content {
        max-width: 100%;
    }

    #main-menu li.open>ul {
        position: initial;
        padding: 0 10px;
    }

    #main-menu .main-menu-first {

        li {
            flex-direction: column;
            align-items: flex-start;
            position: relative;
        }

        li.dropdown {
            &.open {
                >a {
                    &::after {
                        transform: rotate(-90deg);
                    }
                }

            }
        }
    }

    footer #block-menu-block-2 {
        position: initial;
    }

    footer #block-menu-block-2 ul.nav {
        justify-content: center;
    }

}

@media (max-width:768px) {
    #block-views-actualites-home .view-content .views-row {
        height: 100%;
    }
}

@media (min-width:768px) {
    .front .main-container {
        padding-bottom: 62px;
    }

    #main-menu ul>li:last-child {
        border: 0;
    }

    #menu-toggle {
        align-self: flex-end;
        height: initial;

        .icon-bar {
            width: 30px;
            height: 3px;
        }
    }

    .menu-text {
        display: block;
        color: #FFF;
        margin-top: 10px;
        @include font-style($frutiger-roman, 10px, 10px, 1px);
    }

    #menu-toggle[aria-expanded="true"] .menu-text {
        display: block;
    }

    #menu-toggle[aria-expanded=true] .icon-bar {
        margin: 3px 0;
    }

    .front .main-container .block {
        width: 735px;
        margin-left: auto;
        margin-right: auto;

        &:not(.block-views-livre-mise-en-avant-block) {
            margin-top: 80px;
        }
    }

    .view-livre-mise-en-avant .views-row .views-field-nothing {
        .auteur {
            @include font-style($frutiger-roman, 20px, 28px, 0);
        }

        .resume {
            @include font-style($frutiger-roman, 16px, 24px, 0);
        }

    }

    .front .block h2:not(.view-actualites-title-wrapper > h2) {
        margin: 0 0 40px;
        @include font-style($frutiger-bold, 32px, 40px, 0);
    }

    .view-livre-mise-en-avant .views-row .views-field-nothing h1 {
        @include font-style($frutiger-bold, 32px, 40px, 0);
    }

    #block-views-actualites-home .view-content .views-field-title {
        margin: 16px 0 12px 0;
        @include font-style($frutiger-bold, 20px, 28px, 0);
    }

    #block-views-livre-mise-en-avant-block {
        margin-top: 60px;
    }

    .view-livre-mise-en-avant .views-row .views-field-field-couverture {
        width: 66%;

        & img {
            width: 100%;
            height: auto;
        }
    }

    .view-livre-mise-en-avant .views-row .views-field-nothing {
        width: 34%;
    }

    #block-edl-main-edl-block-semaine-home .bloc-semaine-home>a span {
        font-size: 20px;
    }

    .view-actualites-title-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 40px;

        h2 {
            margin: 0;
            @include font-style($frutiger-bold, 32px, 40px, 0);
            color: #3F719F;
        }
    }

    .more-link-desktop {
        display: block;
    }

    .more-link-mobile {
        display: none;
    }

    #block-block-10 .age a {
        width: 102px;
        height: 88px;
        font-size: 20px;
    }

    #block-views-page-h-ros-block .view-page-h-ros>div img {
        max-width: 380px;
    }

    #block-block-11 .droite:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 30%;
        background: linear-gradient(to left,
                rgba(255, 255, 255, 0) 0%,
                #F1F1F1 80%);
        z-index: 1;
    }
}

@media (min-width:992px) {
    .front .main-container .block {
        width: 955px;
    }

    #block-block-9 .droite {
        &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 30%;
            background: linear-gradient(to left,
                    rgba(255, 255, 255, 0) 0%,
                    #263F49 80%);
            z-index: 1;
        }

        &:after {
            display: none;
        }
    }

    #block-block-9 .gauche {
        padding: 28px 40px;
        justify-content: space-between;

        img {
            max-width: 179px;
        }

        span.lien {
            margin-bottom: 0;
        }
    }

    #block-block-10 .age a {
        width: 122px;
        height: 108px;
        font-size: 30px;
    }

    #block-block-11 .droite:after {
        display: none;
    }

    #block-block-11 .gauche {
        padding: 48px 40px;
    }

    #main-menu.active {
        padding: 40px 0 40px;

        .main-menu-container {
            display: flex;
            justify-content: space-between;
        }

        .main-menu-first {
            min-width: 250px;

            ul.nav {
                &>li {
                    &.dropdown {
                        >a {
                            &::after {
                                transform: rotate(0deg);
                            }
                        }
                    }

                    &.open {
                        >a {
                            &::after {
                                transform: rotate(0deg);
                            }
                        }
                    }
                }
            }

            a {
                font-size: 28px;
            }
        }

        .main-menu-sub {
            margin-left: 60px;
        }

        .main-menu-second {
            min-width: 250px;
            margin-left: auto;
            margin-top: 0;
        }

        .dropdown-menu-2 {
            list-style: disc;

            a {
                padding: 0;
                @include font-style($frutiger-regular, 16px, 20px, 0);
            }

            li:not(:last-child) {
                padding-bottom: 24px;
            }
        }
    }

    .main-menu-first .dropdown-menu {
        display: none !important;
    }
}

@media (min-width:1440px) {
    #main-menu.active {
        .main-menu-first {
            min-width: 350px;
        }

        .main-menu-second {
            min-width: 350px;
        }
    }

    .front .main-container .block {
        width: 1200px;
    }

    #block-views-page-h-ros-block .view-page-h-ros>div {
        justify-content: space-between;
    }

    #block-edl-main-edl-block-semaine-home .bloc-semaine-home {
        gap: 63px;

        a span {
            font-size: 24px;
        }
    }

    #block-views-actualites-home .view-content .views-field-field-actu-visuel img {
        max-height: 352px;
        height: 352px;
        width: 352px;
    }

    #block-block-9 .gauche,
    #block-block-11 .gauche {
        flex: 40%;
        padding: 28px 80px;
    }

    #block-block-9 .droite,
    #block-block-11 .droite {
        flex: 60%;
    }

    #block-block-10 .age a {
        width: 164px;
        height: 136px;
        font-size: 40px;
    }
}