// Header - flexslider
.field-name-field-diaporama-image {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
.field-name-field-diaporama-titre {
  position: relative;
  display: none;
  font-size: 2.8rem;
  font-family: $frutiger-bold;
  color: #fff;
  @media screen and (min-width: $screen-sm) {
    display: block;
  }
}
.field-name-field-diaporama-description {
  position: relative;
  display: none;
  margin-bottom: $grid-gutter-width;
  color: #fff;
  @media screen and (min-width: $screen-sm) {
    max-width: 50%;
    display: block;
  }
}
.field-name-field-diaporama-titre, .field-name-field-diaporama-lien {
  position: relative;
  display: none;
  @media screen and (min-width: $screen-sm) {
    display: block;
  }
}
// Video
.video-background {
  background: #000;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -99;
}
.video-foreground,
.video-background iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  filter: brightness(0.8);
}
// header
.view-boutique {
  position: relative;
}
.shopHome-title {
  background-color: #fff;
  @media screen and (min-width: $screen-sm) {
    position: absolute;
    left: 50%;
    bottom: -5px; //Fix Ipad border video
    transform: translateX(-50%);
    max-width: 90%;
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 4px 4px 0 0;
  }
  h1 {
    margin-top: 30px;
    margin-bottom: 0;
  }
}
.shopHome-lead {
  margin-top: 10px;
  p {
    font-size: 1.25em; //20px/16
    line-height: 1.5;
    color: $gray;
  }
}
//
.node-boutique-accueil {
  // margin-bottom: ($grid-gutter-width*4);
  padding-left: $grid-gutter-width;
  padding-right: $grid-gutter-width;
  @media (min-width: $screen-sm) {
    padding-left: ($grid-gutter-width*2);
    padding-right: ($grid-gutter-width*2);
  }
  hr {
    max-width: 30%;
    margin-top: ($grid-gutter-width*4);
    margin-bottom: ($grid-gutter-width*4);
  }
}
// Category block
.category-block {
  margin-bottom: 30px;
  @media (min-width: $screen-md-min) {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-bottom: 60px;
  }
}
.category-block-header {
  position: relative;
  img {
    width: 100%;
    max-width: 100%;
    height: auto;
    filter: brightness(0.8);
  }
}
.category-block-type {
  position: absolute;
  left: 10px;
  bottom: 10px;
  color: #fff;
}
.category-block-title {
  margin-bottom: 0;
  text-transform: uppercase;
  font-size: 2rem;
}
.category-block-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  padding: ($grid-gutter-width*2) $grid-gutter-width;
  border: 1px solid #d8d8d8;
  @media (min-width: $screen-md) {
    padding: ($grid-gutter-width*2);
    min-height: 250px;
  }
  .btn {
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      margin-right: 15px;
    }
    /*@media (max-width: $screen-sm-max) {
      font-size: 11px;
      img {
        margin-right: 5px;
      }
    }*/
  }
}
@media (min-width: $screen-md) {
  .category-block-center {
    border-left: 0;
    border-right: 0;
  }
}
.category-block-text {
  flex: 1;
}
// Flexslider products - Nouveautés
.flexslider-products-title {
  display: inline-block;
  margin-left: 7.5px;
  font-family: FrutigerLTStd-Bold;
  font-size: 2rem;
  line-height: 30px;
  letter-spacing: 0;
  color: $gray-light;
}
.flexslider-products-all {
  margin-left: $grid-gutter-width;
  font-family: $frutiger-regular;
  font-size: 1.6rem;
  color: $gray;
}
.flexslider-products {
  position: relative;
  margin-bottom: ($grid-gutter-width*2);
  @media screen and (max-width: screen-sm) {
    .flex-viewport {
      max-height: none;
    }
  }
  .flex-direction-nav {
    width: 100%;
    max-width: 100px;
    position: absolute;
    top: -20px;
    right: 0;
    li a {
      opacity: 1 !important;
      top: 0px;
      height: 32px;
      width: 32px;
      text-indent: -9999px;
      background-repeat: no-repeat;
      background-image: url(../img/icones/arrow-gray-light.svg);
      text-align: left;
      &.flex-prev {
        left: 14px; // Fix size icon
        transform: rotate(180deg);
      }
      &.flex-next {
        right: 0;
      }
    }
  }
  .flex-control-nav {
    bottom: 30px;
  }
  .flex-control-paging li a {
    display: none;
  }
  .field-name-field-couverture.field-type-image {
    display: block;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 230px;
    text-align: center;
    border: 1px solid $gray-lighter;
    padding: 5px;
    margin-bottom: ($grid-gutter-width/2);
    img {
      max-height: 220px;
    }
  }
  .card-type {
    font-family: $frutiger-bold;
    font-size: 1.4rem;
    text-transform: capitalize;
    color: $gray;
  }
  .field-name-field-isbn {
    display: none;
  }
}

#block-views-boutique-nouveautes  .card-content {
  @media (min-width: $screen-sm) {
    height: 180px;
  }
  @media (min-width: $screen-lg) {
    height: 160px;
  }
}

// Footer Faq block Désactivé
/*.faq-block {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
  background-image: url("../img/faq/faq-bg.jpg");
  background-size: cover;
  padding: ($grid-gutter-width*3) $grid-gutter-width;
  text-align: center;
}
.faq-block-title {
  margin-bottom: $grid-gutter-width;
  text-align: center;
  font-family: $frutiger-light;
  font-size: 3.6rem;
  color: #fff;
}*/
