/* Général */
@media (max-width: 767px){
	.not-front .region-content {
    padding: 0;
	}
	.header-content {
    max-width: 100%;
	}
	iframe{
		max-width: 100%;
	}
	.article-wrapper h3 {
		display:block;
    white-space: normal;
    width: 100%;
	}
	.subtitle.blue-new{
		text-align:center;
	}
}


/* Header */
.region-custom-header .top-most-header{
	display:none;
}
@media (max-width: 767px){
	#navbar {
		z-index: 15;
	}
	.node-type-livre .catalogue .dropdown-toggle:before {
    display:none;
	}
	.region-custom-header{
		position:relative;
	}
	.navbar-collapse.in {
    overflow-y: visible;
	}
	#navbar .header-container .navbar-collapse .nav > li {
    margin: 0;
    padding: 10px 20px;
	}
	.region-custom-header .top-most-header{
		display: block;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    margin-top: 20px;
	}
}

/* Page catalogue */
@media (max-width: 1190px){
	.page-les-collections .view-collection .result-group-row .image-row .collection-photo a span {
		background-size: auto !important;
	}
}

@media (max-width: 991px){
	.page-catalogue .region-content #block-edl-catalogue-edl-filtre-catalogue .filter-top-level .recherche-catalogue #edl-catalogue-search-form .form-type-textfield input,
	.page-catalogue .region-sidebar-second #block-edl-catalogue-edl-filtre-catalogue .filter-top-level .recherche-catalogue #edl-catalogue-search-form .form-type-textfield input {
		width: 150px;
	}
}
@media (max-width: 767px){
	.page-catalogue .custom-page-header {
    margin: 35px 0 20px;
		height: auto;
	}
	.page-catalogue .region-content #block-edl-catalogue-edl-filtre-catalogue .filter-top-level a,
	.page-catalogue .region-sidebar-second #block-edl-catalogue-edl-filtre-catalogue .filter-top-level a {
		display:block;
		float:none;
	}
	.filters #filter-prix {
    display: none;

	}
	.page-catalogue .region-content #block-edl-catalogue-edl-filtre-catalogue .filter-top-level .recherche-catalogue,
	.page-catalogue .region-sidebar-second #block-edl-catalogue-edl-filtre-catalogue .filter-top-level .recherche-catalogue {
    float: none;
	}
	.page-catalogue .region-content #block-edl-catalogue-edl-filtre-catalogue .filter-top-level .recherche-catalogue .form-type-textfield,
	.page-catalogue .region-sidebar-second #block-edl-catalogue-edl-filtre-catalogue .filter-top-level .recherche-catalogue .form-type-textfield {
		width: auto;
	}
	#edl-catalogue-search-form .form-submit,
	#views-exposed-form-auteur-list-block-1 .form-submit,
	#views-exposed-form-auteur-list-page .form-submit {
		width: 27px;
		height: 27px;
		background-size: cover;
	}
	.page-catalogue .region-content #block-edl-catalogue-edl-filtre-catalogue .filter-top-level .recherche-catalogue #edl-catalogue-search-form .form-type-textfield input,
	.page-catalogue .region-sidebar-second #block-edl-catalogue-edl-filtre-catalogue .filter-top-level .recherche-catalogue #edl-catalogue-search-form .form-type-textfield input {
    height:27px;
	}
	.page-catalogue .region-content #block-edl-catalogue-edl-filtre-catalogue .low-filter-container,
	.page-catalogue .region-sidebar-second #block-edl-catalogue-edl-filtre-catalogue .low-filter-container {
    width: 100%;
	}
	.page-catalogue .livre-container .livre-image-content img {
    width:auto;
	}
	.page-les-collections .view-collection .result-group-row .image-row .collection-photo {
		max-width: 290px;
		width:100%;
	}
}


/* Liste auteur */
@media (max-width: 767px){
	.page-auteur-list .region-content #block-system-main .view-auteur-list .view-content .views-row.views-row-even,
	.page-auteur-list .region-content #block-views-auteur-list-block-1 .view-auteur-list .view-content .views-row.views-row-even {
		float: left;
	}
	.page-auteur-list .region-content #block-edl-auteur-edl-auteur-liste-alphabetique {
		margin-bottom:0;
	}
	.page-auteur-list .region-content .view-auteur-list {
		padding-top:20px;
		margin-left:-10px;
		margin-right:-10px;
	}
	.page-auteur-list .region-content #block-system-main .view-auteur-list .view-content .views-row,
	.page-auteur-list .region-content #block-views-auteur-list-block-1 .view-auteur-list .view-content .views-row {
    float: left !important;
		width:50% !important;
		margin:0 !important;
		padding:0 10px !important;
	}
	.page-auteur-list .region-content .view-auteur-list .views-field-field-auteur-nom,
	.page-auteur-list .region-content .view-auteur-list .views-field-field-auteur-prenom{
		display:none;
	}
}


/* Page auteur */
@media (max-width: 767px){
	.node-type-auteur #block-edl-auteur-edl-auteur-bonus .all-bonus {
		padding: 10px 0 0;
	}
	.node-type-auteur #block-edl-auteur-edl-auteur-bonus .lien-download a, .node-type-auteur #block-edl-auteur-edl-auteur-bonus .titre-video {
		font-size: 16px;
		line-height: 1.2;
		position:relative;
		bottom:auto;
	}
	.node-type-auteur .main-container .col-sm-9 #block-edl-auteur-edl-auteur-livres .tab-content #tab1 .table-auteurs table,
	.node-type-auteur .main-container .col-sm-9 #block-edl-auteur-edl-auteur-livres-mobile .tab-content #tab1 .table-auteurs table{
		width:100%;
		table-layout : fixed;
		padding:0 15px;
	}
	.node-type-auteur .main-container .col-sm-9 #block-edl-auteur-edl-auteur-livres .tab-content #tab1 .table-auteurs table tr th,
	.node-type-auteur .main-container .col-sm-9 #block-edl-auteur-edl-auteur-livres .tab-content #tab1 .table-auteurs table tbody tr,
	.node-type-auteur .main-container .col-sm-9 #block-edl-auteur-edl-auteur-livres-mobile .tab-content #tab1 .table-auteurs table tr th,
	.node-type-auteur .main-container .col-sm-9 #block-edl-auteur-edl-auteur-livres-mobile .tab-content #tab1 .table-auteurs table tbody tr {
		width:25%;
		word-wrap:break-word;
	}
}
@media (max-width: 420px){
	.node-type-auteur .main-container .col-sm-9 #block-edl-auteur-edl-auteur-livres .tab-content #tab1 .table-auteurs table tr th,
	.node-type-auteur .main-container .col-sm-9 #block-edl-auteur-edl-auteur-livres .tab-content #tab1 .table-auteurs table tbody tr td,
	.node-type-auteur .main-container .col-sm-9 #block-edl-auteur-edl-auteur-livres-mobile .tab-content #tab1 .table-auteurs table tr th,
	.node-type-auteur .main-container .col-sm-9 #block-edl-auteur-edl-auteur-livres-mobile .tab-content #tab1 .table-auteurs table tbody tr td{
		width:auto;
		word-wrap:break-word;
	}
	.node-type-auteur .main-container .col-sm-9 #block-edl-auteur-edl-auteur-livres .tab-content #tab1 .table-auteurs table tr th:nth-child(2),
	.node-type-auteur .main-container .col-sm-9 #block-edl-auteur-edl-auteur-livres .tab-content #tab1 .table-auteurs table tbody tr td:nth-child(2),
	.node-type-auteur .main-container .col-sm-9 #block-edl-auteur-edl-auteur-livres-mobile .tab-content #tab1 .table-auteurs table tr th:nth-child(2),
	.node-type-auteur .main-container .col-sm-9 #block-edl-auteur-edl-auteur-livres-mobile .tab-content #tab1 .table-auteurs table tbody tr td:nth-child(2),
	.node-type-auteur .main-container .col-sm-9 #block-edl-auteur-edl-auteur-livres .tab-content #tab1 .table-auteurs table tr th:nth-child(4),
	.node-type-auteur .main-container .col-sm-9 #block-edl-auteur-edl-auteur-livres .tab-content #tab1 .table-auteurs table tbody tr td:nth-child(4),
	.node-type-auteur .main-container .col-sm-9 #block-edl-auteur-edl-auteur-livres-mobile .tab-content #tab1 .table-auteurs table tr th:nth-child(4),
	.node-type-auteur .main-container .col-sm-9 #block-edl-auteur-edl-auteur-livres-mobile .tab-content #tab1 .table-auteurs table tbody tr td:nth-child(4){
		display:none;
	}
}


/* Page livre */
@media (max-width: 991px){
	.node-type-livre #parallax-3 .region-parallax-3 .view-bonus-fiche-livre .view-content .flex-viewport li .views-row{
		margin-left:-15px;
		margin-right:-15px;
	}
	.node-type-livre #parallax-3 .region-parallax-3 .view-bonus-fiche-livre .view-content .flex-viewport li .views-row .left-content,
	.node-type-livre #parallax-3 .region-parallax-3 .view-bonus-fiche-livre .view-content .flex-viewport li .views-row .right-content {
    width: 50%;
		margin: 0;
		padding:0 5px;
	}
	.node-type-livre #parallax-3 .region-parallax-3 .view-bonus-fiche-livre .view-content .flex-viewport li .views-row .left-content img {
    height: auto !important;
    max-width: 100%;
	}
}
@media (max-width: 767px){
	.btn-bonus {
    top:auto;
    bottom: 50px;
	}
}


/* Liste événements */
@media (max-width: 767px){
	.page--evenement .haute-wrapper .view-header .date-nav-wrapper .date-nav ul,
	.page-evenement .haute-wrapper .view-header .date-nav-wrapper .date-nav ul {
		display:block;
		padding:0;
		margin:0 5px;
	}
	.page--evenement .haute-wrapper .view-header .date-nav-wrapper .date-nav ul li.month_filter,
	.page-evenement .haute-wrapper .view-header .date-nav-wrapper .date-nav ul li.month_filter {
    float: left;
		width:60%;
	}
	.page--evenement .haute-wrapper .view-header .date-nav-wrapper .date-nav ul li.date-prev,
	.page-evenement .haute-wrapper .view-header .date-nav-wrapper .date-nav ul li.date-prev {
		width:20%;
		padding-right:10px;
	}
	.page--evenement .haute-wrapper .view-header .date-nav-wrapper .date-nav ul li.date-next,
	.page-evenement .haute-wrapper .view-header .date-nav-wrapper .date-nav ul li.date-next {
		width:20%;
		padding-left:10px;
	}
	.page--evenement .haute-wrapper .view-header .date-nav-wrapper .date-nav ul li.month_filter a,
	.page-evenement .haute-wrapper .view-header .date-nav-wrapper .date-nav ul li.month_filter a {
    max-width:100%;
	}
	.page--evenement .haute-wrapper .view-filters .views-exposed-widgets .views-exposed-widget #pop-filter-list li a,
	.page-evenement .haute-wrapper .view-filters .views-exposed-widgets .views-exposed-widget #pop-filter-list li a {
		display: block;
    color: #a09d9d;
    background-image: none;
		padding: 5px 0 5px 0;
	}
	.page--evenement .haute-wrapper .view-filters .views-exposed-widgets .views-exposed-widget #pop-filter-list li a.active,
	.page--evenement .haute-wrapper .view-filters .views-exposed-widgets .views-exposed-widget #pop-filter-list li a:hover,
	.page-evenement .haute-wrapper .view-filters .views-exposed-widgets .views-exposed-widget #pop-filter-list li a.active,
	.page-evenement .haute-wrapper .view-filters .views-exposed-widgets .views-exposed-widget #pop-filter-list li a:hover {
    color: #702283;
	}
	.page--evenement .col-sm-9 .views-row .details-evenement .siteweb-evenement,
	.page-evenement .col-sm-9 .views-row .details-evenement .siteweb-evenement{
		display: block;
		clear: both;
		padding-top: 5px;
		word-wrap:break-word;
	}
}


/* Page repertoire thematique */
@media (max-width: 767px){
	.page-thematique-liste .cd-accordion-menu {
    width: 100%;
	}
	.page-thematique-liste .cd-accordion-menu label,
	.page-thematique-liste .cd-accordion-menu a {
    padding: 3px 0px 2px 5px;
	}
}



