.node-type-404 {
    section.col-sm-12 {
        padding: 0;
    }

    img {
        width: 100%;
    }

    .field-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 50px 32px 74px 32px;
    }

    .text-wrapper {
        margin-top: 33px;

        p:nth-child(1) {
            @include font-style($frutiger-bold, 40px, 37.75px, 0);
        }
        
        p:nth-child(2) {
            @include font-style($frutiger-bold, 16px, 15px, 0);
            margin-top: 18px;
        }
        
        p:nth-child(3) {
            @include font-style($frutiger-roman, 16px, 15px, 0);
            margin-top: 24px;
        }
    }

    a.btn-arrow-circle-right {
        margin-top: 23px;
        max-width: 320px;
    }
}