.qs-nous {
    margin-top: 8px;

    >p {
        display: none;
    }

    hr {
        border-color: #BDBDBD;
        display: none;
    }

    h2 {
        @include font-style($frutiger-bold, 20px, 100%, 0);
        margin: 0;
        text-transform: uppercase;
        color: $queen-blue;

        button {
            border: none;
            background-color: transparent;
            padding: 0;
            text-transform: uppercase;
            text-align: left;
        }

        &.qs-nous__section-title-desktop {
            display: none;
        }

        &.qs-nous__section-title-mobile button[aria-expanded="true"] {
            span {
                display: inline-block;
                transform: rotate(180deg);
            }
        }


    }

    ul {
        list-style: disc;
        padding: 0 0 0 20px;

        li:not(:last-child) {
            margin-bottom: 10px;
        }
    }

    img {
        width: 100%;
    }

    .qs-nous__section-intro-subtitle {
        @include font-style($frutiger-bold, 16px, 24px, 0px);
    }

    /* ----- NAVIGATION DESKTOP (STICKY) ----- */
    &__nav {
        position: sticky;
        top: 1rem;
        align-self: start;
        display: none;

        p {
            @include font-style($frutiger-bold, 20px, 24px, 0);
            margin-bottom: 12px;
            color: $queen-blue;
        }

        ul {
            li {
                a {
                    @include font-style($frutiger-roman, 16px, 24px, 0);
                    color: #555555;

                    &:hover,
                    &:focus,
                    &.active {
                        @include font-style($frutiger-bold, 16px, 24px, 0);
                    }
                }
            }
        }
    }

    &__section {
        margin-bottom: 40px;

        &-content {
            margin-top: 20px;

            >img+p {
                margin-top: 20px;
            }

            .paragraph-labels {
                @include font-style($frutiger-bold, 16px, 24px, 0);
                text-transform: uppercase;
                margin-bottom: 20px;
            }

            .wrapper-list-image-left,
            .wrapper-list-image-right {
                display: flex;
                flex-direction: column;
                gap: 20px;
                align-items: center;
            }

            .wrapper-list-image-left .image-block img {
                max-width: 400px;
            }

            .wrapper-image-grid,
            .wrapper-image-grid-authors {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                justify-content: center;
                gap: 12px;
                margin-top: 20px;
            }

            .wrapper-image-grid {
                img {
                    width: 252px;
                    height: 252px;
                }
            }

            .wrapper-image-grid-authors {
                margin-bottom: 20px;
            }

            .wrapper-text-image {
                display: flex;
                justify-content: center;
                flex-wrap: wrap;
                gap: 22px;

                img {
                    width: 230px;
                }
            }

            .wrapper-quote {
                color: $queen-blue;
                border: 3px solid $queen-blue;
                border-radius: 8px;
                padding: 20px;
                position: relative;

                img {
                    position: absolute;
                    top: -3px;
                    right: -4px;
                    width: 30px;
                }
            }

            blockquote {
                border-left: none;
                padding: 0;
                margin: 0;
                font-size: 16px;
                font-style: italic;
            }

            .quote-subtitle {
                @include font-style($frutiger-bold, 16px, 24px, 0);
                margin-bottom: 20px;
            }
        }
    }

    #section-international,
    #section-etude {
        a {
            color: #000;
            text-decoration: underline;
        }
    }

    &__section-news {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 28px;
        margin-bottom: 80px;

        p {
            @include font-style($frutiger-bold, 16px, 24px, 0);
            margin: 0;

            &:first-child {
                display: none;
            }
        }

        img {
            width: 40px;
        }

        .wrapper-social-network-icons {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            gap: 25px;

            a {
                @include font-style($frutiger-bold, 12px, 24px, 0);
                color: #000;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
            }
        }
    }
}


@media (min-width: 992px) {
    .qs-nous {
        display: grid;
        grid-template-columns: 1fr 250px;
        column-gap: 40px;
        margin: 2rem auto;
        max-width: 1200px;

        hr {
            display: block;
            margin: 40px 0 40px;
        }

        h2 {
            @include font-style($frutiger-bold, 26px, 100%, 0);

            &.qs-nous__section-title-mobile {
                display: none;
            }

            &.qs-nous__section-title-desktop {
                display: block;
            }
        }

        &__section {
            margin-bottom: 0;

            &-content {
                .wrapper-quote {
                    img {
                        width: 69px;

                    }
                }
            }

            .wrapper-list-image-left,
            .wrapper-list-image-right {
                align-items: flex-start;

                >div {
                    flex: 1;
                }
            }

            .wrapper-list-image-left {
                flex-direction: row-reverse;
            }

            .wrapper-list-image-right {
                flex-direction: row;
            }
        }

        #section-ecouter-content {
            h2 {
                margin-bottom: 33px;
            }
        }

        #section-international {
            .wrapper-text-image {
                flex-direction: row;
                align-items: flex-start;

                h2 {
                    margin-bottom: 20px;
                }

                >div {
                    flex: 1;
                }

                >p:last-child {
                    width: 230px;
                }
            }
        }

        &__nav {
            display: block;
        }

    }
}

@media (min-width: 1440px) {
    .qs-nous {
        column-gap: 140px;
    }
}