.field-name-field-newsletter {
    margin-top: 3rem;
}

.form-item-rgpd-consent {
    margin-bottom: 3rem;

    input[type=checkbox] {
        margin-left: 0;
    }
}