footer {
    position: relative;
    z-index: 1;
    @media (max-width:$m1) {
        margin:0;
    }
    .footer-top {
       background:$noir;
       padding:15px 0;
       #block-menu-menu-lien-footer-top {
        @media (max-width:$m1) {
            padding:0 20px;
        }
         .menu-customised {
            li {
                float: left;
                margin: 0 70px 0 0;
                @media (max-width:$m1) {
                    float: none;
                    margin: 0 0 18px;
                    &.last {
                        margin: 0;
                    }
                }
                 a {
                    color:$textmaron;
                    @include font-style($frutiger-bold,14px,14px,0);
                    text-transform: uppercase;
                        @media (max-width:$m1) {
                            @include font-style($frutiger-bold,18px,18px,0);              
                        }
                    &:hover {
                        color:#fff;
                    }
                 }
            }
         }
       }
    }
    .footer-middle {
       background:#fff;
       padding: 20px 0;
       #block-menu-menu-les-sites-du-groupe-edl ,
       #block-edl-main-edl-main-trouver-librarie ,
       #block-edl-main-edl-main-bloc-sitemap
       {
        float: left;
            @media (max-width:$m1) {
                padding:0 20px;
                float: none;
            }
       }
       
       #block-menu-menu-les-sites-du-groupe-edl {
        width: 400px;
        @media (min-width:$tp) and (max-width:$t) {
            width:345px;
        }
        @media (max-width:$m1) {
            width: auto;
        }
         .block-title {
                @include font-style($frutiger-bold,14px,14px,0);
                color:$noir;
                text-transform: uppercase;
                margin: 0 0 15px;
                display: table;
                @media (max-width:$m1) {
                   @include font-style($frutiger-bold,18px,18px,0.5px); 
                }                
                &:hover {
                    color:$menu-1;
                }
         }
         .menu-customised {
            li {
               a {
                color: $footer;
                @include font-style($frutiger-roman,14px,14px,0);
                @media (max-width:$m1) {
                   @include font-style($frutiger-roman,18px,18px,0.5px); 
                }                  
                span {
                    font-style: italic;
                }
                margin: 0 0 8px;
                    &:hover {
                        color:$menu-1;
                    }
                } 
            }
         }
       }
       #block-edl-main-edl-main-trouver-librarie {
        width: 300px;
        @media (min-width:$tp) and (max-width:$t) {
            width:250px;
        }
        @media (max-width:$m1) {
            margin: 10px 0 0 0;
        }
         .librarie-container {
             li {
                a {
                @include font-style($frutiger-bold,14px,21px,0);
                color:$noir;
                height: 21px;
                text-transform: uppercase;
                margin: -4px 0 15px;
                padding: 0 0 0 20px;
                background-repeat: no-repeat;
                background-image: url("../img/icon-location.png");
                background-position: 0 0;
                display: table;
                 @media (max-width:$m1) {
                   @include font-style($frutiger-bold,18px,66px,0);
                   background-image: url("../img/icon-location-mobile.png");
                   height: 42px;
                   padding: 0 0 0 33px;
                   margin: 0;
                } 
                 &:hover {
                    color:$menu-1;
                 }
                }
             }
         }
       }
       #block-edl-main-edl-main-bloc-sitemap {
         .sitemap-container {
            li {
                a {
                @include font-style($frutiger-bold,14px,14px,0);
                color:$noir;
                text-transform: uppercase;
                margin: 0 0 15px;
                padding: 0;
                display: table;
                @media (max-width:$m1) {
                    @include font-style($frutiger-bold,18px,18px,0.5px);
                    margin: 0;
                } 
                 &:hover {
                    color:$menu-1;
                 }
                }
            }
         }
       }
    }
    .footer-bottom {
       background:$bluefonce;
       padding: 15px 0;
        #block-menu-menu-sites-partenaires {    
            text-align: center;
            @media (max-width:$m1) {
                padding:0 20px;
                text-align: left;
            }
            .block-title {
                display: inline-block;
                @include font-style($frutiger-light,14px,14px,0.5px);
                color:$bluepale;
                text-transform: uppercase;
                margin: 0 35px 0 0;
                padding: 0;
                @media (max-width:$m1) {
                   @include font-style($frutiger-light,18px,18px,0.5px);
                   margin: 0 10px 0 10px;
                }
                &:hover {
                    color:#fff;
                }
            }
            .menu-customised {
                display: inline-block;
                li {
                    a {
                       @include font-style($frutiger-light,14px,14px,0.5px);
                       color:#fff;
                        @media (max-width:$m1) {
                           @include font-style($frutiger-light,18px,18px,0.5px); 
                        }                       
                       &:hover {
                         color:$bluepale;
                       }
                    }
                }
            }
        }
    }
}