// rounded
.btn-rounded-gray {
  padding: 10px 30px;
  background-color: transparent;
  color: $gray-light;
  border: 2px solid $gray-lighter;
  border-radius: 60px;

  &:hover {
    border-color: $gray-light;

    img {
      filter: brightness(0.5);
    }
  }
}

.btn-rounded-white {
  padding: 10px 30px;
  background-color: transparent;
  color: #fff;
  border: 2px solid #fff;
  border-radius: 60px;

  &:hover {
    background-color: #fff;
    color: $gray-light;
  }
}

.btn-rounded-whiteBg {
  padding: 10px 30px;
  background-color: #fff;
  color: $gray-light;
  border: 2px solid #fff;
  border-radius: 60px;

  &:hover {
    background-color: transparent;
    color: #fff;
  }
}

//
.btn-xs {
  padding: 5px 15px;
}

.btn-arrow-circle-right {
  display: inline-flex;
  align-items: center;
  padding: 6px 55px;
  background-color: $queen-blue;
  color: #fff;
  text-decoration: none;
  border-radius: 8px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: $indigo;
    color: #fff;
  }

  &:before {
    content: url("/sites/default/files/404/arrow_circle_right.png");
    margin-right: 8px;
  }
}